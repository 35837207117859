
import { statusOptions, requiresResolution } from 'utils/status-options';

/**
 * categorizeOrders
 *
 * Categorizes selected orders based on whether the target status requires a resolution.
 *
 * @param {Array} orders - The selected repair orders.
 * @param {string} targetStatus - The target status to update the orders to.
 * @returns {Object} - An object containing categorized orders.
 */
export const categorizeOrders = (orders, targetStatus) => {
  const isResolutionRequired = requiresResolution(targetStatus.id) || false;
  const ordersNeedingResolution = [];
  const ordersRequiringRemoval = [];
  const closedOrders = [];

  orders.forEach(order => {
    if (order.deliveredAt) {
      closedOrders.push(order);
      return;
    }
    const hasResolution = !!order.resolutionTypeId;
    if (isResolutionRequired) {
      if (!hasResolution) {
        ordersNeedingResolution.push(order);
      }
    } else {
      if (hasResolution) {
        ordersRequiringRemoval.push(order);
      }
    }


  });

  return {
    isResolutionRequired,
    ordersNeedingResolution,
    ordersRequiringRemoval,
    closedOrders,
  };
};
