import React, { useRef } from "react";
import { useState, useEffect } from 'react';

import {
    Card, CardContent, Typography, Button, Paper, Grid, Divider, InputAdornment,
    OutlinedInput, TableCell, TableRow, Stack, Box,
    Table, TableHead, TableContainer, TableBody, TextField, ToggleButton, Popover
} from "@mui/material";
import { useReactToPrint } from "react-to-print";
import { useSelector } from 'react-redux';
import "./Print.css"

import axios from "../../axios-config";


//ICONS
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { previousDay } from "date-fns";
import { useNavigate } from "react-router-dom";
import ReactiveTextField from "../ReactiveTextField/ReactiveTextField";
import StyledToggleButtonGroup from "../StyledToggleButtonGroup/StyledToggleButtonGroup";
import { Calendar } from "react-date-range";
import UploadFilesDialog from "../UploadFilesDialog/UploadFilesDialog";
import LogoButton from "../Buttons/LogoButton";
import { useTranslation } from "react-i18next";

import { formatCurrency, getCurrencySymbol } from "../../utils/currency";

const InvoiceA4 = React.forwardRef(({ invoice, onUpdateRefId, setInvoice }, ref) => {
    const { t } = useTranslation();

    const dayjs = require('dayjs');
    const navigate = useNavigate();


    // const [taxIncluded, setTaxIncluded] = React.useState(false);
    const [mediaDialogOpen, setMediaDialogOpen] = useState(false);

    //Calendar Popover

    const [anchorEl, setAnchorEl] = React.useState(null);
    const calendarOpen = Boolean(anchorEl);
    const calendarId = calendarOpen ? 'calendar-popover' : undefined;


    const handleCalendarOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCalendarClose = () => {
        setAnchorEl(null);
    };

    const handleCalendarSelect = async (e) => {
        const value = dayjs(e).toISOString();
        const body = { date: value };

        try {
            const resp = await axios.patch(`invoices/date/${invoice.id}`, body);


            if (resp.status !== 200) {
                throw resp.status;
            }
            const newInvoice = { ...invoice };
            newInvoice.date = value;
            setInvoice(newInvoice);
            // handleCalendarClose();
        }
        catch (err) {
            console.log(err)
            // enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        }
    }

    const setInvoiceLogo = async (file) => {
        const body = { fileId: file.id };
        try {
            const resp = await axios.patch(`invoices/logo/${invoice.id}`, body);

            if (resp.status !== 200) {
                throw resp.status;
            }


            const newInvoice = { ...invoice };
            newInvoice.file = file;
            setInvoice(newInvoice);
        }
        catch (err) {
            console.log(err)
            // enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        }
    }


    const paymentTypographyRef = useRef();


    const currency = useSelector((state) => state.auth.currency);
    if (!invoice || !invoice.invoiceItems) {
        return "no invoice";
    }
    const base = invoice.invoiceItems.reduce((prev, current) => prev + parseFloat(current.unitPrice * current.quantity - current.discount), 0)
    let invoiceSubTotal = 0;
    let invoiceTax = 0;
    let invoiceTotal = 0;

    const handleNavigationToOrder = (orderId) => {
        if (!orderId) return;
        navigate(`/editEntry/${orderId}`)
    }


    const handleTaxIncludeToggle = async () => {
        try {
            const newInvoice = { ...invoice };
            const newValue = !newInvoice.taxIncluded;
            newInvoice.taxIncluded = newValue;

            const body = { taxIncluded: newValue };
            const resp = await axios.patch(`invoices/taxIncluded/${invoice.id}`, body);
            if (resp.status !== 200) {
                throw resp.status;
            }

            setInvoice(newInvoice);
        }
        catch (err) {
            console.log(err)
            // enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        }
    }

    const onBlur = async (key, newValue) => {
        const value = newValue;
        const body = { [key]: value };

        try {
            const resp = await axios.patch(`invoices/${key}/${invoice.id}`, body);

            const newInvoice = { ...invoice };
            newInvoice[key] = newValue;
            setInvoice(newInvoice);

            if (resp.status !== 200) {
                throw resp.status;
            }
        }
        catch (err) {
            console.log(err)
            // enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        }
    }


    return (
        <>
            <Paper sx={{ width: "210mm", height: '297mm', marginX: 'auto' }} >
                <Stack height={'100%'} ref={ref}>
                    <Box>
                        <Stack direction={'row'}
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                paddingTop: "25px",
                                paddingBottom: "10px",
                                paddingX: "25px",

                            }}
                        >
                            <Stack direction={'row'} alignItems={'center'} spacing={2}>

                                {invoice.file ? <Box className="editableRow clickable" height={'100px'} p={'10px'} borderRadius={'10px'} width={'190px'} display={'block'} textAlign={'center'} onClick={() => setMediaDialogOpen(true)} >
                                    <img className="logo" src={`${process.env.REACT_APP_IMAGES_URL}${invoice.file.fileName}`} alt="logo" />
                                </Box>
                                    : <LogoButton onClick={() => setMediaDialogOpen(true)} />}
                            </Stack>
                            <Stack spacing={1}>
                                <Stack direction={'row'} alignItems={'center'}>
                                    <Typography variant="h2" textAlign={'right'} className="invoiceText">
                                        {t('Invoice')} #
                                    </Typography>
                                    <Box width={'75px'}>
                                        <ReactiveTextField
                                            initialState={invoice.refId}
                                            variant={'standard'}
                                            onBlurCallback={(newValue) => onBlur('refId', newValue)}
                                            placeholder={'Id'}
                                            className="textareaAsTypography"
                                            InputProps={{ disableUnderline: true, style: { fontSize: '3.5mm' } }}
                                        />
                                    </Box>

                                </Stack>
                                <Stack direction={'row'} alignItems={'center'} spacing={2}
                                    className={'editableRow clickable'} onClick={handleCalendarOpen} >
                                    <Typography className="invoiceText">
                                        {t('Date')}:
                                    </Typography>
                                    <Typography className="invoiceText">
                                        {dayjs(invoice.date).format("DD/MM/YYYY")}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Popover
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                anchorEl={anchorEl} open={calendarOpen}
                                id={calendarId} onClose={handleCalendarClose}>
                                <Calendar
                                    date={dayjs(invoice.date).toDate()}
                                    onChange={handleCalendarSelect}
                                />
                            </Popover>

                        </Stack>
                        <Divider />
                        <Stack direction={'row'} flex padding="30px" spacing={3}>
                            <Stack>
                                <Typography variant="h5" className="invoiceText">
                                    {t('Bill from')}:
                                </Typography>
                                <ReactiveTextField
                                    multiline
                                    initialState={invoice.billingFrom}
                                    variant={'standard'}
                                    onBlurCallback={(newValue) => onBlur('billingFrom', newValue)}
                                    placeholder={"Your billing details"}
                                    className="textareaAsTypography"
                                    InputProps={{ disableUnderline: true, style: { fontSize: '3.5mm' } }}
                                    sx={{ width: '300px' }}
                                />

                            </Stack>
                            <Box>
                                <Box>
                                    <Typography variant="h5" className="invoiceText">
                                        {t('Bill to')}:
                                    </Typography>
                                </Box>
                                <ReactiveTextField
                                    multiline
                                    initialState={invoice.billingTo}
                                    variant={'standard'}
                                    onBlurCallback={(newValue) => onBlur('billingTo', newValue)}
                                    placeholder={"Customer details"}
                                    className="textareaAsTypography"
                                    InputProps={{ disableUnderline: true, style: { fontSize: '3.5mm' } }}
                                    sx={{ width: '300px' }}
                                />                            </Box>
                        </Stack>
                        <TableContainer>
                            <Table sx={{ minWidth: 650, px: '30px' }} aria-label="simple table" className="invoiceTable">
                                <TableHead >
                                    <TableRow>
                                        <TableCell><p className="invoiceTableHeadText">#</p></TableCell>
                                        <TableCell><p className="invoiceTableHeadText">{t('Item')}</p></TableCell>
                                        <TableCell><p className="invoiceTableHeadText">{t('Price')} ({getCurrencySymbol(currency)})</p></TableCell>
                                        <TableCell><p className="invoiceTableHeadText">{t('Quantity')}</p></TableCell>
                                        <TableCell><p className="invoiceTableHeadText">{t('Discount')} ({getCurrencySymbol(currency)})</p></TableCell>
                                        <TableCell><p className="invoiceTableHeadText">{t('Tax')} ({getCurrencySymbol(currency)})</p></TableCell>
                                        <TableCell><p className="invoiceTableHeadText">{t('Total')} ({getCurrencySymbol(currency)})</p></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {invoice.invoiceItems.map((invoiceItem, index) => {
                                        let taxPercentage = 1.00 + (invoice.taxAmount / 100);
                                        // Tax included or excluded?

                                        let discount = invoiceItem.discount ? parseFloat(invoiceItem.discount) : 0;

                                        let unitPrice = invoiceItem.unitPrice ? parseFloat(invoiceItem.unitPrice) : 0;
                                        const quantity = invoiceItem.quantity ? parseInt(invoiceItem.quantity) : 1;
                                        let subTotal = 0;
                                        let tax = 0;
                                        let total = 0;
                                        if (!invoice.taxIncluded) {

                                            subTotal = (unitPrice * quantity - discount);
                                            tax = subTotal * taxPercentage - subTotal;
                                            total = subTotal + tax;
                                        }
                                        else {
                                            subTotal = (unitPrice * quantity - discount) / taxPercentage;
                                            total = (unitPrice * quantity - discount);
                                            unitPrice = unitPrice / taxPercentage;
                                            discount = discount / taxPercentage;
                                            tax = total - total / taxPercentage;
                                        }

                                        invoiceSubTotal += subTotal;
                                        invoiceTax += tax;
                                        invoiceTotal += total;
                                        //  rowsCounter++;

                                        // const discount = service.discount ? service.discount : 0;
                                        // const unitPrice = service.unitPrice ? service.unitPrice : 0;
                                        // const quantity = service.quantity ? service.quantity : 1;
                                        // const subTotal = (unitPrice * quantity - discount);
                                        // const tax = subTotal * 1.21 - subTotal;
                                        // const total = subTotal + tax;
                                        // invoiceSubTotal += subTotal;
                                        // invoiceTax += tax;
                                        // invoiceTotal += total;
                                        // rowsCounter++;
                                        return <TableRow
                                            key={index}
                                        // onClick={() => handleNavigationToOrder(invoiceItem.service?.repairOrder.organizationCounter)}
                                        >
                                            <TableCell>
                                                <p className="invoiceTableCellText">
                                                    {index + 1}
                                                </p>
                                            </TableCell>
                                            <TableCell>
                                                <Typography className={'invoiceTableCellText'}>{invoiceItem.name}</Typography>

                                            </TableCell>
                                            <TableCell>
                                                <Typography className={'invoiceTableCellText'}>{unitPrice.toFixed(2)}</Typography>
                                                {/* <p className="invoiceTableCellText">{unitPrice.toFixed(2)}</p> */}
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction={'row'} alignItems={'center'} >
                                                    <p className="invoiceTableCellText">x{quantity}</p>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Typography className={'invoiceTableCellText'}>{discount.toFixed(2)}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <p className="invoiceTableCellText">{tax.toFixed(2)}</p>
                                            </TableCell>
                                            <TableCell>
                                                <p className="invoiceTableCellText">{total.toFixed(2)}</p>
                                            </TableCell>
                                        </TableRow>


                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Stack justifyContent={'flex-end'} direction={'row'} pr={'30px'} pt={'30px'}>
                            <Box width={'350px'}>
                                <Stack alignItems={'center'} className={'no-print'}>
                                    <StyledToggleButtonGroup
                                        size="small"
                                        value={invoice.taxIncluded}
                                        exclusive
                                        onChange={handleTaxIncludeToggle}
                                        aria-label="text alignment"
                                        sx={{ backgroundColor: "#F1F9FD" }}
                                    >
                                        <ToggleButton value={true}>{t('Tax Included')}</ToggleButton>
                                        <ToggleButton value={false}>{t('Tax Excluded')}</ToggleButton>
                                    </StyledToggleButtonGroup>
                                </Stack>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <p className="invoiceText">
                                        Base:
                                    </p>
                                    <p className="invoiceText bold">
                                        {formatCurrency(invoiceSubTotal, currency)}
                                    </p>
                                </Stack>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <p className="invoiceText">
                                        {t('Tax')} ({invoice.taxAmount}%):
                                    </p>
                                    <p className="invoiceText bold">
                                        {formatCurrency(invoiceTax, currency)}
                                    </p>
                                </Stack>
                                <Divider />
                                <Grid item sx={{ paddingTop: "10px" }}>
                                    <Stack direction={'row'} justifyContent={'space-between'}>
                                        <p className="invoiceText">
                                            {t('Total tax included')}:
                                        </p>
                                        <p className="invoiceText bold">
                                            {formatCurrency(invoiceTotal, currency)}
                                        </p>
                                    </Stack>
                                </Grid>
                            </Box>
                        </Stack>
                    </Box>
                    <Box>
                        <Grid
                            container
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "30px",
                            }}
                        >
                            <Grid item xs={4}>
                                <Grid>
                                    <ReactiveTextField
                                        multiline
                                        fullWidth
                                        initialState={invoice.paymentInfo}
                                        variant={'standard'}
                                        onBlurCallback={(newValue) => onBlur('paymentInfo', newValue)}
                                        placeholder={`${t('Add payment info')}...`}
                                        className={`textareaAsTypography ${invoice.paymentInfo?.length > 0 ? '' : 'no-print'}`}
                                        InputProps={{ disableUnderline: true, style: { fontSize: '4mm' } }}
                                    />
                                </Grid>
                                <Grid sx={{ paddingTop: "14px" }}>
                                    <ReactiveTextField
                                        multiline
                                        fullWidth
                                        initialState={invoice.terms}
                                        variant={'standard'}
                                        onBlurCallback={(newValue) => onBlur('terms', newValue)}
                                        placeholder={`${t('Add terms or note')}....`}
                                        className={`textareaAsTypography ${invoice.terms?.length > 0 ? '' : 'no-print'}`}
                                        InputProps={{ disableUnderline: true, style: { fontSize: '4mm' } }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={5}>

                            </Grid>
                        </Grid>
                    </Box>
                </Stack>
            </Paper>
            <UploadFilesDialog open={mediaDialogOpen} onClose={() => setMediaDialogOpen(false)} onSelect={setInvoiceLogo} />

        </>
    );
})

export default React.memo(InvoiceA4);
