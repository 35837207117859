
import { Box, Typography, Button, Checkbox, Stack, Divider, Link, CircularProgress } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate, Link as RouterLink } from 'react-router-dom';


import { SvgIcon } from '@mui/material';

// ICONS
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import EmergencyIconFilled from '../Icons/EmergencyIconFilled';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import { FaceOutlined } from '@mui/icons-material';

import { useTranslation } from "react-i18next";
import UpdatedAtDate from '../UpdatedAtDate/UpdatedAtDate';
import CreatedAtDate from '../CreatedAtDate/CreatedAtDate';
import DeliveredAtDate from '../DeliveredAtDate/DeliveredAtDate';
import { useEffect, useState } from 'react';

const EntryDetailsCell = ({ rowData, ableToSelect, selectedOrders, handleSelectRow, handlingRequests }) => {
    const { t } = useTranslation();

    let dayjs = require('dayjs')
    const darkGray = "#8B98AB";
    const requestId = `status_cell_request_handle:${rowData.id}`;
    const [isHandlingOrderRequest, setIsHandlingOrderRequest] = useState(false);


    const showOutsourcedTo = () => {
        const isOutsourcedTo = rowData.outsourcedToId && rowData.outsourcedTo
        if (isOutsourcedTo) {
            return <Stack spacing={'5px'} direction={'row'} display={'flex'} alignItems={'center'} ><CallMadeIcon sx={{ fontSize: '14px', alignItems: 'center' }} /><Typography fontSize={'12px'}
                fontWeight={"800"}
                sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: 'your_maximum_width_here'
                }}>{rowData.outsourcedTo.organization.name}</Typography></Stack>;
        }
        // return rowData.organization.name;
        return null;
    }
    const showOutsourcedFrom = () => {
        const isOutsourcedFrom = rowData.outsourcedFromId && rowData.outsourcedFrom;
        if (isOutsourcedFrom) {
            return <Stack spacing={'5px'} direction={'row'} display={'flex'} alignItems={'center'} ><CallReceivedIcon sx={{ fontSize: '14px', alignItems: 'center' }} /><Typography fontSize={'12px'}
                fontWeight={"800"}
                sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: 'your_maximum_width_here'
                }}>{rowData.outsourcedFrom.organization.name}</Typography></Stack>;
        }
        if (rowData.isRequest) {
            return <Stack spacing={'5px'} direction={'row'} display={'flex'} alignItems={'center'} ><CallReceivedIcon sx={{ fontSize: '14px', alignItems: 'center' }} /><Typography fontSize={'12px'}
                fontWeight={"800"}
                sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: 'your_maximum_width_here'
                }}>{rowData.organization.name}</Typography></Stack>;
        }
        // return rowData.organization.name;
        return null;
    }

    useEffect(() => {
        if (handlingRequests && handlingRequests.includes(requestId)) {
            setIsHandlingOrderRequest(true);
        } else {
            setIsHandlingOrderRequest(false);
        }
    }, [handlingRequests])



    const ShowOutsourcedToRequest = () => {
        return <Stack spacing={'5px'} direction={'row'} display={'flex'} alignItems={'center'} ><CallMadeIcon sx={{ fontSize: '14px', alignItems: 'center' }} /><Typography fontSize={'12px'} fontWeight={"800"}>{rowData.repairOrderOutsourceRequest.outsourceReceiver.name}</Typography></Stack>;
    }



    const handleSelect = (e) => {
        e.stopPropagation();
        handleSelectRow(rowData);
    }

    const renderRequestContent = () => {
        if (rowData.isChangingStatus)  {
            return <CircularProgress size={25} />;
        }

        return (
            <Typography
                ml={0}
                fontSize='inherit'
                fontWeight='inherit'
                color='blue'
                display='inline'
                component={Box}
            >
                {t("Request")}
            </Typography>
        );
    };

    const renderLinkContent = () => {
        const linkText = rowData.outsourcedFrom
            ? `${rowData.outsourcedFrom.organization.id}#${rowData.outsourcedFrom.organizationCounter}`
            : `${rowData.organizationId}#${rowData.organizationCounter}`;
        return (
            <Link
                component={RouterLink}
                color={'#0000EE'}
                underline={'hover'}
                variant={'h4'}
                to={`/editEntry/${rowData.id}`}
            >
                {linkText}
            </Link>
        );
    };


    return (
        <Box className='cellContent orderColumnCell' >
            <Stack spacing={'2px'}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Stack direction={'row'} alignItems={'center'}>
                        {ableToSelect ? <Checkbox
                            checked={selectedOrders.find(selectedOrder => selectedOrder.id === rowData.id) !== undefined}
                            onChange={handleSelect} /> : null}
                        <Box display='inline' fontWeight={600} fontSize='1.375rem'>
                            {rowData.isRequest ? renderRequestContent() : renderLinkContent()}
                        </Box>
                    </Stack>
                    <Box display={rowData.levelOfAttention == 1 ? 'block' : 'none'}>
                        <Tooltip enterDelay={20} title={t('Emergency')}>
                            <div>
                                <EmergencyIconFilled />
                            </div>
                        </Tooltip>
                    </Box>
                    {/* <Box>
                    <Button disableElevation variant='outlined' size='small' sx={{fontSize:'0.75rem',fontWeight:'600'}} endIcon={<ArrowForwardOutlinedIcon sx={{fontSize:'5px'}}/>}>Details</Button>
                </Box> */}
                </Box>
                <Box>
                    {showOutsourcedTo()}
                    {showOutsourcedFrom()}
                    {rowData.customer ?
                        <Stack spacing={'5px'} direction={'row'} display={'flex'} alignItems={'center'} >
                            <FaceOutlined sx={{ fontSize: '14px', alignItems: 'center' }} />
                            <Typography
                                fontSize={'12px'}
                                fontWeight={"800"}
                                sx={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxWidth: 'your_maximum_width_here'
                                }}
                            >
                                {rowData.customer.name}
                            </Typography>
                        </Stack>
                        : null
                    }
                    {rowData.repairOrderOutsourceRequest ?
                        <ShowOutsourcedToRequest /> : null
                    }
                </Box>
                <Divider sx={{ borderColor: 'lightGrey.main' }} />
                <Stack direction={'row'} gap={1} display={'inline-flex'}>
                    <CreatedAtDate createdAt={rowData.createdAt} />
                    <UpdatedAtDate updatedAt={rowData.updatedAt} />
                </Stack>
                <Stack direction={'row'} gap={1} flexWrap={'nowrap'} display={'inline-flex'}>

                    <DeliveredAtDate deliveredAt={rowData.deliveredAt} noTime noDate />

                </Stack>

            </Stack >
        </Box >
    )
}

export default EntryDetailsCell;