import { Chip, Stack, Dialog, DialogTitle, DialogContent, IconButton, Button, Typography, DialogActions } from '@mui/material';
import { repairResolutions } from 'utils/repair-statuses-helpers';
import { memo, useCallback } from 'react';
//Resolution Icons
import RepairedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import DeclinedIcon from '@mui/icons-material/DoDisturbAltOutlined';
import IrreproducibleIcon from '@mui/icons-material/HelpOutlineOutlined';
import IrreparableIcon from '@mui/icons-material/PendingOutlined';
import WarrantyIcon from '@mui/icons-material/GppGoodOutlined';
import { useTranslation } from 'react-i18next';

const resolutionIcons = {
    Repaired: { icon: <RepairedIcon />, color: 'primary' },
    Declined: { icon: <DeclinedIcon />, color: 'error' }, // Using Material-UI color names
    Irreparable: { icon: <IrreparableIcon />, color: 'secondary' },
    Irreproducible: { icon: <IrreproducibleIcon />, color: 'info' },
    Warranty: { icon: <WarrantyIcon />, color: 'primary' },
};



const ResolutionSelectDialog = ({ open, onClose, handleResolutionSelect, title, message }) => {
    const { t } = useTranslation();

    const handleClose = (event, reason) => {
        event.stopPropagation();
        onClose(event, reason);
    };


  const onResolutionSelect = useCallback(
        (resolution, event, reason) => {
            handleResolutionSelect(resolution);
            onClose(event, reason);
        },
        [handleResolutionSelect, onClose]
    );


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="resolution-dialog-title"
            aria-describedby="resolution-dialog-description"

        >
            <div
                onClick={(e) => e.stopPropagation()} // Prevent inner clicks from propagating
                style={{ outline: 'none' }} // Remove default focus outline if necessary
            >
                <DialogTitle id="resolution-dialog-title">
                    {title ? title : t('Select a resolution')}
                </DialogTitle>
                <DialogContent id="resolution-dialog-description">
                    {message ? message : t('This status requires a resolution')}
                </DialogContent>
                <DialogContent>
                    <Resolutions
                        repairResolutions={repairResolutions}
                        onSelect={onResolutionSelect}
                        t={t}
                    />
                </DialogContent>

            </div>
            <DialogActions>
                <Button onClick={(event) => onClose(event, 'cancel')} color="primary">
                    {t('common.cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const Resolutions = memo(({ repairResolutions, onSelect, t }) => {
    return (
        <Stack direction="row" flexWrap="wrap" gap={2}>
            {Object.keys(repairResolutions).map((key) => {
                const resolution = repairResolutions[key];
                const { icon, color } = resolutionIcons[key] || {};
                if (!icon || !color) return null; // Safety check

                return (
                    <Chip
                        key={resolution.id || key} // Ensure a unique key
                        color={color}
                        icon={icon}
                        variant="outlined"
                        label={t(resolution.name)}
                        onClick={(event) => {
                            event.stopPropagation();
                            onSelect(resolution, event, 'select');
                        }}
                        sx={{ fontWeight: 500 }}
                        aria-label={`Select ${resolution.name} resolution`}
                    />
                );
            })}
        </Stack>
    );
});

export default ResolutionSelectDialog;