import * as React from 'react';
import axios from "../../axios-config";
import { useSnackbar } from 'notistack';

import {
    Box, Divider, IconButton, Stack,
    TableContainer, TableHead, TableRow,
    TableBody, TableCell, Table, Button, Typography,
} from '@mui/material';
import NewServiceDialog from './Dialogs/NewServiceDialog';
import ServiceRow from './ServiceRow';
import ServicesEmptyPlaceholder from './ServicesEmptyPlaceholder';
import ServicesList from './ServicesList';
import ServiceItemList from './ServiceItemList';

// ICONS
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import PaymentItemList from './PaymentItemList';
import PartItemList from './PartItemList';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import ServiceItemListPartner from './ServiceItemListPartner';
import { useTranslation } from 'react-i18next';
import { getAcceptedAndRefundedServices, getDeclinedAndProposedServices, processService, servicesCalculation } from '../../utils/service-helpers';
import { useSelector } from 'react-redux';
import { formatCurrency } from '../../utils/currency';
import { useUserPermissions } from 'contexts/UserPermissions';
const ServicesContent = React.forwardRef(({ repairOrder, setRepairOrder, addServiceButton,
    services, setServices, outsourceServices,
    setOutsourceServices, repairPayments, setRepairPayments,
    repairOrderParts, setRepairOrderParts }, ref) => {

    const { t } = useTranslation();

    const currency = useSelector((state) => state.auth.currency);

    const [isLoading, setIsLoading] = React.useState(true);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [addServiceOpen, setAddServiceOpen] = React.useState(false);
    const userPermissions = useUserPermissions();

    React.useEffect(() => {
        if (userPermissions?.softLockedUser) {
            setRepairOrderParts([]);
        }
    }, [userPermissions])



    const hanldeServiceDialogOpen = () => {
        setAddServiceOpen(true);
    };

    const handleServiceDialogClose = () => {
        setAddServiceOpen(false);
    };

    const onServiceDelete = (removedService) => {
        setServices(
            services.filter(service => {
                return service.id !== removedService.id
            }))
    }

    React.useImperativeHandle(ref, () => ({

        openAddServicesDialog() {
            hanldeServiceDialogOpen();
        }

    }));

    const onNewServiceAdded = (newService) => {
        setServices(newService);
    }

    const onPaymentAdded = (newPayment) => {
        setRepairPayments(newPayment);
    }

    const onPartsAdded = (parts) => {
        setRepairOrderParts(parts);
    }

    const onServiceAccept = (service) => {
        const updatedServices = services.map(s => {
            if (s.id === service.id) {
                s.isAccepted = true;
                s.decisionAt = new Date().toISOString();
            }
            return s;
        });
        setServices(updatedServices);
    }

    const onRemoveAccept = (service) => {
        const updatedServices = services.map(s => {
            if (s.id === service.id) {
                s.isAccepted = null;
            }
            return s;
        });
        setServices(updatedServices);
    }


    const onDecline = (service) => {
        const updatedServices = services.map(s => {
            if (s.id === service.id) {
                s.isAccepted = false;
                s.decisionAt = new Date().toISOString();
            }
            return s;
        }
        );
        setServices(updatedServices);
    }



    const acceptedAndRefundedServices = getAcceptedAndRefundedServices(services);
    const acceptedAndRefundedServicesProcessed = acceptedAndRefundedServices.reduce((acc, service) => {
        const processed = processService(service)
        return acc += processed.unitPrice * processed.quantity - processed.discount;
    }, 0)


    const declinedAndProposedServices = getDeclinedAndProposedServices(services);
    const declinedAndProposedServicesProcessed = declinedAndProposedServices.reduce((acc, service) => {
        const processed = processService(service)
        return acc += processed.unitPrice * processed.quantity - processed.discount;
    }, 0)

    const repairPaymentsProcessed = repairPayments.reduce((acc, payment) => {
        return acc += parseFloat(payment.amount);
    }, 0)

    return (
        <>
            {
                !services?.length && !repairPayments?.length && !repairOrderParts?.length ?
                    <>
                        <ServicesEmptyPlaceholder addServiceButton={addServiceButton} />
                    </>
                    :
                    <Box>
                        <Box>
                            {/* {outsourceServices?.length > 0 ?
                                <ServicesList title={<Stack alignItems={'center'} direction={'row'}><InsertLinkOutlinedIcon sx={{ mr: '10px' }} />{t('Partner services')}</Stack>}
                                    additionalInfo={services?.length} servicesListItems={outsourceServices?.map(service =>
                                        <ServiceItemListPartner
                                            repairOrderId={repairOrder.id}
                                            key={service.id}
                                            service={service}
                                            onServiceDelete={onServiceDelete}
                                            onAccept={onServiceAccept}
                                            setServices={setServices}
                                        />)}
                                    onSolutionUpdate={setRepairOrder}
                                    sx={{ mb: '5px' }} />
                                : null} */}



                            {/* I need to show both, if it's acceptedAndRefunded are less than 0 to show that we owe */}


                            {repairPayments?.length > 0 ?
                                <ServicesList title={t('Payments')}
                                    servicesListItems={
                                        <>
                                            {/* {Math.abs(acceptedAndRefundedServicesProcessed) - Math.abs(repairPaymentsProcessed) == 0 ? null :
                                            <>
                                                {
                                                    (acceptedAndRefundedServicesProcessed - repairPaymentsProcessed !== acceptedAndRefundedServicesProcessed) && repairPaymentsProcessed > acceptedAndRefundedServicesProcessed ?
                                                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                                            <Typography variant={'body2'}>
                                                                You owe the customer: {formatCurrency(Math.abs(Math.abs(acceptedAndRefundedServicesProcessed) - Math.abs(repairPaymentsProcessed)), currency)}
                                                            </Typography>
                                                            <Button>Mark it as paid</Button>
                                                        </Stack>
                                                        : null
                                                }

                                                {(acceptedAndRefundedServicesProcessed - repairPaymentsProcessed !== acceptedAndRefundedServicesProcessed) && repairPaymentsProcessed < acceptedAndRefundedServicesProcessed ?
                                                    <Stack direction={'row'} alignItems={'center'} spacing={1}>

                                                        <Typography variant={'body2'}>
                                                            Customer owes you:
                                                            {formatCurrency(Math.abs(acceptedAndRefundedServicesProcessed) - Math.abs(repairPaymentsProcessed), currency)}
                                                        </Typography>
                                                        <Button>Mark it as paid</Button>

                                                    </Stack>
                                                    : null}
                                            </>
                                        } */}
                                            {repairPayments?.map(payment =>
                                                <PaymentItemList
                                                    key={payment.id}
                                                    item={payment}
                                                    setPayments={setRepairPayments}
                                                />
                                            )}
                                        </>
                                    }

                                    onSolutionUpdate={setRepairOrder}
                                    sx={{ mb: '5px' }} />
                                : null}


                            {declinedAndProposedServices.length > 0 ?
                                <ServicesList
                                    title={`${t('Proposed services')}`}
                                    additionalInfo={`${formatCurrency(declinedAndProposedServicesProcessed, currency)}`}
                                    servicesListItems={declinedAndProposedServices.map(service =>
                                        <ServiceItemList
                                            key={service.id}
                                            service={service}
                                            onServiceDelete={onServiceDelete}
                                            onAccept={onServiceAccept}
                                            onRemoveAccept={onRemoveAccept}
                                            onDecline={onDecline}
                                            setServices={setServices}
                                            repairOrder={repairOrder}
                                        />)}
                                    onSolutionUpdate={repairOrder}
                                    sx={{ mb: '5px' }}
                                />
                                : null}


                            {acceptedAndRefundedServices.length > 0 ?
                                <ServicesList
                                    title={`${t('Confirmed services')}`}
                                    additionalInfo={`${formatCurrency(acceptedAndRefundedServicesProcessed, currency)}`}
                                    servicesListItems={acceptedAndRefundedServices.map(service =>
                                        <ServiceItemList
                                            key={service.id}
                                            service={service}
                                            onServiceDelete={onServiceDelete}
                                            onAccept={onServiceAccept}
                                            onRemoveAccept={onRemoveAccept}
                                            onDecline={onDecline}
                                            setServices={setServices}
                                            repairOrder={repairOrder}
                                        />)}
                                    onSolutionUpdate={repairOrder}
                                    sx={{ mb: '5px' }}
                                />
                                : null}

                            {repairOrderParts?.length > 0 ?
                                <ServicesList title={t('Parts')}
                                    servicesListItems={repairOrderParts?.map(part =>
                                        <PartItemList
                                            key={part.id}
                                            item={part}
                                            setParts={setRepairOrderParts}
                                        />)}
                                    onSolutionUpdate={setRepairOrder}
                                    sx={{ mb: '5px' }} />
                                : null}
                        </Box>
                    </Box>
            }
            <NewServiceDialog repairOrder={repairOrder} services={services} repairPayments={repairPayments} addServiceOpen={addServiceOpen} handleAddServiceClose={handleServiceDialogClose} onNewServiceAdded={onNewServiceAdded} onPaymentAdded={onPaymentAdded} onPartsAdded={onPartsAdded} />
        </>
    )

})


export default ServicesContent;