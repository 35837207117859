import * as React from 'react';
import { useSnackbar } from 'notistack';
import axios from "../../axios-config";

import {
    Box, List, ListItem, ListItemButton,
    ListItemIcon, ListItemText, Checkbox,
    IconButton, Typography, Stack, Divider, Popover,
    Button, ButtonGroup, circularProgressClasses, Tooltip
} from '@mui/material';
import { styled } from '@mui/system';
import BaseItemList from '../../components/ItemList/BaseItemList';
import EditServiceDialog from './Dialogs/EditServiceDialog';
import { useSelector } from 'react-redux';


//Icons
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import PlusIcon from '@mui/icons-material/AddRounded';
import MinusIcon from '@mui/icons-material/RemoveRounded';
import UTurnLeftIcon from '@mui/icons-material/UTurnLeft';
import RevertAcceptance from '@mui/icons-material/SettingsBackupRestore';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';


import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { red } from '@mui/material/colors';
import {grey} from '@mui/material/colors';

import { formatCurrency } from '../../utils/currency';

const ServiceItemList = ({ service, onServiceDelete, onAccept, onRemoveAccept, onDecline, setServices, repairOrder, fromOutsource }) => {

    const { t } = useTranslation();
    let dayjs = require('dayjs')
    const currency = useSelector((state) => state.auth.currency);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [mouseHover, setMouseHover] = React.useState(false);
    const [editDialog, setEditDialog] = React.useState(false);
    const theme = useTheme();

    const serviceUpdatedAt = dayjs(service.updatedAt).format('DD.MMM.YYYY HH:mm');

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    const InStock = () => {
        if (service.stock === true)
            return <Box>👍 IN STOCK</Box>
        if (service.stock === false)
            return <Box>❌ OUT OF STOCK</Box>
        return null
    }

    const StyledButton = styled(Button, {
        shouldForwardProp: (prop) => prop !== 'positive',
    })(({ positive, theme }) => ({
        backgroundColor: 'white',
        whiteSpace: 'nowrap',
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 4px 12px',
        ...(positive && {
            color: theme.palette.green.main,
        }),
        '&:hover': {
            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 4px 12px',
            backgroundColor: 'white',
        }
    }));

    const handleAcceptService = async (event) => {
        event.stopPropagation();
        try {
            onAccept(service);
            if (fromOutsource) {
                const response = await axios.patch(`/repair-orders/services/${service.id}/acceptPartner/${repairOrder.id}`);
                if (response.status === 200 || response.status === 201) {

                    enqueueSnackbar(`Service accepted!`, { variant: "success" });
                }
            }
            if (!fromOutsource) {
                const response = await axios.patch(`/repair-orders/services/${service.id}/accept`);
                if (response.status === 200 || response.status === 201) {
                    enqueueSnackbar(`Service accepted!`, { variant: "success" });
                }
            }
        }

        catch (err) {
            console.log(err);
        }
    }

    const handleDeclineService = async (event) => {
        event.stopPropagation();
        try {
            onDecline(service)
            if (!fromOutsource) {
                const response = await axios.patch(`/repair-orders/services/${service.id}/decline`);
                if (response.status === 200 || response.status === 201) {
                    enqueueSnackbar(`Service declined!`, { variant: "success" });
                }
            }

            if (fromOutsource) {
                const response = await axios.patch(`/repair-orders/services/${service.id}/declinePartner/${repairOrder.id}`);
                if (response.status === 200 || response.status === 201) {
                    enqueueSnackbar(`Service declined!`, { variant: "success" });
                }
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const handleRemoveAcceptance = async (event) => {
        event.stopPropagation();
        onRemoveAccept(service);
        try {
            if (!fromOutsource) {
                const response = await axios.patch(`/repair-orders/services/${service.id}/removeAcceptance`);
                if (response.status === 200 || response.status === 201) {
                }
            }
            if (fromOutsource) {
                const response = await axios.patch(`/repair-orders/services/${service.id}/removePartnerAcceptance/${repairOrder.id}`);
                if (response.status === 200 || response.status === 201) {
                }
            }
            // console.log(response);
        }
        catch (err) {
            console.log(err);
        }
    }

    const borderAcceptanceColor = () => {
        if (service.isAccepted == true) {
            return '#6DA085'
        }
        if (service.isAccepted == false) {
            return '#DF8989';
        }
        return '#a5b4c9';
    }



    const handleDelete = async (event) => {
        event.stopPropagation();
        if (repairOrder?.deletedAt) {
            return;
        }
        try {
            const response = await axios.delete(`/repair-orders/services/${service.id}`);
            if (response.status == 202 || response.status == 200) {
                enqueueSnackbar(`Service order deleted succesfully!`, { variant: "success" });
                onServiceDelete(service);
            }
        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        }
    }

    const serviceAcceptanceColor = () => {
        if (service.isAccepted == true) {
            return 'lightGreen.main';
        }
        if (service.isAccepted == false) {
            return 'lightRed.main';
        }
        return null;
    }

    const openServiceEditDialog = (event) => {
        event.stopPropagation();
        if (service.refundedServiceId != null || fromOutsource) {
            return;
        }

        setEditDialog(true);

    }

    const closeServiceEditDialog = () => {
        setEditDialog(false);
    }

    const handleServiceEdit = (newServices) => {
        setServices(newServices);
    }

    if (service.refundedServiceId != null) {
        return <>
            <RefundedService repairOrder={repairOrder} service={service} currency={currency} handleDelete={handleDelete} fromOutsource={fromOutsource} />
            <EditServiceDialog repairOrder={repairOrder} service={service} dialogOpen={editDialog} handleDialogClose={closeServiceEditDialog} onEdit={handleServiceEdit} />

        </>
    }
    if (service.isAccepted) {
        return <>
            <AcceptedService repairOrder={repairOrder} service={service} currency={currency} handleDelete={handleDelete}
                handlePopoverOpen={handlePopoverOpen} handlePopoverClose={handlePopoverClose}
                handleRemoveAcceptance={handleRemoveAcceptance}
                serviceUpdatedAt={serviceUpdatedAt}
                openServiceEditDialog={openServiceEditDialog}
                fromOutsource={fromOutsource}
            />
            <EditServiceDialog repairOrder={repairOrder} service={service} dialogOpen={editDialog} handleDialogClose={closeServiceEditDialog} onEdit={handleServiceEdit} />
        </>
    }

    if (service.isAccepted === false) {
        return <>
            <DeclinedService
                repairOrder={repairOrder}
                service={service} currency={currency}
                handleDelete={handleDelete}
                handleRemoveAcceptance={handleRemoveAcceptance}
                openServiceEditDialog={openServiceEditDialog}
                fromOutsource={fromOutsource} />
            <EditServiceDialog repairOrder={repairOrder} service={service} dialogOpen={editDialog} handleDialogClose={closeServiceEditDialog} onEdit={handleServiceEdit} />

        </>
    }
    return (<>

        <BaseItemList
            sx={{
                border: 'solid 1px',
                borderColor: borderAcceptanceColor(),
                '&:hover': {
                    border: 'solid 1px',
                    borderColor: 'black',
                    cursor: !fromOutsource ? 'pointer' : 'default'
                }
            }}
            onMouseEnter={() => setMouseHover(true)}
            onMouseLeave={() => setMouseHover(false)}
            onClick={openServiceEditDialog}
            itemIcon={<CircleOutlinedIcon color={grey[600]} />}
            backgroundColor={serviceAcceptanceColor()}
            mainStack=
            {<>
                <Stack flexGrow={1} overflow={'auto'}>
                    <Tooltip enterDelay={20} title={service.name} >
                        <Typography
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '1',
                                WebkitBoxOrient: 'vertical',
                            }}
                        >{service.name}</Typography>
                    </Tooltip>
                </Stack>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Typography variant='caption' color={grey[600]} noWrap> ({formatCurrency(service.unitPrice, currency)} x{service.quantity})</Typography>
                    <Typography variant='caption' color={grey[600]} noWrap> {service.discount <= 0 ? null : <>-</>}</Typography>
                    <Typography variant='caption' color={grey[600]} noWrap> {service.discount <= 0 ? null : <>{formatCurrency(service.discount, currency)}  {t('Discount')}</>}</Typography>
                    <Typography variant='body' fontWeight={500} noWrap> {formatCurrency(service.unitPrice * service.quantity - service.discount, currency)}</Typography>
                </Stack>
            </>}
            footerStack=
            {<>
                <Stack direction={'row'} spacing={1}>
                    <Button
                        size='small'
                        color='green'
                        variant='outlined'
                        onClick={handleAcceptService}>
                        {t("Accept")}
                    </Button>
                    <Button size='small' color='red' variant='outlined'
                        onClick={handleDeclineService}> {t("Decline")} </Button>
                </Stack>
                <InStock />


            </>
            }

            onDeleteButtonClick={repairOrder?.resolutionTypeId || fromOutsource ? null : handleDelete}
        />
        <EditServiceDialog repairOrder={repairOrder} service={service} dialogOpen={editDialog} handleDialogClose={closeServiceEditDialog} onEdit={handleServiceEdit} />

    </>
    )
}

const DeclinedService = ({ repairOrder, service, currency, handleDelete, handleRemoveAcceptance, openServiceEditDialog, fromOutsource }) => {
    const theme = useTheme();

    const { t } = useTranslation();
    let dayjs = require('dayjs')
    const [mouseHover, setMouseHover] = React.useState(false);

    const serviceDecisionAt = dayjs(service.decisionAt).format('DD.MMM.YYYY HH:mm');
    const [anchorEl, setAnchorEl] = React.useState(null);
    if (!service) {
        return null;
    }

    const InStock = () => {
        if (service.stock === true)
            return <Box>👍 IN STOCK</Box>
        if (service.stock === false)
            return <Box>❌ OUT OF STOCK</Box>
        return null
    }


    return (
        <BaseItemList
            sx={{
                border: 'solid 1px',
                borderColor: theme.palette.red.main,
                backgroundColor: 'lightRed.main',
                '&:hover': {
                    border: 'solid 1px',
                    borderColor: 'black',
                    cursor: !fromOutsource ? 'pointer' : 'default'
                }
            }}
            onMouseEnter={() => setMouseHover(true)}
            onMouseLeave={() => setMouseHover(false)}
            onClick={openServiceEditDialog}
            itemIcon={<HighlightOffIcon sx={{ color: 'red.main' }} />}
            mainStack=
            {<>
                <Stack flexGrow={1} overflow={'auto'}>
                    <Tooltip enterDelay={20} title={service.name} >
                        <Typography
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '1',
                                WebkitBoxOrient: 'vertical',
                            }}
                        >{service.name}</Typography>
                    </Tooltip>
                </Stack>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Typography variant='caption' color={grey[600]} noWrap> ({formatCurrency(service.unitPrice, currency)} x{service.quantity})</Typography>
                    <Typography variant='caption' color={grey[600]} noWrap> {service.discount <= 0 ? null : <>-</>}</Typography>
                    <Typography variant='caption' color={grey[600]} noWrap> {service.discount <= 0 ? null : <>{formatCurrency(service.discount, currency)}  {t('Discount')}</>}</Typography>
                    <Typography variant='body' fontWeight={500} noWrap> {formatCurrency(service.unitPrice * service.quantity - service.discount, currency)}</Typography>
                </Stack>
            </>}
            footerStack=
            {<>

                <Stack direction={'row'} alignItems={'center'} spacing={'5px'}>{

                    mouseHover && !repairOrder?.resolutionTypeId ?
                        < IconButton onClick={handleRemoveAcceptance} size='small' color="red" aria-label="increase quanitity" component="span" sx={{ fontSize: '15px', m: 0 }} >
                            <RevertAcceptance sx={{ fontSize: '15px', m: 0 }} />
                        </IconButton> : null
                } <Typography variant='caption' py={'4px'} fontWeight={500}>{t("Declined on")} {serviceDecisionAt}</Typography></Stack>
                <InStock />

            </>
            }

            onDeleteButtonClick={repairOrder?.resolutionTypeId ? null : handleDelete}
        />
    )
}

const AcceptedService = ({ repairOrder, service, currency, handleDelete, handlePopoverOpen, handlePopoverClose, handleRemoveAcceptance, openServiceEditDialog, fromOutsource }) => {

    const theme = useTheme();

    const { t } = useTranslation();
    let dayjs = require('dayjs')
    const [mouseHover, setMouseHover] = React.useState(false);
    const serviceDecisionAt = dayjs(service.decisionAt).format('DD.MMM.YYYY HH:mm');
    const [anchorEl, setAnchorEl] = React.useState(null);


    if (!service) {
        return null;
    }
    const InStock = () => {
        if (service.stock === true)
            return <Box>👍 IN STOCK</Box>
        if (service.stock === false)
            return <Box>❌ OUT OF STOCK</Box>
        return null
    }

    return (
        <BaseItemList
            sx={{
                border: 'solid 1px',
                borderColor: "#a5b4c9",
                backgroundColor: 'lightGreen.main',
                '&:hover': {
                    border: 'solid 1px',
                    borderColor: '#6DA085',
                    cursor: !fromOutsource ? 'pointer' : 'default'
                }
            }}
            onMouseEnter={() => setMouseHover(true)}
            onMouseLeave={() => setMouseHover(false)}
            onClick={openServiceEditDialog}
            itemIcon={<CheckCircleOutlineIcon sx={{ color: '#00a63c' }} />}
            mainStack=
            {<>
                <Stack flexGrow={1} overflow={'auto'}>
                    <Tooltip enterDelay={20} title={service.name} >
                        <Typography
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '1',
                                WebkitBoxOrient: 'vertical',
                            }}
                        >{service.name}</Typography>
                    </Tooltip>
                </Stack>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Typography variant='caption' color={grey[600]} noWrap> ({formatCurrency(service.unitPrice, currency)} x{service.quantity})</Typography>
                    <Typography variant='caption' color={grey[600]} noWrap> {service.discount <= 0 ? null : <>-</>}</Typography>
                    <Typography variant='caption' color={grey[600]} noWrap> {service.discount <= 0 ? null : <>{formatCurrency(service.discount, currency)}  {t('Discount')}</>}</Typography>
                    <Typography variant='body' fontWeight={500} noWrap> {formatCurrency(service.unitPrice * service.quantity - service.discount, currency)}</Typography>
                </Stack>
            </>}
            footerStack=
            {<>

                <Stack direction={'row'} alignItems={'center'} spacing={'5px'}>{
                    mouseHover && !repairOrder?.resolutionTypeId ?
                        < IconButton onClick={handleRemoveAcceptance} size='small' color="red" aria-label="increase quanitity" component="span" sx={{ fontSize: '15px', m: 0 }} >
                            <RevertAcceptance sx={{ fontSize: '15px', m: 0 }} />
                        </IconButton> : null
                } <Typography variant='caption' py={'4px'} fontWeight={500}>{t("Accepted on")} {serviceDecisionAt}</Typography>
                </Stack>
                <InStock />

            </>
            }

            onDeleteButtonClick={repairOrder?.resolutionTypeId || fromOutsource ? null : handleDelete}
        />
    )
}

const RefundedService = ({ repairOrder, service, currency, handleDelete, fromOutsource }) => {

    const theme = useTheme();

    const { t } = useTranslation();
    const [mouseHover, setMouseHover] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    if (!service) {
        return null;
    }
    const InStock = () => {
        if (service.stock === true)
            return <Box>👍 IN STOCK</Box>
        if (service.stock === false)
            return <Box>❌ OUT OF STOCK</Box>
        return null
    }
    return (
        <BaseItemList
            sx={{
                border: 'solid 1px',
                borderColor: theme.palette.red.main,
                backgroundColor: red[50],
            }}
            itemIcon={<UTurnLeftIcon sx={{ transform: 'rotate(-90deg)' }} />}
            mainStack=
            {<>
                <Stack flexGrow={1} overflow={'auto'}>
                    <Tooltip enterDelay={20} title={service.name} >
                        <Typography
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '1',
                                WebkitBoxOrient: 'vertical',
                            }}
                        >{service.name}</Typography>
                    </Tooltip>
                </Stack>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Typography variant='body' fontWeight={500} noWrap> {formatCurrency(service.unitPrice * service.quantity - service.discount, currency)}</Typography>
                </Stack>
            </>}
            footerStack=
            {<>
                <InStock />
            </>
            }

            onDeleteButtonClick={repairOrder?.resolutionTypeId ? null : handleDelete}
        />
    )
}

export default ServiceItemList;