import React, { useState } from "react";
import {
    MenuItem,
    Paper,
    FormControl,
    Select,
    InputBase,
    Dialog,
    Button,
    cardContentClasses,
    Divider,
    Grid,
    TextField,
    Box,
    Stack,
    InputLabel,
    InputAdornment,
    Autocomplete,
} from "@mui/material";
import { uniqueCurrencies } from "utils/currency";
import { Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";

function CurrencySelect({ selectedCurrency, onCurrencyChange }) {
    const { t } = useTranslation();

    const currencyOptions = Object.entries(uniqueCurrencies).map(([name, symbol]) => ({ name, symbol }));
    const currencyFilterOptions = (options, { inputValue }) => {
        const inputValueLowerCase = inputValue.toLowerCase();

        return options
            .filter((option) => {
                const symbol = `${option.symbol}`.toLowerCase();
                const name = option.name.toLowerCase();
                return symbol.includes(inputValueLowerCase) || name.includes(inputValueLowerCase);
            })
            .slice(0, 20);
    };

    const handleCurrencySelect = (e, val) => {
        onCurrencyChange(val ? val.name : null);
    }
    const selectedOption = currencyOptions.find(option => option.name === selectedCurrency) || null;


    return (
        <>
            <Autocomplete
                // onOpen={handleOpen}
                // onClose={handleClose}
                value={selectedOption}
                options={currencyOptions}
                getOptionLabel={(option) => `${option.name}`}
                onChange={(e, val) => handleCurrencySelect(e, val)}
                fullWidth
                filterOptions={currencyFilterOptions}
                renderOption={(props, option) => (
                    <li {...props} >
                        <Stack
                            direction="row"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                padding: "7px",
                                cursor: "pointer",
                            }}
                            justifyContent="start"
                        >
                            <Typography sx={{ paddingLeft: "12px" }}>
                                {option.name} <strong>({option.symbol})</strong>
                            </Typography>
                        </Stack>                    </li>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}

                        variant='filled'
                        label={t("Currency")}
                        InputProps={{
                            ...params.InputProps, // Spread previous props
                            startAdornment: (
                                <>
                                    {params.InputProps.startAdornment}
                                    <Typography fontWeight={500}>{selectedOption ? selectedOption.symbol : null}</Typography>
                                </>
                            ),
                        }}
                    />
                )}
            />



        </>
    );
}

export default CurrencySelect;
