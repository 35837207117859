import React, { useState } from "react";
import Notification from "../Notification"
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ReminderNotification({ notification }) {

    let navigate = useNavigate();
    const { t } = useTranslation();

    const handleNotificationClick = () => {

        if (!notification.repairOrder) return;
        navigate(`/editEntry/${notification.repairOrder.id}`, { replace: true });
    };

    const modifiedTitle = () => {
        if (!notification.repairOrder) {
            return <Box component={'span'}>{notification.title}</Box>;
        }

        let orgId = notification.repairOrder.organizationId;
        let counter = notification.repairOrder.organizationCounter;
        if (notification.repairOrder?.outsourcedFrom) {
            const { outsourcedFrom } = notification.repairOrder;
            orgId = outsourcedFrom.organizationId;
            counter = outsourcedFrom.organizationCounter;
            return <Box component={'span'}>{t("Order reminder")} <Box component={'span'} color='primary.main'>{orgId}#{counter}</Box> </Box>
        }
        return <Box component={'span'}>{notification.title} <Box component={'span'} color='primary.main'>
            {notification.repairOrder.organizationId}#{notification.repairOrder.organizationCounter}
        </Box> </Box>
    }

    return (
        <Notification
            notification={notification}
            id={notification.id}
            isRead={notification.isRead}
            avatar={<Avatar sx={{ bgcolor: 'blue.main' }}><NotificationsActiveIcon /></Avatar>}
            body={t("You wanted to be reminded about.")}
            title={modifiedTitle()}
            handleNotificationClick={handleNotificationClick}

        />
    );
}

export default ReminderNotification;