import { useParams, Navigate } from 'react-router-dom';

function TokenGuard({ children }) {
    const { token } = useParams(); // Get the token from the URL parameters

    // Check if the token exists
    if (token) {
        return children; // Render the child components if the token is present
    }

    // Redirect to a specific page if the token is missing
    return <Navigate to="/auth/login" />;
}
export default TokenGuard;