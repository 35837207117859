import { memo, useEffect } from "react";

import { connect, useDispatch } from "react-redux";
import NewEntryStepper from "../../components/NewEntryStepper/NewEntryStepper";
import NewClientModal from "../../components/NewClientModal/NewClientModal";
import NavigationBar from "../../components/Navbar/NavigationBar";
import DataTable from "../../components/DataTable/DataTable";
import EditOrderPage from "../../containers/EditOrder/EditOrderPage";
import OrganizationSettings from "../../containers/Settings/OrganizationSettings";
// import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import SearchDialog from "../../components/SearchDialog/SearchDialog";
import { useState } from "react";
import { Box } from "@mui/material";
import PrintSettings from "../Content/PrintSettings";
import InvoiceCreator from "../InvoiceCreator/InvoiceCreator";
import InvoiceView from "../InvoiceView/InvoiceView";
import QuoteView from "containers/QuoteView/QuoteView";
import { useTheme } from "@emotion/react";
import ChartsPage from "../Charts/ChartsPage";
import UserSettingsPage from "../UserSettings/UserSettingsPage";
import ServiceCatalog from "../ServiceCatalog/ServiceCatalog";
import { fetchStatuses } from "../../store/data-table";
import InventoryPage from "containers/Inventory/InventoryPage";
import EditTransferPage from "containers/Inventory/Transfer/EditTransfer";
import {
  UserPermissionsContextProvider,
  useUserPermissions,
} from "contexts/UserPermissions";
import ChartsPageV2 from "containers/Charts/ChartsPage_v2";
import { lightBlue } from "@mui/material/colors";
// const useStyles = makeStyles((theme) => ({
//   toolbar: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "flex-end",
//     padding: theme.spacing(0, 1),
//     // necessary for content to be below app bar
//     // ...theme.mixins.toolbar,
//   },
//   content: {
//     flexGrow: 1,
//     padding: theme.spacing(0),
//   },
// }));

const Main = (props) => {
  // const classes = useStyles();
  // const theme = useTheme();
  const [searchDialog, setSearchDialog] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const dataTableBackgroundColor = theme.palette.lightBlue.main;

  const handleOpenSearchDialog = () => {
    setSearchDialog(true);
  };
  const handleCloseSearchDialog = () => {
    setSearchDialog(false);
  };

  const [color, changeColor] = useState("white");

  useEffect(() => {
    // Set background color based on route
    const path = location.pathname;
    let bgColor = "white"; // default color

    switch (path) {
      case "/":
        bgColor = dataTableBackgroundColor;
        break;
      case "/printSettings":
        bgColor = dataTableBackgroundColor;
        break;
      default:
        bgColor = "white";
        break;
    }
    document.body.style.backgroundColor = bgColor;
  }, [location.pathname]);

  useEffect(() => {
    //Get statuses
    dispatch(fetchStatuses());
  }, []);

  document.body.style.backgroundColor = "white";
  const searchFieldsBucket = [
    "id",
    "malfunction",
    "device",
    "customer",
    "note",
    "resolution",
    "imei",
  ];
  const [searchInFields, setSearchInFields] = useState([
    "id",
    "malfunction",
    "device",
    "customer",
    "note",
    "resolution",
    "imei",
  ]);

  return (
    <>
      <UserPermissionsContextProvider>
        <NavigationBar openSearchDialog={handleOpenSearchDialog}>
          <SearchDialog
            open={searchDialog}
            handleClose={handleCloseSearchDialog}
          />
          {/* <main > */}
          <Routes>
            <Route
              path=""
              element={
                <DataTable
                  searchFieldsBucket={searchFieldsBucket}
                  searchInFields={searchInFields}
                  setSearchInFields={setSearchInFields}
                />
              }
            />
            <Route
              path="/editEntry/:repairOrderId"
              element={<EditOrderPage token={props.token} />}
            />
            <Route path="/viewInvoices/" element={<InvoiceView />} />
            <Route path="/quotes/" element={<QuoteView />} />

            <Route path="/service-catalog" element={<ServiceCatalog />} />
            <Route
              path="/editEntry/request/:tempEntryId"
              element={<EditOrderPage token={props.token} />}
            />
            <Route path="/invoiceCreator" element={<InvoiceCreator />} />
            <Route
              path="/organization"
              element={<OrganizationSettings token={props.token} />}
            />
            <Route path="/printSettings" element={<PrintSettings />} />
            <Route path="/charts" element={<ChartsPageV2 />} />
            <Route path="/userSettings" element={<UserSettingsPage />} />
            <Route path="/inventory" element={<InventoryPage />} />
            <Route
              path="/inventory/transfer/:transferId"
              element={<EditTransferPage />}
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
          {/* </main> */}
        </NavigationBar>

        <NewEntryStepper />
        {props.children}
      </UserPermissionsContextProvider>
    </>
  );
};

export default Main;
