import React from "react";
import { ResponsivePie } from "@nivo/pie";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { Skeleton, Stack, Typography } from "@mui/material";


function PieChart({ repairOrders, loading }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const resolutions = repairOrders.map(order => order.resolutionAt ? t(order.resolutionType.name) : t("No resolution"))
  const counts = {};
  resolutions.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });


  const resolutionsData = []
  Object.keys(counts).forEach(key => {
    // 'repaired': { icon: <RepairedIcon />, color: 'primary' },
    // 'declined': { icon: <DeclinedIcon />, color: 'red' },
    // 'irreparable': { icon: <IrreparableIcon />, color: 'pink' },
    // 'irreproducible': { icon: <IrreproducibleIcon />, color: 'danube' },
    // 'warranty': { icon: <WarrantyIcon />, color: 'darkBlue' },
    // fill: rgb(27, 109, 230);
    let color = null;
    if (key === t("Repaired")) {
      color = "rgba(27,109,230,0.7)";
      // color = "blue"
    }
    if (key === t("Declined")) {
      color = "rgba(255,30,75,1";
    }
    if (key === t("Irreparable")) {
      color = "rgba(255,185,120,1)";
    }
    if (key === t("Irreproducible")) {
      color = "rgba(170,180,140,1)";
    }

    if (key === t("Warranty")) {
      color = "rgba(255,200,200,1)";
    }
    if (key === t("No resolution")) {
      color = "rgba(20,50,50,0.5)";

    }

    resolutionsData.push({
      id: key,
      label: key,
      value: counts[key],
      color: color,
      // borderColor: color
    })
  });
  const data = [...resolutionsData];
  if (loading) {
    return <Box sx={{ height: "250px", width: "100%" }}><Stack alignItems={'center'} flex={1}><Skeleton variant="circular" width={200} height={200} /></Stack></Box>
  }
  if (repairOrders.length === 0) {
    return <Box sx={{ height: "50px", width: "100%" }}><Typography>{t("There are repairs")}</Typography></Box>
  }
  return (
    <div style={{ height: "250px", width: "100%" }}>
      <ResponsivePie
        data={data}
        colors={d => {
          return d.data.color
        }}
        margin={{ top: 20, right: 80, bottom: 20, left: 0 }}
        innerRadius={0.5}
        padAngle={2.5}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.2]],
        }}
        arcLinkLabelsSkipAngle={13}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsDiagonalLength={10}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={13}
        arcLabelsTextColor={{
          from: "color",
          modifiers: [["darker", 2]],
        }}
        legends={[
          {
            anchor: "top-right",
            direction: "column",
            justify: false,
            translateX: 0,
            translateY: -10,
            itemsSpacing: 0,
            itemWidth: 10,
            itemHeight: 23,
            itemTextColor: "#999",
            itemOpacity: 1,
            symbolSize: 10,
            symbolShape: "circle",
            effects: [
              {
                on: "hover",
                style: {
                  itemTextColor: "#000",
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
}

export default PieChart;
