import { Fragment } from "react";
import {
    Container, Card, CardContent, Paper, Typography,
    Box, Grid, Stack, Divider, List, ListItem, ListItemAvatar,
    ListItemButton, ListItemText, Button, Popover
} from "@mui/material";
import * as React from 'react';
import LoginForm from "../LoginPage/components/LoginForm";
import RegisterForm from "../LoginPage/components/RegisterForm"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Logo from "../../images/dalvado-logo.png";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { ThemeContext, useTheme } from '@emotion/react';
import InvoiceA4 from "../../components/Printing/InvoiceA4";
import ToolBarHelper from "../../components/ToolBarHelper/ToolBarHelper";
import Section from "../../components/Section/Section";
import axios from "../../axios-config";

import { useReactToPrint } from "react-to-print";
import { DateRangePicker, defaultInputRanges, createStaticRanges } from "react-date-range";
import * as rdrLocales from 'react-date-range/dist/locale';
import { useTranslation } from "react-i18next";

// ICONS
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import SimplifiedInvoiceView from "../../components/Printing/SimplifiedInvoiceView";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { formatCurrency } from "../../utils/currency";
import QuoteA4 from "components/Printing/QuoteA4";
import { DeleteForeverOutlined } from "@mui/icons-material";
import { useSnackbar } from "notistack";



const QuoteView = (props) => {

    const { t, i18n } = useTranslation();
    const [oldestRepairOrderDate, setOldestRepairOrderDate] = useState("2020-01-01");
    const currency = useSelector((state) => state.auth.currency);

    const [invoice, setInvoice] = useState(null);
    const [invoices, setInvoices] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const componentRef = React.useRef();
    const theme = useTheme()
    const { enqueueSnackbar } = useSnackbar();

    let dayjs = require('dayjs')
    var isBetween = require('dayjs/plugin/isBetween')
    dayjs.extend(isBetween)

    React.useEffect(async () => {
        try {
            // axios.post("/invoices", invoiceData);

            const resp = await axios.get('/quotes');
            const invoices = [...resp.data];
            setInvoices(invoices);
        } catch (err) {
            console.log(err);
        }
    }, [])

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        setSelectedInvoice(filteredInvoices[index]);


    };
    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: selectedInvoice && selectedInvoice.refId ? `${t('Quote')} - ${selectedInvoice.refId}` : `${t("Quote")}`,
        // onBeforePrint: handleBeforePrint,
        // onAfterPrint: handleAfterPrint,
        // removeAfterPrint: true
    });

    const invoiceStatus = (statusId) => {
        switch (statusId) {
            case 0:
                return <Typography fontWeight={500} backgroundColor={theme.palette.red.main} color={'white'} px={1} borderRadius={'5px'}>{t('Unpaid')}</Typography>
            case 1:
                return <Typography fontWeight={500} backgroundColor={theme.palette.accepted.main} color={'white'} px={1} borderRadius={'5px'}>{t('Paid')}</Typography>
        }
    }

    const archiveInvoice = async () => {
        try {
            const resp = await axios.delete(`quotes/delete/${invoices[selectedIndex].id}`);

            if (resp.status == 200) {
                const prevState = [...invoices];
                prevState.splice(selectedIndex, 1);
                setInvoices(prevState);
                enqueueSnackbar(t('Quote deleted'), { variant: 'success' });
            }
        } catch (e) {
            console.log(e);
        }
    }
    const refIdEdit = (newRefId) => {
        const newState = [...invoices];
        newState[selectedIndex].refId = newRefId;
        setInvoices(newState);
    }

    const setCurrentInvoice = (invoice) => {
        const prevInvoices = [...invoices];
        prevInvoices[selectedIndex] = invoice;
        setInvoices(prevInvoices);
    }

    //Calendar
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectionRange, setSelectionRange] = useState({
        startDate: dayjs().subtract(90, 'day').toDate(),
        endDate: dayjs().endOf('day').toDate(),
    });
    const calendarOpen = Boolean(anchorEl);
    const calendarId = calendarOpen ? 'calendar-popover' : undefined;

    const handleCalendarOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCalendarClose = () => {
        setAnchorEl(null);
    };

    const handleCalendarSelect = async (e) => {
        setSelectedIndex(null);
        setSelectedInvoice(null);
        if (e.key === "selection") {
            setSelectionRange(e.selection);
        } else {
            e.range1.endDate = dayjs(e.range1.endDate).endOf('day').toDate();
            setSelectionRange(e.range1);
        }
    }

    const currentYear = dayjs().year();
    const quarter1Start = dayjs(`${currentYear}-01-01`);
    const quarter2Start = dayjs(`${currentYear}-04-01`);
    const quarter3Start = dayjs(`${currentYear}-07-01`);
    const quarter4Start = dayjs(`${currentYear}-10-01`);
    const predefinedRanges = createStaticRanges([
        {
            label: t('Last 30 days'),
            range: () => ({
                startDate: dayjs().subtract(30, 'day').toDate(),
                endDate: dayjs().endOf('day').toDate(),
            }),
        },
        {
            label: t('Last 90 days'),
            range: () => ({
                startDate: dayjs().subtract(90, 'day').toDate(),
                endDate: dayjs().endOf('day').toDate(),
            }),
        },
        {
            label: t('This month'),
            range: () => ({
                startDate: dayjs().startOf('month').toDate(),
                endDate: dayjs().endOf('month').toDate(),
            }),
        },
        {
            label: t('Last month'),
            range: () => ({
                startDate: dayjs().subtract(1, 'month').startOf('month').toDate(),
                endDate: dayjs().subtract(1, 'month').endOf('month').toDate(),
            }),
        },
        {
            label: t('This year'),
            range: () => ({
                startDate: dayjs().startOf('year').toDate(),
                endDate: dayjs().endOf('year').toDate(),
            }),
        },
        {
            label: t('Last year'),
            range: () => ({
                startDate: dayjs().startOf('year').subtract(1, 'year').toDate(),
                endDate: dayjs().endOf('year').subtract(1, 'year').toDate(),
            }),
        },
        {
            label: t('All time'),
            range: () => ({
                startDate: dayjs(oldestRepairOrderDate).toDate(),
                endDate: dayjs().endOf('day').toDate(),
            }),
        },
        {
            label: t('Q1'),
            range: () => ({
                startDate: quarter1Start.startOf('day').toDate(),
                endDate: quarter1Start.clone().add(2, 'month').endOf('month').endOf('day').toDate(),
            }),
        },
        {
            label: t('Q2'),
            range: () => ({
                startDate: quarter2Start.startOf('day').toDate(),
                endDate: quarter2Start.clone().add(2, 'month').endOf('month').endOf('day').toDate(),
            }),
        },
        {
            label: t('Q3'),
            range: () => ({
                startDate: quarter3Start.startOf('day').toDate(),
                endDate: quarter3Start.clone().add(2, 'month').endOf('month').endOf('day').toDate(),
            }),
        },
        {
            label: t('Q4'),
            range: () => ({
                startDate: quarter4Start.startOf('day').toDate(),
                endDate: quarter4Start.clone().add(2, 'month').endOf('month').endOf('day').toDate(),
            }),
        },
    ]);
    let filteredInvoices = invoices.filter(invoice =>
        dayjs(invoice.date).isBetween(dayjs(selectionRange.startDate),
            dayjs(selectionRange.endDate))
    )


    let invoiceSubTotal = 0;
    let invoiceTax = 0;
    let invoiceTotal = 0;

    filteredInvoices.map(invoice => {
        let taxPercentage = 1.00 + (invoice.taxAmount / 100);

        invoice.quoteItems.map(invoiceItem => {
            let taxPercentage = 1.00 + (invoice.taxAmount / 100);

            let discount = invoiceItem.discount ? parseFloat(invoiceItem.discount) : 0;

            let unitPrice = invoiceItem.unitPrice ? parseFloat(invoiceItem.unitPrice) : 0;
            const quantity = invoiceItem.quantity ? parseInt(invoiceItem.quantity) : 1;
            let subTotal = 0;
            let tax = 0;
            let total = 0;

            if (!invoice.taxIncluded) {

                subTotal = (unitPrice * quantity - discount);
                tax = subTotal * taxPercentage - subTotal;
                total = subTotal + tax;
            }
            else {
                subTotal = (unitPrice * quantity - discount) / taxPercentage;
                total = (unitPrice * quantity - discount);
                unitPrice = unitPrice / taxPercentage;
                discount = discount / taxPercentage;
                tax = total - total / taxPercentage;
            }

            invoiceSubTotal += subTotal;
            invoiceTax += tax;
            // console.log(tax);
            invoiceTotal += total;
        });
    });

    return (
        <>
            <Popover
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                anchorEl={anchorEl} open={calendarOpen}
                id={calendarId} onClose={handleCalendarClose}>
                <DateRangePicker
                    locale={rdrLocales[i18n.language]}

                    minDate={dayjs(oldestRepairOrderDate).toDate()}
                    maxDate={dayjs().endOf('month').toDate()}
                    ranges={[selectionRange]}
                    staticRanges={predefinedRanges}
                    onChange={handleCalendarSelect}
                    inputRanges={[{
                        ...defaultInputRanges[0],
                        label: t('Days until now')
                    }]}
                />
            </Popover>

            <Box className={'container mainContainer docsContainer '}>
                <div className='headerPush'></div>

                <div className="content" >
                    <Box className='leftPanelContainerNoToolbar sectionGroup' borderRight={'1px solid darkGrey'} borderBottom={'1px solid darkGrey'} flex={'0 0 auto'} bgcolor={'white'}>
                        <Section sx={{ width: '100%' }}>
                            <Typography variant="h4">{t('Quotes')}</Typography>
                            <Divider />
                            <Box my={2}>
                                <Stack direction={'row'} spacing={3} alignItems={'center'} mb={1}>
                                    <Button startIcon={<CalendarMonthOutlinedIcon />} variant={'outlined'} color={'black'} onClick={handleCalendarOpen}>{dayjs(selectionRange.startDate).format('DD.MMM.YYYY')} - {dayjs(selectionRange.endDate).format('DD.MMM.YYYY')}</Button>
                                    <Typography>{filteredInvoices.length} {t("Quotes")}</Typography>
                                </Stack>
                                {/* <Stack direction={'row'} spacing={3} alignItems={'center'}>
                                    <div> {t("Subtotal")}: {formatCurrency(invoiceSubTotal, currency)}</div>
                                    <div> {t("Tax")}: {formatCurrency(invoiceTax, currency)}</div>
                                    <div> {t("Total")}: {formatCurrency(invoiceTotal, currency)}</div>
                                </Stack> */}
                            </Box>
                            <Divider />
                            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                {filteredInvoices.map((invoice, index) => {
                                    let invoiceSubTotal = 0;
                                    let invoiceTax = 0;
                                    let invoiceTotal = 0;
                                    let taxPercentage = 1.00 + (invoice.taxAmount / 100);

                                    invoice.quoteItems.map(invoiceItem => {

                                        let discount = invoiceItem.discount ? parseFloat(invoiceItem.discount) : 0;

                                        let unitPrice = invoiceItem.unitPrice ? parseFloat(invoiceItem.unitPrice) : 0;
                                        const quantity = invoiceItem.quantity ? parseInt(invoiceItem.quantity) : 1;
                                        let subTotal = 0;
                                        let tax = 0;
                                        let total = 0;
                                        let taxAmount
                                        if (!invoice.taxIncluded) {

                                            subTotal = (unitPrice * quantity - discount);
                                            tax = subTotal * taxPercentage - subTotal;
                                            total = subTotal + tax;
                                        }
                                        else {
                                            subTotal = (unitPrice * quantity - discount) / taxPercentage;
                                            total = (unitPrice * quantity - discount);
                                            unitPrice = unitPrice / taxPercentage;
                                            discount = discount / taxPercentage;
                                            tax = total - total / taxPercentage;
                                        }

                                        invoiceSubTotal += subTotal;
                                        invoiceTax += tax;
                                        invoiceTotal += total;
                                    })

                                    const income = invoice?.invoiceItems?.reduce((total, current) => {
                                        return total + parseFloat(current.unitPrice * current.quantity - current.discount);
                                    }, 0)
                                    return <div key={invoice.id}>

                                        <ListItemButton

                                            selected={selectedIndex === index}
                                            onClick={(event) => handleListItemClick(event, index)}
                                        >
                                            <ListItem alignItems="flex-start">
                                                <ListItemText
                                                    primary={<Stack direction={'row'} justifyContent={'space-between'}>
                                                        <Typography fontWeight={500}>{t('Quote')} #{invoice.refId}</Typography>
                                                        {invoiceStatus(invoice.status)}
                                                    </Stack>
                                                    }
                                                    secondary={
                                                        <React.Fragment>
                                                            <Typography
                                                                sx={{
                                                                    display: 'inline',
                                                                    maxWidth: '300px',
                                                                    textOverflow: 'ellipsis',
                                                                    display: '-webkit-box',
                                                                    overflow: 'hidden',
                                                                    WebkitBoxOrient: 'vertical',
                                                                    WebkitLineClamp: 1,
                                                                }}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                                noWrap
                                                            >
                                                                {/* {invoice.customerPartner[0] ? invoice.customerPartner[0].name
                                                                    : invoice.customers[0] ? invoice.customers[0].name
                                                                        : invoice.billingTo} */}

                                                            </Typography>
                                                            <br></br>
                                                            Total: {formatCurrency(invoiceTotal, currency)}
                                                            <br></br>
                                                            Date: {invoice.date}
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                        </ListItemButton>
                                        <Divider variant="inset" component="li" />
                                    </div>
                                }
                                )}
                            </List>
                        </Section>
                    </Box>
                    <Box className='ticketContainerNoToolbar' flex={'1 0 auto'} >
                        <Box pb={10}>
                            <Stack justifyContent={"center"} alignItems={'center'} py={5} spacing={2}>
                                {filteredInvoices[selectedIndex] ?
                                    <Stack direction={'row'} spacing={2}>
                                        {/* <Button variant="contained" sx={{ backgroundColor: invoices[selectedIndex].status == 0 ? theme.palette.accepted.main : theme.palette.red.main }} onClick={markSelectedAsToggle}>{invoices[selectedIndex].status == 0 ? t("Mark as paid") : t("Mark unpaid")}</Button> */}
                                        {/* <Button variant="contained">Show linked orders</Button> */}
                                        <Button variant={'outlined'} startIcon={<LocalPrintshopOutlinedIcon />} onClick={() => handlePrint(filteredInvoices[selectedIndex])}>{t('Print')}</Button>
                                        <Button variant="outlined" color="red" startIcon={<DeleteForeverOutlined />} onClick={archiveInvoice}>{t('Delete')}</Button>
                                    </Stack>
                                    : null}
                                {
                                    filteredInvoices && filteredInvoices[selectedIndex]?.billingTo?.length > 0 && <QuoteA4 invoice={filteredInvoices[selectedIndex]} ref={componentRef} onUpdateRefId={refIdEdit} setInvoice={setCurrentInvoice} />
                                }

                            </Stack>
                        </Box>
                    </Box>
                </div>

            </Box>


        </>
    );
};
export default QuoteView;
