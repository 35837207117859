import * as React from 'react';
import {
  Container, Card, CardContent, Paper, Typography, Tabs, Tab,
  Box, Grid, Stack, Divider, List, ListItem, ListItemAvatar, Avatar,
  ListItemButton, ListItemText, Button, Dialog, DialogTitle, ToggleButton,
  CardActionArea, CardMedia, Skeleton
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
// import UploadProgress from "./UploadProgress";
import axios from "../../axios-config";
import StyledToggleButtonGroup from '../StyledToggleButtonGroup/StyledToggleButtonGroup';
import { ThemeContext, useTheme } from '@emotion/react';

const UploadFilesDialog = ({ onClose, selectedValue, open, onSelect }) => {


  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };
  const [preview, setPreview] = React.useState();
  const [files, setFiles] = React.useState([]);
  const [successfullyUploadedFiles, setSuccessfullyUploadedFiles] = React.useState([]);
  const [tab, setTab] = React.useState(0);
  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "35px 50px",
    borderWidth: 2,
    borderRadius: 16,
    borderColor: "#c8c5c5",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
    backgroundColor: "#6cb9e92b",
  };

  const acceptStyle = {
    borderColor: "#2196f3",
    backgroundColor: "#6cb9e92b",
  };

  const rejectStyle = {
    borderColor: "#da3535",
    backgroundColor: "da3535",
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        "image/jpeg": [],
        "image/png": [],
      },
      onDrop: (acceptedFiles) => {
        upload(acceptedFiles[0]);
        setFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
      },
    });


  const style = React.useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const requestFiles = async () => {
    const response = await axios.get(`files`);

  }
  const upload = async (file) => {
    try {

      let data = new FormData();
      if (files) {
        const file_name = file.name;
        data.append('file', file);
        data.append('file_name', file_name);
      }
      const response = await axios.post(`/files/images`, data);
      if (response.status === 200) {

        setSuccessfullyUploadedFiles(prevState => [...prevState, file]);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const handleTab = (e, newValue) => {
    setTab(newValue);
  }

  const handleSelect = (file) => {
    onSelect(file);
    onClose();
  }


  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth={'lg'} scroll={'body'} >
      <DialogTitle>Select or Upload Media</DialogTitle>
      <Stack px={'20px'}>
        <Tabs value={tab} onChange={handleTab} aria-label="basic tabs example">
          <Tab label="Upload" />
          <Tab label="Media Library" />
        </Tabs>

        <TabPanel value={tab} index={0}>
          {/* <Grid
            container
            justifyContent={"center"}
            alignItems="center"
            sx={{ height: "80vh", p: '10px' }}
          > */}


          <Stack alignItems={'center'}>
            <Card
              sx={{ width: 800, borderRadius: "20px", padding: "20px" }}
              elevation={0}
            >
              <CardContent>
                <div {...getRootProps({ style })}>
                  <input {...getInputProps()} />
                  <Grid sx={{ mb: 2 }}>
                    <InsertPhotoIcon sx={{ fontSize: "80px", color: "#b3ceff82" }} />
                  </Grid>
                  <Typography variant="h4" sx={{ color: "#36496afa" }} gutterBottom>
                    Drop Your image here, or{" "}
                    <span style={{ color: "#3c91c7", cursor: "pointer" }}>
                      browse
                    </span>
                  </Typography>
                  <Typography variant="p" sx={{ color: "#1b4c7a85" }}>
                    Supports: PNG, JPEG, JPG
                  </Typography>
                </div>
                {successfullyUploadedFiles.map(file => {
                  return <Grid container sx={{ mt: 4 }}>
                    {/* {files.map((file) => (
                  <UploadProgress file={file} progress={file.progress} />
                ))} */}
                    <Box>{file.name} uploaded succesfully</Box>
                  </Grid>
                }
                )}

              </CardContent>
            </Card>

          </Stack>
          {/* </Grid> */}
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <MediaLibrary onSelect={handleSelect} />
        </TabPanel>
      </Stack >

    </Dialog >

  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const MediaLibrary = ({ onSelect }) => {

  const theme = useTheme();
  const dayjs = require('dayjs');
  const [library, setLibrary] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(async () => {
    try {

      setLoading(true);
      const response = await axios.get(`/files`);
      if (response.status === 200) {

        setLibrary(response.data);
      }
      setLoading(false);

    } catch (e) {
      console.log(e);
      setLoading(false);
    }

  }, [])

  const handleSelect = (file) => {
    onSelect(file);
  }

  const handleSkeleton = () => {
    const timesToRender = 3;
    return (
      <>
        {Array(timesToRender).fill(1).map((card, index) => {
          return < Card key={index} elevation={0} sx={{ width: '360px', maxWidth: '360px', minWidth: '360px', borderRadius: '0px', border: '1px solid', borderColor: theme.palette.secondary.main }
          }>

            <Skeleton sx={{ height: 140 }} animation="wave" variant="rectangular" />
            <CardContent>
              <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
              <Skeleton animation="wave" height={10} width="80%" />

            </CardContent>
          </Card >
        })}
      </>
    )
  }

  return <Stack direction={'row'} flexWrap={'wrap'} gap={2}>
    {loading ? handleSkeleton() : library.map((file, index) => {
      return (
        < Card key={index} elevation={0} sx={{ width: '360px', maxWidth: '360px', minWidth: '360px', borderRadius: '0px', border: '1px solid', borderColor: theme.palette.secondary.main }}>
          <CardActionArea onClick={() => handleSelect(file)}>
            <CardMedia
              component="img"
              sx={{ objectFit: "scale-down" }}
              height="140"
              image={`${process.env.REACT_APP_IMAGES_URL}${file.fileName}`}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {file.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {dayjs(file.createdAt).format('DD.MMMM.YYYY')}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      )
    })}
  </Stack>
}


export default UploadFilesDialog;
