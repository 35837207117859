import React, { useState } from "react";
import { ListItem, Box, Stack, IconButton, Tooltip, Menu, MenuItem } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";


import StyledAvatar from "../../../components/StyledAvatar/StyledAvatar";
import { ArrowDownward, ArrowDropDown, ArrowDropDownCircle, Circle, KeyboardArrowDown, LockOutlined, PeopleOutline, PeopleOutlineOutlined, PersonOutline, ShieldTwoTone } from "@mui/icons-material";
import NewFeatureTooltip from "components/NewFeatureTooltip/NewFeatureTooltip";
import { t } from "i18next";
import Linkify from 'react-linkify';

function CommentText({ comment, auth, minimal, setCommentIdToDelete, newFeatureRendered, handleImageClick }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    let dayjs = require("dayjs");
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    var relativeTime = require('dayjs/plugin/relativeTime')

    dayjs.extend(relativeTime)


    //TODO: centralize this function to be available globally for Avatars


    const minimalStyle = minimal ? {
        avatar: { width: '28px', height: '28px', fontSize: '15px' },
        listItem: { margin: '0px' },
        listItemAvatar: { minWidth: '38px' }
    } : {
        listItem: { margin: '10px' }
    };

    // console.log(( auth.userId));
    if (comment) {
        const parsedDate = dayjs(comment.createdAt); // Assumes comment.createdAt is in ISO format
        const formattedDate = parsedDate.fromNow();

    }
    const loggedInUser = comment.user.id == auth.userId;
    const truncatedName = comment.user.name.length > 20
        ? comment.user.name.substring(0, 20) + '...'
        : comment.user.name;

    const getOptionsButton = () => {
        if (newFeatureRendered) {
            return <NewFeatureTooltip
                hintName="comment-options-delete"
                title={t("newFeatureTooltip.commentOptionsDelete.title")}
                description={t("newFeatureTooltip.commentOptionsDelete.description")}
                badge={t("newFeatureTooltip.newBadge")}
            >
                <span>
                    <Tooltip title={t('common.options')}>
                        <IconButton onClick={handleClick} size="small">
                            <KeyboardArrowDown />
                        </IconButton>
                    </Tooltip>
                </span>
            </NewFeatureTooltip>
        }
        if (!loggedInUser || comment.deletedAt) {
            return <IconButton
                size="small"
                sx={{
                    backgroundColor: 'transparent',
                    color: 'transparent',
                }}
                disabled
            >
                <KeyboardArrowDown sx={{ color: 'transparent' }} />
            </IconButton>
        }

        return <Tooltip title={t('common.options')}>
            <IconButton
                size="small"
                onClick={handleClick}
            >
                <KeyboardArrowDown />
            </IconButton>
        </Tooltip>
    }

    return (
        // <Box alignSelf={auth.userId == comment.userId ? 'flex-end' : 'unset'}>
        <Box>
            <Stack
                direction={'row'}
                alignItems={'flex-start'}
                spacing={'10px'}
                sx={{
                    backgroundColor: comment.isPublic ? "#F1F9FD" : "#FFF8E1", // Change color based on isPublic
                    border: comment.isPublic ? '1px solid #E3F2FD' : '1px solid #FFD700', // Subtle border for public, gold border for non-public
                    py: '8px',
                    px: '15px',
                    borderRadius: '10px',
                    width: 'fit-content',
                    // border: '1px solid #e0e0e0', // Subtle border
                    // boxShadow: '0 1px 1px rgba(0, 0, 125, 0.1)', // Slight blue-tinted box shadow            
                }}
            >
                <StyledAvatar name={comment.user.name} userId={comment.user.id} alt={comment.user.name} size={'small'} src="/static/images/avatar/1.jpg" sx={{ width: '24px', height: '24px', mt: '6px', fontSize: '14px' }} />
                <Stack spacing={0} sx={{ maxWidth: '550px' }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={'10px'}>
                        <Stack direction="row" spacing={'5px'} alignItems="center">
                            <Typography variant="h6" fontSize={'14px'}>{truncatedName}</Typography>
                            <Typography variant="body" fontSize={'14px'} color={'darkGrey.main'}> {dayjs(comment.createdAt).fromNow()}</Typography>
                            {comment.user.organizationId && auth.organizationId != undefined && auth.organizationId != 'undefined' && comment.user.organizationId != auth.organizationId ? <PeopleOutlineOutlined sx={{ fontSize: '20px' }} /> : null}
                            {!comment.isPublic && <Circle sx={{ fontSize: '4px', color: 'darkGrey.main' }} />}
                            {!comment.isPublic && <Stack direction={'row'} alignItems={'center'}>
                                <LockOutlined sx={{ fontSize: '14px' }} />
                                <Typography variant="body" sx={{ fontSize: '14px' }} color={'darkGrey.main'}>{t('comment.private')}</Typography>
                            </Stack>}
                            {getOptionsButton()}
                        </Stack>
                    </Stack>
                    <Typography
                        variant="body"
                        fontSize={'14px'}
                        sx={{
                            whiteSpace: 'pre-wrap', // Preserve line breaks
                            wordBreak: 'break-word', // Break long words
                            overflowWrap: 'break-word', // Ensure long words break to the next line
                        }}
                    >
                        <Linkify>{comment.deletedAt ? "comment was deleted" : comment.body}</Linkify>
                        {comment.commentFiles && comment.commentFiles.length > 0 &&
                            <Stack direction={'row'} gap={'5px'} flexWrap={'wrap'} >
                                {comment.commentFiles.map((file, index) => {
                                    return (
                                        <Box
                                            key={index}
                                            onClick={(e) => {
                                                // setItemIndex(img.filePath);
                                                // handleClickOpen(img.filePath);
                                                handleImageClick(file.filePath);
                                            }}
                                            sx={{ cursor: "pointer" }}
                                        >
                                            <img
                                                src={`${process.env.REACT_APP_API_URL}${file.filePath}`}
                                                alt={"title"}
                                                loading="lazy"
                                                style={{
                                                    height: "100px",
                                                    width: "100px",
                                                    borderRadius: "8px",
                                                    objectFit: "cover", // Ensures the image covers the area without stretching
                                                    objectPosition: "center", // Centers the image within the area
                                                    border: "1px solid #ccc", // Adds a small border to the image
                                                }}
                                            />
                                        </Box>
                                    )
                                })}
                            </Stack>
                        }

                    </Typography>
                </Stack>
            </Stack>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={() => {
                    setCommentIdToDelete(comment.id)
                    handleClose()
                }}>
                    {t('common.delete')}
                </MenuItem>
            </Menu>
            {/* {comment && auth ? (
                <ListItem
                    alignItems="flex-start"
                    sx={{
                        ...minimalStyle.listItem,
                        backgroundColor: "lightBlue.main",
                        marginLeft: comment.user.id == auth.userId ? 'auto' : '0',
                        width: "fit-content",
                        paddingRight: "25px",
                        borderRadius: "5px",
                        maxWidth: "90%",
                    }}
                    key={comment.id}
                >
                    {comment.user.id == auth.userId ? null : <ListItemAvatar sx={{ ...minimalStyle.listItemAvatar }}>
                        <StyledAvatar name={comment.user.name} alt={comment.user.name} size={'small'} src="/static/images/avatar/1.jpg" />
                    </ListItemAvatar>}

                    <ListItemText

                        align={comment.user.id == auth.userId ? 'right' : 'left'}
                        primary={
                            <Typography sx={{ fontWeight: 500 }}>
                                {comment.user.name}
                            </Typography>
                        }
                        secondary={
                            <>
                                <Typography
                                    sx={{ display: "inline", wordBreak: 'break-word' }}
                                    component="span"
                                    variant="body2"
                                    color="darkBlue.main"
                                >
                                    {comment.body}
                                </Typography>

                                {minimal ? null : <Typography
                                    sx={{ display: "block", paddingTop: "6px" }}
                                    component="span"
                                    variant="body2"
                                    color="darkGrey.main"
                                >
                                    {dayjs(comment.createdAt).fromNow()}
                                </Typography>}
                            </>
                        }
                    />
                    {comment.user.id == auth.userId ? <ListItemAvatar
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",

                        }}
                    >
                        <StyledAvatar alt={comment.user.name} name={comment.user.name} size={'small'} src="/static/images/avatar/1.jpg" />
                    </ListItemAvatar> : null}
                </ListItem>
            ) : null} */}
        </Box>
    );
}

export default CommentText;
