import { createSlice } from '@reduxjs/toolkit'


const initialState = {
   showNewEntryModal: false,
   nextButtonLoading: false,
   activeStep: 0,
   entryData: {
      category: null,
      brand: "",
      model: "",
      malfunction: "",
      password: "",
      isPasswordPattern: false,
      imei: "",
      note: "",
      customer: null,
      estimatedServices: 0,
      repairPaymentDeposit: 0,
   },
};

const newEntryModalSlice = createSlice({
   name: 'newEntryModal',
   initialState: initialState,
   reducers: {
      toggleModal(state) {
         state.showNewEntryModal = !state.showNewEntryModal;
      },
      setNextButtonLoading(state, action) {
         state.nextButtonLoading = action.payload;
      },
      nextStep(state) {
         state.activeStep = state.activeStep + 1
      },
      setStep(state, action) {
         state.activeStep = action.payload;
      },
      previousStep(state) {
         if (state.activeStep > 0)
            state.activeStep = state.activeStep - 1
      },
      updateCategory(state, action) {
         state.entryData.category = action.payload;
      },
      updateBrand(state, action) {
         state.entryData.brand = action.payload;
      },
      updateModel(state, action) {
         state.entryData.model = action.payload;
      },
      updateMalfunction(state, action) {
         state.entryData.malfunction = action.payload;
      },
      updatePassword(state, action) {
         state.entryData.password = action.payload;
      },
      updateIsPasswordPattern(state, action) {
         state.entryData.isPasswordPattern = action.payload;
      },
      updateImei(state, action) {
         state.entryData.imei = action.payload;
      },
      updateNote(state, action) {
         state.entryData.note = action.payload;
      },
      updateClient(state, action) {
         state.entryData.customer = action.payload;

      },
      updateEstimatedServices(state, action) {
         const payload = action.payload.replace(/[^0-9]/g, "");
         state.entryData.estimatedServices = payload;
      },
      updateRepairPaymentDeposit(state, action) {
         const payload = action.payload.replace(/[^0-9]/g, "");
         state.entryData.repairPaymentDeposit = payload;
      },
      clear: (state) => {
         // state.showNewEntryModal = initialState.showNewEntryModal;
         state.nextButtonLoading = initialState.nextButtonLoading;
         state.activeStep = initialState.activeStep;
         state.entryData = initialState.entryData;
      },
   }
})

export const newEntryModalActions = newEntryModalSlice.actions;
export default newEntryModalSlice.reducer;