import * as React from 'react';
import { Typography, Stack, Chip, Button } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useTranslation } from 'react-i18next';

const AddServiceButton = (props) => {
    const { t } = useTranslation();
    const { childRef, ...others } = props;
    return (
        <Button
            {...others}
            onClick={() => childRef.current.openAddServicesDialog()}
            variant='outlined'
            color='darkGrey'
            startIcon={<AddCircleOutlineOutlinedIcon color={'blue'} />}>
            <Typography color='black' sx={{ fontSize: '1rem' }}>
                {t('Service')}
            </Typography>
        </Button>
    )
}

export default AddServiceButton;