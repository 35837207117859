import { useState, useEffect, memo } from 'react';
import { Box, display } from '@mui/system';
import {
    Typography, Button, IconButton, Tab, Tabs, InputAdornment,
    FormControl, MenuItem, Select, InputLabel, ToggleButtonGroup, ToggleButton
} from '@mui/material';
import axios from "../../../axios-config";
import { useSnackbar } from 'notistack';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import ReactiveTextField from '../../../components/ReactiveTextField/ReactiveTextField';
import { useSelector } from 'react-redux';
import DebouncedTextField from '../../../common/DebouncedTextField';
import { useTranslation } from "react-i18next";
import { getCurrencySymbol } from '../../../utils/currency';
import AssignUserButton from 'components/AssignUserButton/AssignUserButton';
import { ca } from 'react-date-range/dist/locale';

const EditServiceDialog = ({ dialogOpen, handleDialogClose, service, onEdit, repairOrder }) => {
    const [name, setName] = useState(service.name || '');
    const [unitPrice, setUnitPrice] = useState(service.unitPrice || 0);
    const [quantity, setQuantity] = useState(service.quantity || 0);
    const [discount, setDiscount] = useState(service.discount || 0);
    const [assignedTo, setAssignedTo] = useState(null);
    const auth = useSelector((state) => state.auth);

    useEffect(() => {
        setUnitPrice(service.unitPrice || 0);
        setQuantity(service.quantity || 0);
        setDiscount(service.discount || 0);
        setAssignedTo(service.assignedTo);
    }, [dialogOpen]);


    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const currency = useSelector((state) => state.auth.currency);
    const onBlur = async (key, newValue) => {
        const value = newValue;
        try {
            const response = await axios.patch(`repair-orders/services/${service.id}/${key}`, { [key]: value })
            if (response.status !== 200) {
                throw response.status;
            }
            if (response.status === 200) {
                onEdit(response.data);
                enqueueSnackbar(t('keyUpdatedSuccessfully', { field: key }), { variant: "success" });
            }
        } catch (err) {
            console.log(err)
            enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        }
    }

    const onPatchCompleted = async (success, data, field) => {
        if (success) {
            onEdit(data);
            enqueueSnackbar(t('keyUpdatedSuccessfully', { field }), { variant: "success" });
        } else {
            enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        }
    };

    const assignToMe = () => {
        if (auth.userName && auth.userId) {
            const user = { id: auth.userId, name: auth.userName, email: auth.email };
            onSelectUser(user);
        }
    }

    const onSelectUser = (user) => {
        setAssignedTo(user);
        onBlur('assignedToId', user.id);
    }



    return (
        <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth maxWidth={'xs'}>
            <DialogTitle variant='h4'>
                Service Edit
            </DialogTitle>
            <DialogContent>
                <Stack direction={'row'} spacing={2} pt={3}>
                    <DebouncedTextField
                        id='name'
                        fullWidth
                        value={name}
                        setValue={setName}
                        label={t("Service name")}
                        dataKey='name'
                        url={`/repair-orders/services/${service.id}/name`}
                        onPatchCompleted={(success, data) => onPatchCompleted(success, data, t('Service name'))}
                        multiline
                        maxRows={5}
                        disabled={repairOrder?.resolutionTypeId}

                        placeholder={t("Screen")}
                    />
                </Stack>
                <Stack direction={'row'} spacing={2} pt={3}>
                    {/* Change it with debounce */}
                    <DebouncedTextField
                        fullWidth
                        value={unitPrice}
                        setValue={setUnitPrice}
                        label={t("Unit price")}
                        dataKey='unitPrice'
                        url={`/repair-orders/services/${service.id}/unitPrice`}
                        onPatchCompleted={(success, data) => onPatchCompleted(success, data, t('Unit price'))}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>,
                        }}
                        disabled={repairOrder?.resolutionTypeId}
                        numericOnly
                        minValue={0}
                        placeholder={"0.00"}
                    />

                    <DebouncedTextField
                        value={quantity}
                        setValue={setQuantity}
                        label={t("Quantity")}
                        dataKey='quantity'
                        url={`/repair-orders/services/${service.id}/quantity`}
                        onPatchCompleted={(success, data) => onPatchCompleted(success, data, t("Quantity"))}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">x</InputAdornment>,
                        }}
                        disabled={repairOrder?.resolutionTypeId}
                        numericOnly
                        integersOnly
                        minValue={1}
                        placeholder={"1"}
                    />
                    <DebouncedTextField
                        fullWidth
                        value={discount}
                        setValue={setDiscount}
                        label={t("Discount")}
                        dataKey='discount'
                        url={`/repair-orders/services/${service.id}/discount`}
                        onPatchCompleted={(success, data) => onPatchCompleted(success, data, t("Discount"))}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>,
                        }}
                        disabled={repairOrder?.resolutionTypeId}
                        numericOnly
                        minValue={0}
                        maxValue={unitPrice * quantity}
                        placeholder={"0.00"}
                    />
                    {/* <ReactiveTextField
                        fullWidth
                        initialState={service.discount}
                        type='number'
                        label="Discount"
                        onBlurCallback={(newValue) => onBlur('discount', newValue)}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                        }}
                        disabled={repairOrder?.deliveredAt}
                    /> */}
                </Stack>
                <Stack direction={'row'} spacing={2} pt={3} alignItems={'center'}>
                    <Typography>{t("dialogs.newService.assignee")}:</Typography>
                    <AssignUserButton assignedTo={assignedTo} onSelectUser={onSelectUser} assignToMe={assignToMe} />
                </Stack>
                {repairOrder?.resolutionTypeId ? null :
                    <Stack direction={'row'} spacing={2} pt={3}>
                        <ToggleButtonGroup
                            color="primary"
                            value={service.stock == null ? 'null' : service.stock}
                            exclusive
                            onChange={(event) => onBlur('stock', event.target.value === "null" ? null : event.target.value)}
                        >
                            <ToggleButton value={true}>{t("In stock")}</ToggleButton>
                            <ToggleButton value={"null"}>{t("Unkown")}</ToggleButton>
                            <ToggleButton value={false}>{t("Out of stock")}</ToggleButton>
                        </ToggleButtonGroup>
                    </Stack>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose}>{t("Close")}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default memo(EditServiceDialog);