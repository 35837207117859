import * as React from 'react';
import { Box, display } from '@mui/system';
import {
    Typography, Button, IconButton, Tab, Tabs, InputAdornment,
    FormControl, MenuItem, Select, InputLabel, ToggleButtonGroup, ToggleButton
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import axios from "../../../axios-config";
import { useEffect, useState, memo } from 'react';
import { useParams } from "react-router-dom";
import Popover from '@mui/material/Popover';
import { useSnackbar } from 'notistack';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { TextField } from '@mui/material';
import ReactiveTextField from '../../../components/ReactiveTextField/ReactiveTextField';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const EditClientDialog = ({ dialogOpen, handleDialogClose, item, onEdit }) => {

    const { t } = useTranslation();

    const { enqueueSnackbar } = useSnackbar();
    const currency = useSelector((state) => state.auth.currency);

    const onBlur = async (key, newValue) => {
        const value = newValue;
        try {
            const response = await axios.patch(`customers/${item.id}/${key}`, { "value": value })

            if (response.status !== 200) {
                throw response.status;
            }
            if (response.status === 200) {
                onEdit?.(response.data);
                enqueueSnackbar(`${key} updated succesfully!`, { variant: "success" });
            }
        } catch (err) {
            console.log(err)
            enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        }
    }
    if (!item)
        return null
    if (!item.name) return null;

    return (
        <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth maxWidth={'xs'}>
            <DialogTitle variant='h4'>
                {t('Customer Edit')}
            </DialogTitle>
            <DialogContent>
                <Stack direction={'column'} spacing={2}>
                    <ReactiveTextField
                        id='name'
                        fullWidth
                        initialState={item.name}
                        label={t("Name")}
                        onBlurCallback={(newValue) => onBlur('name', newValue)}
                        multiline
                        minRows={2}
                        maxRows={2}
                    />
                    <ReactiveTextField
                        fullWidth
                        initialState={item.phone}
                        label={t("Phone")}
                        onBlurCallback={(newValue) => onBlur('phone', newValue)}
                    />
                    <ReactiveTextField
                        fullWidth
                        initialState={item.email}
                        label={t("Email")}
                        onBlurCallback={(newValue) => onBlur('email', newValue)}
                    />
                    <ReactiveTextField
                        fullWidth
                        initialState={item.taxIdentifier}
                        label={t("Tax identifier")}
                        onBlurCallback={(newValue) => onBlur('taxIdentifier', newValue)}
                    />
                    <ReactiveTextField
                        fullWidth
                        initialState={item.address}
                        label={t("Address")}
                        onBlurCallback={(newValue) => onBlur('address', newValue)}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose}>{t('Close')}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default memo(EditClientDialog);