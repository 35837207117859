import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { NavLink } from 'react-router-dom';

import { styled } from '@mui/material/styles';

const StyledListItemButton = styled(ListItemButton)(() => ({
    "&.Mui-selected": {
        backgroundColor: `#003982`,
        color: 'white',
        boxShadow: ' rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px'
    },
    color: '#8EBBFD',
    marginTop: '3px',

}));

const CustomListItem = ({ icon, text, to }) => {
    const isExternal = to.startsWith('http://') || to.startsWith('https://');

    if (isExternal) {
        return (
            <StyledListItemButton component='a' href={to} target="_blank" rel="noopener noreferrer" sx={{ borderRadius: '10px', p: 1 }}>
                <ListItemIcon sx={{ color: 'inherit' }}>
                    {icon}
                </ListItemIcon>
                <ListItemText primary={text}/>
            </StyledListItemButton>
        )
    } else {
        return (
            <StyledListItemButton component={NavLink} to={to} selected={to === window.location.pathname} sx={{ borderRadius: '10px', p: 1 }}>
                <ListItemIcon sx={{ color: 'inherit' }}>
                    {icon}
                </ListItemIcon>
                <ListItemText primary={text} />
            </StyledListItemButton>
        )
    }
}

export default CustomListItem;