import * as React from 'react';
import {
    Box, List, ListItem, ListItemButton,
    ListItemIcon, ListItemText, Checkbox,
    IconButton, Typography, Stack, Divider,
    Button,
    ButtonGroup
} from '@mui/material';

import { parseWithOptions } from 'date-fns/fp';
import axios from '../../axios-config'




const ServicesList = ({ additionalInfo, title, servicesListItems }) => {

    return (
        <Box>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Stack direction={'row'}>
                    <Typography variant='caption' fontWeight={600} >
                        {title}
                    </Typography>
                </Stack>
                <Typography sx={{ fontSize: '0.8rem', fontWeight: 600 }} >
                    {additionalInfo}
                </Typography>
            </Stack>
            <List sx={{ width: '100%' }}>
                {servicesListItems}
            </List>
        </Box>
    )

}

export default ServicesList;