import { memo } from "react";
import {
    IconButton, TextField, Autocomplete, Paper, Stack, Typography, Button, ListItemButton, ListItemIcon, ListItemText, List, ListItem, Box,
    FormGroup,
    FormControlLabel,
    Checkbox
} from '@mui/material';

import { useTranslation } from "react-i18next";
import { dataTableActions } from '../../store/data-table';
import { useSelector, useDispatch } from 'react-redux';

//ICONS
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import { useState, useEffect, forwardRef } from "react";
import { createFilterOptions } from '@mui/material/Autocomplete';
import { AddOutlined, Circle } from "@mui/icons-material";

const SearchBar = ({ searchInFields, setSearchInFields, searchFieldsBucket }) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const search = useSelector(state => state.dataTable.search);

    const onAutocompleteSearch = (value) => {
        dispatch(dataTableActions.setSearch(value));
    }

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);


    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const filter = createFilterOptions({
        stringify: (option) => option.name + option.phone + option.email,
    });


    const SearchPopover = ({ children, ...other }) => (
        <Paper {...other} sx={{ height: '250px', px: '15px' }} onMouseDown={(event) => event.preventDefault()}>
            <Stack spacing={2}>
                <Stack spacing={1}>
                    {/* <Typography variant="caption"> Actions</Typography> */}
                    <Stack direction={'row'}>
                    </Stack>
                </Stack>
                <Stack spacing={0}>
                    {/* <Typography variant="caption">{t("Options")}</Typography> */}
                    <FormGroup>
                        {searchFieldsBucket.map((field, index) => (
                            <FormControlLabel key={index} control={<Checkbox
                                checked={searchInFields.includes(field)}
                                onChange={(event) => {
                                    if (event.target.checked) {
                                        setSearchInFields([...searchInFields, field]);
                                    } else {
                                        setSearchInFields(searchInFields.filter(item => item !== field));
                                    }
                                }}
                            />} label={t(field?.charAt(0).toUpperCase() + field.slice(1))} />
                        ))}
                    </FormGroup>
                </Stack>
            </Stack>
        </Paper >
    );

    const ForwardedList = forwardRef((props, ref) => (
        <List ref={ref} sx={{ overflow: 'auto', maxHeight: '500px' }} {...props} />
    ));


    const OptionList = ({ option, handleOptionSelect }) => {

        const handleOnClick = () => {
            handleOptionSelect(option);
        }

        return <ListItemButton onClick={handleOnClick} sx={{ minHeight: '72px' }}>
            <ListItemIcon sx={{ minWidth: '26px', }}>
                <Circle sx={{ fontSize: '8px' }} />
            </ListItemIcon>
            <ListItemText
                primary={option.name}
                secondary={
                    [
                        option.phone ? (option.phone.length > 20 ? option.phone.slice(0, 20) + '...' : option.phone) : null,
                        option.email ? (option.email.length > 20 ? option.email.slice(0, 20) + '...' : option.email) : null
                    ].filter(Boolean).join(', ')
                }
                primaryTypographyProps={{ component: 'div' }}
                secondaryTypographyProps={{ component: 'div' }} />
        </ListItemButton>
    };

    return <>
        {/* <TextField
            variant='filled'
            placeholder={t('Search')}
            onChange={setSearch}
            value={search}
            hiddenLabel
            InputProps={{
                sx: { width: '200px' },
                startAdornment: <SearchIcon color='darkGrey' />,
                endAdornment:
                    <IconButton aria-label="reset-search" size={'small'} onClick={resetSearch}
                        edge="end"
                    >
                        {search.length > 0 ? <CloseIcon fontSize="inherit" /> : null}
                    </IconButton>
            }}
            size='small' sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} /> */}

        <Autocomplete
            freeSolo
            filterOptions={filter}
            id="customers"
            inputValue={search}
            sx={{ width: "200px" }}
            size="small"
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            onInputChange={(event, newValue) => {
                onAutocompleteSearch('');
            }}
            onMouseDown={(event) => event.preventDefault()}
            options={options}
            PaperComponent={SearchPopover}
            ListboxComponent={ForwardedList}
            ListboxProps={{ sx: { overflow: 'hidden', maxHeight: '720px' } }}
           
            fullWidth
            renderInput={(params) => (
                <TextField
                    {...params}
                    hiddenLabel
                    placeholder={t("Search")}
                    variant="filled"
                    size='small'
                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                    onChange={(e) => onAutocompleteSearch(e.target.value)}
                    onBlur={(e) => onAutocompleteSearch(e.target.value)}
                    onClick={(event) => {
                        if (!open) {
                            setOpen(true)
                        }
                    }}

                    // size="small"
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: <SearchIcon color='darkGrey' />,

                        // The -3px is to align the icon with the text
                        // It's just a workaround, not the best solution
                        // TODO: Find a better solution
                        // startAdornment: <InputAdornment position="start" style={{ marginTop: '-3px' }}>
                        //   <LocalShippingOutlined />
                        // </InputAdornment>
                    }}
                />
            )}
        />


    </>
}





export default SearchBar