import * as React from "react";
import { ToggleButtonGroup } from "@mui/material";
import { styled } from "@mui/material/styles"


const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    "& .MuiToggleButtonGroup-grouped": {
        margin: theme.spacing(0.5),
        backgroundColor: "rgba(0, 0, 0, 0.05)",
        fontWeight: 600,
        border: 0,

        "&:not(:first-of-type)": {
            borderRadius: theme.shape.borderRadius,
            padding: "6px 20px"
        },
        "&:first-of-type": {
            borderRadius: theme.shape.borderRadius,
            padding: "6px 20px"
        },
        "&.Mui-selected": {
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 3px",
            backgroundColor: "white",
        },
    },
}));

export default StyledToggleButtonGroup;