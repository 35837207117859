import {
    Card,
    Box,
    Grid,
    Stack,
    Divider
} from "@mui/material";
import * as React from 'react';
import Logo from "../../images/dalvado-logo.png";
import "./AuthPage.css";
import LanguageButton from "../../components/LanguageButton/LanguageButton";
import { useNavigate } from "react-router-dom";
import { set } from "lodash";


const AuthCard = ({ children }) => {

    const navigate = useNavigate();
    const handleNavigationToLogin = () => {
        navigate('/auth/login');
    }

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}
        >
            <Grid item xs={12} sm={8} md={6} lg={4} xl={3}>
                <Card
                    sx={{
                        borderRadius: "10px", py: '15px', maxWidth: '500px', minWidth: '300px', px: '15px', '@media (min-width: 1280px)': { // Adjust this breakpoint
                            minWidth: '500px', // Set to your desired width
                        },
                    }}
                >
                    <Box fullWidth justifyContent={'center'} textAlign={'center'}>
                        {!process.env.REACT_APP_COSTI_LOCK && <Box
                            className="clickable"
                            component="img"
                            sx={{ height: 70 }}
                            alt="dalvado"
                            src={Logo}
                            mt={'15px'}
                            onClick={handleNavigationToLogin}
                        />}
                    </Box>
                    <Stack direction={'row'} justifyContent={'center'} marginY={'15px'}>
                        <LanguageButton />
                    </Stack>
                    <Divider style={{ marginTop: "25px", marginBottom: '25px' }} />
                    {children}
                </Card>
            </Grid>
        </Grid>
    )
}

export default AuthCard;