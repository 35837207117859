// useUpdateOrderStatusAndResolutionMutation.js
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack'; // Correct import for useSnackbar
import dayjs from 'dayjs';
import { updateOrderStatusWithResolution } from 'api/repair-orders'; // Adjust the path accordingly



const useUpdateOrderStatusAndResolutionMutation = (queryKeys) => {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar(); // Use the useSnackbar hook to get enqueueSnackbar
    const dateNow = dayjs().toISOString();
    return useMutation(updateOrderStatusWithResolution, {
        onMutate: async ({ repairOrderId, status, resolution }) => {
            await queryClient.cancelQueries({ queryKey: queryKeys, exact: false });

            const previousOrders = queryClient
                .getQueriesData({ queryKey: queryKeys, exact: false })
                .filter(([queryKey, oldData]) => oldData !== undefined);


            // Optimistically update all matching queries
            previousOrders.forEach(([queryKey, oldData]) => {
                if (!oldData) {
                    console.warn(`No data for queryKey: ${queryKey}`);
                    return;
                }

                let newData;
                if (Array.isArray(oldData)) {
                    newData = oldData.map((o) =>
                        o.id === repairOrderId
                            ? {
                                  ...o,
                                  repairOrderStatus: { id: status.id, name: status.name },
                                  repairOrderStatusId: status.id,
                                  resolutionTypeId: resolution.id,
                                  resolutionType: { id: resolution.id, name: resolution.name },
                                  resolutionAt: dateNow,
                                  updatedAt: dateNow,
                                  isChangingStatus: true, // Add the pending property
                              }
                            : o
                    );
                } else {
                    // oldData is a single object
                    if (oldData.id === repairOrderId) {
                        newData = {
                            ...oldData,
                            repairOrderStatus: { id: status.id, name: status.name },
                            repairOrderStatusId: status.id,
                            resolutionTypeId: resolution.id,
                            resolutionType: { id: resolution.id, name: resolution.name },
                            resolutionAt: dateNow,
                            updatedAt: dateNow,
                            isChangingStatus: true, // Add the pending property
                        };
                    } else {
                        newData = oldData;
                    }
                }

                queryClient.setQueryData(queryKey, newData);
            });

            return { previousOrders };
        },
        onSuccess: (data, variables) => {
            // Update all matching queries with the new data
            const updatedOrder = data;

            const queries = queryClient
                .getQueriesData({ queryKey: queryKeys, exact: false })
                .filter(([queryKey, oldData]) => oldData !== undefined);

            queries.forEach(([queryKey, oldData]) => {
                if (!oldData) {
                    console.warn(`No data for queryKey: ${queryKey}`);
                    return;
                }

                let newData;
                if (Array.isArray(oldData)) {
                    newData = oldData.map((o) =>
                        o.id === variables.repairOrderId
                            ? {
                                  ...o,
                                  ...updatedOrder,
                              }
                            : o
                    );
                } else {
                    // oldData is a single object
                    if (oldData.id === variables.repairOrderId) {
                        newData = {
                            ...oldData,
                            ...updatedOrder,
                        };
                    } else {
                        newData = oldData;
                    }
                }

                queryClient.setQueryData(queryKey, newData);
            });
            enqueueSnackbar(`Status updated`, { variant: "success" });
        },
        onError: (err, variables, context) => {
            // Rollback each query to its previous state
            context.previousOrders.forEach(([queryKey, oldData]) => {
                queryClient.setQueryData(queryKey, oldData);
            });
            console.log(err?.response?.data?.message);
            enqueueSnackbar(`Failed to update status`, { variant: "error" });
        },

        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys, exact: false });
        },
    });
};

export default useUpdateOrderStatusAndResolutionMutation;
