import { Stack, Tooltip, Typography } from "@mui/material";
import LocalShippingTwoToneIcon from '@mui/icons-material/LocalShippingOutlined';
import { useTranslation } from "react-i18next";
import { getDate } from "date-fns";

const DeliveredAtDate = ({ deliveredAt, noTime, noDate }) => {

    const { t } = useTranslation();

    let dayjs = require('dayjs')

    const daysSinceDelivery = dayjs(new Date()).diff(dayjs(deliveredAt), 'day');


    return deliveredAt ? <Tooltip enterDelay={20} title={`${t('Delivery date')}: ${dayjs(deliveredAt).format('DD MMM. YYYY HH:mm')}`} >
        <Stack color={'darkGrey.main'}>
            <Stack gap={'5px'} direction={'row'} border={'0px solid'} borderRadius={'5px'} borderColor={'darkGrey.main'} alignItems={'center'} py={'2px'} fontSize='0.75rem' color={'darkGrey.main'} > <LocalShippingTwoToneIcon sx={{ fontSize: '1rem', mr: '0px' }} />
                <Typography fontSize={'0.75rem'} fontWeight={"600"}>{t("Delivered")} </Typography>

                <Typography fontSize={'0.75rem'} fontWeight={"600"}>{dayjs(deliveredAt).fromNow(true)} </Typography>
                {/* <Typography fontSize={'0.75rem'} fontWeight={"600"} >{t("daysAgo", { count: daysSinceDelivery })}</Typography> */}
            </Stack>

        </Stack>
    </Tooltip> : null
}

export default DeliveredAtDate