import * as React from 'react';
import axios from '../../axios-config';
import CircularProgress from "@mui/material/CircularProgress";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';

import { useSnackbar } from 'notistack';
import { getCategories, addNewCategory } from './formController'

//Redux
import { useSelector, useDispatch } from "react-redux";
import { newEntryModalActions } from '../../store/new-entry-modal';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';



// Keep the state outside this component to be able to reuse it on Edit Order / New Order Dialog etc

const CategorySearch = ({ onCategoryUpdate, value }) => {

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const filter = createFilterOptions();

  const [isFetching, setIsFetching] = React.useState(false);
  const [dialog, toggleDialog] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const categoryState = useSelector(state => state.newEntryModal.entryData.category);

  const updateCategory = (payload) => {
    // dispatch(newEntryModalActions.updateCategory(payload))
    onCategoryUpdate(payload);
  }

  const loading = open && isFetching;

  React.useEffect(async () => {
    let active = true;
    try {
      if (!open) return;
      setIsFetching(true);
      const response = await axios.get(`/repair-orders/categories`);
      setIsFetching(false);
      if (active)
        setOptions(response.data.entryCategories);

    } catch (err) {
      console.log(err);
    }

  }, [open]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleClose = () => {
    setDialogValue({
      name: '',
      id: '',
    });

    toggleDialog(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    name: '',
    id: '',
  });

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const data = {
        categoryName: dialogValue.name
      }
      const response = await axios.put(`/repair-orders/category`, data);

      const category = {
        name: response.data.name,
        id: response.data.id
      }

      await updateCategory(category);

      enqueueSnackbar(`Category [${response.data.name}] added succesfully`, { variant: "success" });
      handleClose();

    } catch (err) {
      enqueueSnackbar(`Something went wrong!`, { variant: "error" });
    }
  };
  const translatedText = t('confirmAddCategory', { name: dialogValue.name });

  const parts = translatedText.split(dialogValue.name);

  return (
    <React.Fragment>
      <Autocomplete
        id="category"
        loading={loading}
        size="small"
        value={value}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(option) => {

          return option.name
        }}

        onChange={(event, newValue) => {

          if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleDialog(true);
              setDialogValue({
                name: newValue,
                id: '',
              });
            });
          } else if (newValue && newValue.inputValue) {
            toggleDialog(true);
            setDialogValue({
              name: newValue.inputValue,
              id: '',
            });
          } else {

            updateCategory(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option.name);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              name: `Add "${inputValue}"`,
            });
          }

          return filtered;
        }}
        options={options}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.name;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("Category")}
            variant='filled'
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      <Dialog fullWidth maxWidth='xs' open={dialog} onClose={handleClose} aria-labelledby="responsive-dialog-name">
        <DialogTitle>{t("Add a new category")}</DialogTitle>
        <DialogContent>

          <Typography display="inline">
            {parts[0]}
            <Box component="span" sx={{ fontWeight: "700", display: "inline", backgroundColor: "#DDDD", p: "3px", borderRadius: "5px" }}>
              {dialogValue.name}
            </Box>
            {parts[1]}
          </Typography>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
          >

          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("Cancel")}</Button>
          <Button type="submit" onClick={handleSubmit}>{t("Add")}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment >
  );
};


export default CategorySearch;

