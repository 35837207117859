import {
    Typography, Stack,
    Dialog, DialogTitle, DialogContent, DialogActions, Button,
    Box,
    Alert,
    AlertTitle,
} from '@mui/material'

import { useTranslation } from 'react-i18next';

import { NavigateNext, WarningTwoTone } from '@mui/icons-material';

import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';



const DeleteDialogWarningGeneric = ({ dialogOpen, handleDialogClose, onDeleteAccept, isAccepting, title, warningText, message }) => {
    const theme = useTheme(); // Access the theme object
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const handleAccept = async () => {
        try {
            await onDeleteAccept();
        } catch (error) {
            console.error("Deletion error:", error);
        }
    };

    return <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth maxWidth={'xs'}>
        <DialogTitle variant='h4'>
            {title}
        </DialogTitle>
        <DialogContent>
            <Alert severity="warning" >
                <AlertTitle>{t("Warning!")}</AlertTitle>
                {warningText}
            </Alert>
            {message && <Box sx={{ pb: '25px' }}>
                <Typography variant='body' color='textSecondary' >{message}</Typography>
            </Box>}
        </DialogContent>
        <DialogActions>
            <Button onClick={handleDialogClose} color='black'>{t("Cancel")}</Button>
            <LoadingButton loading={isAccepting} onClick={handleAccept} loadingPosition="end" variant="contained" color={'red'} endIcon={<NavigateNext />} >
                {t("Delete")}
            </LoadingButton>
            {/* <Button onClick={handleDelete} variant='contained' color='red'>{t("Delete")}</Button> */}
        </DialogActions>
    </Dialog>
}

DeleteDialogWarningGeneric.propTypes = {
    dialogOpen: PropTypes.bool.isRequired,
    handleDialogClose: PropTypes.func.isRequired,
    onDeleteAccept: PropTypes.func.isRequired,
    isAccepting: PropTypes.bool,
    title: PropTypes.string.isRequired,

};

DeleteDialogWarningGeneric.defaultProps = {
    isAccepting: false,
};

export default DeleteDialogWarningGeneric;