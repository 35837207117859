// AUTOCOMPLETE
import { useState, useEffect, forwardRef } from "react";
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from "@mui/material/CircularProgress";
import { TextField, Typography, Chip, IconButton, Paper, List, ListItem, ListItemIcon, ListItemText, Stack, Button, ListItemButton, InputAdornment, Skeleton } from "@mui/material";
import { createFilterOptions } from '@mui/material/Autocomplete';
import { Box } from "@mui/system";

import { useSelector, useDispatch } from "react-redux";


//  ICONS
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MailIcon from '@mui/icons-material/Mail';
import { useTranslation } from "react-i18next";
import axios from "axios-config";

import { AddBoxOutlined, AddOutlined, Circle, Inventory2Outlined, LocalShippingOutlined } from "@mui/icons-material";
import { NavigateNext, WarningTwoTone } from '@mui/icons-material';
import ConsoleHelper from "utils/ConsoleHelper";
import AddNewProductDialog from "../AllProducts/AddNewProductDialog";
import { set } from "lodash";

// AUTOCOMPLETE
const ProductSearch = ({ required, warehouse, state, onOptionSelect, sx, newProductInitialQuantity }) => {

    // const [openClientModal, setOpenClientModal] = useState(false);
    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [newProductDialogOpen, setNewProductDialogOpen] = useState(false);
    const [searchText, setSearchText] = useState('');

    const hanldeAddNewProduct = (event) => {
        event.stopPropagation();
        setNewProductDialogOpen(true);
    }

    const handleNewProductDialogClose = () => {
        setNewProductDialogOpen(false);
        setSearchText('');
    }


    const SearchPopover = ({ children, ...other }) => (
        <Paper {...other} sx={{ height: '250px', p: '15px' }} onMouseDown={(event) => event.preventDefault()}>
            <Stack spacing={2}>
                <Stack spacing={1}>
                    {/* <Typography variant="caption"> Actions</Typography> */}
                    <Stack direction={'row'}>
                        <Button variant="outlined" onClick={hanldeAddNewProduct} startIcon={<AddOutlined />} size="small" color="black">{t("New product")}</Button>
                    </Stack>
                </Stack>
                <Stack spacing={1}>
                    <Typography variant="caption">Products</Typography>
                    {children}
                </Stack>
            </Stack>
        </Paper >
    );

    const handleOptionSelect = (option) => {
        onOptionSelect(option);
        setOpen(false);
    }

    const onNewProductAdded = (product) => {
        // fetchProducts();
        // Search for the newly added product
        // and select it
        //add the new product to the options
        // setOptions([...options, product]);
        // const newProduct = options.find(p => p.id === product.id);
        // if (newProduct) {
        //     onOptionSelect(newProduct);
        // }

        if (product.productInventory && product.productInventory.quantityInStock > 0) {
            onOptionSelect(product);
        }

    }

    const fetchProducts = async () => {
        try {
            // Make API call or database query to fetch inventory data
            setLoading(true);
            const response = await axios.get(`/inventory/warehouse/${warehouse.id}/products`);
            if (response.status === 200) {

                const data = response.data;

                // ConsoleHelper(data);
                // setProducts(data);
                setOptions([...data])
                // if (active) {
                // }
                // return () => {
                //     active = false;
                // };
            }
        } catch (error) {
            console.error('Error fetching inventory data:', error);
        }
        setLoading(false);

    }

    useEffect(async () => {
        try {
            if (open) {

                fetchProducts();
            }


        } catch (err) {
            console.log(err);
        }
    }, [open]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const filter = createFilterOptions({
        stringify: (option) => option.name + option.sku + option.barcode,
    });

    if (!warehouse || !warehouse.id) {
        return <Stack direction={"row"} spacing={1} alignItems={'center'}>
            {/* <Typography variant='h6' color='textSecondary'>{selectedServices.length} {selectedServices.length == 1 ? 'service' : 'services'} selected</Typography> */}
            <WarningTwoTone sx={{ color: 'red.main' }} />
            <Typography variant='body' fontWeight={500}>{t("There is no warehouse selected")}</Typography>
        </Stack>


    }

    return (
        <>
            <Autocomplete
                sx={{ ...sx }}
                filterOptions={filter}
                id="products"
                value={state}
                // onChange={(e, v, r) => {
                //     handleOptionSelect(v)
                // }}

                onInputChange={(event, newValue, reason) => {
                    if (reason === 'reset') {
                        return;
                    }
                    setSearchText(newValue);
                }}
                open={open}
                onOpen={() => {
                    if (!open) {
                        setOpen(true);
                    }
                }}
                onClose={() => {
                    if (open) {
                        setOpen(false);
                    }
                }}
                disableCloseOnSelect={true}
                onMouseDown={(event) => event.preventDefault()}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => {
                    if (option.name) {
                        return option.name;
                    } else return "";
                }}
                options={options}
                loading={loading}
                loadingText={<>
                    {[...Array(3)].map((e, i) => <Skeleton key={i} height={50} />)}
                </>}
                PaperComponent={SearchPopover}
                ListboxComponent={forwardRef(({ children }, ref) => (
                    <List ref={ref} sx={{ overflow: 'hidden', height: '100%' }}>
                        {children}
                    </List>
                ))}
                renderOption={(props, option) => <OptionList {...props} key={option.id} option={option} handleOptionSelect={handleOptionSelect} />}
                fullWidth
                renderInput={(params) => (
                    <TextField
                        {...params}
                        onClick={(event) => {
                            if (!open) {
                                setOpen(true)
                            }
                        }}
                        required={required}
                        label={t("Product")}
                        placeholder={t("Search or add a product")}
                        variant="filled"
                        // size="small"
                        InputProps={{
                            ...params.InputProps,
                            // The -3px is to align the icon with the text
                            // It's just a workaround, not the best solution
                            // TODO: Find a better solution
                            startAdornment: <InputAdornment position="start" style={{ marginTop: '-3px' }}>
                                <Inventory2Outlined />
                            </InputAdornment>
                        }}
                    />
                )}
            />
            {/* TODO: Add dialog box for adding new product */}
            <AddNewProductDialog open={newProductDialogOpen} onClose={handleNewProductDialogClose} onNewProductAdded={onNewProductAdded} warehouse={warehouse} newProductInitialQuantity={newProductInitialQuantity || 0} newProductInitialName={searchText} />

        </>
    );
};


const OptionList = forwardRef(({ option, handleOptionSelect }, ref) => {
    const { t } = useTranslation();
    const handleOnClick = () => {
        handleOptionSelect(option);
    }

    return <div ref={ref}>
        <ListItemButton onClick={handleOnClick}>
            <ListItemIcon sx={{ minWidth: '26px' }}>
                <Circle sx={{ fontSize: '8px' }} />
            </ListItemIcon>
            <ListItemText
                primary={<div style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical'
                }}>{option.name}</div>}
                secondary={
                    <span>
                        {`${t("Available")}: ${option.quantityInStock}`}
                        {option.barcode && option.barcode.length > 0 && <><br />{option.barcode}</>}
                        {option.sku && option.sku.length > 0 && <><br />{option.sku}</>}
                    </span>
                }
            />    </ListItemButton>
    </div>
});

export default ProductSearch;
