import {
    Typography, Stack,
    Dialog, DialogTitle, DialogContent, DialogActions, Button,
    List,
    ListItem,
    Checkbox,
    ListItemButton,
    ListItemText,
    ToggleButton,
    TextField,
    Popover,
    InputAdornment,
    Divider,
    Box,
    Grid
} from '@mui/material'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { NavigateNext, WarningTwoTone } from '@mui/icons-material';

import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

import StyledToggleButtonGroup from 'components/StyledToggleButtonGroup/StyledToggleButtonGroup';
import { Calendar } from 'react-date-range';
import ImageUploadButton from 'common/ImageUploadButton';

import { formatCurrency, getCurrencySymbol } from 'utils/currency';
import { useSelector } from 'react-redux';
import axios from "axios-config";

import { use } from 'i18next';

const QuoteCreatorDialog = ({ open, onClose, services, repairOrder }) => {
    const theme = useTheme(); // Access the theme object


    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [filteredServices, setFilteredServices] = useState(null);
    const [partner, setPartner] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [taxIncluded, setTaxIncluded] = useState(true);
    const [calendarOpen, setCalendarOpen] = useState(false);
    const [calendarDate, setCalendarDate] = useState(new Date());
    const [anchorEl, setAnchorEl] = useState(null);
    const [refId, setRefId] = useState('');
    const [billingFrom, setBillingFrom] = useState('');
    const [billingTo, setBillingTo] = useState('');
    const [note, setNote] = useState('');
    const [terms, setTerms] = useState('');


    const [selectedImageURL, setSelectedImageURL] = useState(null);
    const [selectedImageFile, setSelectedImageFile] = useState(null);
    const currency = useSelector((state) => state.auth.currency);
    const [taxPercentage, setTaxPercentage] = useState(21);
    const [invoiceGranTotal, setInvoiceGranTotal] = useState(0);
    const [invoiceTotal, setInvoiceTotal] = useState(0);
    const [invoiceTotalTax, setInvoiceTotalTax] = useState(0);
    const [isFormValid, setIsFormValid] = useState(false);
    const [isCreating, setIsCreating] = useState(false);

    const calendarId = 'calendar-popover';
    const dayjs = require('dayjs');

    const handleCalendarOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setCalendarOpen(true);
    };

    const handleCalendarClose = () => {
        setAnchorEl(null);
        setCalendarOpen(false);
    };

    const handleCalendarSelect = (date) => {
        setCalendarDate(date);
        setCalendarOpen(false);
    };

    //Reset form when open
    useEffect(() => {
        setRefId('');
        setBillingFrom('');
        setBillingTo('');
        setCalendarDate(new Date());
        setTaxIncluded(true);
        setTaxPercentage(21);
        setSelectedImageURL(null);
        setSelectedImageFile(null);
        setNote('');
        setTerms('');
    }, [open]);

    useEffect(() => {
        //validate form
        // is refId a number?
        let refIdRegEx = /^[0-9]+$/;


        if (refId && refId.match(refIdRegEx) && billingFrom && billingTo && filteredServices && filteredServices.length > 0) {
            let atLeastOneService = filteredServices.some(service => service.isAccepted);
            if (atLeastOneService) {
                setIsFormValid(true);
            }
            else {
                setIsFormValid(false);
            }

        } else {
            setIsFormValid(false);
        }
    }, [refId, billingFrom, billingTo, filteredServices])





    useEffect(() => {
        if (Array.isArray(services)) {
            // accept all services
            const acceptedServices = services.map(service => ({ ...service, isAccepted: true }));
            // const neutralServices = services.filter(service => service.isAccepted === null);
            setFilteredServices(acceptedServices);
        }

        if (repairOrder) {
            setPartner(repairOrder.outsourcedFrom ? repairOrder.outsourcedFrom.organization : null)
            setCustomer(repairOrder.customer);
            if (repairOrder.customer) {
                setBillingTo(repairOrder.customer.name);

            }
        }
    }, [services, repairOrder, open]);

    useEffect(() => {
        if (filteredServices) {
            let totalTaxCalculation = 0.00;
            let calculationInvoiceGranTotal = filteredServices.reduce((acc, service) => {
                if (!service.isAccepted) return acc;
                let discount = service.discount ? parseFloat(service.discount) : 0;
                if (taxIncluded) {
                    discount = (discount / (1 + taxPercentage / 100));
                }
                let unitPrice = service.unitPrice ? parseFloat(service.unitPrice) : 0;
                if (taxIncluded) {
                    unitPrice = (unitPrice / (1 + taxPercentage / 100));
                }
                let quantity = service.quantity ? service.quantity : 1;
                let subTotal = (unitPrice * quantity - discount);
                let tax = 0;
                if (taxIncluded) {
                    tax = (subTotal * taxPercentage / 100);
                } else {
                    tax = (subTotal * taxPercentage / 100);
                }
                totalTaxCalculation += tax;

                let total = subTotal + tax;
                if (discount > 0) {
                    discount = -discount;
                }
                return acc + total;
            }, 0);
            setInvoiceGranTotal(calculationInvoiceGranTotal);
            setInvoiceTotalTax(totalTaxCalculation);
            // invoice

        }
    }, [filteredServices, taxIncluded, taxPercentage])

    const handleGenerateQuote = async () => {
        try {
            setIsCreating(true);
            let acceptedServices = filteredServices.filter(service => service.isAccepted);
            let formData = new FormData();
            formData.append('refId', refId);
            formData.append('billingFrom', billingFrom);
            formData.append('billingTo', billingTo);
            formData.append('taxIncluded', taxIncluded);
            formData.append('taxAmount', taxPercentage);
            formData.append('currency', currency);
            formData.append('date', calendarDate.toISOString());
            formData.append('quoteItems', JSON.stringify(acceptedServices));
            formData.append('invoiceGranTotal', invoiceGranTotal);
            formData.append('invoiceTotalTax', invoiceTotalTax);
            formData.append('customerId', repairOrder.customer.id);
            formData.append('logo', selectedImageFile);
            formData.append('note', note);
            formData.append('terms', terms);
            // for (let pair of formData.entries()) {
            //     console.log(pair[0] + ', ' + pair[1]);
            // }
            //send to backend
            const response = await axios.post(`/quotes`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.status === 200 || response.status === 201) {
                enqueueSnackbar(`Quote created successfully`, { variant: "success" });
                onClose();
            }
        }
        catch (err) {
            console.log(err);
            enqueueSnackbar(err.response.data.message || `Something went wrong!`, { variant: "error" });
        }
        finally {
            setIsCreating(false);
        }
    };

    const handleServiceToggle = (service) => {
        const servicesCopy = [...filteredServices];
        const serviceIndex = servicesCopy.findIndex(s => s.id === service.id);
        servicesCopy[serviceIndex].isAccepted = !servicesCopy[serviceIndex].isAccepted;
        setFilteredServices(servicesCopy);
    }

    const handleRefIdFiled = (e) => {
        setRefId(e.target.value);
    }

    if (!repairOrder) {
        return null;
    }
    const handleImageSelect = (image) => {
        setSelectedImageURL(URL.createObjectURL(image));
        setSelectedImageFile(image);
    };

    const allowOnlyNumber = (value) => {
        return value.replace(/[^0-9]/g, '')
    }

    const handleImageRmove = () => {
        setSelectedImageURL(null);
        setSelectedImageFile(null);
    }




    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth={'md'}>
            {/* <DialogTitle variant='h4'>
                {t("Quote Creator")}
            </DialogTitle> */}
            <DialogContent>
                {/* <Stack backgroundColor={theme.palette.warningBackground} p={2} spacing={1} borderRadius={"10px"}>
                    <Stack direction={"row"} spacing={1} alignItems={'center'}>
                        <WarningTwoTone sx={{ color: 'red.main' }} />
                        <Typography variant='body' fontWeight={500}>{t("Warning!")}</Typography>
                    </Stack>
             
                </Stack> */}
                <Stack direction={'row'}
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "25px",
                    }}
                >
                    <Stack direction={'row'} alignItems={'center'} spacing={2}>
                        <ImageUploadButton
                            onImageSelected={handleImageSelect}
                            imageUrl={selectedImageURL}
                            onImageRemoved={handleImageRmove}
                            imageStyle={{
                                maxWidth: "190px",
                                maxHeight: "80px",
                                width: "auto",
                                height: "auto",
                                border: "0px solid #e0e0e0",
                            }}
                            noImageStyle={{
                                width: "190px",
                                height: "80px",
                                border: "1px solid #e0e0e0",
                                borderRadius: "5px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                            noImageText={t("Logo")}

                        />

                    </Stack>
                    <Stack spacing={2} alignItems={'flex-end'}>
                        <Stack direction={'row'} spacing={2}>
                            <Typography variant="h5" textAlign={'right'}>
                                {t('Quote')}
                            </Typography>
                            <TextField
                                variant="outlined"
                                size="small"
                                value={refId}
                                onChange={handleRefIdFiled}
                                sx={{
                                    backgroundColor: theme.palette.lightBlue.main,
                                    width: '150px'
                                }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">#</InputAdornment>,
                                }}
                            />
                        </Stack>
                        <Stack direction={'row'} alignItems={'center'} spacing={2}>
                            <Popover
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                anchorEl={anchorEl} open={calendarOpen}
                                id={calendarId} onClose={handleCalendarClose}>
                                <Calendar
                                    date={calendarDate}
                                    onChange={handleCalendarSelect}
                                />
                            </Popover>

                            <Stack direction={'row'} px={'5px'} className={'editableRow clickable'} onClick={handleCalendarOpen} >
                                <Typography>
                                    {t('Date')}: {dayjs(calendarDate).format("DD/MM/YYYY")}
                                </Typography>
                            </Stack>
                        </Stack>

                    </Stack>
                </Stack>
                <Divider />

                <Stack direction={'row'} spacing={5} flex padding="30px" divider={<Divider sx={{ borderColor: '#8B98AB' }} orientation="vertical" flexItem />}>
                    <Stack>
                        <Typography gutterBottom component="div" variant="h5">
                            {t('Quote from')}:
                        </Typography>
                        <TextField multiline maxRows={10} minRows={5} variant={'filled'} value={billingFrom} onChange={(e) => setBillingFrom(e.target.value)} hiddenLabel />
                    </Stack>
                    <Stack direction={'column'} spacing={2} alignItems={'start'} justifyContent={'start'}>
                        <Box>
                            <Box>
                                <Typography gutterBottom component="div" variant="h5">
                                    {t('Quote to')}:
                                </Typography>
                            </Box>
                            < TextField width={'200px'} multiline maxRows={10} minRows={5} variant={'filled'} value={billingTo} onChange={(e) => setBillingTo(e.target.value)} hiddenLabel />
                        </Box>
                    </Stack>
                </Stack>


                {filteredServices && filteredServices.length > 0 ? (
                    <TableContainer>
                        <Table size="small" className='invoiceTable'>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>{t("Item")}</TableCell>
                                    <TableCell>{t("Unit Price")}</TableCell>
                                    <TableCell>{t("Quantity")}</TableCell>
                                    <TableCell>{t("Discount")}</TableCell>
                                    <TableCell>{t("Tax")}</TableCell>
                                    <TableCell>{t("Total")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredServices.map((service) => (
                                    <ItemRow key={service.id} service={service} taxIncluded={taxIncluded} taxPercentage={taxPercentage} handleServiceToggle={handleServiceToggle} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Typography variant='body' color='textSecondary'>{t("No services available")}</Typography>
                )}
                <Grid
                    container
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "30px",
                    }}
                >
                    <Grid item xs={4}>
                        <Grid>
                            <TextField
                                multiline
                                minRows={2}
                                placeholder={t("Note")}
                                variant="outlined"
                                value={note}
                                sx={{ backgroundColor: theme.palette.lightBlue.main }}
                                onChange={(e => setNote(e.target.value))}
                            />
                        </Grid>

                    </Grid>
                    <Grid item xs={6}>
                        <Stack spacing={2}>
                            <Stack direction={'row'} justifyContent={'space-between'}>
                                <Typography>
                                    {t('Base')}:
                                </Typography>
                                <Typography>
                                    {formatCurrency((invoiceGranTotal - invoiceTotalTax), currency)}
                                </Typography>
                            </Stack>
                            <Stack alignItems={'center'}>
                                <Box width={'350px'}>
                                    <StyledToggleButtonGroup
                                        size="small"
                                        value={taxIncluded}
                                        exclusive
                                        onChange={(e) => {
                                            // console.log(e.target.value, 'target')
                                            setTaxIncluded(!taxIncluded);
                                        }}
                                        aria-label="text alignment"
                                        sx={{ backgroundColor: "#F1F9FD" }}
                                    >
                                        <ToggleButton value={true}>{t('Tax Included')}</ToggleButton>
                                        <ToggleButton value={false}>{t('Tax Excluded')}</ToggleButton>
                                    </StyledToggleButtonGroup>
                                </Box>
                            </Stack>
                            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography>
                                    {t('Tax')}:
                                </Typography>
                                <Stack direction={'row'}>
                                    <Box width={'80px'}>
                                        <TextField size="small" value={taxPercentage} onChange={(e) => setTaxPercentage(e.target.value)} />
                                    </Box>
                                    {/* <TaxPercentageSwitch onChange={() => setPercentTax(!percentTax)} checked={percentTax} /> */}
                                </Stack>
                            </Stack>
                            <Divider />
                            <Grid item sx={{ paddingTop: "10px" }}>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Typography sx={{ fontWeight: "bold" }}>
                                        {t('Total tax included')}:
                                    </Typography>
                                    <Typography sx={{ fontWeight: "bold" }}>{formatCurrency(invoiceGranTotal, currency)}
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid>
                    <Grid item xs={12} px={'30px'}>
                        <TextField
                            multiline
                            fullWidth
                            // initialState={invoice.terms}
                            value={terms}
                            onChange={(e) => setTerms(e.target.value)}
                            variant={'outlined'}
                            placeholder={`${t('Add terms or note')}....`}
                            InputProps={{ disableUnderline: true, style: { fontSize: '4mm' } }}
                            sx={{ backgroundColor: theme.palette.lightBlue.main }}

                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color='black'>{t("Cancel")}</Button>
                <LoadingButton loading={isCreating} disabled={!isFormValid} onClick={handleGenerateQuote} loadingPosition="end" variant="contained" endIcon={<NavigateNext />} >
                    {t("Create")}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

const ItemRow = ({ service, taxIncluded, taxPercentage, handleServiceToggle }) => {

    let discount = service.discount ? parseFloat(service.discount) : 0;
    if (taxIncluded) {
        discount = (discount / (1 + taxPercentage / 100));
    }
    let unitPrice = service.unitPrice ? parseFloat(service.unitPrice) : 0;
    if (taxIncluded) {
        unitPrice = (unitPrice / (1 + taxPercentage / 100));
    }
    let quantity = service.quantity ? service.quantity : 1;
    let subTotal = (unitPrice * quantity - discount);
    let tax = 0;
    if (taxIncluded) {
        tax = (subTotal * taxPercentage / 100);
    } else {
        tax = (subTotal * taxPercentage / 100);
    }

    let total = subTotal + tax;
    if (discount > 0) {
        discount = -discount;
    }


    // let total = service.unitPrice * service.quantity - service.discount;
    // // const [tax, setTax] = useState(21);

    // if (!taxIncluded) {
    //     total = total * (1 + taxPercentage / 100);
    //     total = total.toFixed(2);
    // }

    return <TableRow key={service.id}>
        <TableCell>
            <Checkbox
                checked={service.isAccepted}
                onChange={() => handleServiceToggle(service)}
            />
        </TableCell>
        <TableCell>{service.name}</TableCell>
        <TableCell>{unitPrice?.toFixed(2)}</TableCell>
        <TableCell>x{service.quantity}</TableCell>
        <TableCell>{discount?.toFixed(2)}</TableCell>
        <TableCell>{tax.toFixed(2)}</TableCell>
        <TableCell>{total.toFixed(2)} </TableCell>
    </TableRow>
}
export default QuoteCreatorDialog;