import React, { useRef } from "react";
import { useState, useEffect } from 'react';

import {
    Card,
    CardContent,
    Typography,
    Button,
    Paper,
    Grid,
    Divider,
    InputAdornment,
    OutlinedInput,
    TableCell,
    TableRow,
    Stack,
    Box
} from "@mui/material";
import { useReactToPrint } from "react-to-print";
import { useSelector } from 'react-redux';
import "./Print.css"

import axios from "../../axios-config";


//ICONS
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';

import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../utils/currency";

const DeviceDepositTicketDemo = React.forwardRef(({ settings, preview, logo, organization, repairOrder, repairPayments }, ref) => {

    const currency = useSelector((state) => state.auth.currency);
    const { t } = useTranslation();
    let dayjs = require('dayjs')
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const getStyles = () => {
        return `@page { margin: 0px 0px 0px 0px !important; }
        @page {
        size: 80mm 25cm;
        body-width: 80mm!important;
      }
      `;
    };

    const Heading = (props) => {
        const { text, ...other } = props;
        if (!text) return null;
        return <Typography variant="h4" sx={{
            lineHeight: "1.5",
            mt: 2,
        }}
            {...other}
        >
            {text}
        </Typography >
    }
    const Paragraph = (props) => {
        const { text, ...other } = props;
        if (!text) return null;
        return <Typography {...other}> {text}</Typography>
    }

    const InputStyle = ({ data, icon }) => {
        return (
            <Grid
                sx={{
                    width: "100%",
                    mt: 1.5,
                    display: "flex",
                    alignItems: "center",
                    color: "black",
                    fontWeight: "500",
                    border: "1px solid black",
                    padding: "4px",
                    borderRadius: "4px",
                }}
            >
                {icon}

                <Divider
                    orientation="vertical"
                    sx={{
                        borderColor: "#050505",
                        height: "50%",
                        borderWidth: "0.6px",
                    }}
                />
                <Typography fontSize={'3.3mm'} fontWeight={'600'} sx={{ ml: 2 }}>
                    {data}
                </Typography>
            </Grid>
        );
    };
    if (!settings) {
        return "There are no settings"
    }


    const handleOrganizationData = () => {

        if (!settings) return null;
        const orgNameEnabled = settings.organizationNameEnabled;
        const orgAddressEnabled = settings.organizationAddressEnabled;

        if (!orgNameEnabled && !orgAddressEnabled) return null;
        const address = orgAddressEnabled ? ` ${organization?.address} ${organization?.city} ${organization?.postalCode} ${organization?.state}` : "";
        let orgName = orgNameEnabled && organization && organization.name ? organization.name : "";
        if (orgName.length > 0 && address.length > 0) {
            orgName = `${orgName} , `;
        }

        return <Stack direction={'row'} alignItems={'center'} spacing={1} mb={1}>
            <LocationOnOutlinedIcon />
            <Typography fontSize={'3.3mm'}>{orgName} {address}</Typography>
        </Stack>
    }

    if (!settings || !organization || !repairOrder) {
        return null
    }

    let totalDeposit = 0;
    if (repairPayments) {

        totalDeposit = repairPayments.reduce((total, current) => {
            if (current.isDeposit) {
                return total + current.amount;
            }
        }, 0);
    }

    return (
        <>
            <style>{getStyles()}</style>
            <Card sx={{ width: "78mm", marginX: 'auto' }} ref={ref} elevation={2} className={'paper'}>
                <CardContent>
                    {settings && settings.organizationLogoEnabled && (logo) ?
                        < Box
                            sx={{
                                margin: 'auto',
                                display: "flex",
                                justifyContent: 'center',
                                borderBottom: 'none',
                                height: '70px',
                                maxWidth: '200px',
                                mb: '10px',
                            }}
                        >

                            <img className="logo" src={`${process.env.REACT_APP_IMAGES_URL}${logo.fileName}`} alt="logo" />

                        </Box>
                        : null}
                    {settings.title && settings.titleEnabled && settings.title.length > 0 ? <>
                        < Typography
                            variant="h4"
                            gutterBottom
                            sx={{
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {settings?.title}
                        </Typography>

                        <Divider
                            sx={{
                                borderBottomWidth: "2px",
                                borderColor: "#3f4142",
                                mt: 1,
                                mb: 1,
                                ml: 3,
                                mr: 3,
                            }}
                        />
                    </> : null
                    }

                    <Typography
                        variant="h4"
                        component="div"
                        sx={{ textAlign: "center", fontWeight: "bold" }}
                    >
                        {repairOrder.organizationId}#{repairOrder.organizationCounter}
                    </Typography>

                    <Typography
                        variant="h4"
                        sx={{
                            lineHeight: "1.6",
                            fontSize: '5mm',
                            fontWeight: "500",
                            textAlign: "center",
                            mt: 2,
                            mr: 1,
                        }}
                    >
                        {repairOrder.brand} {repairOrder.model}
                    </Typography>

                    <Grid container sx={{ mt: 1, mb: 2 }}>

                        {settings.customerNameEnabled &&
                            repairOrder.customer && repairOrder.customer.name ?
                            < InputStyle icon={<PersonOutlinedIcon sx={{ marginRight: "10px", marginLeft: "8px" }} />} data={repairOrder.customer?.name} />
                            : null}
                        {settings.customerPhoneEnabled && repairOrder.customer && repairOrder.customer.phone ?
                            <InputStyle icon={<PhoneInTalkOutlinedIcon sx={{ marginRight: "10px", marginLeft: "8px" }} />} data={repairOrder.customer?.phone} />
                            : null}
                        {settings.malfunctionEnabled && repairOrder.malfunction ?
                            <InputStyle icon={<HandymanOutlinedIcon sx={{ marginRight: "10px", marginLeft: "8px" }} />} data={repairOrder.malfunction} />
                            : null}
                        {settings.repairOrderNoteEnabled && repairOrder.note ?
                            <InputStyle icon={<CommentOutlinedIcon sx={{ marginRight: "10px", marginLeft: "8px" }} />} data={repairOrder.note} />
                            : null}
                        {repairOrder.password ?
                            <InputStyle icon={<LockOutlinedIcon sx={{ marginRight: "10px", marginLeft: "8px" }} />} data={repairOrder.password} />
                            : null}
                        <Stack direction="row" spacing={2} pt={'10px'} alignItems={'center'} justifyContent={'center'} width={'100%'}>
                            {settings.estimatedServicesEnabled && repairOrder.estimatedServices > 0 ?
                                <Box sx={{ border: '1px solid black', borderRadius: '5px', px: '10px' }}>
                                    <Typography fontSize={'3.3mm'}>{t('Estimated')}</Typography>
                                    <Typography fontSize={'3.3mm'} fontWeight={'600'}>{formatCurrency(repairOrder.estimatedServices,currency)}</Typography>
                                </Box>
                                : null}
                            {settings.repairPaymentsDepositsEnabled && totalDeposit > 0 ?
                                <Box sx={{ border: '1px solid black', borderRadius: '5px', px: '10px' }}>
                                    <Typography fontSize={'3.3mm'}>{t('Deposit')}</Typography>
                                    <Typography fontSize={'3.3mm'} fontWeight={'600'}>{formatCurrency(totalDeposit,currency)}</Typography>
                                </Box>
                                : null}
                        </Stack>
                    </Grid>
                    {settings && settings.note ?
                        <>
                            <Heading text={"Note"} />
                            <Paragraph text={settings.note} />\
                        </>
                        : null
                    }
                    {handleOrganizationData()}
                    <Stack direction={'row'} alignItems={'center'} spacing={1} mb={1}>
                        {settings.dateEnabled && repairOrder.createdAt ?
                            <>
                                <AccessTimeOutlinedIcon />
                                <Typography fontSize={'3.3mm'}>
                                    {dayjs().format('DD-MM-YYYY HH:mm')}
                                </Typography>
                            </>
                            : null}
                    </Stack>
                    {settings.customerSignatureEnabled && settings.customerSignatureLabel.length > 0 ?
                        <Heading text={settings.customerSignatureLabel} marginBottom={10} />
                        : null}
                    {settings.firstNoteEnabled && settings.firstNote.length > 0 ?
                        < Typography mb={2} fontSize={'3.3mm'} sx={{ border: '1px solid black', padding: '10px', wordWrap: 'break-word' }} fontSize={12}>
                            {settings.firstNote}
                        </Typography>
                        : null
                    }
                    {settings.secondNoteEnabled && settings.secondNote.length > 0 ?
                        < Typography fontSize={'3.3mm'} sx={{ wordWrap: 'break-word' }}>
                            {settings.secondNote}
                        </Typography>
                        : null
                    }
                </CardContent>
                <Typography sx={{ color: 'rgba(0,0,0,0)' }}>-</Typography>
            </Card>
        </>
    );
})

export default React.memo(DeviceDepositTicketDemo);
