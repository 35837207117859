import { servicesCalculation } from "./service-helpers"

export const processRepairOrder = (order) => {

    let processedOrder = { ...order };
    processedOrder.processed = {};

    // calculate services
    // if(order.proces)
    processedOrder.processed.services = servicesCalculation(order.services)

    // calculate parts
    if (!order.repairOrderParts) {
        processedOrder.processed.parts = 0;
    } else {
        processedOrder.processed.parts = order.repairOrderParts.reduce((acc, current) => {
            if (current.deletedAt) {
                return acc;
            }
            return acc + (parseFloat(current.unitPrice) * current.quantity);
        }, 0)
    }

    // calculate payments
    if (!order.repairPayments) {
        processedOrder.processed.payments = 0
    } else {
        processedOrder.processed.payments = order.repairPayments.reduce((acc, current) => acc + parseFloat(current.amount), 0)
    }

    // set payment status
    const isDelivered = processedOrder.deliveredAt;
    processedOrder.processed.paymentStatus = null;
    let calc = processedOrder.processed.services.totalAccepted - processedOrder.processed.payments;
    processedOrder.processed.totalUnpaid = 0;

    if (processedOrder.processed.payments < processedOrder.processed.services.totalAccepted) {
        processedOrder.processed.totalUnpaid = parseFloat(processedOrder.processed.services.totalAccepted) - parseFloat(processedOrder.processed.payments);
    }

    if (isDelivered && processedOrder.processed.services.totalAccepted !== 0) {
        if (calc === 0) {
            processedOrder.processed.paymentStatus = "Paid"
        }
        else if (processedOrder.processed.payments > 0 && calc > 0) {
            processedOrder.processed.paymentStatus = "Partial"
        } else if (processedOrder.processed.payments > 0 && calc <= 0) {
            processedOrder.processed.paymentStatus = "Overpaid"
        } else {
            processedOrder.processed.paymentStatus = "Unpaid"
        }
    }


    return processedOrder;

}


