// useUpdateOrderStatusMutation.js
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack'; // Correct import for useSnackbar
import dayjs from 'dayjs';
import { acceptOrderRequest } from 'api/repair-orders'; // Adjust the path accordingly

const useAcceptOrderRequest = () => {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar(); // Use the useSnackbar hook to get enqueueSnackbar

    return useMutation(acceptOrderRequest, {
        onMutate: async ({ repairOrderId }) => {

            // Cancel any outgoing refetches
            await queryClient.cancelQueries({ queryKey: ['repairOrders'], exact: false });

            // Snapshot the previous value
            const previousOrders = queryClient
                .getQueriesData({ queryKey: ['repairOrders'], exact: false })
                .filter(([queryKey, oldData]) => oldData !== undefined);

            console.log('Previous orders: ', previousOrders);

            // Optimistically update all matching queries
            previousOrders.forEach(([queryKey, oldData]) => {
                if (!oldData) {
                    console.warn(`No data for queryKey: ${queryKey}`);
                    return;
                }

                const newData = oldData.map((o) =>
                    o.id === repairOrderId
                        ? {
                            ...o,
                            updatedAt: dayjs().toISOString(),
                            isChangingStatus: true, // Add the pending property
                        }
                        : o
                );

                queryClient.setQueryData(queryKey, newData);
            });

            // Return context with the previous orders for rollback
            return { previousOrders };
        },
        onSuccess: (data, variables) => {
            // Update all matching queries with the new data
            const updatedOrder = data;

            const queries = queryClient
                .getQueriesData({ queryKey: ['repairOrders'], exact: false })
                .filter(([queryKey, oldData]) => oldData !== undefined);

            queries.forEach(([queryKey, oldData]) => {
                if (!oldData) {
                    console.warn(`No data for queryKey: ${queryKey}`);
                    return;
                }

                const newData = oldData.map((o) =>
                    o.id === variables.repairOrderId
                        ? {
                            ...o,
                            ...updatedOrder,
                        }
                        : o
                );

                queryClient.setQueryData(queryKey, newData);
            });

            enqueueSnackbar(`Order accepted`, { variant: 'success' });
        },
        onError: (err, variables, context) => {
            // Rollback to previous orders
            context.previousOrders.forEach(([queryKey, oldData]) => {
                queryClient.setQueryData(queryKey, oldData);
            });

            console.error(err?.response?.data?.message || 'Error updating status');
            enqueueSnackbar(`Failed to accept status`, { variant: 'error' });
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['repairOrders'], exact: false });
        },
    });
};

export default useAcceptOrderRequest;
