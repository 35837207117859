import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Typography, Paper, Tooltip, Stack, Checkbox, TableSortLabel,
    Snackbar, Box, Chip, IconButton, Dialog, TextField, DialogTitle, DialogContent, DialogActions, Button, InputAdornment, ToggleButton, FormControl, InputLabel, Select, MenuItem, FormControlLabel, TablePagination
} from '@mui/material'
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { tableCellClasses } from '@mui/material/TableCell';
import { useTranslation } from 'react-i18next';
import { formatCurrency, getCurrencySymbol } from '../../utils/currency';
import { useTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from "react-redux";
import { AddLinkOutlined, ControlPointDuplicateOutlined, DeleteOutline, DevicesOutlined, Edit, LinkOutlined, MobileFriendlyOutlined, MobileOffOutlined, PriceChangeOutlined, WarningTwoTone } from '@mui/icons-material';
import DebouncedTextField from '../../common/DebouncedTextField';
import { useSnackbar } from 'notistack';
import { set, update } from 'lodash';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import axios from "../../axios-config";
import DialogComponent from '../../common/DialogComponent';
import DeleteDialogWarning from './DeleteDialogWarning';

const ServiceCatalogTable = ({ services, setServices, searchedVal, editMode, selectedServices, setSelectedServices, deviceData }) => {
    const [serviceFilteredRows, setServiceFilteredRows] = useState([]);

    const theme = useTheme();
    const { t } = useTranslation();
    let dayjs = require('dayjs');
    const currency = useSelector((state) => state.auth.currency);
    const currencySymbol = useSelector((state) => state.auth.currencySymbol);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10); // or whatever default you'd like

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    useEffect(() => {
        let fr;
        // if (deviceData === "") {
        if (deviceData) {
            fr = services.filter((row) => {
                return row.deviceCatalogId === deviceData.id;
            });
            fr = fr.filter((row) => {
                const deviceName = row.deviceCatalog?.name || '';
                const serviceName = row.name || '';
                const brand = row.deviceCatalog?.brand || '';

                const mergedString = `${brand} ${deviceName} ${serviceName}`.toLowerCase();
                const searchTerms = searchedVal.toLowerCase().split(' ');

                // Check if each term is present in the mergedString
                return searchTerms.every((term) => mergedString.includes(term));
            });
            // console.log(fr, "fr")
            // setServiceFilteredRows(fr);
            // return
        }
        else {

            fr = services.filter((row) => {
                const deviceName = row.deviceCatalog?.name || '';
                const serviceName = row.name || '';
                const brand = row.deviceCatalog?.brand || '';
                const mergedString = `${brand} ${deviceName} ${serviceName}`.toLowerCase();
                const searchTerms = searchedVal.toLowerCase().split(' ');

                // Check if each term is present in the mergedString
                return searchTerms.every((term) => mergedString.includes(term));
            });
        }
        // } else {
        // fr = services.filter((row) => {
        //     return (
        //         row.name.toLowerCase().includes(searchedVal.toLowerCase()) &&
        //         row.linkedDevice
        //             .toLowerCase()
        //             .includes(deviceData.device_name.toLowerCase())
        //     );
        // });
        // }

        setServiceFilteredRows(fr);
    }, [searchedVal, services, deviceData]);

    useEffect(() => {


        setPage(0);


    }, [searchedVal, services, deviceData])

    // START IMPLEMENTING NEW TABLE
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('updatedAt');

    const isSelected = (id) => selectedServices.indexOf(id) !== -1;

    const handleClick = (event, id) => {
        event.stopPropagation();
        const selectedIndex = selectedServices.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedServices, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedServices.slice(1));
        } else if (selectedIndex === selectedServices.length - 1) {
            newSelected = newSelected.concat(selectedServices.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedServices.slice(0, selectedIndex),
                selectedServices.slice(selectedIndex + 1),
            );
        }
        setSelectedServices(newSelected);
    };


    useEffect(() => {
        // console.log(selectedServices);
    }, [selectedServices])

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = sortedServices().map((service) => service.id);
            setSelectedServices(newSelecteds);
            return;
        }
        setSelectedServices([]);
    };


    const sortedServices = () => {
        return (searchedVal === "" && !deviceData ? services : serviceFilteredRows).sort((a, b) => {
            let comparison = 0;
            if (orderBy === 'updatedAt') {
                const dateA = new Date(a.updatedAt);
                const dateB = new Date(b.updatedAt);
                comparison = dateA.getTime() - dateB.getTime();
            } else if (orderBy === 'price') {
                comparison = parseFloat(a[orderBy]) - parseFloat(b[orderBy]);
            } else {
                comparison = a[orderBy] > b[orderBy] ? 1 : -1;
            }

            return order === 'asc' ? comparison : -comparison; // Adjust based on the sort order
        });
    };

    const handleSortRequest = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleServiceEdit = (updatedService) => {
        setServices(prevServices => {
            const updated = prevServices.map(service =>
                service.id === updatedService.id ? updatedService : service
            );

            // Only update if there's an actual change
            if (JSON.stringify(prevServices) === JSON.stringify(updated)) {
                return prevServices;
            }

            return updated;
        });
    }




    return (<>
        <TableContainer component={Paper} elevation={0} sx={{ pb: '55px' }}>
            <Table>

                <TableHead>
                    <TableRow>
                        {editMode && <TableCell padding="checkbox">
                            <Checkbox
                                color="primary"
                                checked={selectedServices.length === sortedServices().length && sortedServices().length !== 0}
                                onChange={handleSelectAllClick}
                            />
                        </TableCell>
                        }
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'name'}
                                direction={order}
                                onClick={() => handleSortRequest('name')}
                            >
                                <Typography variant='body2' fontWeight={500}>{t("Name")}</Typography>
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align='right'>
                            <TableSortLabel
                                active={orderBy === 'updatedAt'}
                                direction={order}
                                onClick={() => handleSortRequest('updatedAt')}
                            >
                                <Typography variant='body2' fontWeight={500}>{t("Last update")}</Typography>
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align='right'>
                            <TableSortLabel
                                active={orderBy === 'price'}
                                direction={order}
                                onClick={() => handleSortRequest('price')}
                                sx={{ justifyContent: 'flex-end' }}
                            >
                                <Typography variant='body2' fontWeight={500}>{t("Price")}</Typography>
                            </TableSortLabel>

                        </TableCell>
                    </TableRow>
                </TableHead>


                <TableBody >

                    {sortedServices().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, idx) => (

                        <StyledTableRow
                            hover
                            // onClick={(event) => editMode && handleServiceSelected(event, row)}
                            role="checkbox"
                            aria-checked={isSelected(row.id)}
                            tabIndex={-1}
                            key={row.id}
                            selected={isSelected(row.id)}
                        >
                            {editMode && <TableCell padding="checkbox">
                                <Checkbox
                                    color="primary"
                                    checked={isSelected(row.id)}
                                    onClick={(event) => handleClick(event, row.id)}

                                />
                            </TableCell>
                            }
                            <TableCell>
                                {editMode ? <NameCell service={row} onEdit={handleServiceEdit} /> : <Typography variant='body2' fontWeight={500}>{row.name}</Typography>}
                                {row.deviceCatalog && <Typography variant='body2' >{row.deviceCatalog.brand} {row.deviceCatalog.name}</Typography>}
                            </TableCell>
                            <TableCell align='right'>
                                <Tooltip enterDelay={20} title={`${dayjs(row.updatedAt).format('DD MMM. YYYY HH:mm')}`}>
                                    <Typography noWrap>{dayjs(row.updatedAt).fromNow(true)}</Typography>
                                </Tooltip>
                            </TableCell>
                            <TableCell align='right'>
                                {editMode ? <PriceCell service={row} currency={currency} onEdit={handleServiceEdit} /> : <Typography variant='body2' >{formatCurrency(row.price, currency)}</Typography>}
                            </TableCell>

                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={sortedServices().length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t('Rows per page')}

            />
        </TableContainer>
    </>
    );
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const PriceCell = ({ service, currency, onEdit }) => {
    const [price, setPrice] = useState(service?.price);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    useEffect(() => {
        setPrice(service?.price);
    }, [service])

    const onPatchCompleted = async (success, data, field) => {
        if (success) {
            onEdit(data);
            enqueueSnackbar(t('keyUpdatedSuccessfully', { field }), { variant: "success" });
        } else {
            enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        }
    };

    return <DebouncedTextField
        sx={{ width: '120px' }}
        className={`textareaAsTypography editableRow`}
        variant='standard'
        fullWidth
        value={price}
        setValue={setPrice}
        dataKey='price'
        url={`/service-catalogs/${service?.id}/price`}
        onPatchCompleted={(success, data) => onPatchCompleted(success, data, t('Price'))}
        InputProps={{ disableUnderline: true, endAdornment: <Typography variant='body2' >{getCurrencySymbol(currency)}</Typography> }}
        inputProps={{ disableUnderline: true, style: { textAlign: 'right', ...Typography.body2 } }}
        numericOnly
        minValue={0}
        placeholder={"0.00"}
        loadingType='linearBottom'
        onBlurOnly

    />
}

const NameCell = ({ service, onEdit }) => {
    const [name, setName] = useState(service?.name);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const onPatchCompleted = async (success, data, field) => {
        // console.log("patch complete")
        if (success) {
            onEdit(data);
            enqueueSnackbar(t('keyUpdatedSuccessfully', { field }), { variant: "success" });
        } else {
            enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        }
    };

    return <DebouncedTextField
        sx={{ width: '100%' }}
        className={`textareaAsTypography editableRow`}
        variant='standard'
        fullWidth
        value={name}
        setValue={setName}
        dataKey='name'
        url={`/service-catalogs/${service?.id}/name`}
        onPatchCompleted={(success, data) => onPatchCompleted(success, data, t('Name'))}
        InputProps={{ disableUnderline: true, }}
        inputProps={{ disableUnderline: true, style: { ...Typography.body2 } }}
        multiline
        loadingType='linearBottom'
    />
}

const EditServiceCatalogDialog = ({ dialogOpen, handleDialogClose, service, onEdit }) => {
    const [name, setName] = useState(service?.name);
    const [price, setPrice] = useState(service?.price);
    const { t } = useTranslation();
    const currency = useSelector((state) => state.auth.currency);
    const { enqueueSnackbar } = useSnackbar();

    const onPatchCompleted = async (success, data, field) => {
        if (success) {
            onEdit(data);
            enqueueSnackbar(t('keyUpdatedSuccessfully', { field }), { variant: "success" });
        } else {
            enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        }
    };

    useEffect(() => {
        if (!service) {
            handleDialogClose();
            return;
        }
        setName(service?.name);
        setPrice(service?.price);

    }, [service])

    return <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth maxWidth={'xs'}>
        <DialogTitle variant='h4'>
            Service Edit
        </DialogTitle>
        <DialogContent>
            <Stack spacing={2}>
                <DebouncedTextField
                    variant='filled'
                    fullWidth
                    value={name}
                    setValue={setName}
                    label={t("Service name")}
                    dataKey='name'
                    url={`/service-catalogs/${service?.id}/name`}
                    onPatchCompleted={(success, data) => onPatchCompleted(success, data, t('Service name'))}
                    multiline
                    maxRows={2}
                    minRows={2}
                />
                <DebouncedTextField
                    variant='filled'
                    fullWidth
                    value={price}
                    setValue={setPrice}
                    label={t("Price")}
                    dataKey='price'
                    url={`/service-catalogs/${service?.id}/price`}
                    onPatchCompleted={(success, data) => onPatchCompleted(success, data, t('Price'))}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>,
                    }}
                    numericOnly
                    minValue={0}
                    placeholder={"0.00"}
                />


            </Stack>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleDialogClose}>{t("Close")}</Button>
        </DialogActions>
    </Dialog>
}








export default ServiceCatalogTable;