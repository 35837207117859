import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Typography, Paper, Tooltip, Stack, Checkbox, TableSortLabel,
    Snackbar, Box, Chip, IconButton, Dialog, TextField, DialogTitle, DialogContent, DialogActions, Button, InputAdornment, ToggleButton, FormControl, InputLabel, Select, MenuItem, FormControlLabel
} from '@mui/material'
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { tableCellClasses } from '@mui/material/TableCell';
import { Trans, useTranslation } from 'react-i18next';
import { formatCurrency, getCurrencySymbol } from '../../utils/currency';
import { useTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from "react-redux";
import { AddLinkOutlined, ControlPointDuplicateOutlined, DeleteOutline, DevicesOutlined, Edit, LinkOutlined, MobileFriendlyOutlined, MobileOffOutlined, PriceChangeOutlined, WarningTwoTone } from '@mui/icons-material';
import DebouncedTextField from '../../common/DebouncedTextField';
import { useSnackbar } from 'notistack';
import { set } from 'lodash';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import axios from "../../axios-config";
import DialogComponent from '../../common/DialogComponent';


const AdjustPriceDialog = ({ dialogOpen, handleDialogClose, selectedServices, onEdit, services, setServices }) => {
    const [operationType, setOperationType] = useState('adjust');
    const [price, setPrice] = useState();
    const { t } = useTranslation();
    const currency = useSelector((state) => state.auth.currency);
    const { enqueueSnackbar } = useSnackbar();
    const [roundDecimals, setRoundDecimals] = useState(false);


    const onPatchCompleted = async (success, data, field) => {
        if (success) {
            handleServicesEdit(data);
            enqueueSnackbar(t('keyUpdatedSuccessfully', { field }), { variant: "success" });
        } else {
            enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        }
    };

    const handleServicesEdit = (updatedServices) => {
        const newServices = services.map(service =>
            updatedServices.find(updatedService => updatedService.id === service.id) || service
        );
        setServices(newServices);
    }

    const handleAdjustmnetSelect = (e, type) => {

        setOperationType(type.props.value);
    }
    const handleSave = async () => {
        try {
            const serviceIds = selectedServices;
            const sendData = {
                serviceIds,
                operationType,
                price,
                roundDecimals
            };


            const response = await axios.patch(`/service-catalogs/bulk/price`, sendData);
            if (response.status === 200) {
                handleServicesEdit(response.data);
                closeDialog();
            }

        } catch (err) {
            console.log(err)
            enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        }
    }

    const closeDialog = () => {
        setOperationType('adjust');
        setPrice();
        setRoundDecimals(false);
        handleDialogClose();
    }

    return <Dialog open={dialogOpen} onClose={closeDialog} fullWidth maxWidth={'xs'}>
        <DialogTitle variant='h4'>
            {t("Adjust price")}
        </DialogTitle>
        <DialogContent>
            <Stack spacing={2}>
                <Typography variant='h6' color='textSecondary'>
                    <Trans i18nKey="selectedService" count={selectedServices.length}>
                        {{ count: selectedServices.length }} service selected
                    </Trans>
                </Typography>
                <FormControl variant="filled" >
                    <InputLabel id="demo-simple-select-filled-label">{t("Operation type")}</InputLabel>
                    <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={operationType}
                        onChange={handleAdjustmnetSelect}
                    >
                        <MenuItem value={'adjust'}>{t("Adjust by value +/-")}</MenuItem>
                        <MenuItem value={'percent'}>{t("Adjust by %")}</MenuItem>
                        <MenuItem value={'set'}>{t("Set new value")}</MenuItem>
                    </Select>
                    {operationType == 'adjust' && <Typography variant='body2' color='textSecondary'>{t("Add or substract from price.")}</Typography>}
                    {operationType == 'percent' && <Typography variant='body2' color='textSecondary'>{t("Adjust procentually the price.")}</Typography>}
                    {operationType == 'set' && <Typography variant='body2' color='textSecondary'>{t("Set the price for all the selected services.")}</Typography>}
                </FormControl>
                <TextField
                    fullWidth
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    label={t("Price")}
                    variant='filled'
                    placeholder='0.00'
                    InputProps={{
                        startAdornment: <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>,
                        endAdornment: (
                            <div>
                                {operationType === 'adjust' && <Typography variant='body2' color='textSecondary'>+/-</Typography>}
                                {operationType === 'percent' && <Typography variant='body2' color='textSecondary'>%</Typography>}
                                {/* {adjustmentType === 'set' && <Typography variant='body2' color='textSecondary'></Typography>} */}
                            </div>
                        )
                    }}
                />
                {/* <ToggleButtonGroup value={adjustmentType} exclusive onChange={handleAdjustmnetSelect} >
                    <ToggleButton value="adjust">Adjust</ToggleButton>
                    <ToggleButton value="percentage">Percentage</ToggleButton>
                    <ToggleButton value="set">Set</ToggleButton>
                </ToggleButtonGroup> */}
                <FormControlLabel control={<Checkbox checked={roundDecimals} onChange={() => setRoundDecimals(!roundDecimals)} />} label={t("Round decimals.")} />
            </Stack>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleDialogClose}>{t("Close")}</Button>
            <Button onClick={handleSave}>{t("Save")}</Button>
        </DialogActions>
    </Dialog>
}

export default AdjustPriceDialog;