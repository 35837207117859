import { useState, useEffect } from "react";
import {
    DialogContent, DialogContentText, DialogActions, Stack, ToggleButtonGroup, FormControl, MenuItem, Button,
    TextField, InputLabel, Select, FormControlLabel, Checkbox, InputAdornment, ToggleButton
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { processService } from '../../../utils/service-helpers';
import axios from "../../../axios-config";
import { useSnackbar } from "notistack";

import { getCurrencySymbol } from "../../../utils/currency";
import ProductSearch from "containers/Inventory/Transfer/ProductSearch";
import { LoadingButton } from "@mui/lab";


const NewPartTab = ({ repairOrder, services, handleAddNewService, handleAddServiceClose, onPartsAdded, isHandlingRequest,setIsHandlingRequest }) => {

    const initialServiceState = { name: '', quantity: 1, unitPrice: '', discount: '' };
    const currency = useSelector((state) => state.auth.currency);

    const [formErrors, setFromErrors] = useState({ ...initialServiceState });

    const initialPartState = { name: '', quantity: 1, unitPrice: '' };
    const [part, setPart] = useState({ ...initialPartState });
    const [warehouses, setWarehouses] = useState(null);
    const [loadingWarehouses, setLoadingWarehouses] = useState(false);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);


    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const handleNewPartState = (prop) => (event) => {
        setPart(prevState => {
            return { ...prevState, [prop]: event.target.value }
        });
    }

    const handleNewPart = async () => {
        try {
            setIsHandlingRequest(true);
            const data = { ...part };
            const response = await axios.post(`/repair-orders/${repairOrder.id}/parts`, data);

            if (response.status == 200 || response.status == 201) {
                enqueueSnackbar(`Part added!`, { variant: "success" });
                handleAddServiceClose();
                onPartsAdded(response.data);
            }
        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        }finally{
            setIsHandlingRequest(false);
        }
    }

    const handleAddProduct = async (product) => {
        // Attach to repair, send to backend that, decrease from inventory
        if (product.quantityInStock <= 0) {
            // TODO: show a dialog if they want to add to stock before proceeding
            enqueueSnackbar("There is not enough stock.", { variant: 'error' })
            return;
        }
        // Set part
        try {
            const data = { name: product.name, quantity: 1, unitPrice: product.cost, productInventoryId: product.id, warehouseId: selectedWarehouse.id };
            const response = await axios.post(`/repair-orders/${repairOrder.id}/parts`, data);

            if (response.status == 200 || response.status == 201) {
                enqueueSnackbar(`Part added!`, { variant: "success" });
                handleAddServiceClose();
                onPartsAdded(response.data);
            }
        } catch (err) {
            console.log(err.response.data.message);
            enqueueSnackbar(err.response.data.message, { variant: 'error' });
        }
    }

    useEffect(() => {
        // Fetch inventory data from API or database
        // and update the state
        fetchDefaultWarehouse();
    }, []);

    const fetchDefaultWarehouse = async () => {
        try {
            // Make API call or database query to fetch inventory data
            // setWarehouseLoading(true);
            const response = await axios.get('/inventory/warehouse/default');
            if (response.status === 200) {
                const data = await response.data;
                setWarehouses([data])
                setSelectedWarehouse(data);
            }
        } catch (error) {
            console.error(error);
        }
        // setWarehouseLoading(false);
    };


    return <>
        <DialogContent>
            <DialogContentText>{t('Parts used on this order, will be calculated as an expense on charts')}.</DialogContentText>
            <Stack spacing={2}>
                {warehouses &&
                    <FormControl variant="filled">
                        <InputLabel id="demo-simple-select-label" variant="filled">{t("Warehouse")}</InputLabel>
                        <Select
                            size='small'
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedWarehouse ? selectedWarehouse.name : ""}
                            label={t("Warehouse")}
                            variant="filled"
                        // onChange={handleChange}
                        >
                            <MenuItem value={selectedWarehouse?.name}>{selectedWarehouse?.name}</MenuItem>
                        </Select>
                    </FormControl>
                }
                {selectedWarehouse && <ProductSearch onOptionSelect={handleAddProduct} warehouse={selectedWarehouse} newProductInitialQuantity={1} />}

            </Stack>
            {/* <Stack direction={'row'} spacing={2} pt={3}>
                <TextField
                    variant="filled"
                    error={formErrors.name != ''}
                    id="name"
                    label={t("Part name")}
                    type='text'
                    fullWidth
                    value={part.name}
                    onChange={handleNewPartState('name')}
                />
            </Stack>
            <Stack direction={'row'} spacing={2} pt={3}>
                <TextField
                    variant="filled"
                    id="unit-price"
                    label={t("Unit price")}
                    type='number'
                    value={part.unitPrice}
                    onChange={handleNewPartState('unitPrice')}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>,
                    }}
                />
                <TextField
                    variant="filled"
                    id="quantity"
                    label={t("Quantity")}
                    type='number'
                    value={part.quantity}
                    onChange={handleNewPartState('quantity')}
                    sx={{ width: '150px' }}
                />
            </Stack> */}
        </DialogContent>
        <DialogActions >
            <Button onClick={handleAddServiceClose}>{t('Cancel')}</Button>
            {/* <LoadingButton onClick={handleNewPart} loading={isHandlingRequest}>{t('Add')}</LoadingButton> */}
        </DialogActions>
    </>

}
export default NewPartTab;