import React from "react";

import DeviceDepositTicketDemo from "./DeviceDepositTicketDemo";
import DeviceDepositA4SplitDemo from "./DeviceDepositA4SplitDemo";

import { usePrintSettings } from "../../hooks/usePrintSettings";

const PrintComponent = React.forwardRef((props, ref) => {


  const { settings, logo, organization } = usePrintSettings();



  // if (!props.settings || !props.settings.paperType) {
  //   if (settings.paperType === "0") {
  //     return <DeviceDepositTicketDemo settings={settings} organization={organization} logo={logo} ref={ref} repairOrder={props.repairOrder} repairPayments={props.repairPayments} />;
  //   } else {
  //     return <DeviceDepositA4SplitDemo settings={settings} logo={props.settings?.file} ref={ref} repairOrder={props.repairOrder} organization={organization} repairPayments={props.repairPayments} />;
  //   }
  // } else {
  if (settings.paperType === "0") {
    return <DeviceDepositTicketDemo settings={settings} organization={organization} logo={logo} ref={ref} repairOrder={props.repairOrder} repairPayments={props.repairPayments} />;
  } else {
    // return "Something went wront"
    return <DeviceDepositA4SplitDemo settings={settings} organization={organization} logo={logo} ref={ref} repairOrder={props.repairOrder} repairPayments={props.repairPayments} />;
  }
  // }
});




export default PrintComponent;
