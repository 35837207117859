import React, { useState } from "react";
import Notification from "../Notification"
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Handyman } from "@mui/icons-material";

function RepairRequestNotification({ notification, handleClose }) {

    let navigate = useNavigate();
    const { t } = useTranslation();

    // const handleNotificationClick = () => {
    //     if (!notification.repairOrder) return;
    //     navigate(`/editEntry/${notification.repairOrder.organizationCounter}`, { replace: true });
    //     handleClose();
    // };

    const modifiedTitle = () => {
        return <Box component={'span'}>{t("notifications.repairRequest.title")}</Box>;
    };

    const getBody = () => {
        let body = "You have a new repair request";
        if (notification.performedBy?.organization?.name) {
            body = t('notifications.repairRequest.body', { performedByName: notification.performedBy.organization.name });
        }
        return body;
    };

    return (
        <Notification
            notification={notification}
            id={notification.id}
            isRead={notification.isRead}
            avatar={<Avatar sx={{ bgcolor: 'orange.main' }}><Handyman /></Avatar>}
            body={getBody()}
            title={modifiedTitle()}
        />
    );
}

export default RepairRequestNotification;