import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    showNewClientModal: false,
    nextButtonLoading: false,
    customerData: {
        name: '',
        phone: '',
        email: '',
        taxIdentifier:'',
        address:'',
    },
};

const newCustomerModalSlice = createSlice({
    name: 'newCustomerModal',
    initialState: initialState,
    reducers: {
        openModal(state) {
            state.showNewClientModal = true;
        },
        closeModal(state) {
            state.showNewClientModal = false;
        },
        updateName(state, action) {
            state.customerData.name = action.payload;
        },
        updatePhone(state, action) {
            state.customerData.phone = action.payload;
        },

        updateEmail(state, action) {
            state.customerData.email = action.payload;
        },
        updateTaxIdentifier(state, action) {
            state.customerData.taxIdentifier = action.payload;
        },
        updateAddress(state, action) {
            state.customerData.address = action.payload;
        },
        clear: (state) => {
            state.nextButtonLoading = initialState.nextButtonLoading;
            state.customerData = initialState.customerData;
        },
    }
})

export const newCustomerModalActions = newCustomerModalSlice.actions;
export default newCustomerModalSlice.reducer;