import * as React from 'react';
import { Box, display } from '@mui/system';
import {
    Typography, Button, IconButton, Tab, Tabs, InputAdornment,
    FormControl, MenuItem, Select, InputLabel, ToggleButtonGroup, ToggleButton,
    FormControlLabel, Checkbox
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import axios from "../../../axios-config";
import { useEffect, useState, memo } from 'react';
import { useParams } from "react-router-dom";
import Popover from '@mui/material/Popover';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/system';

import { useTheme } from '@emotion/react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { TextField } from '@mui/material';
import ReactiveTextField from '../../../components/ReactiveTextField/ReactiveTextField';
import { useSelector } from 'react-redux';
import { toggleButtonClasses } from '@mui/material/ToggleButton';
import ColoredToggleButton from '../../../common/ColoredToggleButton';
import { getCurrencySymbol } from '../../../utils/currency';




const EditPaymentDialog = ({ dialogOpen, handleDialogClose, payment, onEdit }) => {
    const { enqueueSnackbar } = useSnackbar();
    const currency = useSelector((state) => state.auth.currency);
    const onBlur = async (key, newValue) => {
        const value = newValue;
        try {
            const response = await axios.patch(`repair-orders/payments/${payment.id}/${key}`, { value: value })
            if (response.status !== 200) {
                throw response.status;
            }
            if (response.status === 200) {
                onEdit(response.data);
                enqueueSnackbar(`${key} updated succesfully!`, { variant: "success" });
            }
        } catch (err) {
            console.log(err)
            enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        }
    }

    return (
        <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth maxWidth={'xs'}>
            <DialogTitle variant='h4'>
                Payment Edit
            </DialogTitle>
            <DialogContent>
                <Stack direction={'row'} spacing={2} pt={3}>
                    <ToggleButtonGroup
                        color="primary"
                        exclusive
                        value={payment.paymentType}
                        onChange={(event) => onBlur('paymentType', event.target.value)}

                    >
                        <ColoredToggleButton color="green" value={'in'}>Pay in</ColoredToggleButton>
                        <ColoredToggleButton color="red" value={'out'}>Pay out </ColoredToggleButton>
                    </ToggleButtonGroup>
                </Stack>
                <Stack direction={'row'} spacing={2} pt={3}>
                    <FormControl fullWidth>
                        {/* <InputLabel id="Amount">Amount</InputLabel> */}
                        <ReactiveTextField
                            fullWidth
                            initialState={payment.amount}
                            type='number'
                            label="Amount"
                            onBlurCallback={(newValue) => onBlur('amount', newValue)}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>,
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel >Payment method</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Payment method"
                            value={payment.paymentMethod}
                            onChange={(event) => onBlur('paymentMethod', event.target.value)}
                        >
                            <MenuItem value={0}>Cash</MenuItem>
                            <MenuItem value={1}>Card</MenuItem>
                            <MenuItem value={2}>Transfer</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>


                <FormControlLabel control={<Checkbox checked={payment.isDeposit} onChange={(e) => onBlur('isDeposit', e.target.checked)} />} label="Deposit" />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default memo(EditPaymentDialog);