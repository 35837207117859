import { Typography, Button, Grid, Stack, Box, Container } from '@mui/material';
import BoxContainer from 'common/BoxContainer/BoxContainer';
import NavigationBar from 'components/Navbar/NavigationBar';
import React, { useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import axios from "axios-config";

import { useStore } from 'react-redux';
import { authActions } from 'store/auth';
const SubscriptionPage = () => {
    const { t } = useTranslation();
    const store = useStore();
    const dispatch = store.dispatch;

    // use effect to check subscription status every 5 seconds


    const fetchSubscriptionStatus = useCallback(async () => {
        const response = await axios.get(`/organizations/subscription-status`);
        if (response.status === 200) {
            console.log('DISPATCH')
            dispatch(authActions.updateActiveSubscription(true));

        }
    })

    useEffect(() => {
        fetchSubscriptionStatus();
        const interval = setInterval(() => {
            fetchSubscriptionStatus();
        }, 5000);
        return () => clearInterval(interval);
    }, []);


    return (
        <NavigationBar activeSubscription={false} >
            <Container>
                <div className='headerPush'></div>
                <Grid container spacing={2} mt={8}>
                    <Grid item xs={12} md={8}>
                        <Stack spacing={2}>
                            <BoxContainer>
                                <Stack direction={'row'} justifyContent={'space-between'} mb={3}>
                                    <Typography variant={'overline'} color={'darkGrey.main'} textTransform={'uppercase'} mb={3}>
                                        {t('Subscription expired!')}

                                    </Typography>
                                    <Stack direction={'row'}>
                                        {/* <Button variant='outlined' color={'red'}>
                                                Cancel plan
                                            </Button> */}
                                    </Stack>
                                </Stack>
                                <Typography variant={'body1'} mb={2}>
                                    {!process.env.REACT_APP_COSTI_LOCK && <Trans i18nKey="yourSubscriptionHasExpired">
                                        Your subscription has expired. Please go to
                                        <a href="https://dalvado.com/support">support</a>
                                        to get support.
                                    </Trans>}
                                </Typography>

                            </BoxContainer>
                        </Stack>
                    </Grid>

                </Grid>
            </Container >
        </NavigationBar>
    );
};

export default SubscriptionPage;