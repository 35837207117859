import { Box } from "@mui/material";
import { styled } from '@mui/material/styles';
import './Section.css'


const Section = (props) => {

    return <Box className={props.variant == 'sm' ? 'section-sm' : 'section'} size={props.size} {...props.sx}>
        {props.children}
    </Box>
}

export default Section;