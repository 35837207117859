/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { useDropzone } from "react-dropzone";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
// import UploadProgress from "../../FileUpload/UploadProgress";

function ImageUpload({ handleUploadDone }) {
  const [files, setFiles] = useState([]);
  const [uploadCompletedFiles, setUploadCompledFiles] = useState([]);

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "25px 30px",
    borderWidth: 2,
    borderRadius: 16,
    borderColor: "#c8c5c5",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
    backgroundColor: "#6cb9e92b",
  };

  const acceptStyle = {
    borderColor: "#2196f3",
    backgroundColor: "#6cb9e92b",
  };

  const rejectStyle = {
    borderColor: "#da3535",
    backgroundColor: "da3535",
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        "image/jpeg": [],
        "image/png": [],
      },
      maxFiles: 1,
      onDrop: (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              progress: 0,
            })
          )
        );
      },
    });

  const handleFilesProgress = () => {
    const timer = setTimeout(() => {
      const newFiles = [];
      const uploadDone = uploadCompletedFiles;

      files.forEach((file) => {
        const obj = JSON.parse(JSON.stringify(file));
        obj.progress = obj.progress + 25;

        if (obj.progress < 100) {
          newFiles.push(obj);
        } else if (obj.progress >= 100) {
          uploadDone.push(obj);
          setUploadCompledFiles(uploadDone);
          handleUploadDone(uploadDone);
        }
      });

      if (newFiles.length === 0) {
        setFiles([]);
        clearTimeout(timer);
      } else {
        setFiles(newFiles);
      }
    }, 1200);
  };

  useEffect(() => {
    if (files.length > 0) {
      handleFilesProgress();
    }
  }, [files]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
   
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <Grid container justifyContent={"center"} alignItems="center">
      <Card
        sx={{ width: 800, borderRadius: "20px", padding: "20px" }}
        elevation={0}
      >
        <CardContent>
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <Grid>
              <InsertPhotoIcon sx={{ fontSize: "80px", color: "#b3ceff82" }} />
            </Grid>
            <Typography variant="h4" sx={{ color: "#36496afa" }} gutterBottom>
              Drop Your image here, or{" "}
              <span style={{ color: "#3c91c7", cursor: "pointer" }}>
                browse
              </span>
            </Typography>
            <Typography variant="p" sx={{ color: "#1b4c7a85" }}>
              Supports: PNG, JPEG, JPG
            </Typography>
          </div>
          {files.length > 0 && (
            <Grid container sx={{ mt: 4 }}>
              {/* {files.map((file, idx) => (
                <UploadProgress
                  key={idx}
                  file={file}
                  progress={file.progress}
                />
              ))} */}
            </Grid>
          )}

          {uploadCompletedFiles.length > 0 && (
            <Grid container sx={{ mt: 4 }}>
              {/* {uploadCompletedFiles.map((file, idx) => (
                <UploadProgress key={idx} file={file} progress={100} />
              ))} */}
            </Grid>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
}

export default ImageUpload;
