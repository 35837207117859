import React, { useState } from "react";
import Notification from "../Notification"
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HandymanRounded, NotificationsActive, SubjectRounded } from "@mui/icons-material";

function NotificationRemoveAccepted({ notification, handleClose }) {

    let navigate = useNavigate();
    const { t } = useTranslation();
    const handleNotificationClick = () => {

        if (!notification.repairOrder) return;
        navigate(`/editEntry/${notification.repairOrder.id}`, { replace: true });
        handleClose();
    };

    const modifiedTitle = () => {
        return <Box component={'span'}>{t("notifications.serviceAcceptanceRemoved.title")}</Box>
    }

    const getBody = () => {
        if (!notification.performedBy) {
            return '';
        }

        return t('notifications.serviceAcceptanceRemoved.body', { performedBy: notification.performedBy });
    }

    return (
        <Notification
            notification={notification}
            id={notification.id}
            isRead={notification.isRead}
            avatar={<Avatar sx={{  }}><HandymanRounded /></Avatar>}
            body={getBody()}
            title={modifiedTitle()}
            handleNotificationClick={handleNotificationClick}
        />
    );
}

export default NotificationRemoveAccepted;