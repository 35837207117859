import * as React from 'react';
import { Typography, Stack, Chip, Button, Checkbox } from '@mui/material';
import axios from '../../axios-config';
// ICONS
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { WhatshotRounded } from '@mui/icons-material';
import EmergencyIconFilled from '../Icons/EmergencyIconFilled';
import { useSnackbar } from 'notistack';


const EmergencyCheckbox = ({ repairOrder, onChange }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleEmergencyCheck = async (event) => {
        onChange(event.target.checked);
        try {
            const response = await axios.patch(`/repair-orders/${repairOrder.id}/levelOfAttention`, { levelOfAttention: event.target.checked });
            if (response.status === 200 || response.status === 201) {
                enqueueSnackbar(`Level of attention updated!`, { variant: "success" });
            }
        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        }
    }
    return (
        <Checkbox onChange={handleEmergencyCheck} checked={repairOrder.levelOfAttention == 1 ? true : false} icon={<WhatshotRounded color='darkGrey' />} checkedIcon={<EmergencyIconFilled />} inputProps={{ 'aria-label': 'controlled' }}
        />
    )
}

export default EmergencyCheckbox;