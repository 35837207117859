import * as React from 'react';
import { Box, display } from '@mui/system';
import {
    Typography, Button, Stack, Grid, Divider, TextField, List,
    ListItem, ListItemAvatar, Avatar, ListItemText, Tab, Tabs,
    FormControl, Select, MenuItem, ListSubheader, Container, Dialog, DialogTitle, DialogContent, DialogActions, IconButton
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import axios from "../../axios-config";
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from '@mui/material/styles';
import { authActions } from '../../store/auth';
import ReactiveTextField from '../../components/ReactiveTextField/ReactiveTextField';
import '../../App.css';
import { lightBlue } from '@mui/material/colors';

import { useTranslation } from "react-i18next";
import { BorderRight } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import PartnerRequests from './PartnerRequests';
import BoxContainer from '../../common/BoxContainer/BoxContainer';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
//ICONS
import AddIcon from '@mui/icons-material/Add';
import ConsoleHelper from '../../utils/ConsoleHelper';
import InviteUserForm from './InviteUserForm';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import CurrencySelect from './CurrencySelect';
import StyledAvatar from 'components/StyledAvatar/StyledAvatar';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}

const OrganizationSettings = ({ changeColor }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    let dayjs = require('dayjs');
    var relativeTime = require('dayjs/plugin/relativeTime')
    dayjs.extend(relativeTime);

    const [organizationData, setOrganizationData] = useState({});

    const [editedOrganizationData, setEditedOrganizationData] = useState({
        name: '',
        legalName: '',
        taxIdentifier: '',
        address: '',
        state: '',
        city: '',
        postalCode: '',
        currency: ''
    });
    const [isEdited, setIsEdited] = useState(false);

    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const [tabValue, setTabValue] = useState(0);


    document.body.style.backgroundColor = 'white';
    useEffect(async () => {
        try {
            const response = await axios.get(`/organizations`);
            const successfuly = response.status === 200 || response.status === 201;
            if (successfuly) {
                ConsoleHelper(response.data);
                setOrganizationData(response.data);
                setEditedOrganizationData(response.data);
                setLoading(false);
                if (response.data.organizationSubscription) {
                    dispatch(authActions.updateSubscription({
                        subscriptionType: response.data.organizationSubscription.subscriptionType,
                        endDate: response.data.organizationSubscription.endDate
                    }));
                    localStorage.setItem('subscriptionType', response.data.organizationSubscription.subscriptionType);
                    localStorage.setItem('endDate', response.data.organizationSubscription.endDate);
                }
            }
        }
        catch (err) {
            console.log(err);
        }
    }, [])

    useEffect(() => {
        validate();
    }, [editedOrganizationData])

    const validate = () => {
        // console.log(organizationData.name, editedOrganizationData.name)
        setIsEdited(false);
        // if (loading || editedOrganizationData.name.length < 1)
        //     return;

        if (organizationData.name !== editedOrganizationData.name) {
            setIsEdited(true);
        }
        if (organizationData.legalName !== editedOrganizationData.legalName) {
            setIsEdited(true);
        }
        if (organizationData.taxIdentifier !== editedOrganizationData.taxIdentifier) {
            setIsEdited(true);
        }
        if (organizationData.address !== editedOrganizationData.address) {
            setIsEdited(true);
        }
        if (organizationData.state !== editedOrganizationData.state) {
            setIsEdited(true);
        }
        if (organizationData.city !== editedOrganizationData.city) {
            setIsEdited(true);
        }
        if (organizationData.postalCode !== editedOrganizationData.postalCode) {
            setIsEdited(true);
        }
        if (organizationData.currency !== editedOrganizationData.currency) {
            setIsEdited(true);
        }
        if (editedOrganizationData.currency === '' || editedOrganizationData.currency === null) {
            setIsEdited(false);
        }
    }

    const handleInput = (event) => {

        setEditedOrganizationData((prevState) => {
            return { ...prevState, [event.target.name]: event.target.value }
        });
    }
    const handleSave = async () => {
        try {
            const response = await axios.patch(`/organizations/settings`, editedOrganizationData);
            if (response.status === 200 || response.status === 201) {
                setOrganizationData({ ...editedOrganizationData });
                setIsEdited(false);
                enqueueSnackbar("Settings updated succesfully", { variant: "success" })
                dispatch(authActions.updateAuthOrganization({ name: response.data.name, currency: response.data.currency }));
            }
        }
        catch (err) {
            console.log(err);
        }
    }
    const onCurrencyChange = (currency) => {
        ConsoleHelper(currency, 'currency');
        setEditedOrganizationData(prevState => ({ ...prevState, currency: currency }));
    }

    const getSubscriptionType = () => {
        const sub = organizationData.organizationSubscription;

        const subsType = sub.subscriptionType;

        const now = dayjs();
        const untilEndDate = dayjs(sub.endDate).diff(now, 'day');

        if (subsType === 'partnerPremium') {
            return <>
                <Typography variant='h4'>{t('Premium')}</Typography>
                {!process.env.REACT_APP_COSTI_LOCK && <Typography variant='caption' textTransform={'uppercase'} color={'darkGrey.main'}>  ({t("Dalvado's partner")})</Typography>}
            </>
        }
        if (subsType === 'premium') {
            return <>
                <Typography variant='h4'>{t('Premium')}</Typography>
                <Typography variant='caption' textTransform={'uppercase'} color={'darkGrey.main'}>{dayjs(sub.endDate).format('DD.MMM.YYYY')}</Typography>

            </>
        }
        if (subsType === 'trial') {
            return <>
                <Typography variant='h4'>{t('Premium')}</Typography>
                <Typography variant='caption' textTransform={'uppercase'} color={'darkGrey.main'}>  {t("Trial")}: {t("daysLeft", { count: untilEndDate })}</Typography>
            </>
        }
    }
    if (loading)
        return <><Typography>Loading...</Typography></>


    return (
        <>

            <Container>
                <div className='headerPush'></div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box mt={5}>
                            <Typography variant='h4' >{t('Organization settings')}</Typography>
                            <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)} sx={{ mb: '15px' }}>
                                <Tab label={"General"} />
                                <Tab label={"Plan"} />
                            </Tabs>
                        </Box>
                    </Grid>
                </Grid>
                <TabPanel value={tabValue} index={0}>
                    <Grid container spacing={2} mb={5}  >
                        <Grid item xs={12} md={6}>
                            <BoxContainer sx={{ mb: 2 }}>
                                <Stack spacing={2} alignItems={'flex-start'}>
                                    <Typography variant={'overline'} color={'darkGrey.main'} textTransform={'uppercase'}>{t("Organization ")}</Typography>
                                    <Stack width={'100%'} spacing={2} alignItems={'flex-start'} direction={'row'}>
                                        <TextField fullWidth value={editedOrganizationData.name} name={'name'} onChange={handleInput} variant={'filled'} label={t('Organization name')} />
                                    </Stack>
                                    <Stack width={'100%'} spacing={2} alignItems={'flex-start'} direction={'row'}>
                                        <TextField fullWidth value={editedOrganizationData.legalName} name={'legalName'} onChange={handleInput} variant={'filled'} label={t('Legal name')} />
                                        <TextField fullWidth value={editedOrganizationData.taxIdentifier} name={'taxIdentifier'} onChange={handleInput} variant={'filled'} label={t('Tax identifier')} />
                                    </Stack>
                                    <Stack width={'100%'} spacing={2} alignItems={'flex-start'} direction={'row'}>
                                        <TextField fullWidth value={editedOrganizationData.address} name={'address'} onChange={handleInput} variant={'filled'} label={t('Address')} />
                                        <TextField fullWidth value={editedOrganizationData.state} name={'state'} onChange={handleInput} variant={'filled'} label={t('State')} />
                                    </Stack>
                                    <Stack width={'100%'} spacing={2} alignItems={'flex-start'} direction={'row'}>
                                        <TextField fullWidth value={editedOrganizationData.city} name={'city'} onChange={handleInput} variant={'filled'} label={t('City')} />
                                        <TextField fullWidth value={editedOrganizationData.postalCode} name={'postalCode'} onChange={handleInput} variant={'filled'} label={t('Postal code')} />
                                    </Stack>
                                    {/* <Typography variant={'overline'} color={'darkGrey.main'} textTransform={'uppercase'}>{t("Currency and Region ")}</Typography> */}

                                    <CurrencySelect selectedCurrency={editedOrganizationData.currency} onCurrencyChange={onCurrencyChange} />
                                    <Button variant={'contained'} disabled={!isEdited} onClick={handleSave}>{t("Save")}</Button>
                                </Stack>
                            </BoxContainer>
                            <BoxContainer sx={{ display: { xs: 'block', md: 'none' }, mb: 2 }}>
                                <OrganizationUsers organizationData={organizationData} />
                            </BoxContainer>
                            <BoxContainer>
                                <PartnerRequests currentOrganization={organizationData} />
                            </BoxContainer>
                        </Grid>
                        <Grid item xs={12} md={6} display={{ xs: 'none', md: 'block' }}>
                            <BoxContainer>
                                <OrganizationUsers organizationData={organizationData} />
                            </BoxContainer>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8}>
                            <Stack spacing={2}>
                                <BoxContainer>
                                    <Stack direction={'row'} justifyContent={'space-between'} mb={3}>
                                        <Typography variant={'overline'} color={'darkGrey.main'} textTransform={'uppercase'} mb={3}>
                                            {t('Organization plan')}
                                        </Typography>
                                        <Stack direction={'row'}>
                                            {/* <Button variant='outlined' color={'red'}>
                                                Cancel plan
                                            </Button> */}
                                        </Stack>
                                    </Stack>
                                    {getSubscriptionType()}

                                    {/* <Typography variant='caption' color={'darkGrey.main'}>Trial: 99 days left</Typography> */}
                                </BoxContainer>
                                {/* <BoxContainer>
                                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={3}>
                                        <Typography variant={'overline'} color={'darkGrey.main'}>
                                            PAYMENT METHOD
                                        </Typography>
                                        <Stack direction={'row'}>
                                            <Button variant='outlined' startIcon={<AddIcon />} color={'green'}>
                                                Add card
                                            </Button>
                                        </Stack>
                                    </Stack>
                                    <Typography variant='body1' color={'darkGrey.main'}>No card was added</Typography>
                                </BoxContainer>
                                <BoxContainer>
                                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={3}>
                                        <Typography variant={'overline'} color={'darkGrey.main'} textTransform={'uppercase'} >
                                            Billing information
                                        </Typography>
                                    </Stack>
                                    <Stack>
                                        <Typography variant='subtitle'>
                                            Name name
                                        </Typography>
                                        <Typography variant='body2'>
                                            <Box component={'span'} color={'darkGrey.main'} mr={1}>
                                                Address:
                                            </Box>
                                            <Box component={'span'}>
                                                41256 Kamille Turnpike, East Sambury, New Hampshire, Kenya 85807
                                            </Box>
                                        </Typography>
                                        <Typography variant='body2'>
                                            <Box component={'span'} color={'darkGrey.main'} mr={1}>
                                                Phone:
                                            </Box>
                                            <Box component={'span'}>
                                                +40 725 330 280
                                            </Box>
                                        </Typography>
                                    </Stack>
                                </BoxContainer> */}
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box>
                                <Typography variant={'overline'} color={'darkGrey.main'} textTransform={'uppercase'}>
                                    {t('Invoice history')}
                                </Typography>
                                <Typography color={'darkGrey.main'}>
                                    {t('No invoices')}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Dialog open={false} maxWidth={'xs'} >
                        <DialogTitle>
                            Add new card
                        </DialogTitle>
                        <DialogContent>
                            <Stack spacing={2} alignItems={'flex-start'}>
                                <TextField fullWidth label={'Name on card'} variant={'filled'} />
                                <TextField fullWidth label={'Card number'} variant={'filled'} />
                                <Stack direction={'row'} spacing={2}>
                                    <TextField fullWidth label={'MM/YY'} variant={'filled'} />
                                    <TextField fullWidth label={'CVV'} variant={'filled'} />
                                </Stack>
                            </Stack>
                        </DialogContent>
                        <DialogActions sx={{ px: '24px', pb: '24px' }}>
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'} spacing={2}>
                                <Button variant='outlined' color={'red'}>Cancel</Button>
                                <Button variant='contained'>Add</Button>
                            </Stack>
                        </DialogActions>
                    </Dialog>
                </TabPanel>
            </Container >


        </>
    )
}


const OrganizationUsers = React.memo(({ organizationData }) => {
    //TODO: centralize this function to be available globally for Avatars
    const { t } = useTranslation();
    const [userInvitationDialogOpen, setUserInvitationDialogOpen] = useState(false);
    const [userInvitations, setUserInvitations] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    const fetchData = async () => {
        try {

            const response = await axios.get(`/organizations/inviteUsers`);
            if (response.status === 200) {

                setUserInvitations(response.data);
            }

        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    const handleDialogClose = () => {
        setUserInvitationDialogOpen(false)
    }

    const User = ({ userData }) => {
        return <>
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <StyledAvatar name={userData.name} userId={userData.id} alt={userData.name} src="/static/images/avatar/1.jpg" />
                </ListItemAvatar>
                <ListItemText
                    primary={userData.name}
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                {userData.email}
                            </Typography>
                            {` - ${t(userData.userRole.name)}`}
                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
        </>
    }
    const handleInvitationCancel = async (invitationId) => {
        try {
            const response = await axios.delete(`/organizations/inviteUser/${invitationId}`);
            if (response.status === 200) {
                setUserInvitations(prevInvitations => prevInvitations.filter(invitation => invitation.id !== invitationId));
                enqueueSnackbar(t("Invitation canceled."), { variant: "success" })

            }
        } catch (error) {
            console.log(error);
        }
    }
    const handleInvitationResend = async (invitationId) => {
        try {
            const response = await axios.patch(`/organizations/inviteUser/${invitationId}`);
            if (response.status === 200) {
                enqueueSnackbar(t("Invitation sent successfully!"), { variant: "success" })
                // Remove invitation from state
            }
        } catch (error) {
            if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, { variant: 'error' });
            }
        }
    }
    const onInvitationSent = (respData) => {

        fetchData();
        handleDialogClose()
    }

    return <>
        <Box mb={'15px'}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography variant={'overline'} color={'darkGrey.main'} textTransform={'uppercase'}>{t("Members")}</Typography>
                {!process.env.REACT_APP_COSTI_LOCK && <Button startIcon={<PersonAddIcon />} variant={'outlined'} size={'small'} onClick={() => setUserInvitationDialogOpen(true)}>{t("Invite People")}</Button>}
            </Stack>
            {/* Invited users */}

            {/* Present users */}
            <List sx={{ width: '100%', maxWidth: 360, }}>
                {organizationData.users.map(user => {
                    return <User key={user.id} userData={user} />;
                })}
            </List>
            <List>
                {userInvitations?.map(userInvite => {
                    return <ListItem alignItems="flex-start" key={userInvite.id}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: 'gray' }}  >
                                    <MoreHorizRoundedIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={userInvite.inviteeEmail}
                                secondary={t("Pending...")}

                            />
                            <Stack direction={'row'} spacing={1} marginLeft={2}>
                                <Button onClick={() => handleInvitationResend(userInvite.id)} >{t('Resend invitation')}</Button>
                                <IconButton onClick={() => handleInvitationCancel(userInvite.id)}><ClearRoundedIcon /></IconButton>
                            </Stack>
                        </Stack>
                    </ListItem>
                })}
            </List>
        </Box>
        <Dialog open={userInvitationDialogOpen} fullWidth maxWidth={'sm'} onClose={handleDialogClose}>
            <DialogTitle>Invite people to organization</DialogTitle>
            <InviteUserForm onInvitationSent={onInvitationSent} />
        </Dialog>
    </>
})

export default OrganizationSettings;