import * as React from "react";
import { useEffect, useState } from "react";

import { useTheme, styled } from "@mui/material/styles";
import {
    Box, Paper, Typography, Table, Button, TableBody,
    TableCell, TableContainer, TableHead, TableRow, OutlinedInput,
    Grid, TextField, FormControl, Select, MenuItem, Divider, Dialog,
    Stack, IconButton, InputAdornment, InputLabel, ToggleButton, ToggleButtonGroup, CircularProgress,
    List, ListSubheader, ListItemButton, ListItemIcon, ListItemText, Collapse, Checkbox, ListItem, Popover,
    Switch,
} from "@mui/material";

//ICONS 
import HandymanOutlined from "@mui/icons-material/HandymanOutlined";

import { useSelector } from "react-redux";
import axios from "../../axios-config";

import Section from "../../components/Section/Section";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../utils/currency";
import { getAcceptedAndRefundedServices, processService } from "../../utils/service-helpers";


const SideMenuCustomers = React.memo(({ customer, checkedOrders, setCheckedOrders }) => {

    const [t] = useTranslation();
    const [partnerOrdersLoading, setPartnerOrdersLoading] = useState(false);
    const [customerOrders, setCustomerOrders] = useState([]);
    const currency = useSelector((state) => state.auth.currency);

    useEffect(async () => {
        getOrders();
    }, [customer])

    const getOrders = async () => {
        try {
            setPartnerOrdersLoading(true);
            const resp = await axios.get(`repair-orders/fromCustomer/${customer.id}`);

            setCustomerOrders(resp.data);
            setPartnerOrdersLoading(false);

        } catch (err) {
            setPartnerOrdersLoading(false);
            console.log(err);
        }
    }

    if (checkedOrders === undefined || setCheckedOrders === undefined) {
        console.log("Mandatory arguments missing");
        return null
    }

    const handleToggle = (value) => () => {
        const currentIndex = checkedOrders.findIndex((checkedOrder) => checkedOrder.id == value.id);
        const newChecked = [...checkedOrders];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedOrders(newChecked);

    };
    const orders = customerOrders.filter(order => {
        let totalPayments = 0;

        // No resolution
        if (order.resolutionTypeId === null) {
            // console.log(order)
            return false
        }
        
        // Orders we care about, acccepted, refunded, and not invoiced
        const acceptedAndRefundedServices = getAcceptedAndRefundedServices(order.services).filter(service => !service.invoiceItem);
        const acceptedAndRefundedServicesProcessed = acceptedAndRefundedServices.reduce((acc, service) => {
            const processed = processService(service)
            return acc += processed.unitPrice * processed.quantity - processed.discount;
        }, 0)

        if (acceptedAndRefundedServices.length == 0) {
            return false;
        }


        // Total Payments
        totalPayments = order.repairPayments?.reduce((acc, payment) => {
            return acc + parseFloat(payment.amount);
        }, 0);


        // Already paid
        if (acceptedAndRefundedServicesProcessed == totalPayments) {
            return false;
        }

        order.services = acceptedAndRefundedServices;


        return true;

    });

    return < Box className='invoiceNavContainer sectionGroup' borderLeft={'1px solid darkGrey'} borderBottom={'1px solid darkGrey'} flex={'1 0 auto'} bgcolor={'white'} >
        <Box className=" sectionGroup">
            <Section sx={{ width: '100%' }}>
                <Typography variant='h4'>{t("Customer entries")}</Typography>
                <Typography variant='p'>{t("Showing all accepted, unpaid services.")}</Typography>
            </Section>
        </Box>
        <Box className="sectionGroup">
            <Section>
                {partnerOrdersLoading ? <Typography>Loading...</Typography> :
                    <List
                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                        dense
                        aria-labelledby="nested-list-subheader"
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                {orders.length} Orders
                            </ListSubheader>
                        }
                    >
                        {orders.map((order) => <>

                            {/* <ListItemIcon>
                                                <InboxIcon />
                                            </ListItemIcon> */}

                            <ListItemButton onClick={handleToggle(order)} sx={{ backgroundColor: "lightBlue.main", borderRadius: '5px' }}>
                                <ListItemIcon sx={{ minWidth: '20px' }}>
                                    <Checkbox edge="start" tabIndex={-1} disableRipple
                                        checked={checkedOrders.find(checkedOrder => checkedOrder.id == order.id) !== undefined}
                                        inputProps={{ 'aria-labelledby': 'labelId' }} />
                                </ListItemIcon>
                                <ListItemText secondary= {t(order?.repairOrderStatus?.name)} primary={<Typography variant="h5"><Box sx={{ color: "primary.main" }} component={'span'}>{order.organizationId}#{order.organizationCounter}</Box> {order.brand} {order.model}</Typography>} />

                            </ListItemButton>

                            {
                                order.services.map((service) =>
                                    // <Box pl={'50px'}>
                                    //     <ServiceItemListShort service={service} />
                                    // </Box>
                                    <List dense sx={{ pl: '30px' }}>
                                        <ListItem sx={{ border: '1px solid lightGrey', borderRadius: '15px' }}>
                                            <ListItemIcon>
                                                <HandymanOutlined />
                                            </ListItemIcon>
                                            <ListItemText primary={<Stack direction={'row'} justifyContent={'space-between'}><div>{service.name}</div><Typography variant="h6">{formatCurrency(service.unitPrice * service.quantity - service.discount, currency)}</Typography></Stack>}
                                                secondary={`(${service.unitPrice} x${service.quantity}) ${service.discount > 0 ? `-${service.discount}` : ""}`} />
                                        </ListItem>
                                        < Divider component="li" />
                                    </List>
                                )
                            }
                            < Divider component="li" />
                        </>)
                        }
                    </List>
                }
            </Section>
        </Box>
    </Box >
})

export default SideMenuCustomers;