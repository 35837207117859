import { useCallback } from 'react';
import { Box, display } from '@mui/system';
import {
    Typography, Button, Stack,  TextField, 
    TableCell, Table, TableRow, TableBody, TableHead,
    InputAdornment,
    Skeleton,
    Snackbar,
    Tooltip,
    IconButton,
    MenuItem,
    Menu,
    Alert
} from '@mui/material';
import axios from "../../axios-config";

import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTheme } from '@mui/material/styles';

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddService from "./AddService";
import HandymanIcon from "@mui/icons-material/Handyman";
// import DeviceResults from "./DeviceResults";
// import { searchVal } from "./Helper";
import debounce from 'lodash/debounce';
import DeviceData from './DeviceData';
import AddDevice from './AddDevice';
import { Trans, useTranslation } from 'react-i18next';
import ServiceCatalogTable from './ServiceCatalogTable';
import { AddLinkOutlined, ControlPointDuplicateOutlined, DeleteOutline, HighlightOffOutlined, ListAltOutlined, ModeEdit, PriceChangeOutlined,  VisibilityOutlined,  WarningRounded } from '@mui/icons-material';
import ToolBarHelper from '../../components/ToolBarHelper/ToolBarHelper';
import DeleteDialogWarning from './DeleteDialogWarning';
import AdjustPriceDialog from './AdjustPriceDialog';
import DuplicateDialogWarning from './DuplicateDialogWarning';
import DeviceSearchField from '../../components/DeviceSearchField/DeviceSearchField';
import LinkDeviceDialog from './LinkDeviceDialog';
import CatalogAccessDialog from './CatalogAccessDialog';
import { useUserPermissions } from 'contexts/UserPermissions';


const ServiceCatalog = ({ changeColor }) => {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    let dayjs = require('dayjs');
    const [model, setModel] = useState("");
    const [matchedResults, setMachedResults] = useState([]);
    const [addServiceOpen, setAddServiceOpen] = useState(false);
    const [addDeviceOpen, setAddDeviceOpen] = useState(false);
    const [linkDeviceDialogOpen, setLinkDeviceDialogOpen] = useState(false);
    const [ourOrganization, setOurOrganization] = useState(null);
    // provide a random date in ISO format string

    const [services, setServices] = useState(null);
    const [serviceSearchVal, setServiceSearchVal] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [deviceInput, setDeviceInput] = useState('');
    const [deviceData, setDeviceData] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [selectedServices, setSelectedServices] = useState([]);
    const [adjustPriceDialogOpen, setAdjustPriceDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
    const [catalogAccesssDialogOpen, setCatalogAccesssDialogOpen] = useState(false);
    const [selectedCatalog, setSelectedCatalog] = useState('Our Catalog');
    const [partnersServiceCatalogs, setPartnersServiceCatalogs] = useState(null);
    const userPermissions = useUserPermissions();
    const handleDeviceClick = (obj) => {
        setModel("");
        setDeviceData(obj.item);
    };

    const handleDelete = () => {
        setDeviceData("");
    };

    const handleServiceOpen = () => {
        setAddServiceOpen(true);
    };

    const handleAddDeviceDialogOpen = () => {
        setAddDeviceOpen(true);
    }

    const handleAddServiceClose = () => {
        setAddServiceOpen(false);
    };

    const handleAddNewService = (newService) => {

        setServices([...services, newService]);
    };

    const requestServiceSearch = (searchedVal) => {
        setServiceSearchVal(searchedVal);

    };
    const handleDeviceSearchChange = (val) => {
        setDeviceData(val);
    }
    const debouncedSave = useCallback(
        debounce((nextValue) => setModel(nextValue), 350),
        [] // will be created only once initially
    );

    const handleDeviceChange = (val) => {
        const nextValue = val;
        setDeviceInput(nextValue);
        debouncedSave(nextValue);
    }


    // useEffect(() => {
    //     const deviceSearchResult = searchVal(model, appleData);
    //     setMachedResults(deviceSearchResult);
    // }, [model]);

    const fetchOurOrganization = async () => {
        try {
            const response = await axios.get(`/organizations`);

            if (response.status === 200) {
                setOurOrganization(response.data);
            }
        } catch (err) {
            enqueueSnackbar(err.response.data.message || `Something went wrong!`, { variant: "error" });
        }
    };

    const getSharedServiceCatalogs = async () => {
        try {
            const response = await axios.get('service-catalogs/partner/shared-service-catalogs');
            // console.log(response.data)
            setPartnersServiceCatalogs(response.data);
        } catch (err) {
            enqueueSnackbar(err.response.data.message || `Something went wrong!`, { variant: "error" });

        }
    };

    useEffect(() => {
        fetchOurOrganization();
        getSharedServiceCatalogs();
    }, []);


    useEffect(() => {
        setEditMode(false);
        setSelectedServices([]);
        setDeviceData(null);
        const fetchServiceCatalog = async () => {
            try {

                if (selectedCatalog === 'Our Catalog' || selectedCatalog === "") {
                    const response = await axios.get("/service-catalogs");
                    if (response.status === 200) {
                        setServices(response.data);
                    }
                } else {
                    const response = await axios.get(`/service-catalogs/from-partner/${selectedCatalog.organization.id}`);
                    if (response.status !== 200) {
                        throw response.status;
                    }
                    if (response.status === 200) {
                        setServices(response.data);
                    }
                }
            } catch (err) {

                setSelectedCatalog('Our Catalog');
                enqueueSnackbar(err.response.data.message || `Something went wrong!`, { variant: "error" });
            }
        };
        fetchServiceCatalog();
    }, [selectedCatalog]);

    useEffect(() => {
        if (!editMode) {
            setSelectedServices([]);
        }
    }, [editMode]);



    document.body.style.backgroundColor = 'white';


    const openAdjustPriceDialog = () => {
        setAdjustPriceDialogOpen(true);
    }

    const handleOpenSelectCatalog = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseSelectCatalog = () => {
        setAnchorEl(null);
    };


    // Modify your handleCloseSelectCatalog function to take a catalog parameter
    const handleCatalogSelect = (catalog) => {
        setSelectedCatalog(catalog);
        handleCloseSelectCatalog();
    };


    return (
        <>
            <Box className={'container mainContainer docsContainer '} >
                {/* <div className='headerPush'></div> */}
                <ToolBarHelper>
                    <Stack direction={'row'} gap={1} flexWrap={'wrap'} alignItems={'flex-start'} width={"100%"}>
                        {/* <FormControl sx={{  minWidth: 120 }} size="small">
                            <InputLabel id="organization-catalog">Catalog</InputLabel>
                            <Select
                                labelId="organization-catalog"
                                id="organization-catalog-select"
                                value={10}
                                label="Catalog"
                                size='small'

                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                        </FormControl> */}
                        <Button
                            color={'black'}
                            variant={'outlined'}
                            startIcon={<ListAltOutlined />}
                            onClick={handleOpenSelectCatalog}

                        >
                            {t("Select catalog")}
                        </Button>
                        {selectedCatalog == "Our Catalog" && !userPermissions?.softLockedUser && <Button
                            color={'black'}
                            variant={'outlined'}
                            startIcon={<VisibilityOutlined />}
                            onClick={() => setCatalogAccesssDialogOpen(true)}
                        >
                             {t("Catalog access")}
                        </Button>}
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleCloseSelectCatalog}
                        >
                            <MenuItem
                                onClick={() => handleCatalogSelect('Our Catalog')}
                                selected={selectedCatalog === 'Our Catalog'}
                            >
                                {t("Our Catalog")}
                            </MenuItem>
                            {partnersServiceCatalogs?.map((partnerCatalog) => <MenuItem
                                key={partnerCatalog.id}
                                onClick={() => handleCatalogSelect(partnerCatalog)}
                                selected={selectedCatalog?.organization?.name === partnerCatalog.organization.name}
                            >
                                {partnerCatalog.organization.name}
                            </MenuItem>)
                            }

                        </Menu>
                        {selectedCatalog == "Our Catalog" && !userPermissions?.softLockedUser && < Button
                            color={'black'}
                            variant={'outlined'}
                            startIcon={<ModeEdit />}
                            onClick={() => setEditMode(!editMode)}

                        >
                            {editMode ? t("Stop editing") : t("Edit services")}
                        </Button>
                        }
                    </Stack>
                </ToolBarHelper>
                <div className="content">
                    <Box className='splitLeft' borderRight={'1px solid'} borderColor={'lightGrey.main'}>
                        <Stack spacing={1} py={'25px'} px={'25px'}>
                            {selectedCatalog != "Our Catalog" && <Alert severity="info" sx={{ border: '1px solid rgba(0,0,0,0.25)', mb: '15px', alignItems: "center" }} icon={<WarningRounded />}>
                                <Stack direction="row" alignItems="center" justifyContent={'space-between'} sx={{ width: '100%' }}>
                                    <Typography variant={'body'}>
                                        {t("Currently displaying services from")} <strong>{selectedCatalog?.organization?.name}</strong>. {t("You can't edit this catalog.")}
                                    </Typography>
                                    {/* <Button color="success" variant='outlined' size="small">
                                        {t("Make it your default catalog")}
                                    </Button> */}
                                </Stack>
                            </Alert>
                            }


                            <Stack direction={'row'} spacing={2}>
                            </Stack>
                            <Stack direction={'row'} spacing={2}>
                                <TextField
                                    size="small"
                                    variant="filled"
                                    label={t("Filter services")}
                                    placeholder={t('Display Replacement')}
                                    value={serviceSearchVal}
                                    onChange={(e) => requestServiceSearch(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <HandymanIcon fontSize="small" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Box minWidth={'300px'}>
                                    <DeviceSearchField onChange={handleDeviceSearchChange} />
                                </Box>
                                {editMode && <Button
                                    variant="contained"
                                    startIcon={<AddCircleOutlineIcon />}
                                    onClick={handleServiceOpen}
                                    sx={{
                                        backgroundColor: "green.main",
                                        "&:hover": {
                                            backgroundColor: "green.main",
                                        },
                                        borderRadius: "42px",
                                        marginRight: "20px",
                                    }}
                                >
                                    {t("Add services")}
                                </Button>
                                }
                                {editMode && false && <Button
                                    variant="contained"
                                    startIcon={<AddCircleOutlineIcon />}
                                    onClick={handleAddDeviceDialogOpen}
                                    color='green'
                                    sx={{
                                        borderRadius: "42px",
                                        marginRight: "20px",
                                    }}
                                >
                                    Add Device
                                </Button>
                                }
                            </Stack>

                            <AddService
                                handleAddServiceClose={handleAddServiceClose}
                                addServiceOpen={addServiceOpen}
                                handleAddNewService={handleAddNewService}
                                deviceDataSelected={deviceData}
                                handleDeviceClick={handleDeviceClick}
                                handleDelete={handleDelete}
                            />
                            <AddDevice
                                addDeviceOpen={addDeviceOpen}
                                handleAddDeviceClose={() => setAddDeviceOpen(false)}
                            />

                            {services === null ? <Box>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {Array.from({ length: 4 }).map((_, index) => (
                                                <TableCell key={index}>
                                                    <Skeleton variant="text" width="80%" height={40} /> {/* Adjust width and height values as needed */}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Array.from({ length: 10 }).map((_, rowIndex) => (
                                            <TableRow key={rowIndex}>
                                                {Array.from({ length: 4 }).map((_, colIndex) => (
                                                    <TableCell key={colIndex}>
                                                        <Skeleton variant="text" />
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box> : services?.length === 0 ? <Box>
                                <Stack direction={'column'} alignItems={'center'} justifyContent={'center'} spacing={2} py={10}>
                                    <Typography variant={'h6'}>{t("No services")}</Typography>
                                    <Typography variant={'body'}>{t("Add a new service to get started")}</Typography>
                                </Stack>
                            </Box> : <>
                                <ServiceCatalogTable
                                    services={services}
                                    setServices={setServices}
                                    searchedVal={serviceSearchVal}
                                    editMode={editMode}
                                    selectedServices={selectedServices}
                                    setSelectedServices={setSelectedServices}
                                    deviceData={deviceData}
                                />
                            </>
                            }

                        </Stack>
                    </Box>
                    <Box className='splitRight' id={'window'} display={'flex'} flexDirection={'column'} minHeight={'1px'} sx={{ overflowY: 'hidden' }}>
                        <DeviceData deviceData={deviceData} title={"test"} maxWidth={"xs"} />
                    </Box>
                </div>
            </Box >
            <CatalogAccessDialog
                ourOrganization={ourOrganization}
                dialogOpen={catalogAccesssDialogOpen}
                handleDialogClose={() => setCatalogAccesssDialogOpen(false)}
            />
            <DeleteDialogWarning
                dialogOpen={deleteDialogOpen}
                handleDialogClose={() => setDeleteDialogOpen(false)}
                selectedServices={selectedServices}
                setSelectedServices={setSelectedServices}
                services={services}
                setServices={setServices} />
            <DuplicateDialogWarning
                dialogOpen={duplicateDialogOpen}
                handleDialogClose={() => setDuplicateDialogOpen(false)}
                selectedServices={selectedServices}
                setSelectedServices={setSelectedServices}
                services={services}
                setServices={setServices} />
            <AdjustPriceDialog
                dialogOpen={adjustPriceDialogOpen}
                handleDialogClose={() => setAdjustPriceDialogOpen(false)}
                selectedServices={selectedServices}
                setServices={setServices}
                services={services}
            />
            <LinkDeviceDialog
                dialogOpen={linkDeviceDialogOpen}
                handleDialogClose={() => setLinkDeviceDialogOpen(false)}
                selectedServices={selectedServices}
                setServices={setServices}
                services={services}
                deviceDataSelected={deviceData}
            />

            <Snackbar open={selectedServices?.length > 0}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Box sx={{ backgroundColor: "#252628", px: '20px', py: '10px', borderRadius: '50px' }}>
                    <Stack alignItems={'center'} color={'white'} direction={'row'} spacing={3} >
                        <Trans i18nKey="selectedService" count={selectedServices.length}>
                            {{ count: selectedServices.length }} service selected
                        </Trans>
                        <Stack direction={'row'} spacing={0}>
                            <Tooltip title={t('Delete')} fontSize={15} placement='top'>
                                <IconButton color="inherit" aria-label="delete" onClick={() => setDeleteDialogOpen(true)}>
                                    <DeleteOutline />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t('Adjust price')} fontSize={15} placement='top'>
                                <IconButton color="inherit" aria-label="adjust price" onClick={openAdjustPriceDialog}>
                                    <PriceChangeOutlined />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t('Duplicate')} fontSize={15} placement='top' >
                                <IconButton color="inherit" aria-label="duplicate" onClick={() => setDuplicateDialogOpen(true)}>
                                    <ControlPointDuplicateOutlined />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t('Link to device')} fontSize={15} placement='top'>
                                <IconButton color="inherit" aria-label="link to device" onClick={() => setLinkDeviceDialogOpen(true)}>
                                    < AddLinkOutlined />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t('Deselect services')} fontSize={15} placement='top'>
                                <IconButton color="inherit" aria-label="deselect services" onClick={() => setSelectedServices([])}>
                                    <  HighlightOffOutlined />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </Stack>
                </Box>
            </Snackbar>
        </>
    )
}


// const options = [
//     {
//         id: 1, deviceName: 'iPhone 12 Pro Max', "device_name": 'iPhone 12 Pro Max', models: 'A123, A321, A6435, A1244', deviceImage: 'https://fdn2.gsmarena.com/vv/bigpic/apple-iphone-12-pro-max-.jpg',
//         "device_info": "Apple iPhone 12 Pro Max smartphone. Announced Oct 2020. Features 6.7\u2033  display, Apple A14 Bionic chipset, 3687 mAh battery, 512 GB storage, 6 GB RAM, Ceramic Shield glass.",
//         "device_img_link": "https://fdn2.gsmarena.com/vv/bigpic/apple-iphone-12-pro-max-.jpg",
//         "device_link": "https://www.gsmarena.com/apple_iphone_12_pro_max-10237.php",
//         "device_specs": {
//             "Network": {
//                 "Technology": "GSM / CDMA / HSPA / EVDO / LTE / 5G",
//                 "2G bands": "GSM 850 / 900 / 1800 / 1900 - SIM 1 & SIM 2 (dual-SIM) - for China",
//                 "NaN": "CDMA 800 / 1900",
//                 "3G bands": "HSDPA 850 / 900 / 1700(AWS) / 1900 / 2100",
//                 "4G bands": "1, 2, 3, 4, 5, 7, 8, 12, 13, 14, 17, 18, 19, 20, 25, 26, 28, 29, 30, 32, 34, 38, 39, 40, 41, 42, 46, 48, 66, 71 - A2342",
//                 "5G bands": "1, 2, 3, 5, 7, 8, 12, 20, 25, 28, 38, 40, 41, 66, 71, 77, 78, 79, 260, 261 Sub6/mmWave - A2342",
//                 "Speed": "HSPA, LTE-A, 5G, EV-DO Rev.A 3.1 Mbps"
//             },
//             "Launch": {
//                 "Announced": "2020, October 13",
//                 "Status": "Available. Released 2020, November 13"
//             },
//             "Body": {
//                 "Dimensions": "160.8 x 78.1 x 7.4 mm (6.33 x 3.07 x 0.29 in)",
//                 "Weight": "228 g (8.04 oz)",
//                 "Build": "Glass front (Corning-made glass), glass back (Corning-made glass), stainless steel frame",
//                 "SIM": "Nano-SIM and eSIM or Dual SIM (Nano-SIM, dual stand-by) - for China",
//                 "NaN": "IP68 dust/water resistant (up to 6m for 30 mins)Apple Pay (Visa, MasterCard, AMEX certified)"
//             },
//             "Display": {
//                 "Type": "Super Retina XDR OLED, HDR10, Dolby Vision, 800 nits (HBM), 1200 nits (peak)",
//                 "Size": "6.7 inches, 109.8 cm2(~87.4% screen-to-body ratio)",
//                 "Resolution": "1284 x 2778 pixels, 19.5:9 ratio (~458 ppi density)",
//                 "Protection": "Ceramic Shield glass"
//             },
//         }
//     },
//     { id: 2, deviceName: 'iPhone 12', models: 'A3421, A9342,A3280', deviceImage: '/avatars/avatar2.png' },
//     { id: 3, deviceName: 'iPhone 12 Mini', models: 'A2358, A2399,A2383', deviceImage: '/avatars/avatar3.png' },
//     // Add more options with avatars as needed
// ];




export default ServiceCatalog;