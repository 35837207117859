import i18n from '../i18n';

export const servicesToItems = (order, services) => {
    if (!order) {
        console.log("Order not provided.");
        return null;
    }
    if (!services) {
        console.log("No services provided.");
        return null;
    }

    let items = [];
    services.forEach(service => {
        let item = {};
        item.name = `${order.brand} ${order.model} ${service.name}`;
        item.discount = service.discount;
        item.quantity = service.quantity;
        item.unitPrice = service.unitPrice;
        item.serviceId = service.id;
        items.push(item);
    });
    return items;
};


export const organizationToInvoiceData = (organization, short) => {

    const fullAddress = `${organization.address} ${organization.city} ${organization.state ? `(${organization.state})\n` : ''}`;
    const taxIdentifier = organization.taxIdentifier ? `${i18n.t('Tax identifier')}: ${organization.taxIdentifier}` : "";
    const legalName = organization.legalName ? `${organization.legalName}\n` : "";
    if (short) {
        return `${legalName}${taxIdentifier}`
    }
    return `${legalName}${fullAddress}${taxIdentifier}`;
}

export const customerToInvoiceData = (customer) => {
    const name = customer.name ? `${customer.name}\n` : "";
    const fullAddress = `${customer.address}\n`;
    const taxIdentifier = customer.taxIdentifier ? `${i18n.t('Tax identifier')}: ${customer.taxIdentifier}\n` : "";
    return `${name}${fullAddress}${taxIdentifier}`;
}