import * as actionTypes from '../actions/actionTypes';
const initialState = {
  serverDown: false,
  updateAvailable: false,
  networkNotAvailable: false,
  minimumVersionNotMet: false,
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_SERVER_DOWN:
      return {
        ...state,
        serverDown: action.payload,
      };
    case actionTypes.SET_UPDATE_AVAILABLE:
      return {
        ...state,
        updateAvailable: action.payload,
      };
    case actionTypes.SET_NETOWKR_NOT_AVAILABLE:
      return {
        ...state,
        networkNotAvailable: action.payload,
      };
    case actionTypes.SET_MINIMUM_VERSION_NOT_MET:
      return {
        ...state,
        minimumVersionNotMet: action.payload,
      };
    default:
      return state;
  }
}
