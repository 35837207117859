import * as React from 'react';
import { useEffect, useState } from 'react';

import {
    Button, Paper, Grid, Stack, Divider, Box, Typography, TextField,
    FormGroup, FormControlLabel, Switch, ToggleButtonGroup, ToggleButton,
} from '@mui/material';
import ReactToPrint from "react-to-print";
import Section from '../../components/Section/Section';
import DeviceDepositTicketDemo from '../../components/Printing/DeviceDepositTicketDemo';
import ToolBarHelper from '../../components/ToolBarHelper/ToolBarHelper';
import { useTheme } from '@emotion/react';
import { styled } from '@mui/material/styles';
import axios from "../../axios-config";
import { useDropzone } from 'react-dropzone';
import { useSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';

//ICONS
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';

import { useReactToPrint } from "react-to-print";
import { ConstructionOutlined } from '@mui/icons-material';
import Logo from "../../images/dalvado-logo.png";
import DeviceDepositA4Demo from '../../components/Printing/DeviceDepositA4Demo';
import DeviceDepositA4SplitDemo from '../../components/Printing/DeviceDepositA4SplitDemo';
import UploadFilesDialog from '../../components/UploadFilesDialog/UploadFilesDialog';
import LogoButton from '../../components/Buttons/LogoButton';
import { useTranslation } from 'react-i18next';

import { usePrintSettings } from '../../hooks/usePrintSettings';


const PrintSettings = () => {

    const { t } = useTranslation();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [mediaDialogOpen, setMediaDialogOpen] = useState(false);

    const [saving, setSaving] = useState(false);
    const repairOrderDemo = {
        organizationId: 1,
        organizationCounter: 1126,
        brand: "Brand",
        model: "Model",
        malfunction: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed auctor velit metus, at tincidunt magna auctor id.",
        customer: { name: "John Doe", phone: "643212345" },
        createdAt: "2022-06-19T08:23:27.000Z",
        estimatedServices: 100,
        password: "password123",
        note: "Observation"
    }
    const [repairPayments, setRepairPaymemts] = useState([
        {
            amount: 20.00, isDeposit: true
        }
    ])
    const Input = styled('input')({
        display: 'none',
    });
    const [preview, setPreview] = useState();



    const { loading, settings, organization, setSettings, logo, setLogo } = usePrintSettings();


    const handleSettingsEnable = (e, key) => {
        const newChecked = e.target.checked
        setSettings((prevState) => {
            return {
                ...prevState,
                [key]: newChecked
            }
        })
    }

    const handleSettingUpdate = (key, value) => {
        setSettings((prevState) => {
            return {
                ...prevState,
                [key]: value
            }
        });
    }

    const handleSave = async () => {
        try {
            setSaving(true);
            const data = { ...settings, fileId: logo ? logo.id : null }
            const response = await axios.put(`/organizations/printSettings`, data);
            if (response.status == 200) {
                enqueueSnackbar("Saved succesfully!", { variant: 'success' })
            }
            setSaving(false);

        } catch (e) {
            console.log(e);
            setSaving(false);
        }
    }

    const theme = useTheme();
    const componentRef = React.useRef();
    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);


    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: "Ticket",
        // onBeforePrint: handleBeforePrint,
        // onAfterPrint: handleAfterPrint,
        // removeAfterPrint: true
    });


    const paperDemo = () => {

        if (!settings || !settings.paperType) return null;

        switch (settings.paperType) {
            case "0":
                return <DeviceDepositTicketDemo
                    ref={componentRef}
                    settings={settings}
                    preview={preview}
                    logo={logo}
                    organization={organization}
                    repairOrder={repairOrderDemo}
                    repairPayments={repairPayments}
                />
            case "1":
                return < DeviceDepositA4SplitDemo
                    ref={componentRef}
                    settings={settings}
                    preview={preview}
                    logo={logo}
                    organization={organization}
                    repairOrder={repairOrderDemo}
                    repairPayments={repairPayments}
                />
        }

    }

    const ticketLogo = () => {

        if (logo && logo.fileName) {
            return <Box className="editableRow clickable" height={'100px'} p={'10px'} borderRadius={'10px'} border={'2px dashed'} width={'190px'} display={'block'} textAlign={'center'} onClick={() => setMediaDialogOpen(true)}>
                <img className="logo" src={`${process.env.REACT_APP_IMAGES_URL}${logo.fileName}`} alt="logo" />
            </Box>
        }
        return <LogoButton onClick={() => setMediaDialogOpen(true)} />
    }


    if (loading > 0) {
        return <>
            <Box className={'container mainContainer docsContainer '}>
                <ToolBarHelper>
                </ToolBarHelper>
                <div className="content" >
                    <Box className='docsNavContainer sectionGroup' borderRight={'1px solid darkGrey'} borderBottom={'1px solid darkGrey'} flex={'0 0 auto'} bgcolor={'white'}>
                        <Box display={'flex'} justifyContent={'center'}>Loading...</Box>
                    </Box></div>
            </Box >
        </>
    }

    return <Box className={'container mainContainer docsContainer '}>
        <ToolBarHelper>
            <Box display="flex" justifyContent="space-between" width="100%">
                <Stack direction={'row'} spacing={3} alignItems={'center'}>
                    <Button color={'black'} variant={'outlined'} startIcon={<LocalPrintshopOutlinedIcon />} onClick={handlePrint}>Print Demo Receipt</Button>
                </Stack>
                <LoadingButton loading={saving} onClick={handleSave} variant="contained" color={"black"}>
                    {t('Save settings')}
                </LoadingButton>
            </Box>
        </ToolBarHelper>
        <div className="content" >
            <Stack spacing={2} className='docsNavContainer' borderRight={'1px solid darkGrey'} borderBottom={'1px solid darkGrey'} flex={'0 0 auto'} bgcolor={'white'}>
                {/* Inbox feed */}
                <Box sx={{ position: 'sticky', flex: '0 0 auto', top: '0', zIndex: 100, backgroundColor: 'white', borderBottom: '1px solid darkGrey' }}>
                    <Section sx={{ width: '100%' }}>
                        <Typography variant='h3'>{t('Print settings')}</Typography>
                        <Typography variant='p' display={'block'}>{t('Edit the way you want to print your tickets')}</Typography>
                        <ToggleButtonGroup
                            color="primary"
                            exclusive
                            value={settings.paperType || "0"}
                            sx={{ my: "15px" }}
                            onChange={(e) => setSettings(prevState => {
                                return { ...prevState, paperType: e.target.value }
                            })}

                        >
                            <ToggleButton value="0">{t('Thermal receipt')}</ToggleButton>
                            <ToggleButton value="1">{t('Full page')}</ToggleButton>
                        </ToggleButtonGroup>
                    </Section>
                </Box>
                <Section>
                    <Typography variant='h6'>General</Typography>
                    <FormControlLabel control={<Switch checked={settings.titleEnabled} onChange={(e) => handleSettingsEnable(e, 'titleEnabled')} />} label={t("Title")} />

                    <FormControlLabel
                        control={
                            <Switch checked={settings.dateEnabled}
                                onChange={(e) => handleSettingsEnable(e, 'dateEnabled')} />}
                        label={t("Date")} />

                    <TextField
                        variant='filled'
                        fullWidth
                        label={t('Title')}
                        value={settings.title}
                        onChange={(e) => handleSettingUpdate('title', e.target.value)}
                    />
                </Section>
                <Divider />
                <Section >
                    <Typography variant='h6'>{t('Organization')}</Typography>
                    <Section >
                        <FormControlLabel control={<Switch checked={settings.organizationNameEnabled} onChange={(e) => handleSettingsEnable(e, "organizationNameEnabled")} />} label={t("Name")} />
                        <FormControlLabel control={<Switch checked={settings.organizationAddressEnabled} onChange={(e) => handleSettingsEnable(e, "organizationAddressEnabled")} />} label={t("Address")} />
                        <FormControlLabel control={<Switch checked={settings.organizationLogoEnabled} onChange={(e) => handleSettingsEnable(e, "organizationLogoEnabled")} />} label={t("Logo")} />
                        <Stack direction={'row'} justifyContent={'flex-start'}>
                            <Box>
                                {ticketLogo()}
                            </Box>
                        </Stack>
                    </Section>
                </Section>
                <Divider />
                <Section>
                    <Typography variant='h6'>{t('Customer data')}</Typography>
                    <FormControlLabel control={<Switch checked={settings.customerNameEnabled} onChange={(e) => handleSettingsEnable(e, "customerNameEnabled")} />} label={t("Name")} />
                    <FormControlLabel control={<Switch checked={settings.customerPhoneEnabled} onChange={(e) => handleSettingsEnable(e, "customerPhoneEnabled")} />} label={t("Phone")} />
                    <FormControlLabel control={<Switch checked={settings.malfunctionEnabled} onChange={(e) => handleSettingsEnable(e, "malfunctionEnabled")} />} label={t("Malfunction")} />
                    <FormControlLabel control={<Switch checked={settings.repairOrderNoteEnabled} onChange={(e) => handleSettingsEnable(e, "repairOrderNoteEnabled")} />} label={t("Repair order note")} />
                    <FormControlLabel control={<Switch checked={settings.estimatedServicesEnabled} onChange={(e) => handleSettingsEnable(e, "estimatedServicesEnabled")} />} label={t("Estimated services")} />
                    <FormControlLabel control={<Switch checked={settings.repairPaymentsDepositsEnabled} onChange={(e) => handleSettingsEnable(e, "repairPaymentsDepositsEnabled")} />} label={t("Payments deposit")} />
                </Section>
                <Section>
                    <FormControlLabel control={<Switch checked={settings.customerSignatureEnabled} onChange={(e) => handleSettingsEnable(e, "customerSignatureEnabled")} />} label={t("Signature")} />
                    <TextField
                        variant='filled'
                        fullWidth
                        label={t('Signature heading')}
                        onChange={(e) => handleSettingUpdate('customerSignatureLabel', e.target.value,)}
                        value={settings.customerSignatureLabel}
                    />

                </Section>
                <Divider />
                <Section>
                    <Typography variant='h6'>{t('Notes & Terms')}</Typography>
                    <Section>
                        <FormControlLabel control={<Switch checked={settings.firstNoteEnabled} onChange={(e) => handleSettingsEnable(e, "firstNoteEnabled")} />} label={`${t("Note")} #1`} />
                        <TextField
                            variant='filled'
                            fullWidth
                            label={`${t("Ticket note")} #1`}
                            multiline
                            maxRows={4}
                            minRows={2}
                            onChange={(e) => handleSettingUpdate('firstNote', e.target.value)}
                            value={settings.firstNote}
                        />
                    </Section>
                    <Section>
                        <FormControlLabel control={<Switch checked={settings.secondNoteEnabled} onChange={(e) => handleSettingsEnable(e, "secondNoteEnabled")} />} label={`${t("Note")} #2`} />
                        <TextField
                            variant='filled'
                            fullWidth
                            label={`${t("Ticket note")} #2`}
                            multiline
                            maxRows={4}
                            minRows={2}
                            onChange={(e) => handleSettingUpdate('secondNote', e.target.value)}
                            value={settings.secondNote}
                        />
                    </Section>
                </Section>
            </Stack >
            <Box className='ticketContainer' flex={'1 0 auto'} >
                <Box pb={10}>
                    <Typography variant='h6' my={3} align={'center'}>
                        {t('Visual example')}
                    </Typography>
                    {paperDemo()}
                </Box>
            </Box>
        </div >

        <UploadFilesDialog open={mediaDialogOpen} onClose={() => setMediaDialogOpen(false)} onSelect={(link) => setLogo(link)} />

    </Box >

}

export default React.memo(PrintSettings);