import { useEffect, useState } from "react";
import { Button, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import axios from "axios-config";

import dayjs from "dayjs";

const NewFeatureTooltip = ({ title, description, children, badge, hintName }) => {

    const [open, setOpen] = useState(false);
    const handleClose = async () => {
        try {
            setOpen(false);
            if (hintName) {
                await axios.post(`/users/hint/${hintName}/seen`);
            }
        }
        catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        let isMounted = true;

        const getHint = async () => {
            try {
                const response = await axios.get(`/users/hint/${hintName}`);
                if (isMounted && response.status === 200) {
                    const data = response.data;
                    if ((!data.userHint || !data.userHint?.isSeen) && data.user && dayjs(data.user.createdAt).isBefore(dayjs().subtract(3, 'day'))) {
                        setOpen(true);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        };

        if (hintName) getHint();

        return () => {
            isMounted = false;
        };
    }, [hintName]);

    return (
        <>
            <Tooltip
                title={
                    <Stack alignItems={"flex-start"} direction={"column"}>
                        <Stack>
                            <Stack
                                direction={"row"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                            >
                                <Stack direction={"row"} spacing={1}>
                                    {badge && <Typography
                                        fontSize={"10px"}
                                        sx={{
                                            backgroundColor: "white",
                                            color: "#1863dc",
                                            px: "5px",
                                            borderRadius: "5px",
                                        }}
                                        fontWeight={"500"}
                                        alignSelf={"center"}
                                    >
                                        {badge}
                                    </Typography>}
                                    <Typography fontSize={"14px"} fontWeight={"700"} whiteSpace={'nowrap'} >
                                        {title}
                                    </Typography>
                                </Stack>
                                <IconButton
                                    onClick={handleClose}
                                    size="small"
                                    sx={{ marginLeft: 1, color: "white" }}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            </Stack>

                            <Typography variant="body2" textAlign={"start"}>
                                {description}
                            </Typography>
                        </Stack>
                    </Stack>
                }
                placement="top"
                arrow
                open={open} // Control tooltip visibility
                componentsProps={{
                    tooltip: {
                        sx: {
                            backgroundColor: "#1863dc", // Deep purple background
                            color: "#fff", // White text
                            fontSize: "1.5em", // Larger font size
                            padding: "10px 15px", // Padding for better readability
                            borderRadius: "8px", // Rounded corners
                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Subtle shadow for a floating effect
                            maxWidth: '280px', // Control the tooltip width
                            height: '100%'

                        },
                    },
                    arrow: {
                        sx: {
                            color: "#1863dc", // Ensure the arrow color matches the tooltip background
                        },
                    },
                }}
                PopperProps={{
                    modifiers: [
                        {
                            name: 'preventOverflow',
                            options: {
                                boundary: 'viewport',
                                padding: 5, // Add padding to prevent overflow
                            },
                        },
                        {
                            name: 'flip',
                            options: {
                                fallbackPlacements: ['bottom', 'right', 'left'], // Flip to these placements if 'top' overflows
                            },
                        },
                    ],
                }}
            >
                {children}
            </Tooltip>
        </>
    );
}

export default NewFeatureTooltip;