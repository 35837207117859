import React, { useState, useRef, useEffect } from "react";
import { Box, IconButton, Stack, Button } from "@mui/material";
import { ArrowBackIosNew, ArrowForwardIos, ZoomIn, ZoomOut } from "@mui/icons-material";
import { useTheme } from "@emotion/react";

function ImageViewer({ images, index = 0, fullScreen, handleClose }) {
  const [currentIndex, setCurrentIndex] = useState(index);
  const [zoom, setZoom] = useState(1);
  const thumbnailRefs = useRef([]);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [scrollStart, setScrollStart] = useState({ x: 0, y: 0 });

  const theme = useTheme();

  useEffect(() => {
    setZoom(1); // Reset zoom level whenever the currentIndex changes
  }, [currentIndex]);


  useEffect(() => {
    // Delay the scrollToThumbnail to ensure the thumbnails are rendered
    const timeout = setTimeout(() => {
      scrollToThumbnail(currentIndex);
    }, 30); // delay in ms

    return () => clearTimeout(timeout); // Cleanup timeout when component unmounts
  }, [currentIndex]);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        handlePrev();
      } else if (event.key === "ArrowRight") {
        handleNext();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
  };

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
  };

  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 0.5, 3)); // Max zoom level of 3
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => {
      const newZoom = Math.max(prevZoom - 0.5, 1);
      return newZoom;
    });
  };


  const scrollToThumbnail = (index) => {
    if (thumbnailRefs.current[index]) {
      thumbnailRefs.current[index].scrollIntoView({ behavior: 'smooth', inline: 'center' });
    }
  };

  const handleThumbnailClickWithScroll = (index) => {
    handleThumbnailClick(index);
  };


  const handleMouseDown = (e) => {
    if (zoom > 1) {
      const container = e.target.closest('div');
      setIsDragging(true);
      setDragStart({ x: e.clientX, y: e.clientY });
      setScrollStart({
        x: container.scrollLeft,
        y: container.scrollTop,
      });
    }
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const container = e.target.closest('div');
      const deltaX = e.clientX - dragStart.x;
      const deltaY = e.clientY - dragStart.y;

      // Set the scroll relative to the starting position of the drag
      container.scrollLeft = scrollStart.x - deltaX;
      container.scrollTop = scrollStart.y - deltaY;
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  return (
    <Stack spacing={2} sx={{ width: "100%" }} justifyContent={'space-between'} flex={1}>
      {fullScreen && <Stack direction={'row'} pt={'10px'} ><Button startIcon={<ArrowBackIosNew />} onClick={handleClose}>Close</Button></Stack>}
      <Stack direction="row" justifyContent="center" alignItems={'center'} spacing={1} style={{ marginTop: "10px" }} flex={1}>
        <IconButton onClick={handleZoomOut} style={{ color: "black" }}>
          <ZoomOut />
        </IconButton>
        <IconButton onClick={handleZoomIn} style={{ color: "black" }}>
          <ZoomIn />
        </IconButton>
      </Stack>
      <Stack direction={'row'} alignItems={'center'} justifyContent={"center"}>
        <IconButton
          onClick={handlePrev}
          style={{ color: "black" }}
        >
          <ArrowBackIosNew />
        </IconButton>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ height: fullScreen ? '70vh' : "450px", width: "100%", overflow: "hidden" }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: zoom > 1 ? "unset" : "center",
              alignItems: zoom > 1 ? "unset" : "center",
              overflow: zoom > 1 ? "auto" : "hidden",
              cursor: zoom > 1 ? (isDragging ? "grabbing" : "grab") : "unset",
            }}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
          >
            <img
              src={images[currentIndex]}
              alt={`Slide ${currentIndex}`}
              style={{
                width: `${zoom * 100}%`,
                height: `${zoom * 100}%`,
                objectFit: "contain",
              }}
              onDragStart={(e) => e.preventDefault()}
            />
          </div>
        </Stack>
        <IconButton
          onClick={handleNext}
          style={{ color: "black" }}
        >
          <ArrowForwardIos />
        </IconButton>
      </Stack>
      <Box flex={1} />
      <Stack 
        direction="row" 
        sx={{ 
          overflowX: "auto", 
          whiteSpace: "nowrap", 
          justifyContent:"center" 
        }}
      >
        {images.map((image, idx) => (
          <Box
            key={idx}
            component="img"
            src={image}
            alt={`Thumbnail ${idx}`}
            ref={(el) => (thumbnailRefs.current[idx] = el)}
            sx={{
              width: {
                xs: "50px", // small screens
                sm: "60px", // medium screens
                md: "75px"  // large screens
              },
              height: {
                xs: "50px", // small screens
                sm: "60px", // medium screens
                md: "75px"  // large screens
              },
              cursor: "pointer",
              border: currentIndex === idx ? "2px solid blue" : "2px solid transparent",
              display: "inline-block",
              objectFit: "cover",
            }}
            onClick={() => handleThumbnailClickWithScroll(idx)}
          />
        ))}
      </Stack>
    </Stack>
  );
}

export default ImageViewer;
