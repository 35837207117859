import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, DialogActions, Box, DialogContentText } from '@mui/material';
import { Autocomplete, TextField } from '@mui/material';
import axios from "../../../axios-config";
import { tr } from 'react-date-range/dist/locale';
import { set } from 'date-fns';
import { useSnackbar } from 'notistack';
import { Trans, useTranslation } from 'react-i18next';

const OrderParentingDialog = ({ repairOrder, open, onClose, handleParentChange }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

    const handleDialogClose = () => {
        setSelectedOption(null);
        onClose();
    };
    const handleSave = async () => {
        try {
            const response = await axios.patch(`/repair-orders/${repairOrder.id}/parent`, { parentOrderId: selectedOption.value.id });
            if (response.status !== 200) {
                throw response.status;
            }
            if (response.status === 200) {
                enqueueSnackbar(`Parenting updated succesfully!`, { variant: "success" });
                onClose();
                handleParentChange(response.data);

            }
        } catch (err) {
            console.log(err.response.data.message)
            enqueueSnackbar(err.response.data.message || `Something went wrong!`, { variant: "error" });
        }
    }

    const handleRemoveParent = async () => {
        try {

            const response = await axios.patch(`/repair-orders/${repairOrder.id}/unparent`);
            if (response.status !== 200) {
                throw response.status;
            }
            if (response.status === 200) {
                enqueueSnackbar(`Parenting updated succesfully!`, { variant: "success" });
                handleParentChange(response.data);
                onClose();

            }
        } catch (err) {
            if (err.response?.data?.message) {
                console.log(err.response.data.message)
            }
            enqueueSnackbar(err.response.data.message || `Something went wrong!`, { variant: "error" });
        }
    }

    if (repairOrder.parentOrderId && !repairOrder.deliveredAt) {
        return (<Dialog open={open} onClose={handleDialogClose}>
            <DialogTitle>Remove parent</DialogTitle>
            <DialogContent>
                <Box>
                    Do you wish to unparent this repair?
                </Box>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose}>Cancel</Button>
                <Button onClick={handleRemoveParent}>Yes</Button>
            </DialogActions>
        </Dialog>)
    }
    return (
        <Dialog open={open} onClose={handleDialogClose} maxWidth={'sm'}>
            <DialogTitle>{t("Order parent")}</DialogTitle>
            <DialogContent>
                <DialogContentText>

                    <Trans i18nKey="repairParentConnectContext" components={[<strong />, <br />]}>
                        Link this repair with a prior one to form a <strong>Follow-Up Repair</strong>. You'd usually do this from the original repair's page. <br />Just a heads-up: You can only issue refunds through a follow-up repair.
                    </Trans>
                </DialogContentText>
                <Box sx={{ py: 3 }}>
                    <MyAutocomplete onChange={handleOptionChange} selectedOption={selectedOption} setSelectedOption={setSelectedOption} repairOrder={repairOrder} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose}>Cancel</Button>
                <Button onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};


const MyAutocomplete = ({ selectedOption, setSelectedOption, repairOrder }) => {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [isLoading, setIsloading] = useState(false);

    const fetchData = async () => {
        // Simulating an API call to fetch data
        // const paymentsResponse = await axios.get(`/repair-orders/${repairOrder.id}/payments`);
        try {
            setIsloading(true);
            const response = await axios.get(`/repair-orders/parent-options/${repairOrder.id}`);

            let data = await response.data;
        
            if (repairOrder.outsourcedFrom) {

                data = data.filter((item) => {
                    return item.outsourcedFrom?.organization?.id === repairOrder?.outsourcedFrom?.organization.id
                });
            }
            // setOptions(data);
            const formattedOptions = data.map((item) => {
                if (item.outsourcedFrom) {
                    return {
                        label: `${item.outsourcedFrom.organization.id}#${item.outsourcedFrom.organizationCounter}`,
                        value: item,
                    }
                }
                return {
                    label: `${item.organizationId}#${item.organizationCounter}`,
                    value: item,
                }
            });

            setOptions(formattedOptions);
            setIsloading(false);

        } catch (err) {
            setIsloading(false);
            console.log(err);
        }
    };

    const handleOpen = () => {
        setOpen(true);
        fetchData();
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleOptionChange = (event, value) => {

        setSelectedOption(value);
    };

    return (
        <Autocomplete
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            options={options}
            loading={options.length === 0}
            onChange={handleOptionChange}

            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Select an option"
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {params.InputProps.endAdornment}
                                {options.length === 0 && isLoading && <span>Loading...</span>}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
};

// export default MyAutocomplete;


export default OrderParentingDialog;
