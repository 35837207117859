import * as React from "react";

function EmergencyIconFilled(props) {
  return (
    <svg
    // fontSize={12}
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={24} height={24} rx={12} fill="#FF1E4B" />
      <path
        d="M15.986 6.343a13.846 13.846 0 00-1.88-2.232c-.273-.266-.735-.016-.657.36.149.736.305 1.707.305 2.576 0 1.614-1.057 2.921-2.67 2.921-1.206 0-2.193-.728-2.623-1.77a1.953 1.953 0 01-.157-.422c-.086-.33-.517-.431-.705-.141a9.007 9.007 0 00-.4.65 10.785 10.785 0 00-1.464 5.45A6.263 6.263 0 0012 20a6.263 6.263 0 006.265-6.265c0-2.733-.846-5.27-2.279-7.392zM11.773 17.65c-1.394 0-2.522-1.097-2.522-2.46 0-1.268.823-2.16 2.201-2.443 1.151-.234 2.334-.728 3.156-1.503.22-.204.58-.11.642.18.18.799.274 1.629.274 2.467a3.748 3.748 0 01-3.75 3.759z"
        fill="#fff"
      />
    </svg>
  );
}

export default EmergencyIconFilled;