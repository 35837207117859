
import React, { useState, useEffect } from 'react';
import axios from '../axios-config';
export function usePrintSettings() {
    const [loading, setLoading] = useState(true);
    const [settings, setSettings] = useState({
        paperType: "0",
        title: 'Ticket',
        titleEnabled: true,
        dateEnabled: true,
        organizationName: "",
        organizationNameEnabled: true,
        organizationAddress: "",
        organizationAddressEnabled: true,
        organizationLogoEnabled: true,
        customerNameEnabled: true,
        customerPhoneEnabled: true,
        malfunctionEnabled: true,
        estimatedServicesEnabled: true,
        customerSignatureEnabled: true,
        customerSignatureLabel: "",
        firstNoteEnabled: true,
        firstNote: "",
        secondNoteEnabled: true,
        secondNote: "",
        repairPaymentsDepositsEnabled: true,
        repairOrderNoteEnabled: true,
    });
    const [organization, setOrganization] = useState(null);
    const [logo, setLogo] = useState(null);

    function handleStatusChange(status) {
        setSettings();
    }

    useEffect(async () => {
        setLoading(true);
        const printSettingsResp = await axios.get(`/organizations/printSettings`);
        const organizationResp = await axios.get('organizations');

        if (organizationResp.status == 200 || organizationResp.status == 201) {
            setOrganization(organizationResp.data);
        }
        if (printSettingsResp.status == 200) {
            if (printSettingsResp.data) {
                // const newSettings = { ...settings };
                setSettings(printSettingsResp.data);

                if (printSettingsResp.data.file) {
                    setLogo(printSettingsResp.data.file);
                }
            }
        }
        setLoading(false);
    }, []);

    return { settings, setSettings, organization, logo, setLogo, loading };
}




