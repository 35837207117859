import { useState, memo } from 'react';
import { Chip, Stack, Dialog, DialogTitle, DialogContent, IconButton, Button, Typography, Alert, AlertTitle } from '@mui/material';
import axios from "../../axios-config";

import StatusChip from '../StatusChip/StatusChip';
import ResolutionChip from '../ResolutionChip/ResolutionChip';

import { useSelector, useDispatch } from 'react-redux';
import { dataTableActions } from "../../store/data-table";

import { useTranslation } from 'react-i18next';
import { processService } from '../../utils/service-helpers';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import { formatCurrency } from '../../utils/currency';
import { useSnackbar } from 'notistack';
import { id } from 'react-date-range/dist/locale';
import useAcceptOrderRequest from 'hooks/mutations/orders/acceptOrderRequest';
import useDeclineOrderRequest from 'hooks/mutations/declineOrderRequest';
import useCancelOrderRequest from 'hooks/mutations/orders/cancelOrderRequest';
import useRepairStatusUpdate from 'hooks/mutations/orders/useRepairStatusUpdate';
import useUpdateOrderStatusAndResolution from 'hooks/mutations/orders/useUpdateOrderStatusAndResolutionMutation';


import ResolutionSelectDialog from './ResolutionSelectDialog';
import ConsoleHelper from 'utils/ConsoleHelper';
import ConfirmationDialog from 'common/ConfirmDialog';
import useStatusUpdateLogic from 'hooks/useStatusUpdateLogic';

const StatusCell = ({ repairOrder }) => {
    // console.log(repairOrderData);
    const { onStatusSelect, ResolutionDialogs } = useStatusUpdateLogic(repairOrder, ['repairOrders']);

    const currency = useSelector((state) => state.auth.currency);
    const acceptOrderRequest = useAcceptOrderRequest();
    const declineOrderRequest = useDeclineOrderRequest();
    const cancelOrderRequest = useCancelOrderRequest();

    const { t } = useTranslation();

    const cancelRequest = async (event) => {
        event.stopPropagation();
        cancelOrderRequest.mutate({ repairOrderId: repairOrder.id });
    }

    const acceptRequest = async (event) => {
        event.stopPropagation();
        acceptOrderRequest.mutate({ repairOrderId: repairOrder.id });
    }
    const declineRequest = async (event) => {
        event.stopPropagation();
        declineOrderRequest.mutate({ repairOrderId: repairOrder.id });
    }

    const services = repairOrder.services;
    const acceptedAndRefundedServices = services?.filter(service => service.isAccepted || service.refundedServiceId);

    const acceptedAndRefundedServicesProcessed = acceptedAndRefundedServices?.reduce((acc, service) => {
        const processed = processService(service)
        return acc += processed.unitPrice * processed.quantity - processed.discount;
    }, 0)

    const totalPayments = repairOrder.repairPayments?.reduce((acc, payment) => {
        return acc += parseFloat(payment.amount);
    }, 0)

    const PaidStatus = () => {
        if (repairOrder.paid) {
            return <Chip color='accepted' variant='outlined' label={t("Paid")} icon={<PaymentsOutlinedIcon />} />
        }
        if ((!repairOrder.repairPayments && !acceptedAndRefundedServices) || (repairOrder.repairPayments?.length == 0 && acceptedAndRefundedServices.length == 0) || (acceptedAndRefundedServicesProcessed == 0 && totalPayments == 0)) {
            return null;
        }
        if (totalPayments == acceptedAndRefundedServicesProcessed) {
            return <Chip color='accepted' variant='outlined' label={t("Paid")} icon={<PaymentsOutlinedIcon />} />
            // return <Stack sx={{ border: '1px solid', borderColor: 'success.main', borderRadius: '20px', px: '10px', py: '3px' }} direction={'row'} alignItems={'center'} spacing={1}><PaymentsOutlinedIcon color='success' /> <Typography variant='body2'>{t("Paid")}</Typography></Stack>
        }
        return <Stack direction={'row'}><Typography variant='body2'>{formatCurrency(totalPayments, currency)}/{formatCurrency(acceptedAndRefundedServicesProcessed, currency)}</Typography> </Stack>
    }


    if (!repairOrder) {
        return null;
    }


    return (<>
        <Stack className='statusColumnCell' alignItems={'center'} justifyContent={'center'} height={'100%'}>
            <Stack spacing={1} alignItems={'center'}>
                {/* Add resolution chip */}
                {repairOrder.resolutionType ? <>
                    <ResolutionChip variant={repairOrder.resolutionType.name} />
                </>

                    : null}

                <StatusChip repairOrderData={repairOrder} onStatusSelect={onStatusSelect} />

                {repairOrder.resolutionType ? <>
                    <PaidStatus />
                </>
                    : null}

                {repairOrder.repairOrderOutsourceRequest && !repairOrder.isChangingStatus ? <Button size='small' color='red' variant='outlined' onClick={cancelRequest}> {t('Cancel')} </Button> : null}

                {repairOrder.isRequest && !repairOrder.isChangingStatus ? <Stack direction={'row'} spacing={1}>
                    <Button
                        size='small'
                        color='green'
                        variant='outlined'
                        onClick={acceptRequest}
                    >
                        {t('Accept')}
                    </Button>
                    <Button size='small' color='red' variant='outlined' onClick={declineRequest}> {t('Decline')} </Button></Stack> : null}
            </Stack>
        </Stack >
        {ResolutionDialogs}

    </>
    )
}

export default memo(StatusCell);