import * as React from 'react';
import { TextField, Box, Skeleton, Divider } from '@mui/material';
import { useState } from 'react';

const LeftPanelSkeleton = () => {

    const p = { height: 10 }
    const h1 = { height: 40 }
    const h2 = { height: 30 }

    return (
        <>
            <Box py={'15px'} px={'25px'}>
                <Box display={'fex'} alignItems={'center'}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Skeleton width={150} height={h1.height} />
                    </Box>
                    <Box>
                        <Skeleton width={80} height={h1.height} />
                    </Box>
                </Box>
            </Box>
            <Divider />
            <Box py={'15px'} px={'25px'}>
                <Box display={'fex'} alignItems={'center'}>
                    <Box sx={{ flexGrow: 1, flexDirection: 'row' }}>
                        <Skeleton width={150} />
                    </Box>
                    <Skeleton variant='circular' width={40} height={40} />
                </Box>
            </Box>
            <Box py={'15px'} px={'25px'}>
                <Box fullwidth>
                    <Skeleton width={'100%'} height={80} />
                    <Skeleton width={'100%'} height={80} />
                    <Skeleton width={'100%'} height={80} />
                </Box>
            </Box>
            <Box py={'15px'} px={'25px'}>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Skeleton width={100} />
                    <Skeleton width={100} />
                    <Skeleton width={100} />
                    <Skeleton width={100} />
                </Box>
            </Box>
            <Divider />
            <Box py={'15px'} px={'25px'}>
                <Box display={'flex'}>
                    <Skeleton variant='rectangle' width={70} height={70} sx={{ marginRight: '10px' }} />
                    <Skeleton variant='rectangle' width={70} height={70} sx={{ marginRight: '10px' }} />
                    <Skeleton variant='rectangle' width={70} height={70} sx={{ marginRight: '10px' }} />
                </Box>
            </Box>
            <Box py={'15px'} px={'25px'}>
                <Skeleton variant='rectangle' width={300} height={h2.height} sx={{ mb: '15px' }} />
                <Box display={'flex'}>
                    <Skeleton variant='rectangle' width={100} height={h2.height} sx={{ mr: '10px' }} />
                    <Skeleton variant='rectangle' width={100} height={h2.height} sx={{ mr: '10px' }} />
                    <Skeleton variant='rectangle' width={100} height={h2.height} sx={{ mr: '10px' }} />
                    <Skeleton variant='rectangle' width={100} height={h2.height} sx={{ mr: '10px' }} />
                </Box>
            </Box>
        </>
    )
}

export default LeftPanelSkeleton;