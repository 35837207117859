import { useState, useEffect, memo } from 'react';
import {
    Paper, Box, IconButton, Stack, Popover, OutlinedInput, InputLabel, MenuItem,
    FormControl, Select, TextField,
    List, ListItem, ListItemText, Checkbox, ListItemButton, ListItemAvatar, Avatar,
    Button, Divider, Typography,
} from '@mui/material';

import axios from "../../axios-config";
import { useSelector, useDispatch } from "react-redux";
import { dataTableActions } from '../../store/data-table';


// ICONS
import ContentPasteSearchOutlinedIcon from '@mui/icons-material/ContentPasteSearchOutlined';
import TabletAndroidOutlinedIcon from '@mui/icons-material/TabletAndroidOutlined';
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useTranslation } from 'react-i18next';
import { TurnedIn } from '@mui/icons-material';

const PartnersFilter = memo(() => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const partnersFilter = useSelector(state => state.dataTable.partnersFilter)

    const [anchorEl, setAnchorEl] = useState(null);
    const [partnersListOpen, setPartnersListOpen] = useState(false);
    const [partnersAvailable, setPartnersAvailable] = useState([]);
    const [ourOrganization, setOurOrganization] = useState({});

    const handlePartnersOpen = (e) => {
        setAnchorEl(e.target);
        setPartnersListOpen(true);
    }

    const handlePartnerFliterClick = (partner) => {
        const partnerAdded = partnersFilter.filter((item) => {
            return item.id === partner.id
        }
        );

        if (partnerAdded.length === 0) {
            const partnersFilteredCopy = [...partnersFilter, partner];
            dispatch(dataTableActions.setPartnersFiltered(partnersFilteredCopy))
        }
        else {
            const partnersFilteredCopy = partnersFilter.filter((statePartner) => statePartner.id !== partner.id);
            dispatch(dataTableActions.setPartnersFiltered(partnersFilteredCopy))
        }
    }

    const partnerParentCheck = () => {
        if (partnersFilter.length == 0) {
            return false;
        }
        const allOrgIncludingSession = [...partnersAvailable, ourOrganization];
        return allOrgIncludingSession.length === partnersFilter.length;

    }

    const partnerIsIntermediate = () => {

        if (partnersFilter.length == 0) {
            return false;
        }
        const allOrgIncludingSession = [...partnersAvailable, ourOrganization];
        if (allOrgIncludingSession.length !== partnersFilter.length) {
            return true;
        }

        return false;
    }
    const handleCheckAll = () => {

        const isChecked = partnerParentCheck();

        if (isChecked) {
            dispatch(dataTableActions.setPartnersFiltered([]))
        }
        else {
            dispatch(dataTableActions.setPartnersFiltered([...partnersAvailable, ourOrganization]))

        }
    }




    // const hideAllStatuses = () => dispatch(dataTableActions.hideAllStatuses());
    // const showAllStatuses = () => dispatch(dataTableActions.showAllStatuses());

    // console.log(defaultInputRanges[0].label);
    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {

            const response = await axios.get(`/organizations/partners`)
            if (!isMounted) return;
            if (response.status == 200) {
                const partners = response.data.sort((a, b) => a.name.localeCompare(b.name));
                setPartnersAvailable(partners);
            }

            const responseOurOrg = await axios.get(`/organizations`);
            if (!isMounted) return;
            if (responseOurOrg.status == 200) {
                setOurOrganization(responseOurOrg.data);
            }
        }

        fetchData().catch(console.error);;
        return () => {
            isMounted = false;
        }
    }, [])

    return <>
        <Button onClick={handlePartnersOpen} color={'black'} variant='outlined' startIcon={<GroupOutlinedIcon />} > {t('Partners')}</Button>

        <Popover
            size='small'
            open={partnersListOpen}
            anchorEl={anchorEl}
            onClose={() => setPartnersListOpen(false)}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
        >
            <Box p={'10px'} maxHeight={'300px'} maxWidth={'370px'} sx={{ overflow: 'hidden' }}>
                <List dense>
                    <ListItem
                        secondaryAction={
                            <Checkbox
                                checked={partnerParentCheck()}
                                indeterminate={partnerIsIntermediate()}
                                onChange={handleCheckAll}
                            />
                        }
                        disablePadding
                    >
                        <ListItemButton onClick={handleCheckAll}>
                            <ListItemText primary={t("Select all")} />
                        </ListItemButton>
                    </ListItem>
                </List>
                <Box sx={{ maxHeight: '230px', overflow: 'auto' }}>
                    <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        <Divider />
                        {ourOrganization ?
                            <>
                                <ListItem
                                    secondaryAction={
                                        <Checkbox
                                            // icon={<VisibilityOffIcon />}
                                            // checkedIcon={<VisibilityIcon />}
                                            edge="end"
                                            checked={partnersFilter.findIndex((item) => item.id === ourOrganization.id) !== -1}
                                            onChange={() => handlePartnerFliterClick(ourOrganization)}
                                        />
                                    }
                                    disablePadding
                                >
                                    <ListItemButton onClick={() => handlePartnerFliterClick(ourOrganization)}>
                                        <ListItemText primary={t("Our organization")} />
                                    </ListItemButton>
                                </ListItem>
                                <Divider />
                            </>
                            : null}
                        {partnersAvailable.map((partner) => {
                            const labelId = `checkbox-list-secondary-label-${partner.id}`;
                            return (
                                <ListItem
                                    key={partner.id}
                                    secondaryAction={
                                        <Checkbox
                                            // icon={<VisibilityOffIcon />}
                                            // checkedIcon={<VisibilityIcon />}
                                            edge="end"
                                            onChange={() => handlePartnerFliterClick(partner)}
                                            checked={partnersFilter.findIndex((item) => item.id == partner.id) !== -1}
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    }
                                    disablePadding
                                >
                                    <ListItemButton onClick={() => handlePartnerFliterClick(partner)}>
                                        <ListItemText id={labelId} primary={partner.name} />
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                    </List>
                </Box>
            </Box>
        </Popover>
    </>
})


export default PartnersFilter;