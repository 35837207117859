import axios from "axios-config";

export const repairsRevenuePerUser = async ({ startDate, endDate }) => {

  if (!startDate || !endDate) throw new Error("Missing required parameters");

  const response = await axios.get('/reports/repairs-revenue-per-user', {
    params: {
      startDate: startDate,
      endDate: endDate,
    },
  });

  return response.data;
};
