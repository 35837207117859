import {
    FormControl,
    CardContent,
    TextField,
    Link,
    Button,
    Grid,
    Stack,
    FormControlLabel,
    Checkbox,
    Box,
    Alert,
} from "@mui/material";
import Typography from "@mui/material/Typography";

import axios from 'axios';
import { useState, useRef } from "react";
import { useSnackbar } from "notistack";
import Logo from "../../images/dalvado-logo.png";
import { set } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { authActions } from "../../store/auth";
import { useDispatch } from "react-redux";
import { WarningAmberOutlined } from "@mui/icons-material";
import ConsoleHelper from "../../utils/ConsoleHelper";


const CodeInput = ({ codeLength, onCodeFilled }) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [code, setCode] = useState(Array.from({ length: codeLength }, () => ""));
    const inputs = useRef([]);


    useEffect(() => {
        if (code.join('').length === 6) {
            onCodeFilled(code.join(''));
        }
    }, [code]);
    useEffect(() => {
        setCode(Array.from({ length: codeLength }, () => ""));
    }, [codeLength]);

    const focusNext = (index, value) => {
        let newCode = [...code];
        let i = index;

        // Remove special characters and spaces, convert to uppercase
        value = value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
        // console.log(`focusNext :${index},${value}`);
        if (value === '') {
            // Delete operation
            newCode[index] = '';
            setCode(newCode);
            if (index > 0) {
                inputs.current[index - 1].focus();
            }
            return;
        }

        for (let char of value) {
            if (i >= code.length) break;
            newCode[i] = char;
            i++;
        }

        setCode(newCode);

        if (i < code.length) {
            inputs.current[i].focus();
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        // Remove special characters and spaces, convert to uppercase
        const pastedData = e.clipboardData
            .getData('text/plain')
            .replace(/[^a-zA-Z0-9]/g, '')
            .toUpperCase();

        focusNext(0, pastedData);
    };

    const handleOnInput = (index, e) => {
        if (code[index] && e.target.value.length > 1 && index < code.length - 1) {
            inputs.current[index + 1].focus();
            //get the next character of e.target.value
            const nextChar = e.target.value.charAt(1);
            focusNext(index + 1, nextChar);
            return;
        }
        focusNext(index, e.target.value);
    };
    const handleKeyDown = (index, e) => {
        const { key } = e;
        if (key === "Backspace") {
            let newCode = [...code];

            // Empty current and move focus to the previous if it's not the first input
            if (code[index] === '' && index > 0) {
                newCode[index - 1] = '';
                setCode(newCode);
                inputs.current[index - 1].focus();
            }

            // Empty current if it's the first input
            if (code[index] === '' && index === 0) {
                newCode[index] = '';
                setCode(newCode);
            }
        }
    };

    return (

        <Grid container spacing={2} justifyContent="center" mb={2}>
        {code.map((digit, index) => (
            <Grid item key={index}>
                <TextField
                    inputRef={(ref) => (inputs.current[index] = ref)}
                    variant="outlined"
                    value={digit}
                    inputProps={{
                        style: {
                            width: '2ch',
                            textAlign: 'center',
                            letterSpacing: '0.5ch',
                        },
                        maxLength: 2,
                    }}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    onInput={(e) => handleOnInput(index, e)}
                    onPaste={handlePaste}
                />
            </Grid>
        ))}
    </Grid>
    );
};
export default CodeInput;
