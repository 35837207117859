import { repairStatusesId } from "./repair-statuses-helpers";

export const processLogValue = (field, value) => {

    if (field === "isAccepted") {
        return processIsAcceptedValue(value);
    }
    if (field === "stock") {
        if (value === null) {
            return "Not set"
        }
        if (value === true) {
            return "In Stock"
        }
        if (value === false) {
            return "Not in Stock"
        }
    }
    if (field === "repairOrderStatusId") {
        if (repairStatusesId[value]) {
            return repairStatusesId[value].name;
        }
    }

    return value;
}

export const processIsAcceptedValue = (value) => {
    let logDetail = "-"
    if (value === true) logDetail = 'Accepted'
    if (value === false) logDetail = 'Not accepted'

    return logDetail;
}

export const processField = (field) => {
    if (field === "isAccepted") {
        return "Acceptance"
    }
    if (field === "repairOrderStatusId") {
        return "Status";
    }
    if (field === "unitPrice") {
        return "Unit Price";
    }
    if (field === "createdAt") {
        return "Created At"
    }
    if (field === "quantity") {
        return "Quantity"
    }

    if (field === "name") {
        return "Name"
    }
    else return field;
}