
import axios from 'axios-config';
import ConsoleHelper from 'utils/ConsoleHelper';

// ========= API FUNCTIONS RECOMMENDATION =========
// React Query and the nature of the API functions, 
// destructured parameters are generally the better choice.

export const fetchRepairOrder = async ({ repairOrderId }) => {

    if (!Number.isInteger(repairOrderId)) {
        throw new Error('Missing required parameters');
    }
    const response = await axios.get(`/repair-orders/${repairOrderId}`);

    return response.data;
};


export const updateOrderStatus = async ({ repairOrderId, status }) => {
    // console.log(`API: repairOrderId: ${repairOrderId}, statusId: ${statusId}`);
    if (!repairOrderId || !status || !status.id) throw new Error('Missing required parameters');
    const response = await axios.patch(`/repair-orders/${repairOrderId}/status/${status.id}`);
    if (response.status !== 200) throw new Error('Failed to update status');
    return response.data;
};


export const updateOrderStatusWithResolution = async ({ repairOrderId, status, resolution }) => {
    // const response = await axios.patch(`/repair-orders/${repairOrderId}/status/${statusIndexSelected}`, { resolutionIndex: resolutionId });
    if (!repairOrderId || !resolution || !status || !resolution.id || !status.id) throw new Error('Missing required parameters');
    //TODO: change resolutionIndex to resolutionId
    const response = await axios.patch(`/repair-orders/${repairOrderId}/status/${status.id}`, { resolutionId: resolution.id });
    if (response.status !== 200) throw new Error('Failed to update status');
    return response.data;

}

export const acceptOrderRequest = async ({ repairOrderId }) => {
    //be sure repairOrderId is not null and is a number
    if (!repairOrderId || !Number.isInteger(repairOrderId)) {
        throw new Error('Missing required parameters');
    }
    const response = await axios.put(`repair-orders/outsource/${repairOrderId}/accept`)
    if (response.status !== 200) throw new Error('Failed to accept request');
    return response.data;
}

export const declineOrderRequest = async ({ repairOrderId }) => {
    //be sure repairOrderId is not null and is a number
    if (!repairOrderId || !Number.isInteger(repairOrderId)) {
        throw new Error('Missing required parameters');
    }
    const response = await axios.delete(`repair-orders/outsource/${repairOrderId}`)
    if (response.status !== 200) throw new Error('Failed to accept request');
    return response.data;
}

export const bulkUpdateStatus = async ({ repairOrderIds, status, resolution }) => {

    if (!repairOrderIds || !status || !status.id) {
        //log which parameter is missing
        throw new Error('Missing required parameters');
    }
    let resolutionId = null;
    if (resolution) {
        resolutionId = resolution.id;
    }
    const response = await axios.patch('/repair-orders/bulk-update/status', { repairOrderIds, statusId: status.id, resolutionId: resolutionId });
    // if (response.status !== 200) throw new Error('Failed to update status');
    return response.data;
}


export const bulkMarkAsPaid = async ({ repairOrderIds, paymentMethod }) => {
    if (!Array.isArray(repairOrderIds) || repairOrderIds.length === 0 || typeof paymentMethod !== 'number') {
        ConsoleHelper(repairOrderIds, paymentMethod);
        throw new Error('Missing required parameters');
    }
    const response = await axios.post(`/repair-orders/bulk/mark-as-paid`, { repairOrderIds, paymentMethod: paymentMethod });
    if (response.status !== 200) throw new Error('Failed to mark as paid');
    return response.data;
}