import React, { useState, useEffect } from 'react';
import { Avatar, Box, Button, Container, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Stack, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Paper } from '@mui/material';

import { set } from 'lodash';
import axios from "axios-config";

import ToolBarHelper from 'components/ToolBarHelper/ToolBarHelper';
import { ArrowBack, Image, MoreHoriz, ThreeDRotationRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator, timelineItemClasses } from '@mui/lab';



const EditTransferPage = () => {

    const [trasnferLoading, setTransferLoading] = useState(true);
    const [transferData, setTransferData] = useState(null);

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { transferId } = useParams();
    const theme = useTheme();

    document.body.style.backgroundColor = theme.palette.lightBlue.main;

    useEffect(() => {
        // Fetch inventory data from API or database
        // and update the state
        fetchTransfer();
        setTransferData({
            id: 1,

        })
    }, []);

    const fetchTransfer = async () => {
        try {
            // Make API call or database query to fetch inventory data
            // get id from url param

            setTransferLoading(true);
            const response = await axios.get(`/inventory/transfers/${transferId}`);
            if (response.status === 200) {
                const data = await response.data;

                setTransferData(data);
            }
        } catch (error) {
            console.error(error);
        }
        setTransferLoading(false);
    };


    return (
        <>
            <Box className={'container mainContainer docsContainer '} >
                <ToolBarHelper>
                    <Stack direction={'row'} gap={1} flexWrap={'wrap'} alignItems={'flex-start'} width={"100%"}>
                        <Button color={'black'} variant='outlined' startIcon={<ArrowBack />} onClick={() => navigate("/")}>{t('Back')}</Button>
                    </Stack>
                </ToolBarHelper>
                <Box sx={{ px: '20px', py: '10px' }}>
                    {trasnferLoading ? <Typography>Loading...</Typography> :
                        <>
                            <Typography variant='h3'>Transfer: {transferData.id}</Typography>
                            <Typography variant='caption'>{transferData.status}</Typography>
                        </>
                    }
                    {trasnferLoading ? null :
                        <Box width={'600px'}>
                            <Typography variant='h6'>Timeline</Typography>
                            <Timeline
                                sx={{
                                    [`& .${timelineItemClasses.root}:before`]: {
                                        flex: 0,
                                        padding: 0,
                                    },
                                }} >
                                {transferData?.transferLogs?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((log, index, self) => {
                                    let Component;
                                    if (log.logType === 'comment') {
                                        Component = TransferLogComment;
                                    } else {
                                        Component = TransferLogUpdate;
                                    }
                                    const isLast = index === self.length - 1;


                                    return (
                                        <React.Fragment key={log.id}>
                                            <Component log={log} isLast={isLast} />
                                        </React.Fragment>
                                    );
                                })}
                                {/* <TimelineItem sx={{ width: '100%', pl: "35px" }}>
                                    <TimelineSeparator>
                                        <TimelineDot />
                                        <TimelineConnector />

                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'} spacing={2}>
                                            <Typography>
                                                Alex completed this transfer
                                            </Typography>
                                            <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'} flex={1}>
                                                <Typography color={'gray'} whiteSpace={'nowrap'}>
                                                    2021-09-01
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </TimelineContent>
                                </TimelineItem>
                                <TimelineItem sx={{ width: '100%' }}>

                                    <Paper sx={{ backgroundColor: "white", padding: '5px', width: '100%' }} >
                                        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <Image />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'} spacing={0}>
                                                    <Stack>
                                                        <Typography variant='h6'>Alex</Typography>
                                                        <Typography variant='body'>Only a portion of the shipment has arrived. The remainder is expected to be received on Monday, 04.02.2024.</Typography>
                                                    </Stack>

                                                    <IconButton>
                                                        <MoreHoriz />
                                                    </IconButton>
                                                </Stack>
                                            </ListItem>
                                        </List>
                                    </Paper>
                                </TimelineItem>
                                <Box height={'20px'}>

                                    <TimelineItem sx={{ width: '100%', pl: "40px", pt: '0px', pb: '0px' }}>
                                        <TimelineSeparator >
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                    </TimelineItem>
                                </Box>
                                <TimelineItem sx={{ width: '100%', pl: "35px" }}>
                                    <TimelineSeparator>
                                        <TimelineDot />
                                        <TimelineConnector />

                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'} spacing={2}>
                                            <Typography>
                                                Alex partially received this transfer.
                                            </Typography>
                                            <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'} flex={1}>
                                                <Typography color={'gray'} whiteSpace={'nowrap'}>
                                                    2021-09-01
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </TimelineContent>
                                </TimelineItem>
                                <TimelineItem sx={{ width: '100%', pl: "35px" }}>
                                    <TimelineSeparator>
                                        <TimelineDot />
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'} spacing={2}>
                                            <Typography>
                                                Alex created this transfer.
                                            </Typography>
                                            <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'} flex={1}>
                                                <Typography color={'gray'} whiteSpace={'nowrap'}>
                                                    2021-09-01
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </TimelineContent>
                                </TimelineItem> */}

                            </Timeline>

                            {/* <Divider sx={{ my: '15px' }} /> */}
                        </Box>
                    }
                </Box>
            </Box>
        </>
    );
};

const TransferLogUpdate = ({ log, isLast }) => {
    const dayjs = require('dayjs');
    return (
        <TimelineItem sx={{ width: '100%', pl: "35px" }}>
            <TimelineSeparator>
                <TimelineDot />
                {!isLast && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'} spacing={2}>
                    {log.logType === 'created' &&
                        <Typography>
                            {log.createdByUser.name} created this transfer
                        </Typography>
                    }
                    {log.logType === 'accepted_items_from_supplier' &&
                        <Typography>
                            {log.createdByUser.name} accepted items from supplier
                        </Typography>
                    }

                    <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'} flex={1}>
                        <Typography color={'gray'} whiteSpace={'nowrap'}>
                            {dayjs(log.createdAt).format('YYYY-MM-DD')}
                        </Typography>
                    </Stack>
                </Stack>
            </TimelineContent>
        </TimelineItem>
    )
}

const TransferLogComment = ({ log, isLast }) => {
    return (
        <>
            <TimelineItem sx={{ width: '100%' }}>
                <Paper sx={{ backgroundColor: "white", padding: '5px', width: '100%' }} >
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <Image />
                                </Avatar>
                            </ListItemAvatar>
                            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'} spacing={0} sx={{ width: '100%' }}>
                                <Stack>
                                    <Typography variant='h6'>{log.createdByUser.name}</Typography>
                                    <Typography variant='body'>{log.description}</Typography>
                                </Stack>

                                <IconButton>
                                    <MoreHoriz />
                                </IconButton>
                            </Stack>
                        </ListItem>
                    </List>
                </Paper>
            </TimelineItem>
            {!isLast && <Box height={'40px'}>
                <TimelineItem sx={{ width: '100%', pl: "40px", pt: '0px', pb: '0px' }}>
                    <TimelineSeparator sx={{height:'40px'}}>
                        <TimelineConnector />
                    </TimelineSeparator>
                </TimelineItem>
            </Box>}
        </>
    )
}


export default EditTransferPage;
