import { useCallback } from 'react';
import { Box, display } from '@mui/system';
import {
    Typography, Button, Stack, Grid, Divider, TextField, List, Paper,
    ListItem, ListItemAvatar, Avatar, ListItemText, Tab, Tabs, Autocomplete,
    TableCell, Table, TableRow, TableBody, TableHead, TableContainer, Checkbox,
    InputAdornment,
    Container,
    Skeleton,
    Snackbar,
    Tooltip,
    IconButton
} from '@mui/material';
import axios from "../../axios-config";

import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from '@mui/material/styles';

import { Smartphone } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';



const DeviceSearchField = ({ onChange, selectedDeviceData }) => {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [isLoading, setIsloading] = useState(false);
    const [deviceData, setDeviceData] = useState(null);
    const { t } = useTranslation();
    const theme = useTheme();
    useEffect(() => {
        if (!selectedDeviceData) return;
        setDeviceData(selectedDeviceData);
    }, [selectedDeviceData])

    const fetchData = async () => {
        try {
            setIsloading(true);
            const response = await axios.get(`/device-catalogs`);
            let data = await response.data;
            setOptions(data);
            setIsloading(false);

        } catch (err) {
            setIsloading(false);
            console.log(err);
        }
    };
    const handleOpen = () => {
        setOpen(true);
        fetchData();
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOnChange = (e, val) => {

        setDeviceData(val);
        onChange(val);
    }
    const filterOptions = (options, { inputValue }) => {
        const terms = inputValue.toLowerCase().split(' ');
    
        return options
            .map((option) => {
                const mergedString = `${option.brand} ${option.name} ${option.models}`.toLowerCase();
                let score = 0;
                // Increase the score for each found term, regardless of its order
                for (const term of terms) {
                    const termIndex = mergedString.indexOf(term);
                    if (termIndex !== -1) {
                        score++;
                    }
                }
                // Add a bonus for exact matches of each field
                [option.brand, option.name, option.models].forEach(field => {
                    if (field && field.toLowerCase() === inputValue.toLowerCase()) {
                        score += 100; // Add a large bonus for exact matches
                    }
                });
                return { ...option, score };
            })
            .filter((option) => option.score > 0)
            .sort((a, b) => b.score - a.score)
            .slice(0, 20);
    };

    const customerOption = (name, brand, deviceImage, customerModel) => {
        const avatar = deviceImage ? <Stack direction={'row'} alignItems={'center'} spacing={2}>
            {/* <Avatar
                variant="square"
                src={deviceImage}
                sx={{
                    height: "100%",
                    width: 56,
                    objectFit: "contain",
                }}
            /> */}
            <Box
                component="img"
                sx={{
                    maxHeight: "70px",
                    minHeight: "70px",
                    maxWidth: "56px",
                    minWidth: "56px",
                    width: "56px",
                    objectFit: "contain",
                    // display: { xs: "none", sm: "block", md: "block", lg: "block" },
                }}
                alt="device"
                src={`${process.env.REACT_APP_BASE_URL}${deviceImage}`}

            />

        </Stack> : "";
        const models = <Stack direction={'column'} alignItems={'start'}>
            {<Typography fontWeight={500}>{brand} {name}</Typography>}
            <Typography >{customerModel}</Typography>
        </Stack>;
        return (<Stack direction={'row'} spacing={1} alignItems={'flex-start'}>
            {avatar}
            {models}
        </Stack>)
    }

    return (
        <Autocomplete
            onOpen={handleOpen}
            onClose={handleClose}
            value={deviceData}
            onChange={(e, val) => handleOnChange(e, val)}
            options={options}
            getOptionLabel={(option) => option.name}
            filterOptions={filterOptions}
            renderOption={(props, option) => (
                <li {...props} key={option.id}>
                    <Box width="100%">{customerOption(option.name, option.brand, option.deviceImageLink, option.models)}</Box>
                </li>
            )}
            renderInput={(params) => <TextField {...params} placeholder='iPhone 12 Pro Max'
                size='small'
                variant='filled' label={t("Filter devices")} InputProps={{
                    ...params.InputProps, // Spread previous props
                    startAdornment: (
                        <>
                            <Smartphone fontSize="small" />
                            {params.InputProps.startAdornment}
                        </>
                    ),
                }} />}
        />
    );
};

export default DeviceSearchField;