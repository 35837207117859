import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { string, z } from 'zod'
import { set } from 'lodash'
import axios from "axios-config";

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import ImageUpload from 'components/ImageUpload/ImageUpload'
import SelectLocationDialog from '../SelectLocation'
import { Add, Close } from '@mui/icons-material'
import { styled } from '@mui/material/styles';
import ImageUploadButton from 'common/ImageUploadButton'
import { LoadingButton } from '@mui/lab'
import { useSelector } from 'react-redux';
import { getCurrencySymbol } from 'utils/currency'

const AddNewProductDialog = ({ open, onClose, onNewProductAdded, warehouse, newProductInitialQuantity, newProductInitialName }) => {
    const { t } = useTranslation()

    const { enqueueSnackbar } = useSnackbar()
    const [image, setImage] = useState([]);
    const [selectLocationDialog, setSelectLocationDialog] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedImageURL, setSelectedImageURL] = useState(null);
    const [selectedImageFile, setSelectedImageFile] = useState(null);
    const currency = useSelector((state) => state.auth.currency);

    const defaultValues = {
        name: newProductInitialName || '',
        description: '',
        sku: '',
        cost: '',
        initialQuantityInStock: newProductInitialQuantity || '',
        barcode: '',
    }

    const schema = z.object({
        name: z.string().min(1, { message: "Required" }),
        description: z.string().optional(),
        sku: z.string().optional(),
        cost: z.string().regex(/^\d*$/, 'Must be an integer').optional(),
        initialQuantityInStock: z.union([z.number(), z.string().regex(/^\d*$/, 'Must be an integer')]).optional(),
        barcode: z.string().optional(),
    })
    const { handleSubmit, control, watch, formState: { isSubmitting, isDirty, isValid, errors }, reset, setValue } = useForm({
        defaultValues: defaultValues,
        resolver: zodResolver(schema)
    });

    useEffect(() => {
        setValue('name', newProductInitialName);
    }, [newProductInitialName, setValue]);

    const handleAddProduct = async (data) => {
        if(selectLocationDialog) return;
        try {
            const formData = new FormData();

            // Append text fields to formData
            for (const key in data) {
                // handle undefined values
                if (data[key] === undefined) {
                    continue;
                }
                formData.append(key, data[key]);
            }

            // Append the file under the 'productImage' key
            // Ensure selectedImage is the File object you want to upload
            if (selectedImageURL && selectedImageFile) {
                formData.append('productImage', selectedImageFile);
            }
            if (selectedLocation && selectedLocation.id) {
                formData.append('locationId', selectedLocation?.id);
            }
            if (warehouse && warehouse.id) {
                formData.append('warehouseId', warehouse?.id);
            }
            const response = await axios.post('/inventory/product', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            const responseData = response.data;
            enqueueSnackbar(t('Product added successfully!'), { variant: 'success' });
            reset();
            setSelectedLocation(null);
            setSelectedImageURL(null);
            setSelectedImageFile(null);
            setImage([]);
            onNewProductAdded(responseData);
            handleNewProductDialogClose();
            //Reset the form
            reset(); // This comes from useForm and resets the form fields
            setSelectedLocation(null);
            setSelectedImageURL(null);
            setSelectedImageFile(null);
            setImage([]);

        }
        catch (err) {
            console.log(err);
            enqueueSnackbar('Failed to add product', { variant: 'error' });
        }
    }

    const handleNewProductDialogClose = () => {
        onClose()
    }



    const handleLocationSelectOpen = (e) => {
        setSelectLocationDialog(true);
    }

    const handleOnLocationSelect = (location) => {
        setSelectLocationDialog(false);
        setSelectedLocation(location);
    }


    const handleImageSelect = (image) => {
        setSelectedImageURL(URL.createObjectURL(image));
        setSelectedImageFile(image);
    };

    const allowOnlyNumber = (value) => {
        return value.replace(/[^0-9]/g, '')
    }

    const handleImageRmove = () => {
        setSelectedImageURL(null);
        setSelectedImageFile(null);

    }

    return <Dialog open={open} onClose={handleNewProductDialogClose} fullWidth maxWidth={'sm'}>
        <form onSubmit={handleSubmit(handleAddProduct)} noValidate>
            <DialogTitle variant='h4'>
                {t("Add new product")}
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <Stack direction={'row'} alignItems={'center'} spacing={2} py={'10px'}>
                        <ImageUploadButton onImageSelected={handleImageSelect} imageUrl={selectedImageURL} onImageRemoved={handleImageRmove} />
                        <Stack>
                            {/* <Typography variant='body2'>Up to 500 KB per file</Typography> */}
                            <Typography variant='body2'>{t("Product image")}</Typography>
                            <Typography variant='body2'>(PNG, JPG, JPED)</Typography>
                        </Stack>
                    </Stack>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field }) => <TextField
                            {...field}
                            required
                            label={t("Name")}
                            type="text"
                            autoComplete="new-password" // trick the browser
                            variant="filled"
                            size="small"
                            error={!!errors.name}
                            helperText={t(errors.name?.message)}
                        />}
                    />

                    <Controller
                        name="description"
                        control={control}
                        defaultValue={''}
                        render={({ field }) => <TextField
                            {...field}
                            label={t("Description")}
                            type='text'
                            variant="filled"
                            size="small"
                            multiline
                            maxRows={3}
                            minRows={3}
                            error={!!errors.description}
                            helperText={t(errors.description?.message)}
                        />}
                    />
                    <Controller
                        name="sku"
                        control={control}
                        defaultValue={''}
                        render={({ field }) => <TextField
                            {...field}
                            label={t("SKU")}
                            type='text'
                            variant="filled"
                            size="small"

                            error={!!errors.sku}
                            helperText={t(errors.sku?.message)}
                        />}
                    />
                    <Controller
                        name="barcode"
                        control={control}
                        defaultValue={''}
                        render={({ field }) => <TextField
                            {...field}
                            label={t("Barcode")}
                            type='text'
                            variant="filled"
                            size="small"
                            error={!!errors.barcode}
                            helperText={t(errors.barcode?.message)}
                        />}
                    />
                    <Controller
                        name="cost"
                        control={control}

                        render={({ field }) => <TextField
                            {...field}
                            label={t("Cost")}
                            type='text'
                            variant="filled"
                            size="small"
                            error={!!errors.cost}
                            helperText={t(errors.cost?.message)}
                            placeholder='0'
                            InputProps={{
                                startAdornment: <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>,
                            }}
                        />}
                    />
                    <Divider />
                    <FormControl>
                        <InputLabel variant='filled' id="demo-simple-select-label">{t("Warehouse")}</InputLabel>
                        <Select
                            variant='filled'
                            readOnly
                            size='small'
                            labelId="warehosue-select-label"
                            id="warehouse-simple-select"
                            value={warehouse.name}
                            label="Warehouse"
                        // onChange={handleChange}
                        >
                            <MenuItem value={warehouse.name}>{warehouse.name}</MenuItem>
                        </Select>
                    </FormControl>
                    <Controller
                        name="initialQuantityInStock"
                        control={control}
                        defaultValue={newProductInitialQuantity}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label={t("Initial quantity in stock")}
                                type='text'
                                placeholder='0'
                                variant="filled"
                                error={!!errors.initialQuantityInStock}
                                helperText={t(errors.initialQuantityInStock?.message)}
                                onInput={(event) => {
                                    event.target.value = allowOnlyNumber(event.target.value);
                                }}
                            />
                        )}
                    />
                    <TextField
                        fullWidth
                        label={t('Location')}
                        onClick={handleLocationSelectOpen}
                        value={selectedLocation != null ? selectedLocation.name : ''}
                        // sx={{ width: "350px" }}
                        variant="filled"
                        // value={!product.location?.fullPath ? t("Not selected") : "location selected"}
                        InputProps={{

                            // startAdornment: <InputAdornment position="start"><CalendarMonthOutlined /></InputAdornment>,
                            endAdornment: (
                                <InputAdornment position='end'>
                                    {selectedLocation && <IconButton onClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedLocation(null)
                                    }}>
                                        <Close />
                                    </IconButton>}
                                    {/* <ArrowDropDownRounded /> */}
                                </InputAdornment>
                            )
                        }}
                    />
                    <SelectLocationDialog open={selectLocationDialog} warehouse={warehouse} onClose={() => setSelectLocationDialog(false)} onLocationSelect={handleOnLocationSelect} />

                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleNewProductDialogClose}>{t("Close")}</Button>
                <LoadingButton loading={isSubmitting} type='submit' variant='contained'>{t("Add")}</LoadingButton>
            </DialogActions>
        </form>
    </Dialog >
}

export default AddNewProductDialog