import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Popover from '@mui/material/Popover';
import { Typography, Collapse, Stack, IconButton, Divider, Dialog, DialogTitle } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import axios from "../../axios-config";
import { repairStatuses } from '../../utils/repair-statuses-helpers';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FormControlLabel from '@mui/material/FormControlLabel';

import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';

import { useTranslation } from 'react-i18next';
import { processLogValue, processField } from '../../utils/log-helpers';

// Cells

import { memo } from "react";
import { Circle, ExitToApp, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

// colors
import { red, green, blue } from '@mui/material/colors';


const RepairOrderLogPopover = ({ anchor, closeRepairLogs, organizationCounter }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [repairdOrderHistory, setRepairOrderHistory] = useState([]);
    const [serviceHistory, setServiceHistory] = useState([]);
    const [partHistory, setPartHistory] = useState([]);
    const [repairPaymentHistory, setRepairPaymentHistory] = useState([]);
    const [repairOrderResolutionHistory, setRepairOrderResolution] = useState([]);
    const [repairLogs, setRepairLogs] = useState(null);

    const { t } = useTranslation();

    // const [anchorEl, setAnchorEl] = React.useState(null);
    let dayjs = require('dayjs')

    const handleClose = () => {
        closeRepairLogs();
    };

    const open = Boolean(anchor);
    const id = open ? 'repair-order-logs-popover' : undefined;


    useEffect(async () => {
        if (!open) return;

        try {
            const response = await axios.get(`/repair-orders/${organizationCounter}/logs`);
            if (response.status === 200) {

                let logs = response.data;
                for (let i = 0; i < logs.length; i++) {
                    // Check if eventDetails is already an object

                    if (logs[i].eventDetails && typeof logs[i].eventDetails === 'string') {
                        logs[i].eventDetails = JSON.parse(logs[i].eventDetails);
                    }
                    if (logs[i].fullState && typeof logs[i].eventDetails === 'string') {
                        logs[i].fullState = JSON.parse(logs[i].fullState);
                    }
                }
                setRepairLogs(response.data);
            }

        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        }
    }, [Boolean(anchor)]);


    const logStatusToIndex = (index) => {
        const keys = Object.keys(repairStatuses);
        // console.log(keys);
        // console.log(repairStatuses[keys[index - 1]]);
        return repairStatuses[keys[index - 1]].name;
    }



    return (
        <Dialog
            id={id}
            open={open}
            // anchorEl={anchor}
            onClose={handleClose}
            // anchorOrigin={{
            //     vertical: 'bottom',
            //     horizontal: 'right',
            // }}
            // transformOrigin={{
            //     vertical: 'top',
            //     horizontal: 'right',
            // }}
            // <IconButton size="small"><ArrowDropDownRoundedIcon/></IconButton>
            fullWidth
            maxWidth="md"
        >
            <DialogTitle>{t("Repair logs")}</DialogTitle>

            {repairLogs === null && <Typography variant='body1'>Loading...</Typography>}
            {repairLogs?.length === 0 ? <Typography variant='body1'>No logs found</Typography> :

                <TableContainer>
                    <Table stickyHeader size="small" aria-label="sticky table" sx={{ px: '15px' }} >
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Log</TableCell>
                                <TableCell align="right">Date</TableCell>
                                <TableCell align="right">User</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {repairLogs?.map((log, index) => {
                                if (log.eventType === 'create' && log.context === "RepairOrderOutsourceRequest") {
                                    return <CreateRepairOutsourceRequest key={index} log={log} />
                                }
                                if (log.eventType === 'create') {
                                    return <CreateRepairLogRow key={index} log={log} />
                                }
                                if (log.eventType === 'update') {

                                    return <UpdateRepairLogRow key={index} log={log} />
                                }
                                if (log.eventType === 'delete' && log.context === "RepairOrderOutsourceRequest") {
                                    return <DeleteRepairOutsourceRequest key={index} log={log} />
                                }
                                if (log.eventType === 'delete') {
                                    return <DeleteRepairLogRow key={index} log={log} />
                                }
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </Dialog>
    )
};

const UpdateRepairLogRow = ({ log }) => {
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen(!open);
    };
    let dayjs = require('dayjs')
    var relativeTime = require('dayjs/plugin/relativeTime')
    dayjs.extend(relativeTime);

    return (
        <>
            <TableRow>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={handleToggle}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <EventInfo log={log} icon={<Circle sx={{ color: green[500], fontSize: '14px' }} />} />
                </TableCell>
                <TableCell align="right">{dayjs(log.createdAt).fromNow(true)}</TableCell>
                <TableCell align='right'>{log.user.name}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Changes
                            </Typography>
                            {log.eventDetails?.changeset && (
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Field</TableCell>
                                            <TableCell>Old Value</TableCell>
                                            <TableCell>New Value</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.entries(log.eventDetails.changeset).map(([field, values], index) => {
                                            return <TableRow key={index}>
                                                <TableCell>{processField(field)}</TableCell>
                                                <TableCell><div>{processLogValue(field, values.oldValue)}</div></TableCell>
                                                <TableCell><div>{processLogValue(field, values.newValue)}</div></TableCell>
                                            </TableRow>
                                        })}
                                    </TableBody>
                                </Table>
                            )}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

const CreateRepairLogRow = ({ log }) => {
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen(!open);
    };
    let dayjs = require('dayjs')
    var relativeTime = require('dayjs/plugin/relativeTime')
    dayjs.extend(relativeTime);

    return (
        <>
            <TableRow>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={handleToggle}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <EventInfo log={log} icon={<Circle sx={{ color: blue[500], fontSize: '14px' }} />} />

                </TableCell>
                <TableCell align="right">{dayjs(log.createdAt).fromNow(true)}</TableCell>
                <TableCell align="right">{log.user.name}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Created data
                            </Typography>
                            {log.eventDetails?.createdData && (
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Field</TableCell>
                                            <TableCell>Value</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.entries(log.eventDetails.createdData).map(([field, value], index) => (
                                            <TableRow key={index}>
                                                <TableCell><div>{processField(field)}</div></TableCell>
                                                <TableCell><div>{processLogValue(field, value)}</div></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            )}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

const DeleteRepairLogRow = ({ log }) => {
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen(!open);
    };
    let dayjs = require('dayjs')
    var relativeTime = require('dayjs/plugin/relativeTime')
    dayjs.extend(relativeTime);

    return (
        <>
            <TableRow>
                <TableCell style={{ width: '1px' }}>
                    <IconButton aria-label="expand row" size="small" onClick={handleToggle}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <EventInfo log={log} icon={<Circle sx={{ color: red[500], fontSize: '14px' }} />} />

                </TableCell>
                <TableCell align="right">{dayjs(log.createdAt).fromNow(true)}</TableCell>
                <TableCell align="right">{log.user.name}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Deleted data
                            </Typography>
                            {log.eventDetails?.deletedData && (
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Field</TableCell>
                                            <TableCell>Value</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.entries(log.eventDetails.deletedData).map(([field, values], index) => {

                                            return <TableRow key={index}>
                                                <TableCell>{processField(field)}</TableCell>
                                                <TableCell><div>{processLogValue(field, values)}</div></TableCell>
                                            </TableRow>
                                        })}
                                    </TableBody>
                                </Table>
                            )}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

const CreateRepairOutsourceRequest = ({ log }) => {
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen(!open);
    };
    let dayjs = require('dayjs')
    var relativeTime = require('dayjs/plugin/relativeTime')
    dayjs.extend(relativeTime);

    return (
        <>
            <TableRow>
                <TableCell style={{ width: '1px' }}>
                    <IconButton aria-label="expand row" size="small" onClick={handleToggle}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <EventInfo log={log} icon={<Circle sx={{ color: blue[500], fontSize: '14px' }} />} />

                </TableCell>
                <TableCell align="right">{dayjs(log.createdAt).fromNow(true)}</TableCell>
                <TableCell align="right">{log.user.name}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Outsource request
                            </Typography>

                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Field</TableCell>
                                        <TableCell>Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.entries(log.eventDetails).map(([field, values], index) => {
                                        return <TableRow key={index}>
                                            <TableCell>{processField(field)}</TableCell>
                                            <TableCell><div>{processLogValue(field, values)}</div></TableCell>
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

const DeleteRepairOutsourceRequest = ({ log }) => {
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen(!open);
    };
    let dayjs = require('dayjs')
    var relativeTime = require('dayjs/plugin/relativeTime')
    dayjs.extend(relativeTime);

    return (
        <>
            <TableRow>
                <TableCell style={{ width: '1px' }}>
                    <IconButton aria-label="expand row" size="small" onClick={handleToggle}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <EventInfo log={log} icon={<Circle sx={{ color: red[500], fontSize: '14px' }} />} />

                </TableCell>
                <TableCell align="right">{dayjs(log.createdAt).fromNow(true)}</TableCell>
                <TableCell align="right">{log.user.name}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Outsource request
                            </Typography>

                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Field</TableCell>
                                        <TableCell>Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.entries(log.eventDetails).map(([field, values], index) => {
                                        return <TableRow key={index}>
                                            <TableCell>{processField(field)}</TableCell>
                                            <TableCell><div>{processLogValue(field, values)}</div></TableCell>
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

const EventInfo = ({ log, icon }) => {
    // let fieldsChanged = log.eventDetails?.changeset ? Object.keys(log.eventDetails.changeset) : 0;
    // console.log(fieldsChanged)
    return <Stack direction={'row'} alignItems={'center'} spacing={1}>
        {icon}
        <Typography>
            {log.eventType} {log.context} {log.eventDetails?.partnerService ? "Partner" : null}
            {/* {fieldsChanged.length > 0 fieldsChanged.map((field, index) => {
                return <span key={index}> {processField(field)} </span>
            })} */}
        </Typography>
    </Stack>
};



export default memo(RepairOrderLogPopover);
