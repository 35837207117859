
import { Box, Button, Checkbox, FormControlLabel, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { dataTableActions } from "../../store/data-table";
import { getAcceptedAndRefundedServices, processService, servicesCalculation } from "../../utils/service-helpers"
import { formatCurrency } from "../../utils/currency";
import { useEffect, useState } from "react";
import axios from "axios-config";

import { useQuery } from "react-query";

const DueFromServices = ({ onClick }) => {
    const { t } = useTranslation();

    const currency = useSelector((state) => state.auth.currency);

    const [unpaidOrders, setUnpaidOrders] = useState([]);
    const [totalToBePaid, setTotalToBePaid] = useState(0);
    const serverDown = useSelector((state) => state.app.serverDown);

    const partnersFilter = useSelector(state => state.dataTable.partnersFilter)

    const unpaidCheck = useSelector(state => state.dataTable.unpaidCheck)
    const dispatch = useDispatch();


    // useEffect(() => {
    //     let totalToBePaid = 0;
    //     let orderIds = [];

    //     if (repairOrders && repairOrders.length > 0) {
    //         let repairOrdersFiltered = getRepairOrders(partnersFilter, repairOrders)

    //         totalToBePaid = repairOrdersFiltered.reduce((totalAmountUnpaid, order) => {
    //             if (order.services === null || !order.services?.length) {
    //                 return totalAmountUnpaid;
    //             }
    //             const acceptedAndRefundedServices = getAcceptedAndRefundedServices(order.services);
    //             const acceptedAndRefundedServicesProcessed = acceptedAndRefundedServices.reduce((acc, service) => {
    //                 const processed = processService(service)
    //                 return acc += processed.unitPrice * processed.quantity - processed.discount;
    //             }, 0)

    //             // let servicesSum = 0;
    //             let payments = 0;

    //             if (order.services) {
    //                 const hasResolution = order.resolutionTypeId !== null;
    //                 if (order.repairPayments) {
    //                     payments = order.repairPayments.reduce((total, payment) => parseFloat(total) + parseFloat(payment.amount), 0);
    //                 }
    //                 const isDelivered = order.deliveredAt !== null;
    //                 if (isDelivered) {
    //                     if (payments != acceptedAndRefundedServicesProcessed) {
    //                         orderIds.push(order.id);
    //                         return totalAmountUnpaid + parseFloat(acceptedAndRefundedServicesProcessed) - parseFloat(payments);
    //                     }
    //                 }
    //             }
    //             return totalAmountUnpaid
    //         }, 0);
    //     }

    // const fetchUnpaidTotal = async () => {
    //     try {
    //         const response = await axios.get('/repair-orders/calculate-unpaid-from-customers');
    //         if (response.status === 200) {
    //             dispatch(dataTableActions.setUnpaidIds(response.data.repairOrders.map(order => order.id)));
    //             setUnpaidOrders(response.data.repairOrders);
    //         }
    //     }
    //     catch (e) {
    //         console.log(e);
    //     }
    // }

    const fetchUnpaidTotal = async () => {
        try {
            const response = await axios.get('/repair-orders/calculate-unpaid-from-customers');
            if (response.status === 200) {
                return response.data; // Return the data
            }
        } catch (e) {
            console.log(e);
            throw e; // Rethrow the error to handle it in useQuery
        }
    }

    const updateUnpaidTotal = (repairOrdersToCalculate) => {
        let sumTotal = 0;
        if (repairOrdersToCalculate && repairOrdersToCalculate.length > 0) {
            let repairOrdersFiltered = getRepairOrders(partnersFilter, repairOrdersToCalculate)
            sumTotal = repairOrdersFiltered.reduce((sumTotal, order) => {
                const servicesTotal = parseFloat(order.serviceTotal);
                const refundsTotal = parseFloat(order.refundsTotal);
                const paymentsTotal = parseFloat(order.paymentsTotal);

                return sumTotal + (servicesTotal + refundsTotal - paymentsTotal);
            }, 0);
        }
        setTotalToBePaid(sumTotal);
    }
    useEffect(() => {
        updateUnpaidTotal(unpaidOrders)
    }, [unpaidOrders, partnersFilter])

    const { data, isLoading, isError } = useQuery(
        ['unpaidFromCustomers', unpaidCheck, partnersFilter],
        fetchUnpaidTotal,
        {
            refetchInterval: 1000 * 10, // Refetch every 10 seconds
            refetchOnWindowFocus: 'always',
            enabled: !serverDown ,
            onSuccess: (data) => {
                dispatch(dataTableActions.setUnpaidIds(data.repairOrders.map(order => order.id)));
                setUnpaidOrders(data.repairOrders);
            }
        }
    );
    // useEffect(() => {

    //     setTotalToBePaid(totalToBePaid);

    //     dispatch(dataTableActions.setUnpaidIds(orderIds));

    // }, [repairOrders, partnersFilter])
    const handleUnpaidCheck = () => {
        dispatch(dataTableActions.toggleDueFromServices());
    }

    return <Stack alignItems={'center'} pl={'5px'}>
        {/* <Button variant={'outlined'} onClick={onClick}>
            Total unpaid ${totalToBePaid}npm
        </Button> */}
        <FormControlLabel control={<Checkbox checked={unpaidCheck} onChange={handleUnpaidCheck} />} label={`${t("Unpaid services")} ${formatCurrency((totalToBePaid), currency)}`} />

    </Stack>
}

export default DueFromServices;

function getRepairOrders(partnersFilter, repairOrders) {
    return partnersFilter.length == 0 ? repairOrders : repairOrders.filter(order => {
        if (order.outsourcedFrom) {
            const match = partnersFilter.find(element => element.id == order.outsourcedFrom.organization.id);
            // console.log(match?.name);
            if (match) {
                return true;
            }
        }
        if (order.outsourcedTo) {
            const match = partnersFilter.find(element => element.id == order.outsourcedTo.organization.id);
            if (match) {
                return true;
            }
        }
        if (!order.outsourcedFrom && !order.outsourcedTo) {
            const ourOrgMatch = partnersFilter.find(element => element.id == order.organizationId);
            if (ourOrgMatch) {
                return true;
            }
        }
    });
}
