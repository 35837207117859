export const servicesCalculation = (services) => {

    if (!services) {
        return { totalAccepted: 0, totalUnaccepted: 0, totalUnanswered: 0, totalProposed: 0, totalRefunded: 0 }
    }

    const totalAccepted = services.reduce((total, service) => {
        const processed = processService(service);
        if (processed.isAccepted) {
            return parseFloat(total) + processed.unitPrice * processed.quantity - processed.discount;
        }
        return total;
    }, 0);
    const totalUnaccepted = services.reduce((total, service) => {
        const processed = processService(service);
        if (service.isAccepted === false) {
            return parseFloat(total) + processed.unitPrice * processed.quantity - processed.discount;
        }
        return total;
    }, 0);
    const totalUnanswered = services.reduce((total, service) => {
        const processed = processService(service);
        if (service.isAccepted === null && !service.refundedServiceId) {
            return parseFloat(total) + processed.unitPrice * processed.quantity - processed.discount;
        }
        return total;
    }, 0);

    const totalProposed = services.reduce((total, service) => {
        const processed = processService(service);
        if (!service.refundedServiceId) {
            return parseFloat(total) + processed.unitPrice * processed.quantity - processed.discount;
        }
        return total;
    }, 0);

    const totalRefunded = services.reduce((total, service) => {
        const processed = processService(service);
        if (service.refundedServiceId) {
            return parseFloat(total) + processed.unitPrice * processed.quantity - processed.discount;
        }
        return 0;
    }, 0);

    return { totalAccepted: totalAccepted, totalUnaccepted: totalUnaccepted, totalUnanswered: totalUnanswered, totalProposed: totalProposed, totalRefunded: totalRefunded }
}

export const processService = (service) => {
    const processedService = { ...service };
    processedService.unitPrice = processedService.unitPrice === null ? 0 : parseFloat(processedService.unitPrice);
    processedService.quantity = processedService.quantity === null ? 0 : parseInt(processedService.quantity);
    processedService.discount = processedService.discount === null ? 0 : parseFloat(processedService.discount);
    return processedService;
}


export const getAcceptedAndRefundedServices = (services) =>
    services.filter(service => service.isAccepted || service.refundedServiceId);


export const getDeclinedAndProposedServices = (services) =>
    services.filter(service => (!service.isAccepted || service.isAccepted === null) && !service.refundedServiceId);

