import React from "react";
import { useRef, useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Button,
    Paper,
    Grid,
    Divider,
    InputAdornment,
    OutlinedInput,
    TableCell,
    TableRow,
    Stack,
    Box
} from "@mui/material";
import { useReactToPrint } from "react-to-print";
import { useSelector } from 'react-redux';
import "./Print.css"


import axios from "../../axios-config";

//ICONS
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import { useTranslation } from "react-i18next";
import { formatCurrency, getCurrencySymbol } from "../../utils/currency";

const DeviceDepositA4SplitDemo = React.forwardRef(({ settings, preview, logo, organization, repairOrder, repairPayments }, ref) => {
    const { t } = useTranslation();

    const currency = useSelector((state) => state.auth.currency);
    let dayjs = require('dayjs')
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });


    const getStyles = () => {
        return `@page { margin: 0px 0px 0px 0px !important; }
        @page {
        size: 80mm 25cm;
        body-width: 80mm!important;
      }
      `;
    };


    const Heading = (props) => {
        const { text, ...other } = props;
        if (!text) return null;
        return <Typography variant="h4" sx={{
            lineHeight: "1.5",
            mt: 2,
        }}
            {...other}
        >
            {text}
        </Typography >
    }
    const Paragraph = (props) => {
        const { text, ...other } = props;
        if (!text) return null;
        return <Typography {...other}> {text}</Typography>
    }

    const InputStyle = ({ data, icon, maxLines = 1 }) => {
        return (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "black",
                    // fontWeight: "500",
                    border: "1px solid black",
                    paddingY: "4px",
                    paddingX: '10px',
                    borderRadius: "4px",
                    width: '100%',
                    alignSelf: 'stretch'
                }}
            >
                {icon}
                <Divider
                    orientation="vertical"
                    sx={{
                        borderColor: "#050505",
                        borderWidth: "0.6px",
                        height: '10px',
                    }}
                />
                <Typography fontSize={'3.3mm'} fontWeight={'600'} sx={{
                    ml: 2,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: maxLines,
                    WebkitBoxOrient: 'vertical',
                }}>
                    {data}
                </Typography>
            </Box >
        );
    };
    if (!settings) {
        return "There are no settings"
    }

    const handleOrganizationData = () => {

        const orgNameEnabled = settings.organizationNameEnabled;
        const orgAddressEnabled = settings.organizationAddressEnabled;

        if (!orgNameEnabled && !orgAddressEnabled) return null;
        const address = orgAddressEnabled ? ` ${organization?.address} ${organization?.city} ${organization?.postalCode} ${organization?.state}` : "";
        let orgName = orgNameEnabled && organization && organization.name ? organization.name : "";
        if (orgName.length > 0 && address.length > 0) {
            orgName = `${orgName} , `;
        }

        return <Stack direction={'row'} alignItems={'start'} spacing={1} mb={1}>
            <LocationOnOutlinedIcon />
            <Typography>{orgName} {address}</Typography>
        </Stack>
    }
    let totalDeposit = 0;
    if (repairPayments) {
        totalDeposit = repairPayments.reduce((total, current) => {
            if (current.isDeposit) {
                return total + current.amount;
            }
        }, 0);
    }

    const CardPrintContent = () => {
        return <>
            <Box height={'125mm'}>
                <Box sx={{ mb: '15px', display: "flex", justifyContent: "space-between" }}>
                    <Box flexGrow={5}>
                        <Stack spacing={0} >
                            < Box
                                sx={{
                                    margin: '0',
                                    display: "flex",
                                    justifyContent: 'start',
                                    borderBottom: 'none',
                                    maxHeight: '70px',
                                    minHeight: '30px',
                                    maxWidth: '200px',
                                    mb: '10px',
                                }}
                            >
                                {settings && settings.organizationLogoEnabled && (logo) ?
                                    <Box p={'10px'}>
                                        <img className="logo" src={`${process.env.REACT_APP_IMAGES_URL}${logo.fileName}`} alt="logo" />
                                    </Box>
                                    : null}
                            </Box>
                            <Stack direction={'row'} justifyContent={'space-between'}>
                                <Box maxWidth={'300px'}>
                                    {handleOrganizationData()}
                                    <Stack direction={'row'} alignItems={'center'} spacing={1} mb={1}>
                                        {settings.dateEnabled && repairOrder.createdAt ?
                                            <>
                                                <AccessTimeOutlinedIcon />
                                                <Typography>
                                                    {dayjs(repairOrder.createdAt).format('DD-MM-YYYY HH:mm')}
                                                </Typography>
                                            </>
                                            : null}
                                    </Stack>
                                    <Box width={'250px'}>
                                        <Typography
                                            variant="h4"

                                            sx={{
                                                lineHeight: "1.6",
                                                fontWeight: "500",
                                                textAlign: "center",
                                                mt: 2,
                                                mr: 1,
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                display: '-webkit-box',
                                                WebkitLineClamp: '2',
                                                WebkitBoxOrient: 'vertical',
                                            }}
                                        >
                                            {repairOrder?.brand} {repairOrder?.model}
                                        </Typography>
                                    </Box>
                                </Box>

                            </Stack>
                        </Stack>
                    </Box>
                    <Box maxWidth={'120mm'}>
                        <Stack direction={'row'} justifyContent={'end'} spacing={2}>
                            {settings.title && settings.title.isEnabled && settings.title.label.length > 0 ? <>
                                < Typography
                                    variant="h4"
                                    gutterBottom
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <HandymanOutlinedIcon sx={{ marginRight: "8px" }} />
                                    {settings?.title?.label}
                                </Typography>
                            </> : null
                            }
                            <Typography
                                variant="h4"
                                component="div"
                                sx={{ textAlign: "center", fontWeight: "bold" }}
                            >
                                {repairOrder.organizationId}#{repairOrder.organizationCounter}
                            </Typography>
                        </Stack>
                        <Stack sx={{ mt: 1, mb: 2 }} direction={'column'} spacing={1} >
                            <Stack direction="row" spacing={1} alignItems={'center'} justifyContent={'start'}>
                                {settings.customerNameEnabled &&
                                    repairOrder.customer && repairOrder.customer.name ?
                                    < InputStyle icon={<PersonOutlinedIcon sx={{ marginRight: "10px", marginLeft: "8px" }} />} data={repairOrder?.customer?.name} />
                                    : null}
                                {settings.customerPhoneEnabled && repairOrder.customer && repairOrder.customer.phone ?
                                    <InputStyle icon={<PhoneInTalkOutlinedIcon sx={{ marginRight: "10px", marginLeft: "8px" }} />} data={repairOrder?.customer?.phone} />
                                    : null}
                            </Stack>
                            <Stack direction="row" spacing={1} alignItems={'center'} justifyContent={'stretch'} >
                                {settings.malfunctionEnabled && repairOrder.malfunction ?
                                    <InputStyle icon={<HandymanOutlinedIcon sx={{ marginRight: "10px", marginLeft: "8px" }} />} data={repairOrder.malfunction} maxLines={3} />
                                    : null}
                                {settings.repairOrderNoteEnabled && repairOrder.note ?
                                    <InputStyle icon={<CommentOutlinedIcon sx={{ marginRight: "10px", marginLeft: "8px", }} />} data={repairOrder.note} />
                                    : null}
                            </Stack>

                            {repairOrder.password ?
                                <InputStyle icon={<LockOutlinedIcon sx={{ marginRight: "10px", marginLeft: "8px" }} />} data={repairOrder.password} />
                                : null}
                            <Stack direction="row" spacing={2} pt={'10px'} alignItems={'center'} justifyContent={'start'} width={'100%'}>
                                {settings.estimatedServicesEnabled && repairOrder.estimatedServices > 0 ?
                                    <Box sx={{ border: '1px solid black', borderRadius: '5px', px: '10px' }}>
                                        <Typography fontSize={'3.3mm'}>{t('Estimated')}</Typography>
                                        <Typography fontSize={'3.3mm'} fontWeight={'600'}>{formatCurrency(repairOrder.estimatedServices, currency)}</Typography>
                                    </Box>
                                    : null}
                                {settings.repairPaymentsDepositsEnabled && totalDeposit > 0 ?
                                    <Box sx={{ border: '1px solid black', borderRadius: '5px', px: '10px' }}>
                                        <Typography fontSize={'3.3mm'}>{t('Deposit')}</Typography>
                                        <Typography fontSize={'3.3mm'} fontWeight={'600'}>{formatCurrency(totalDeposit, currency)}</Typography>
                                    </Box>
                                    : null}
                            </Stack>
                        </Stack>
                    </Box>
                </Box>

                {
                    settings && settings.note ?
                        <>
                            <Heading text={"Note"} />
                            <Paragraph text={settings.note} />
                        </>
                        : null
                }
                {settings.firstNoteEnabled && settings.firstNote.length > 0 ?
                    < Typography mb={2} sx={{ border: '1px solid black', padding: '10px', wordWrap: 'break-word' }} fontSize={12}>
                        {settings.firstNote}
                    </Typography>
                    : null
                }
                {settings.secondNoteEnabled && settings.secondNote.length > 0 ?
                    < Typography fontSize={10} sx={{ wordWrap: 'break-word' }}>
                        {settings.secondNote}
                    </Typography>
                    : null
                }
            </Box>

            {settings.customerSignatureEnabled && settings.customerSignatureLabel.length > 0 ?
                <Heading text={settings.customerSignatureLabel} marginBottom={0} />
                : null
            }

        </>
    }

    if (!settings || !organization || !repairOrder) {
        return <Box display={'flex'} justifyContent={'center'}>Not enough data</Box>
    }
    return (
        <>
            <Card sx={{ width: "210mm", height: '293mm', marginX: 'auto' }} ref={ref} elevation={2} className={'paper'}>
                <CardContent display={'flex'} flex={'1 1 auto'}>
                    <Box height={'138mm'}>
                        <CardPrintContent />
                    </Box>
                    <Box borderBottom={'2px dashed'} width={'100%'} my={'20px'} />
                    <CardPrintContent />
                </CardContent>
            </Card>
        </>
    );
})

export default React.memo(DeviceDepositA4SplitDemo);
