
import * as React from 'react';
import { useState } from "react";
import { useSelector } from "react-redux";
import {  Navigate, useLocation, useNavigate, Outlet } from "react-router-dom";
import { useTheme } from '@emotion/react';

import "./AuthPage.css";
import AuthCard from "./AuthCard";

const AuthPage = () => {
  const [register, setRegister] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const navigate = useNavigate();

  const toggleRegister = () => {
    setRegister(true);
  };

  const toggleLogin = () => {
    setRegister(false);
  };

  const handleLoginError = (errorMessage) => {
    setLoginError({ errorMessage: errorMessage });
  };


  const theme = useTheme();

  document.body.style.backgroundColor = theme.palette.lightBlue.main;

  const PublicRoute = ({ children }) => {
    const location = useLocation();
    const auth = useSelector(state => state.auth.isAuth);
    return !auth ? children
      : <Navigate to="/" replace state={{ from: location }} />;
  }

  return (
    <PublicRoute>
      <AuthCard >
        <Outlet />
      </AuthCard>
    </PublicRoute >
  );
};
export default AuthPage;
