// import * as React from "react"
import { useEffect, useState } from "react";
import { Box, Button, Checkbox, FormControlLabel, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { dataTableActions } from "../../store/data-table";


import { getAcceptedAndRefundedServices, processService, servicesCalculation } from "../../utils/service-helpers"
import { formatCurrency } from "../../utils/currency";
import axios from "axios-config";

import { useQuery } from "react-query";


const DueToPartner = ({ onClick }) => {
    const { t } = useTranslation();

    const currency = useSelector((state) => state.auth.currency);
    const partnersFilter = useSelector(state => state.dataTable.partnersFilter)
    const unpaidCheck = useSelector(state => state.dataTable.dueToPartnerCheck)
    const serverDown = useSelector((state) => state.app.serverDown);
    const dispatch = useDispatch();

    const [servicesTotal, setServicesTotal] = useState(0);
    const [paymentsTotal, setPaymentsTotal] = useState(0);


    // React.useEffect(() => {
    //     let totalToBePaid = 0;
    //     let orderIds = [];

    //     if (repairOrders && repairOrders.length > 0) {
    //         let repairOrdersFiltered = getRepairOrders(partnersFilter, repairOrders)
    //         repairOrdersFiltered = repairOrdersFiltered.filter(order => order.outsourcedToId !== null);


    //         totalToBePaid = repairOrdersFiltered.reduce((totalAmountUnpaid, order) => {
    //             if (order.outsourcedTo === null || !order.outsourcedTo?.services) {
    //                 return totalAmountUnpaid;
    //             }

    //             const acceptedAndRefundedServices = getAcceptedAndRefundedServices(order.outsourcedTo?.services);
    //             const acceptedAndRefundedServicesProcessed = acceptedAndRefundedServices.reduce((acc, service) => {
    //                 const processed = processService(service)
    //                 return acc += processed.unitPrice * processed.quantity - processed.discount;
    //             }, 0)

    //             let servicesSum = 0;
    //             let payments = 0;
    //             let invoice = null;
    //             if (order.outsourcedTo?.services) {
    //                 servicesSum = servicesCalculation(order.outsourcedTo.services).totalAccepted;
    //                 const hasResolution = order.outsourcedTo.resolutionTypeId !== null;
    //                 if (order.outsourcedTo?.repairPayments) {
    //                     payments = order.outsourcedTo.repairPayments.reduce((total, payment) => parseFloat(total) + parseFloat(payment.amount), 0);
    //                 }
    //                 const isDelivered = order.outsourcedTo.deliveredAt !== null;
    //                 if (isDelivered) {
    //                     if (payments != acceptedAndRefundedServicesProcessed) {
    //                         orderIds.push(order.id);
    //                         return totalAmountUnpaid + parseFloat(acceptedAndRefundedServicesProcessed) - parseFloat(payments);
    //                     }
    //                 }
    //             }
    //             return totalAmountUnpaid
    //         }, 0);
    //     }
    //     setTotalToBePaid(totalToBePaid);
    //     dispatch(dataTableActions.setDueToPartnerIds(orderIds));
    // }, [repairOrders, partnersFilter])
    // const handleUnpaidCheck = () => {
    //     dispatch(dataTableActions.toggleDueToPartner());
    // }

    // const fetchUnpaidTotal = async () => {
    //     try {
    //         const response = await axios.get('/repair-orders/calculate-unpaid-to-partners');
    //         if (response.status === 200) {
    //             console.log(response.data);
    //             // setRepairsFoundUnpaid(response.data.repairOrders);
    //             setServicesTotal(response.data.servicesTotal);
    //             setPaymentsTotal(response.data.paymentsTotal);
    //             // dispatch(dataTableActions.setUnpaidIds(orderIds));
    //             // filtered = filtered.filter(order => dueTopartnerIds.find(id => id === order.id) !== undefined)
    //             dispatch(dataTableActions.setDueToPartnerIds(response.data.repairOrders.map(order => order.id)));


    //         }
    //     }
    //     catch (e) {
    //         console.log(e);
    //     }
    // }

    const fetchUnpaidTotal = async () => {
        try {
            const response = await axios.get('/repair-orders/calculate-unpaid-to-partners');
            if (response.status === 200) {
                return response.data; // Return the data
            }
        } catch (e) {
            console.log(e);
            throw e; // Rethrow the error to handle it in useQuery
        }
    }

    const { data, isLoading, isError } = useQuery(
        ['unpaidToPartners', unpaidCheck, partnersFilter],
        fetchUnpaidTotal,
        {
            refetchInterval: 1000 * 10, // Refetch every 10 seconds
            refetchOnWindowFocus: "always",
            enabled: !serverDown,
            onSuccess: (data) => {
                setServicesTotal(data.servicesTotal);
                setPaymentsTotal(data.paymentsTotal);
                dispatch(dataTableActions.setDueToPartnerIds(data.repairOrders.map(order => order.id)));
            }
        }
    );

    const handleUnpaidCheck = () => {
        dispatch(dataTableActions.toggleDueToPartner());
    }

    useEffect(() => {
        fetchUnpaidTotal();
    }, [unpaidCheck])

    
    return <Stack alignItems={'center'}>
        {/* <Button variant={'outlined'} onClick={onClick}>
            Total unpaid ${totalToBePaid}npm
        </Button> */}
        <FormControlLabel control={<Checkbox checked={unpaidCheck} onChange={handleUnpaidCheck} />} label={`${t("Due to partners")} ${formatCurrency((servicesTotal - paymentsTotal), currency)}`} />

    </Stack>
}

export default DueToPartner;

function getRepairOrders(partnersFilter, repairOrders) {
    return partnersFilter.length == 0 ? repairOrders : repairOrders.filter(order => {
        if (order.outsourcedFrom) {
            const match = partnersFilter.find(element => element.id == order.outsourcedFrom.organization.id);
            // console.log(match?.name);
            if (match) {
                return true;
            }
        }
        if (order.outsourcedTo) {
            const match = partnersFilter.find(element => element.id == order.outsourcedTo.organization.id);
            if (match) {
                return true;
            }
        }
        if (!order.outsourcedFrom && !order.outsourcedTo) {
            const ourOrgMatch = partnersFilter.find(element => element.id == order.organizationId);
            if (ourOrgMatch) {
                return true;
            }
        }
    });
}
