import { TextField, Grid, Stack, Box, Divider, InputAdornment } from "@mui/material";

import BrandsSearch from "./BrandsSearch";
import CustomersSearch from "./CustomersSearch";
import CategorySearch from "./CategorySearch";

import { useSelector, useDispatch } from 'react-redux';
import { newEntryModalActions } from '../../store/new-entry-modal';
import { useTranslation } from "react-i18next";
import PasswordTextField from "./PasswordTextField";

const Step1 = (props) => {

  const { t } = useTranslation();
  const currency = useSelector((state) => state.auth.currency);

  const modalState = useSelector(state => state.newEntryModal);

  const dispatch = useDispatch();
  const updateModel = (payload) => {
    dispatch(newEntryModalActions.updateModel(payload))
  }
  const updateMalfunction = (payload) => {
    dispatch(newEntryModalActions.updateMalfunction(payload))
  }
  const updatePassword = (payload) => {
    dispatch(newEntryModalActions.updatePassword(payload))
  }
  const updatePasswordIsPasswordPattern = (payload) => {
    dispatch(newEntryModalActions.updateIsPasswordPattern(payload))
  }

  const updateImei = (payload) => {
    dispatch(newEntryModalActions.updateImei(payload))
  }
  const updateNote = (payload) => {
    dispatch(newEntryModalActions.updateNote(payload))
  }
  const updateCategory = (payload) => {
    dispatch(newEntryModalActions.updateCategory(payload))
  }

  const updateClient = (payload) => {
    dispatch(newEntryModalActions.updateClient(payload))
  }

  const updateEstimatedServices = (payload) => {
    dispatch(newEntryModalActions.updateEstimatedServices(payload))

  }
  const updateRepairPaymentDeposit = (payload) => {
    dispatch(newEntryModalActions.updateRepairPaymentDeposit(payload))

  }

  return <Box id='step'>
    <Stack direction={'column'} spacing={2} pt={1}>
      <CategorySearch onCategoryUpdate={updateCategory} value={modalState.entryData.category} />
      <Stack direction={'row'} spacing={2}>
        <BrandsSearch
          fullWidth
          required
          token={props.token}
          updateState={props.updateState}
          brandState={props.brand}
        />

        <TextField
          fullWidth
          autoComplete={'off'}
          required
          label={t("Model")}
          id="model"
          variant="filled"
          size="small"
          value={modalState.entryData.model}
          onChange={(event) => updateModel(event.target.value)}
        />
      </Stack>
      <TextField
        fullWidth
        autoComplete={'off'}
        // error={"test"}
        // helperText="This field is required"
        required
        label={t("Malfunction")}
        id="malfunction"
        multiline
        rows={2}
        variant="filled"
        size="small"
        value={modalState.entryData.malfunction}
        onChange={(event) => updateMalfunction(event.target.value)
        }
      />
      <CustomersSearch
        required
        onOptionSelect={updateClient}
        state={modalState.entryData.customer}
      />
      <Stack direction={'row'} alignItems={'center'} spacing={2}>
        <TextField
          fullWidth
          autoComplete={'off'}
          // error={"test"}
          // helperText="This field is required"
          label={t("Estimated services")}
          id="estimatedServices"
          variant="filled"
          size="small"
          value={modalState.entryData.estimatedServices || ""}
          onChange={(event) => updateEstimatedServices(event.target.value)}
          placeholder={'0.00'}
          InputProps={{
            startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
          }}
        />
        <TextField
          fullWidth
          autoComplete={'off'}
          // error={"test"}
          // helperText="This field is required"
          label={t("Deposit")}
          id="repairPaymentDeposit"
          variant="filled"
          size="small"
          value={modalState.entryData.repairPaymentDeposit || ""}
          onChange={(event) => updateRepairPaymentDeposit(event.target.value)}
          placeholder={'0.00'}
          InputProps={{
            startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
          }}
        />
      </Stack>
      <Divider />

      <Stack direction={'row'} spacing={2}>
        <PasswordTextField
          value={modalState.entryData.password}
          setValue={updatePassword}
          isPasswordPattern={modalState.entryData.isPasswordPattern}
          setIsPasswordPattern={updatePasswordIsPasswordPattern} />

        {/* <TextField
          fullWidth
          autoComplete={'off'}
          label={t("Password")}
          id="password"
          variant="filled"
          size="small"
          value={modalState.entryData.password}
          onChange={(event) => updatePassword(event.target.value)}
        /> */}
        <TextField
          fullWidth
          autoComplete={'off'}
          label="IMEI"
          id="imei"
          variant="filled"
          size="small"
          value={modalState.entryData.imei}
          onChange={(event) => updateImei(event.target.value)}
        />
      </Stack>
      <TextField
        id="note"
        autoComplete={'off'}
        label={t("Note")}
        multiline
        rows={2}
        variant="filled"
        value={modalState.entryData.note}
        onChange={(event) => updateNote(event.target.value)}
      />
    </Stack>
  </Box>
};


export default Step1;
