import * as React from 'react';
import {
    Box, List, ListItem, ListItemButton,
    ListItemIcon, ListItemText, Checkbox,
    IconButton, Typography, Stack, Divider,
    Button,
    ButtonGroup,
    circularProgressClasses
} from '@mui/material';
import { styled } from '@mui/system';



//Icons
import CommentIcon from '@mui/icons-material/Comment';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DoDisturbAltOutlinedIcon from '@mui/icons-material/DoDisturbAltOutlined';

const BaseItemList = (props) => {
    const { mainStack, footerStack, itemIcon, backgroundColor, onDeleteButtonClick, ...other } = props;
    return (<li>
        <Box
            backgroundColor={backgroundColor ? backgroundColor : 'lightBlue.main'}
            mb={'10px'}
            py={2}
            px={1.5}
            borderRadius={'5px'}
            width={'100%'}
            {...other}
        >

            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                {itemIcon}
                {mainStack}
                {onDeleteButtonClick ?
                    <IconButton onClick={onDeleteButtonClick} aria-label="delete" size="small">
                        <DeleteIcon fontSize="inherit" />
                    </IconButton>
                    :
                    null
                }
            </Stack>
            <Stack pl={4} pr={4} direction={'row'} spacing={3} color={'darkGray'} fontSize={'0.75rem'} fontWeight={500} width={'100%'} alignItems={'center'}>
                {footerStack}
            </Stack>
        </Box>
    </li>
    )
}

export default BaseItemList;