import * as React from 'react';
import { Box, display } from '@mui/system';
import { Typography, Button, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import axios from "../../../axios-config";
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import Popover from '@mui/material/Popover';
import { useSnackbar } from 'notistack';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ResolutionDialog = ({ repairOrder, openDialog, handleDialogClose, onActionComplete, resolutionChip, resolutionIndex }) => {

    const { t } = useTranslation();

    const { enqueueSnackbar } = useSnackbar();

    const [note, setNote] = useState('');

    const handleNoteInput = (event) => {
        setNote(event.target.value);
    }
    const handleAddNewService = async () => {


        // Validation
        try {
            const data = { resolutionIndex: resolutionIndex, note: note };
            const response = await axios.put(`repair-orders/${repairOrder.id}/resolution`, data);

            if (response.status === 200 || response.status === 201) {
                enqueueSnackbar(`Resolution was set!`, { variant: "success" });
                handleDialogClose();
                onActionComplete(response.data);
                setNote('');
            }
        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Something went wrong!`, { variant: "error" });

        }
    }

    return (
        <Dialog open={openDialog} onClose={handleDialogClose} fullWidth maxWidth={'sm'}>
            <DialogTitle variant='h4'>{t('Set resolution')}</DialogTitle>
            <DialogContent>
                <Stack direction={'row'} alignItems={'center'}>
                    <DialogContentText mr={'10px'} display={'inline'}>
                        {t('Resolution chosen')}:
                    </DialogContentText>
                    {resolutionChip}
                </Stack>
                <Stack direction={'row'} spacing={2} pt={3}>
                    {/* <TextField
                        autoFocus
                        multiline
                        rows={2}
                        id="note"
                        label={t("Note")}
                        type='text'
                        fullWidth
                        variant="outlined"
                        value={note}
                        onChange={handleNoteInput}
                    /> */}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose}>{t('Cancel')}</Button>
                <Button onClick={handleAddNewService}>{t('Ok')}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ResolutionDialog;