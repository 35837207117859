import React, { useState } from "react";
import Notification from "../Notification"
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { useNavigate, matchPath, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ParetnerRequestNotification({ notification, handleClose }) {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const handleNotificationClick = () => {
        const isAlreadyOnPath = !!matchPath(location.pathname, '/organization');
        if (isAlreadyOnPath) {
            navigate(0);

        } else {
            navigate('/organization')
        }
        handleClose();
    };

    const title = t('notifications.partnerRequest.title', { notification: notification.performedBy });
    let body = '';
    if (notification.meta?.status === 'accepted') {
        body = t('notifications.partnerRequest.body.accepted', { performedByName: notification.performedBy?.organization?.name });
    }
    if (notification.meta?.status === 'rejected') {
        body = t('notifications.partnerRequest.body.rejected', { performedByName: notification.performedBy?.organization?.name });
    }
    if (!notification.meta) {
        body = t('notifications.partnerRequest.body.request', { performedByName: notification.performedBy?.organization?.name });
    }
    return (
        <Notification
            notification={notification}
            id={notification.id}
            isRead={notification.isRead}
            avatar={<Avatar sx={{ bgcolor: 'orange.main' }}><GroupOutlinedIcon color="black" /></Avatar>}
            title={title}
            body={body}
            handleNotificationClick={handleNotificationClick}

        />
    );
}

export default ParetnerRequestNotification;