import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, Stack, Switch, TextField, Typography } from "@mui/material";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { GridOnOutlined, TableRowsRounded, WarningRounded } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { set } from "lodash";
import axios from "axios-config";
import { useSnackbar } from "notistack";

const EditLocationDialog = ({ selectedLocation, setSelectedLocation, locations, setLocations }) => {
    const { t } = useTranslation();

    const [binStructure, setBinStructure] = useState(false);
    const [binRowsCount, setBinRowsCount] = useState(0);
    const [binColumnsCount, setBinColumnsCount] = useState(0);
    const [locationName, setLocationName] = useState('');
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setBinStructure(Boolean(selectedLocation?.isCellHolder));
        setBinRowsCount(selectedLocation?.rowCount);
        setBinColumnsCount(selectedLocation?.columnCount);
        setLocationName(selectedLocation?.name);
    }, [selectedLocation]);

    if (!selectedLocation) {
        return null;
    }

    const hasChildern = locations.filter(location => location.parentId === selectedLocation.id).length > 0;
    const handleBinChange = (e) => {
        setBinStructure(e.target.checked);
    }

    const handleNameChange = (e) => {
        setLocationName(e.target.value);
    }

    const handleNameSave = async () => {
        try {
            const response = await axios.patch(`/inventory/location/${selectedLocation.id}`, {
                name: locationName
            });

            const locationsCopy = [...locations];
            const locationIndex = locationsCopy.findIndex(location => location.id === selectedLocation.id);
            locationsCopy[locationIndex].name = locationName;
            setLocations(locationsCopy);
            if (response.status === 200) {
            }
        }
        catch (error) {
            console.log(error);
            // Show the error message
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    }

    return <Dialog open={!!selectedLocation} onClose={() => setSelectedLocation(null)} fullWidth maxWidth={'xs'}>
        <DialogTitle variant='h4'>
            {t("Edit location")}
        </DialogTitle>
        <DialogContent>
            <Stack spacing={2}>
                <TextField label="Name" variant="filled" value={locationName} onChange={handleNameChange} onBlur={handleNameSave} />
                {/* <Stack direction={'row'} justifyContent={'space-between'}>
                    <Stack>
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <GridOnOutlined />
                            <Stack>
                                <Typography>Enable Bin Structure for this location</Typography>
                                <Typography variant='caption' color={"gray"}>Rows and Columns (e.g., R1-C1, R1-C6)</Typography>
                            </Stack>
                        </Stack>

                    </Stack>
                    <Switch disabled={hasChildern} checked={binStructure && !hasChildern} onChange={handleBinChange} size="small" />
                </Stack> */}
                {hasChildern && false && <Alert severity="info" sx={{ border: '1px solid rgba(0,0,0,0.25)', mb: '15px', alignItems: "center" }} icon={<WarningRounded />}>
                    <Stack direction="row" alignItems="center" justifyContent={'space-between'} sx={{ width: '100%' }}>
                        <Typography variant={'body'}>
                            {t("Remove child locations before enabling bin structure.")}
                        </Typography>
                        {/* <Button color="success" variant='outlined' size="small">
                                        {t("Make it your default catalog")}
                                    </Button> */}
                    </Stack>
                </Alert>
                }
                {binStructure && <Stack direction={'column'} spacing={2}>
                    <TextField
                        label={'Rows count'}
                        value={binRowsCount}
                        onChange={(e) => setBinRowsCount(e.target.value)}
                        variant='filled'
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    < TableRowsRounded />

                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        label={'Columns count'}
                        value={binColumnsCount}
                        onChange={(e) => setBinColumnsCount(e.target.value)}
                        variant='filled'
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    < TableRowsRounded sx={{ transform: "rotate(90deg)" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Stack>
                }
            </Stack>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setSelectedLocation(null)}>{t("Close")}</Button>
        </DialogActions>
    </Dialog >
}
EditLocationDialog.propTypes = {
    selectedLocation: PropTypes.object.isRequired,
};

export default EditLocationDialog;

