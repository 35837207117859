import * as React from 'react';
import { Box, display } from '@mui/system';
import {
    Typography, Button, IconButton, Tab, Tabs, InputAdornment,
} from '@mui/material';
import axios from "../../../axios-config";
import { memo } from 'react';

import { useSnackbar } from 'notistack';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';

import ReactiveTextField from '../../../components/ReactiveTextField/ReactiveTextField';
import { useSelector } from 'react-redux';

const EditPartDialog = ({ dialogOpen, handleDialogClose, part, onEdit }) => {
    const { enqueueSnackbar } = useSnackbar();
    const currency = useSelector((state) => state.auth.currency);

    const onBlur = async (key, newValue) => {
        const value = newValue;
        try {
            const response = await axios.patch(`repair-orders/parts/${part.id}/${key}`, { [key]: value })

            if (response.status !== 200) {
                throw response.status;
            }
            if (response.status === 200) {
                onEdit(response.data);
                enqueueSnackbar(`${key} updated succesfully!`, { variant: "success" });
            }
        } catch (err) {
            console.log(err)
            enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        }
    }


    return (
        <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth maxWidth={'xs'}>
            <DialogTitle variant='h4'>
                Parts Edit
            </DialogTitle>
            <DialogContent>
                <Stack direction={'row'} spacing={2} pt={3}>
                    <ReactiveTextField
                        id='name'
                        fullWidth
                        initialState={part.name}
                        label="Service name"
                        onBlurCallback={(newValue) => onBlur('name', newValue)}
                        multiline
                        minRows={2}
                        maxRows={2}
                    />
                </Stack>
                <Stack direction={'row'} spacing={2} pt={3}>
                    <ReactiveTextField
                        fullWidth
                        initialState={part.unitPrice}
                        type='number'
                        label="Unit price"
                        onBlurCallback={(newValue) => onBlur('unitPrice', newValue)}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                        }}
                    />
                    {/* <ReactiveTextField
                        initialState={part.quantity}
                        type='number'
                        label="Quantity"
                        onBlurCallback={(newValue) => onBlur('quantity', newValue)}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">x</InputAdornment>,
                        }}
                    /> */}

                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default memo(EditPartDialog);