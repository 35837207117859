import * as React from 'react';
import { useSnackbar } from 'notistack';
import axios from "../../axios-config";

import {
    Box, List, ListItem, ListItemButton,
    ListItemIcon, ListItemText, Checkbox,
    IconButton, Typography, Stack, Divider, Popover,
    Button, ButtonGroup, circularProgressClasses
} from '@mui/material';
import { styled } from '@mui/system';
import BaseItemList from '../../components/ItemList/BaseItemList';
import EditServiceDialog from './Dialogs/EditServiceDialog';

//Icons
import MemoryIcon from '@mui/icons-material/Memory';
import EditPartDialog from './Dialogs/EditPartDialog';
import { useSelector } from 'react-redux';
import { formatCurrency } from '../../utils/currency';
const PartItemList = ({ item, onServiceDelete, setParts }) => {
    const currency = useSelector((state) => state.auth.currency);

    let dayjs = require('dayjs')
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [mouseHover, setMouseHover] = React.useState(false);
    const [editDialog, setEditDialog] = React.useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleDelete = async (event) => {
        event.stopPropagation();
        try {
            const response = await axios.delete(`repair-orders/parts/${item.id}`);
            if (response.status !== 202) {
                throw response.status;
            }
            if (response.status === 202) {
                setParts(response.data);
                enqueueSnackbar(`Part deleted succesfully!`, { variant: "success" });

            }
        } catch (err) {
            console.log(err)
            enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        }
    }

    const handleCloseDialog = () => {
        setEditDialog(false);
    }



    const StyledButton = styled(Button, {
        shouldForwardProp: (prop) => prop !== 'positive',
    })(({ positive, theme }) => ({
        backgroundColor: 'white',
        whiteSpace: 'nowrap',
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 4px 12px',
        ...(positive && {
            color: theme.palette.green.main,
        }),
        '&:hover': {
            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 4px 12px',
            backgroundColor: 'white',
        }
    }));

    return (<>
        <BaseItemList
            sx={{
                border: 'solid 1px transparent',
                '&:hover': {
                    border: 'solid 1px',
                    borderColor: 'black',
                    cursor: 'pointer'
                }
            }}
            onMouseEnter={() => setMouseHover(true)}
            onMouseLeave={() => setMouseHover(false)}
            itemIcon={<MemoryIcon color='darkGrey' />}
            onClick={() => setEditDialog(true)}
            mainStack=
            {<>
                <Typography flexGrow={1}>{item.name}</Typography>
                <Typography variant='caption' color={'darkGray'} noWrap> ({formatCurrency(item.unitPrice, currency)} x{item.quantity})</Typography>
                <Typography variant='body' fontWeight={500} noWrap color={"red.main"}>{formatCurrency(item.unitPrice * item.quantity, currency)} </Typography>
            </>}
            footerStack={
                <>
                    {dayjs(item.updatedAt).format('DD.MMM.YYYY HH:mm')}
                </>}
            onDeleteButtonClick={handleDelete}
        />
        {/* <EditPaymentDialog payment={item} dialogOpen={editDialog} handleDialogClose={handleCloseDialog} onEdit={(payments) => setPayments(payments)} /> */}
        <EditPartDialog part={item} dialogOpen={editDialog} handleDialogClose={handleCloseDialog} onEdit={(parts) => setParts(parts)} />
    </>
    )
}

export default PartItemList;
