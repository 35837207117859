import { Stack, Tooltip, Typography } from "@mui/material";
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import { useTranslation } from "react-i18next";

const UpdatedAtDate = ({ updatedAt }) => {

    const { t } = useTranslation();

    let dayjs = require('dayjs')
    var relativeTime = require('dayjs/plugin/relativeTime')
    dayjs.extend(relativeTime);

    const getLastEditTime = () => {
        let date_now = new Date();

        let seconds = Math.floor(dayjs().diff(dayjs(updatedAt), 'seconds'));
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        hours = hours - (days * 24);
        minutes = minutes - (days * 24 * 60) - (hours * 60);
        seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);
        if (days > 0) {
            return (`${days} ${t('days')}`);

        } else if (days < 1 && hours > 0) {
            return (` ${hours} ${t('hours')}`);
        }
        else if (hours < 1 && minutes > 0) {
            return (`${minutes} min`);
        }
        else {
            return (`${seconds} seconds`);
        }
    }

    return <Tooltip enterDelay={20} title={`${t('Last update')}: ${dayjs(updatedAt).format('DD MMM. YYYY HH:mm')}`}>
        <Stack direction={'row'} gap={'5px'} border={'0px solid'} borderRadius={'5px'} borderColor={'darkGrey.main'} alignItems={'center'} py={'2px'} fontSize='0.75rem' color={'darkGrey.main'} > <UpdateOutlinedIcon sx={{ fontSize: '1rem' }} /><Typography fontSize={'0.75rem'} fontWeight={"600"}>{dayjs(updatedAt).fromNow(true)}</Typography></Stack>
    </Tooltip>
}

export default UpdatedAtDate