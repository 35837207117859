import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme, alpha } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { dataTableActions } from "store/data-table";
import EntryDetailsCell from './EntryDetailsCell';
import DeviceCell from './DeviceCell';
import MalfunctionCell from './MalfunctionCell';
import StatusCell from './StatusCell';
import PublicNoteCell from './PublicNoteCell';
import BudgetCell from './BudgetCell';
import { areEqual } from "react-window";


import { updateOrderStatus, updateOrderStatusWithResolution } from 'api/repair-orders';

const Row = memo(({ data, index, style, onClickRequestOrder }) => {
    const { t } = useTranslation();
 
    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();
    const items = data.repairOrdersFiltered;
    const repairOrder = items[index];
    const ableToSelect = data.ableToSelect;
    const selectedOrders = data.selectedOrders;
    const handlingRequests = data.handlingRequests;
    const handleSelectRow = data.handleSelectRow;
    const { minWidthOrderColumn, maxWidthOrderColumn, minWidthDeviceColumn,
        maxWidthDeviceColumn, minWidthMalfunctionColumn, maxWidthMalfunctionColumn,
        minWidthStatusColumn, maxWidthStatusColumn, minWidthLastCommentColumn, maxWidthLastCommentColumn } = data.rowStyle;

    const theme = useTheme();

    const handleRowClick = useCallback((index) => {
        if (ableToSelect) {
            handleSelectRow(items[index]);
            return;
        }
        if (items[index].isRequest) {
            enqueueSnackbar(t(`Requests can't be opened.`), { variant: "warning" });
            return;
        }
        navigate(`/editEntry/${items[index].id}`);
    }, [ableToSelect, handleSelectRow, items, navigate, enqueueSnackbar, t]);


    const statusClass = repairOrder.repairOrderStatus ? repairOrder.repairOrderStatus.name.toLowerCase().split(' ').map((word, index) => index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)).join('') : "not-clickable";


    let statusToColor = theme.palette.lightGrey.main;
    if (statusToColor !== "not-clickable") {
        const statusColor = theme.palette[statusClass];
        if (statusColor) {
            statusToColor = statusColor.main;
        }
    }





    return (
        <div style={style} className={"no-scrollbars"}>
            <Stack
                direction={'row'}
                className={` row clickable`}
                role="rowgroup"
                sx={{ width: '100%', border: '2.5px solid', borderColor: statusToColor }}
                onClick={() => handleRowClick(index)}
            // /editEntry/${rowData.organizationCounter}

            >

                <Box className="first table-content orderColumn" role="cell"
                    sx={{
                        minWidth: minWidthOrderColumn,
                        maxWidth: maxWidthOrderColumn,
                        backgroundColor: alpha(statusToColor, 0.1)
                    }}>
                    <EntryDetailsCell rowData={repairOrder} ableToSelect={ableToSelect} selectedOrders={selectedOrders} handleSelectRow={handleSelectRow} handlingRequests={handlingRequests} />
                </Box>
                <Box className="flex-row table-content deviceColumn" role="cell"
                    sx={{
                        minWidth: minWidthDeviceColumn,
                        maxWidth: maxWidthDeviceColumn,
                    }}
                >
                    <DeviceCell rowData={repairOrder} />
                </Box>
                <Box className="flex-row table-content malfunctionColumn" role="cell"
                    sx={{
                        minWidth: minWidthMalfunctionColumn,
                        maxWidth: maxWidthMalfunctionColumn,
                    }}
                >
                    <MalfunctionCell rowData={repairOrder} />
                </Box>
                {/* <div className="flex-row content" role="cell">
                    <PublicNoteCell rowData={items[index]} />
                </div> */}
                <Box className="flex-row table-content statusColumn" role="cell"
                    sx={{
                        minWidth: minWidthStatusColumn,
                        maxWidth: maxWidthStatusColumn,
                    }}
                >
                    <StatusCell  repairOrder={repairOrder} />
                </Box>
                <Box className="flex-row table-content lastCommentColumn" role="cell"
                    sx={{
                        minWidth: minWidthLastCommentColumn,
                        maxWidth: maxWidthLastCommentColumn,
                    }}
                >
                    <PublicNoteCell rowData={repairOrder} />
                </Box>
                <Box className="flex-row table-content servicesColumn" role="cell" >
                    <BudgetCell rowData={repairOrder} />
                </Box>
            </Stack>
        </div>
    );
}, areEqual);

export default Row;