import { createSlice } from '@reduxjs/toolkit'

const initialAuthState = {
    sideMenu: localStorage.getItem('sideMenu') !== null ? JSON.parse(localStorage.getItem('sideMenu')) : true
};

const generalSlice = createSlice({
    name: 'general',
    initialState: initialAuthState,
    reducers:
    {
        toggleSideMenu(state) {
            state.sideMenu = !state.sideMenu;
            localStorage.setItem('sideMenu', JSON.stringify(state.sideMenu));
        },
    }
})

export const generalActions = generalSlice.actions;
export default generalSlice.reducer;
