import { Button, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import axios from "axios-config";

import { useEffect, useState } from "react";
import AddNewTransferDialog from "./AddNewTransferDialog";
import { set } from "lodash";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

const TransferPage = ({ warehouse }) => {

    const [postings, setPostings] = useState(null);
    const [newPostingDialog, setNewPostingDialog] = useState(false);

    useEffect(() => {
        // Fetch inventory data from API
        fetchPostings();
    }, []);

    const fetchPostings = async () => {
        try {
            const response = await axios.get(`/inventory/warehouse/${warehouse.id}/postings`);
            if (response.status === 200) {
                const data = await response.data;
                setPostings(data);
            }
        } catch (error) {
            console.error('Error fetching inventory data:', error);
        }
    }

    const handleDialogOpen = () => {
        setNewPostingDialog(true);
    }

    return <>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography variant='body'>Easily manage new stock coming in</Typography>
            <Button variant='contained' onClick={handleDialogOpen} startIcon={<Add />} >{t("New Posting")}</Button>
        </Stack>
        <TableContainer component={Paper} sx={{ my: '15px' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {/* <TableCell>Ref. ID</TableCell> */}
                        <TableCell align="left">User</TableCell>
                        <TableCell align="left">Provider</TableCell>
                        <TableCell align="center">Created Date</TableCell>
                        <TableCell align="center">Expected Date</TableCell>

                        <TableCell align="left">Status</TableCell>
                        <TableCell align="right">Total</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {postings?.map((item) => (
                        <TransferTableRow key={item.id} item={item} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <AddNewTransferDialog open={newPostingDialog} onClose={() => setNewPostingDialog(false)} warehouse={warehouse} />
    </>
}


const TransferTableRow = ({ item }) => {

    const dayjs = require('dayjs')
    const navigate = useNavigate();


    const handleTransferClick = (item) => {
        // navigate to 
        navigate(`/inventory/transfer/${item.id}`)

    }
    return (
        <TableRow
            key={item.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
            onClick={() => handleTransferClick(item)}

        >
            {/* <TableCell component="th" scope="row">
                {item.refId}
            </TableCell> */}
            <TableCell align="left">{item.user?.name}</TableCell>
            <TableCell align="left">{item.supplier?.name}</TableCell>
            <TableCell align="center">
                {/* <Stack direction={'row'} spacing={2} alignContent={'flex-end'}>
                    <Stack>
                        <Typography variant='body'>Created at</Typography>
                        <Typography variant='body'>{item.createdAt}</Typography>
                    </Stack>
                    <Divider orientation="vertical" flexItem />
                    <Stack>
                        <Typography variant='body'>Expected at</Typography>
                        <Typography variant='body'>{item.expectedAt}</Typography>
                    </Stack>
                </Stack> */}
                <Typography variant='body'>{dayjs(item.createdAt).format('DD MMM. YYYY')}</Typography>
            </TableCell>
            <TableCell align="center">
                <Typography variant='body'>{item.expectedAt}</Typography>
            </TableCell>
            <TableCell align="left">{item.status}</TableCell>
            <TableCell align="right">{item.total}</TableCell>
        </TableRow>
    );
};

export default TransferPage;