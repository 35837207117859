import React, { useEffect, useState } from "react";
import { CardMedia, Grid, Typography, Stack, Box, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DeviceImg from "../../images/no_device.png";
import DeviceSpecs from "./DeviceSpecs";
import { Image } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { InfoOutlined } from "@mui/icons-material";
import axios from "../../axios-config";
import { Trans, useTranslation } from "react-i18next";

const NoDevice = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Stack
      alignItems="center"
      sx={{
        backgroundColor: theme.palette.lightBlue.main,
        flex: 1,
        paddingTop: "120px",
      }}
    >
      <CardMedia
        component="img"
        image={DeviceImg}
        alt="No Device"
        sx={{ width: "60%" }}
      />

      <Typography
        color="#BBBBBB"
        gutterBottom
        variant="h3"
        sx={{ fontWeight: "300" }}
      >
        {t("Select a device")}
      </Typography>
    </Stack>
  );
};

const DeviceInfo = ({ deviceData }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xl'));

  return (
    <Stack
      overflow={'auto'}
      p={'25px'}
      sx={{
        backgroundColor: theme.palette.lightBlue.main,
      }}
    >

      <Stack direction={'row'} alignItems={'flex-start'} flexWrap={isSmallScreen ? 'wrap' : 'nowrap'} gap={2} sx={{ paddingBottom: "20px", }}>
        <Box
          component="img"
          sx={{
            maxHeight: 200,
            minHeight: 100,
            maxWidth: 200,
            minWidth: 70,
            width: "100%",
            objectFit: "contain",
            // display: { xs: "none", sm: "block", md: "block", lg: "block" },
          }}
          alt="device"
          src={deviceData?.deviceImageLink ? `${process.env.REACT_APP_BASE_URL}${deviceData.deviceImageLink}` : DeviceImg}
        />


        <Stack justifyContent="center">
          {deviceData?.isSystem && !process.env.REACT_APP_COSTI_LOCK &&
            <Stack direction={'row'} alignItems={'center'} spacing={1} ml={'24px'}>
              <Tooltip title={
                <Trans i18nKey="deviceTooltip">
                  <span>Devices provided by <strong>dalvado</strong> are maintained by our team. The devices can be used by your organization but can't be edited.</span>
                </Trans>
              }>
                <InfoOutlined sx={{ color: theme.palette.black.main }} />
              </Tooltip>
              <Typography>
                <Trans i18nKey="deviceProvidedByDalvado">
                  Device provided by dalvado
                </Trans>
              </Typography>
            </Stack>
          }
          <Typography
            gutterBottom
            variant="h3"
            sx={{ fontWeight: 500, marginLeft: "24px" }}
          >
            {deviceData?.name}
          </Typography>
          {deviceData?.models && <>
            <Typography
              gutterBottom
              variant="h6"
              sx={{ fontWeight: 200, marginLeft: "24px" }}
            >
              <strong>Models:</strong> {deviceData?.models}
            </Typography>
          </>}
          <Typography
            gutterBottom
            variant="h6"
            sx={{ fontWeight: 200, marginLeft: "24px" }}
          >
            {deviceData?.description}
          </Typography>
        </Stack>
      </Stack>
      <DeviceSpecs

        // keyVal={val}
        deviceData={deviceData}
      />

    </Stack >
  );
};

const DeviceData = ({ deviceData }) => {
  //fetch device details for selected deviceData
  const [fullDeviceData, setFullDeviceData] = useState(deviceData);

  useEffect(() => {

    if (deviceData) {
      const fetchDeviceDetails = async () => {
        const response = await axios.get(`/device-catalogs/${deviceData.id}`);
        if (response.status === 200) {
          setFullDeviceData(response.data);
        }
      }
      fetchDeviceDetails();
    }
  }, [deviceData]);


  return deviceData ? <DeviceInfo deviceData={fullDeviceData} /> : <NoDevice />
}


export default DeviceData;
