import { Fragment } from "react";
import {
    Container, Card, CardContent, Paper, Typography,
    Box, Grid, Stack, Divider, List, ListItem, ListItemAvatar,
    ListItemButton, ListItemText, Button, Popover, ListItemIcon, Checkbox,
    Tab,
    Chip,
    Snackbar,
    Dialog,
    DialogActions,
    Alert,
    AlertTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@mui/material";
import { Pagination } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@mui/material';

import * as React from 'react';
import LoginForm from "../LoginPage/components/LoginForm";
import RegisterForm from "../LoginPage/components/RegisterForm"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Logo from "../../images/dalvado-logo.png";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { ThemeContext, useTheme } from '@emotion/react';
import InvoiceA4 from "../../components/Printing/InvoiceA4";
import ToolBarHelper from "../../components/ToolBarHelper/ToolBarHelper";
import Section from "../../components/Section/Section";
import axios from "../../axios-config";

import { useReactToPrint } from "react-to-print";
import { DateRangePicker, defaultInputRanges, createStaticRanges } from "react-date-range";
import * as rdrLocales from 'react-date-range/dist/locale';
import { useTranslation } from "react-i18next";

// ICONS
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import SimplifiedInvoiceView from "../../components/Printing/SimplifiedInvoiceView";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { formatCurrency } from "../../utils/currency";
import { useSnackbar } from "notistack";
import { FiberManualRecordTwoTone } from "@mui/icons-material";
import BoxContainer from "common/BoxContainer/BoxContainer";
import StatusChip from "components/StatusChip/StatusChip";
import DeleteDialogWarningGeneric from "common/DeleteDialogWarningGeneric";
import { set } from "lodash";
import ConfirmationDialog from "common/ConfirmDialog";


const InvoiceView = (props) => {

    const { t, i18n } = useTranslation();
    const [oldestRepairOrderDate, setOldestRepairOrderDate] = useState("2020-01-01");
    const currency = useSelector((state) => state.auth.currency);

    const [invoice, setInvoice] = useState(null);
    const [invoices, setInvoices] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [selectedInvoices, setSelectedInvoices] = useState([]);
    const [deleteOpenInvoiceDialog, setDeleteOpenInvoiceDialog] = useState(false);
    const [addPaymentDialog, setAddPaymentDialog] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(0);

    const componentRef = React.useRef();
    const theme = useTheme()
    const { enqueueSnackbar } = useSnackbar();
    const [page, setPage] = React.useState(1);
    const itemsPerPage = 10;

    let dayjs = require('dayjs')
    var isBetween = require('dayjs/plugin/isBetween')
    dayjs.extend(isBetween)

    React.useEffect(async () => {
        try {
            // axios.post("/invoices", invoiceData);

            const resp = await axios.get('/invoices');
            const invoices = [...resp.data];
            setInvoices(invoices);
        } catch (err) {
            console.log(err);
        }
    }, [])

    const handleListItemClick = (event, index) => {
        event.stopPropagation();
        setSelectedIndex(index);
        setSelectedInvoice(filteredInvoices[index]);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };


    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: selectedInvoice && selectedInvoice.refId ? `${t('Invoice')} - ${selectedInvoice.refId}` : `${t("Invoice")}`,
        // onBeforePrint: handleBeforePrint,
        // onAfterPrint: handleAfterPrint,
        // removeAfterPrint: true
    });

    const invoiceStatus = (statusId) => {
        switch (statusId) {
            case 0:
                return <Chip color="red" label={t('Unpaid')} />
            case 1:
                return <Chip color="accepted" label={t('Paid')} />
        }
    }
    const markSelectedAsToggle = async () => {
        try {
            const resp = await axios.patch(`invoices/markAsToggle/${invoices[selectedIndex].id}`);
            const prevState = [...invoices];
            prevState[selectedIndex].status = resp.data.status;
            setInvoices(prevState);
        } catch (e) {
            console.log(e);
        }
    }

    const archiveInvoice = async () => {
        try {
            setSelectedInvoice(null);
            setDeleteOpenInvoiceDialog(false);
            const resp = await axios.delete(`invoices/delete/${invoices[selectedIndex].id}`);

            if (resp.status == 200) {
                const prevState = [...invoices];
                prevState.splice(selectedIndex, 1);
                setInvoices(prevState);
            }
        } catch (e) {
            console.log(e);
        }
    }
    const refIdEdit = (newRefId) => {
        const newState = [...invoices];
        newState[selectedIndex].refId = newRefId;
        setInvoices(newState);
    }

    const setCurrentInvoice = (invoice) => {
        const prevInvoices = [...invoices];
        prevInvoices[selectedIndex] = invoice;
        setInvoices(prevInvoices);
    }

    //Calendar
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectionRange, setSelectionRange] = useState({
        startDate: dayjs().subtract(90, 'day').toDate(),
        endDate: dayjs().endOf('day').toDate(),
    });
    const calendarOpen = Boolean(anchorEl);
    const calendarId = calendarOpen ? 'calendar-popover' : undefined;

    const handleCalendarOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCalendarClose = () => {
        setAnchorEl(null);
    };

    const handleCalendarSelect = async (e) => {
        setSelectedIndex(null);
        setSelectedInvoice(null);
        if (e.key === "selection") {
            setSelectionRange(e.selection);
        } else {
            e.range1.endDate = dayjs(e.range1.endDate).endOf('day').toDate();
            setSelectionRange(e.range1);
        }
    }

    const currentYear = dayjs().year();
    const quarter1Start = dayjs(`${currentYear}-01-01`);
    const quarter2Start = dayjs(`${currentYear}-04-01`);
    const quarter3Start = dayjs(`${currentYear}-07-01`);
    const quarter4Start = dayjs(`${currentYear}-10-01`);
    const predefinedRanges = createStaticRanges([
        {
            label: t('Last 30 days'),
            range: () => ({
                startDate: dayjs().subtract(30, 'day').toDate(),
                endDate: dayjs().endOf('day').toDate(),
            }),
        },
        {
            label: t('Last 90 days'),
            range: () => ({
                startDate: dayjs().subtract(90, 'day').toDate(),
                endDate: dayjs().endOf('day').toDate(),
            }),
        },
        {
            label: t('This month'),
            range: () => ({
                startDate: dayjs().startOf('month').toDate(),
                endDate: dayjs().endOf('month').toDate(),
            }),
        },
        {
            label: t('Last month'),
            range: () => ({
                startDate: dayjs().subtract(1, 'month').startOf('month').toDate(),
                endDate: dayjs().subtract(1, 'month').endOf('month').toDate(),
            }),
        },
        {
            label: t('This year'),
            range: () => ({
                startDate: dayjs().startOf('year').toDate(),
                endDate: dayjs().endOf('year').toDate(),
            }),
        },
        {
            label: t('Last year'),
            range: () => ({
                startDate: dayjs().startOf('year').subtract(1, 'year').toDate(),
                endDate: dayjs().endOf('year').subtract(1, 'year').toDate(),
            }),
        },
        {
            label: t('All time'),
            range: () => ({
                startDate: dayjs(oldestRepairOrderDate).toDate(),
                endDate: dayjs().endOf('day').toDate(),
            }),
        },
        {
            label: t('Q1'),
            range: () => ({
                startDate: quarter1Start.startOf('day').toDate(),
                endDate: quarter1Start.clone().add(2, 'month').endOf('month').endOf('day').toDate(),
            }),
        },
        {
            label: t('Q2'),
            range: () => ({
                startDate: quarter2Start.startOf('day').toDate(),
                endDate: quarter2Start.clone().add(2, 'month').endOf('month').endOf('day').toDate(),
            }),
        },
        {
            label: t('Q3'),
            range: () => ({
                startDate: quarter3Start.startOf('day').toDate(),
                endDate: quarter3Start.clone().add(2, 'month').endOf('month').endOf('day').toDate(),
            }),
        },
        {
            label: t('Q4'),
            range: () => ({
                startDate: quarter4Start.startOf('day').toDate(),
                endDate: quarter4Start.clone().add(2, 'month').endOf('month').endOf('day').toDate(),
            }),
        },
    ]);
    let filteredInvoices = invoices.filter(invoice =>
        dayjs(invoice.date).isBetween(dayjs(selectionRange.startDate),
            dayjs(selectionRange.endDate))
    )


    let invoiceSubTotal = 0;
    let invoiceTax = 0;
    let invoiceTotal = 0;

    filteredInvoices.map(invoice => {
        let taxPercentage = 1.00 + (invoice.taxAmount / 100);

        invoice.invoiceItems.map(invoiceItem => {
            let taxPercentage = 1.00 + (invoice.taxAmount / 100);

            let discount = invoiceItem.discount ? parseFloat(invoiceItem.discount) : 0;

            let unitPrice = invoiceItem.unitPrice ? parseFloat(invoiceItem.unitPrice) : 0;
            const quantity = invoiceItem.quantity ? parseInt(invoiceItem.quantity) : 1;
            let subTotal = 0;
            let tax = 0;
            let total = 0;

            if (!invoice.taxIncluded) {

                subTotal = (unitPrice * quantity - discount);
                tax = subTotal * taxPercentage - subTotal;
                total = subTotal + tax;
            }
            else {
                subTotal = (unitPrice * quantity - discount) / taxPercentage;
                total = (unitPrice * quantity - discount);
                unitPrice = unitPrice / taxPercentage;
                discount = discount / taxPercentage;
                tax = total - total / taxPercentage;
            }

            invoiceSubTotal += subTotal;
            invoiceTax += tax;
            // console.log(tax);
            invoiceTotal += total;
        });
    });
    const noOfPages = Math.ceil(filteredInvoices.length / itemsPerPage);
    const paginatedInvoices = filteredInvoices.slice((page - 1) * itemsPerPage, page * itemsPerPage);

    const handleDownloadAllSelected = async () => {
        try {
            // const invoiceIds = selectedInvoices.map(invoice => invoice.id); // assuming selectedInvoices is an array of selected invoice objects
            // const invoiceIds = [416, 431];
            // console.log(selectedInvoices)
            const response = await axios.post(`/invoices/download/all`,
                { invoiceIds: selectedInvoices },
                { responseType: "blob" }
            );

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "AllInvoice.zip");
            document.body.appendChild(link); // This line is required for Firefox
            link.click();

            // Cleanup
            link.remove();
            window.URL.revokeObjectURL(url);
        }
        catch (e) {
            console.error(e);
            enqueueSnackbar("Failed to download invoices", { variant: "error" });
        }
    }


    const handleCheckboxClick = (event, id) => {
        if (id === 'all') {
            if (selectedInvoices.length === paginatedInvoices.length) {
                setSelectedInvoices([]);
            } else {
                setSelectedInvoices(paginatedInvoices.map((invoice) => invoice.id));
            }
        } else {
            const newSelectedInvoices = [...selectedInvoices];
            const currentIndex = newSelectedInvoices.indexOf(id);
            if (currentIndex === -1) {
                newSelectedInvoices.push(id);
            } else {
                newSelectedInvoices.splice(currentIndex, 1);
            }
            setSelectedInvoices(newSelectedInvoices);
        }
    };

    const handleAddPaymentToRepairs = async () => {
        try {
            setAddPaymentDialog(false);
            const resp = await axios.post(`invoices/${selectedInvoice.id}/add-repair-payments`, { paymentMethod: paymentMethod });
            markSelectedAsToggle();
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <>
            <Popover
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                anchorEl={anchorEl} open={calendarOpen}
                id={calendarId} onClose={handleCalendarClose}>
                <DateRangePicker
                    locale={rdrLocales[i18n.language]}

                    minDate={dayjs(oldestRepairOrderDate).toDate()}
                    maxDate={dayjs().endOf('month').toDate()}
                    ranges={[selectionRange]}
                    staticRanges={predefinedRanges}
                    onChange={handleCalendarSelect}
                    inputRanges={[{
                        ...defaultInputRanges[0],
                        label: t('Days until now')
                    }]}
                />
            </Popover>

            <Box className={'container mainContainer docsContainer '} sx={{ px: '20px', py: '10px' }}>
                <div className='headerPush'></div>
                <Stack direction={'row'} alignItems={'flex-start'} spacing={1}>
                    <Typography variant="h3">{t('Invoices')}</Typography>
                </Stack>


                {/* <div className="content" > */}
                <Box borderRight={'1px solid darkGrey'} borderBottom={'1px solid darkGrey'} flex={'0 0 auto'} bgcolor={'white'}>
                    <Section sx={{ width: '100%' }}>
                        <Grid container item alignItems={'flex-start'} spacing={'15px'} xs={12} sm={12} md={12} alignContent={'start'}>
                            <Grid item xs={6} sm={6} md={2} alignContent={'center'}>
                                <BoxContainer >
                                    <Stack justifyContent={'space-between'} height={'100%'}>
                                        <Box>
                                            <Typography
                                                variant={'overline'} color={'darkGrey.main'} textTransform={'uppercase'}
                                                sx={{ mb: 1 }}
                                            >
                                                {t('Subtotal')}
                                            </Typography>
                                            <Stack direction={'row'} spacing={2}>
                                                <Typography
                                                    variant="h4"
                                                    component="div"
                                                    sx={{ fontWeight: "bold", mb: 1 }}
                                                >
                                                    {formatCurrency(invoiceSubTotal, currency)}
                                                </Typography>
                                            </Stack>
                                        </Box>
                                    </Stack>

                                </BoxContainer>
                            </Grid>
                            <Grid item xs={6} sm={6} md={2} alignContent={'center'}>
                                <BoxContainer >
                                    <Stack justifyContent={'space-between'} height={'100%'}>
                                        <Box>
                                            <Typography
                                                variant={'overline'} color={'darkGrey.main'} textTransform={'uppercase'}
                                                sx={{ mb: 1 }}
                                            >
                                                {t('Tax')}
                                            </Typography>
                                            <Stack direction={'row'} spacing={2}>
                                                <Typography
                                                    variant="h4"
                                                    component="div"
                                                    sx={{ fontWeight: "bold", mb: 1 }}
                                                >
                                                    {formatCurrency(invoiceTax, currency)}
                                                </Typography>
                                            </Stack>
                                        </Box>
                                    </Stack>

                                </BoxContainer>
                            </Grid>
                            <Grid item xs={6} sm={6} md={2} alignContent={'center'}>
                                <BoxContainer >
                                    <Stack justifyContent={'space-between'} height={'100%'}>
                                        <Box>
                                            <Typography
                                                variant={'overline'} color={'darkGrey.main'} textTransform={'uppercase'}
                                                sx={{ mb: 1 }}
                                            >
                                                {t('Total')}
                                            </Typography>
                                            <Stack direction={'row'} spacing={2}>
                                                <Typography
                                                    variant="h4"
                                                    component="div"
                                                    sx={{ fontWeight: "bold", mb: 1 }}
                                                >
                                                    {formatCurrency(invoiceTotal, currency)}
                                                </Typography>
                                            </Stack>
                                        </Box>
                                    </Stack>

                                </BoxContainer>
                            </Grid>
                        </Grid>
                        <Box my={2}>
                        </Box>
                        <Divider />
                        <Box my={2} />
                        <Stack direction={'row'} spacing={3} alignItems={'center'} mb={1}>
                            <Button startIcon={<CalendarMonthOutlinedIcon />} variant={'outlined'} color={'black'} onClick={handleCalendarOpen}>{dayjs(selectionRange.startDate).format('DD.MMM.YYYY')} - {dayjs(selectionRange.endDate).format('DD.MMM.YYYY')}</Button>
                            <Typography>{filteredInvoices.length} {t("Invoices")}</Typography>
                            <Pagination count={noOfPages} page={page} onChange={handlePageChange} />
                        </Stack>
                        {paginatedInvoices.length === 0 ? (
                            <Typography variant="h6" align="center">
                                {t("No invoices for the selected period")}
                            </Typography>) : (
                            <TableContainer>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    indeterminate={selectedInvoices.length > 0 && selectedInvoices.length < paginatedInvoices.length}
                                                    checked={paginatedInvoices.length > 0 && selectedInvoices.length === paginatedInvoices.length}
                                                    onChange={(event) => handleCheckboxClick(event, 'all')}
                                                />
                                            </TableCell>
                                            <TableCell>{t("Invoice")}</TableCell>
                                            <TableCell align="left">{t("Date")}</TableCell>
                                            <TableCell >{t("Customer")}</TableCell>
                                            <TableCell align="center">{t("Status")}</TableCell>
                                            <TableCell align="right">{t("Total")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {paginatedInvoices.map((invoice, index) => {
                                            // Your existing code...
                                            const labelId = `checkbox-list-label-${invoice.id}`;

                                            let invoiceSubTotal = 0;
                                            let invoiceTax = 0;
                                            let invoiceTotal = 0;
                                            let taxPercentage = 1.00 + (invoice.taxAmount / 100);

                                            invoice.invoiceItems.map(invoiceItem => {

                                                let discount = invoiceItem.discount ? parseFloat(invoiceItem.discount) : 0;

                                                let unitPrice = invoiceItem.unitPrice ? parseFloat(invoiceItem.unitPrice) : 0;
                                                const quantity = invoiceItem.quantity ? parseInt(invoiceItem.quantity) : 1;
                                                let subTotal = 0;
                                                let tax = 0;
                                                let total = 0;
                                                let taxAmount
                                                if (!invoice.taxIncluded) {

                                                    subTotal = (unitPrice * quantity - discount);
                                                    tax = subTotal * taxPercentage - subTotal;
                                                    total = subTotal + tax;
                                                }
                                                else {
                                                    subTotal = (unitPrice * quantity - discount) / taxPercentage;
                                                    total = (unitPrice * quantity - discount);
                                                    unitPrice = unitPrice / taxPercentage;
                                                    discount = discount / taxPercentage;
                                                    tax = total - total / taxPercentage;
                                                }

                                                invoiceSubTotal += subTotal;
                                                invoiceTax += tax;
                                                invoiceTotal += total;
                                            })

                                            const income = invoice?.invoiceItems?.reduce((total, current) => {
                                                return total + parseFloat(current.unitPrice * current.quantity - current.discount);
                                            }, 0);
                                            return (
                                                <TableRow
                                                    key={invoice.id}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    selected={selectedIndex === index + ((page - 1) * itemsPerPage)} // calculate the actual index in the entire list
                                                // onClick={(event) => handleListItemClick(event, index)}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            checked={selectedInvoices.indexOf(invoice.id) !== -1}
                                                            onChange={(event) => handleCheckboxClick(event, invoice.id)}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <Typography sx={{
                                                            cursor: 'pointer',
                                                            color: 'primary.main',
                                                            '&:hover': {
                                                                textDecoration: 'underline',
                                                                color: 'primary.dark',
                                                            }
                                                        }}
                                                            fontWeight={500} onClick={(e) => handleListItemClick(e, index + ((page - 1) * itemsPerPage))}>{invoice.billingTo && invoice.billingTo.length > 0 ? t('Invoice') : t('Simplified invoice')} #{invoice.refId}  </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">{invoice.date}</TableCell>
                                                    <TableCell>
                                                        {invoice.customerPartner[0] ? invoice.customerPartner[0].name
                                                            : invoice.customers[0] ? invoice.customers[0].name
                                                                : invoice.billingTo}
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ width: '150px' }}> {invoiceStatus(invoice.status)}</TableCell>
                                                    <TableCell align="right">{formatCurrency(invoiceTotal, currency)}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                        <Pagination count={noOfPages} page={page} onChange={handlePageChange} />
                    </Section>
                </Box>
            </Box>
            <Dialog open={selectedInvoice} onClose={() => setSelectedInvoice(null)} maxWidth={'500px'} scroll="body">
                <Stack gap={2} p={2} alignItems={'center'}>


                    <Stack direction={'row'} spacing={1}>
                        <Button variant="contained" sx={{ backgroundColor: selectedInvoice?.status == 0 ? theme.palette.accepted.main : theme.palette.red.main }} onClick={() => selectedInvoice?.status === 0 ? setAddPaymentDialog(true) : markSelectedAsToggle()}>{selectedInvoice?.status == 0 ? t("Mark as paid") : t("Mark unpaid")}</Button>
                        {/* <Button variant="contained">Show linked orders</Button> */}
                        <Button variant={'outlined'} startIcon={<LocalPrintshopOutlinedIcon />} onClick={() => handlePrint(selectedInvoice)}>{t('Print')}</Button>
                        <Button variant="outlined" color="red" onClick={() => setDeleteOpenInvoiceDialog(true)}>{t('Delete')}</Button>
                    </Stack>

                    {

                        selectedInvoice?.billingTo?.length > 0 ? <InvoiceA4 invoice={filteredInvoices[selectedIndex]} ref={componentRef} onUpdateRefId={refIdEdit} setInvoice={setCurrentInvoice} /> : <SimplifiedInvoiceView invoice={filteredInvoices[selectedIndex]} ref={componentRef} onUpdateRefId={refIdEdit} setInvoice={setCurrentInvoice} />
                    }

                </Stack>
            </Dialog>
            <ConfirmationDialog
                title={t("dialogs.deleteInvoice.title")}
                message={
                    <Alert severity="warning">
                        <AlertTitle >{t("Warning!")}</AlertTitle>
                        <Typography variant="body1">{t("dialogs.deleteInvoice.message")}</Typography>
                    </Alert>}
                open={deleteOpenInvoiceDialog}
                onClose={() => setDeleteOpenInvoiceDialog(false)}
                onConfirm={archiveInvoice} />

            <ConfirmationDialog
                open={addPaymentDialog && selectedInvoice}
                title={t("dialogs.invoiceAddRepairPayments.title")}
                message={<Stack spacing={2}>
                    <div>{t("dialogs.invoiceAddRepairPayments.message")}</div>
                    <FormControl variant={'filled'} >
                        <InputLabel >{t('Payment method')}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            label="Payment method"
                            value={paymentMethod}
                            onChange={(event) => setPaymentMethod(event.target.value)}
                        >
                            <MenuItem value={0}>{t('Cash')}</MenuItem>
                            <MenuItem value={1}>{t('Card')}</MenuItem>
                            <MenuItem value={2}>{t('Transfer')}</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>}
                onConfirm={() => {
                    handleAddPaymentToRepairs()
                }}
                onClose={() => { setAddPaymentDialog(false); markSelectedAsToggle(); }}
            />

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={selectedInvoices?.length > 0}
            >
                <Box sx={{ backgroundColor: "#252628", px: '20px', py: '10px', borderRadius: '50px' }}>
                    <Stack alignItems={'center'} color={'white'} direction={'row'} spacing={3} >
                        <>
                            <Typography fontWeight={500} variant={'p'}>{selectedInvoices.length}{selectedInvoices.length == 50 ? "/50" : null} {selectedInvoices.length > 1 ? t("selected") : t("selected")}</Typography>
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                onClick={handleDownloadAllSelected}
                            >   {t("Download all selected")}
                            </Button>
                        </>
                    </Stack>
                </Box>
            </Snackbar>


        </>
    );
};
export default InvoiceView;
