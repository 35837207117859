import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Button, Menu, MenuItem, ListItemIcon, ListItemText, Popover, Grid, Autocomplete, Avatar, Typography, TextField, ListItemButton, Paper } from '@mui/material';
import PermIdentityRounded from '@mui/icons-material/PermIdentityRounded';
import CheckIcon from '@mui/icons-material/Check';
import axios from "axios-config";

import ConsoleHelper from 'utils/ConsoleHelper';
import { Circle } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const AssignUserButton = ({ assignedTo, onSelectUser, assignToMe }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [users, setUsers] = useState([]);
  const [isSelecting, setIsSelecting] = useState(false);
  const [open, setOpen] = useState(false); // Autocomplete open state


  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsSelecting(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsSelecting(false);
  };

  const handleOptionSelect = (user) => {

    onSelectUser(user);
    setAnchorEl(null);
  };

  const onAssignToMe = () => {
    assignToMe();
    setAnchorEl(null);
  }
  const fetchUsers = async () => {
    const response = await axios.get('/organizations/users');
    if (response.status === 200) {
      ConsoleHelper(response.data, 'info');
      setUsers(response.data);
    }
  }

  useEffect(() => {
    // Fetch users from API
    if (!isSelecting) return;
    fetchUsers();
  }, [isSelecting]);



  const filterOptions = (options, { inputValue }) => {
    return options.filter((option) =>
      option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
      option.email.toLowerCase().includes(inputValue.toLowerCase())
    );
  };


  return (
    <>
      {(!assignedTo) && (
        <Button
          variant='text'
          color="black"
          startIcon={<PermIdentityRounded sx={{
            border: '1px dashed black',
            borderRadius: '50%',
            padding: '0px',
          }} />}
          onClick={handleClick}
        >
          {t("No assignee")}
        </Button>
      )}
      {isSelecting && (
        <Popover
          id="assign-task"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={{
            "& .MuiPaper-root": {
              minWidth: "400px", // Control width here
            },
          }}
        >
          <Grid
            component="div"
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Autocomplete
              size="small"
              variant="outlined"
              id="user-details"
              options={users}
              getOptionLabel={(option) => option.name}
              filterOptions={filterOptions}
              onMouseDown={(event) => event.preventDefault()}
              PaperComponent={(props) => (
                <Paper {...props} sx={{ width: '350px' }} />
              )}
              // onChange={handleSelectUser}
              renderOption={(props, option) => <OptionList {...props} key={option.id} option={option} handleOptionSelect={handleOptionSelect} />}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onClick={(event) => {
                    if (!open) {
                      setOpen(true)
                    }
                  }}
                  placeholder={t('assignUser.placeholder')}
                  autoComplete="off"
                />
              )}
              sx={{ width: "55%" }}
              open={open}
              onOpen={() => {
                if (!open) {
                  setOpen(true);
                }
              }}
              onClose={() => {
                if (open) {
                  setOpen(false);
                }
              }}
              disableCloseOnSelect={true}
            />
            <Typography component="div">{t("assignUser.or")}</Typography>
            <Button size="medium" variant="outlined" onClick={() => onAssignToMe()}>
              {t("assignUser.assignToMe")}
            </Button>
          </Grid>
        </Popover>
      )}
      {assignedTo && (
        <Button
          variant='text'
          color="black"
          startIcon={
            <Avatar sx={{ width: 24, height: 24 }}>
              <span style={{ fontSize: '14px' }}>
                {assignedTo.name.charAt(0).toUpperCase()}
              </span>
            </Avatar>
          }
          onClick={handleClick}
        >
          {assignedTo.name}
        </Button>
      )}
    </>
  );
};

const OptionList = forwardRef(({ option, handleOptionSelect }, ref) => {
  const handleOnClick = () => {
    handleOptionSelect(option);
  }

  return <div ref={ref}>
    <ListItemButton onClick={handleOnClick}>
      <ListItemIcon sx={{ minWidth: '10px' }}>
        <Avatar sx={{ mr: 2, width: 24, height: 24, fontSize: '14px' }}>
          {option.name.charAt(0).toUpperCase()}
        </Avatar>
      </ListItemIcon>
      <ListItemText
        primary={<div style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 2,
          fontSize:'14px',
          WebkitBoxOrient: 'vertical'
        }}>{option.name}</div>}
        secondary={
          <span style={{
            fontSize: '12px'
          }}>
            {option.email}
          </span>
        }
      />    </ListItemButton>
  </div>
});



export default AssignUserButton;