import { useEffect } from 'react';

// Listen to force reload in case of minimum version requirement
export const ForceVersionUpdateListener = () => {

  // Listen for messages from other tabs to trigger a reload
  useEffect(() => {
    const channel = new BroadcastChannel('app-version-channel');
    channel.onmessage = (event) => {
      if (event.data && event.data.action === 'reload') {
        window.location.reload();
      }
    };
    return () => {
      channel.close();
    };
  }, []);


  return null
};
