import React, { useState, useEffect, useRef, memo } from "react";
import { Grid, TextField, Fab, Divider, List, Stack, ToggleButton, Box, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import CommentText from "./CommentText";
import axios from '../../../axios-config';
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import StyledToggleButtonGroup from "../../../components/StyledToggleButtonGroup/StyledToggleButtonGroup";
import { useTranslation } from "react-i18next";
import { GroupOutlined } from "@mui/icons-material";
import ConfirmationDialog from "common/ConfirmDialog";

function Comments({ order, orderIsShared, commentTabIndex, setCommentTabIndex, comments, setComments, setIsDoingAction, handleImageClick }) {

    const { t } = useTranslation();
    const [commentIdToDelete, setCommentIdToDelete] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const bottomRef = useRef(null);
    const auth = useSelector(state => state.auth);
    const [newFeatureRendered, setNewFeatureRendered] = useState(false);


    const deleteComment = async () => {
        const cachedComments = comments.slice();
        try {
            setIsDoingAction(true);
            setComments(comments.filter((comment) => comment.id !== commentIdToDelete));
            setCommentIdToDelete(null);
            const response = await axios.delete(`/repair-orders/comment/${commentIdToDelete}`);
            if (response.status === 200) {
                enqueueSnackbar(t('success.deleteComment'), { variant: 'success' });
            }
        } catch (error) {
            setComments(cachedComments);
            console.error(error);
            enqueueSnackbar(t('errors.deleteComment'), { variant: 'error' });
        } finally {
            setIsDoingAction(false);
        }
    }


    if (!comments) {
        return null
    }
    const isPublicBool = commentTabIndex === 1;

    const privateCommentsLength = comments.filter((comment) => comment.isPublic === false).length
    const publicCommentsLength = comments.filter((comment) => comment.isPublic === true).length



    const filteredComments = comments.slice()
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .filter((comment) => comment.isPublic === isPublicBool);

    let newTooltipsRendered = 0;

    return (<>

        <Stack flex={'1'} height={'100%'} borderColor={'lightGrey.main'} >
            <Box flex={1} py={'0px'}>
                <Box>
                    {/* <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}  >
                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                            {getIsPublicAsBool() ? <GroupOutlined /> : null}
                            <Typography flex={1} variant="h4">{getIsPublicAsBool() ? t('Comments with partner') : t('Private comments')} {filteredComments.length > 0 ? `(${filteredComments.length})` : null} </Typography>
                        </Stack>

                        {orderIsShared ?
                            <StyledToggleButtonGroup
                                size="small"
                                value={isPublic}
                                exclusive
                                onChange={(e) => {
                                    // console.log(e.target.value, 'target')
                                    setIsPublic(e.target.value);
                                }}
                                aria-label="text alignment"
                                sx={{ backgroundColor: "#F1F9FD" }}
                            >
                                <ToggleButton value={'private'}>{t('Private')}: {privateCommentsLength}</ToggleButton>
                                <ToggleButton value={'public'}>{t('Partner')}: {publicCommentsLength}</ToggleButton>
                            </StyledToggleButtonGroup>
                            : null}

                    </Stack> */}
                    <Stack spacing={'15px'} mt={'15px'} >
                        {filteredComments
                            .map((comment) => {
                                if (comment.user.id == auth.userId && newTooltipsRendered < 1) {
                                    newTooltipsRendered++;
                                    // RENDER NEW FEATURE TOOLTIP ONCE
                                    return <CommentText newFeatureRendered={true} comment={comment} key={comment.id} auth={auth} setCommentIdToDelete={setCommentIdToDelete} handleImageClick={handleImageClick} />
                                }
                                return <CommentText comment={comment} key={comment.id} auth={auth} setCommentIdToDelete={setCommentIdToDelete} handleImageClick={handleImageClick} />
                            })}

                        {/* <Divider variant="inset" component="li" /> */}
                    </Stack>

                    {comments.length == 0 ? <Typography>{t('No comments')}</Typography> : null}
                    < div ref={bottomRef} ></div>

                </Box>
            </Box>
        </Stack >

        <ConfirmationDialog
            open={Boolean(commentIdToDelete)}
            title={t("dialogs.deleteComment.title")}
            message={t("dialogs.deleteComment.message")}
            onConfirm={() => {
                deleteComment()
            }}
            onClose={() => setCommentIdToDelete(false)}
        />

    </>
    );
}

export default memo(Comments);
