import { Fragment } from "react";
import { Select, MenuItem, Button, Popover, List, ListItem, ListItemText, ListItemButton } from "@mui/material";
import * as React from 'react';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import { useTranslation } from "react-i18next";

const LanguageButton = ({ language, setLanguage }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { t, i18n } = useTranslation();

    const handleChange = (event) => {
        // setAge(event.target.value);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget)
    };
    const open = Boolean(anchorEl);

    const handleSetLanguage = (option) => {
        if (!setLanguage) {
            i18n.changeLanguage(option);
            setAnchorEl(null);
            return;
        }
        setLanguage(option);
        setAnchorEl(null);
    }

    const languageLong = {
        "en": "English",
        "es": "Español"
    }

    const getCurrentLanguage = () => {
        if (!language) {
            const shortLang = i18n.language;
            return languageLong[shortLang]
        }
        return languageLong[language]
    }

    return (<>
        <Button onClick={handleOpen} variant="outlined" color={'black'} startIcon={<LanguageOutlinedIcon />} >{getCurrentLanguage()}</Button>
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }} >

            <List>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => handleSetLanguage('en')}>
                        <ListItemText primary="English" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => handleSetLanguage('es')}>
                        <ListItemText primary="Español" />
                    </ListItemButton>
                </ListItem>
            </List>
        </Popover>
    </>
    );
};
export default LanguageButton;
