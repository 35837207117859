// import { Fragment } from "react";
// import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import * as React from 'react';
import { addNewEntry } from './formController';
import { useSelector, useDispatch } from 'react-redux';
import axios from "../../axios-config";
import { Dialog, DialogTitle, Button, DialogActions, DialogContent, Typography, Box } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';

import Step1 from "./Step1";
import Step2 from "./Step2";

import { newEntryModalActions } from '../../store/new-entry-modal';
import { dataTableActions } from "../../store/data-table";
import { useSnackbar } from 'notistack';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useTranslation } from "react-i18next";





const NewEntryStepper = (props) => {

  const { t } = useTranslation();
  const dayjs = require('dayjs');
  const [isProcessing, setIsProcessing] = React.useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const modalState = useSelector(state => state.newEntryModal);
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <Step1 />;
      // return <Step2 repairOrderAdded={repairOrderAdded} setRepairOrderAdded={setRepairOrderAdded} closeModal={handleClose} />;

      case 1:
        if (!repairOrderAdded) return null;
        return <Step2 repairOrderAdded={repairOrderAdded.orderData} partners={repairOrderAdded.partners} setRepairOrderAdded={setRepairOrderAdded} closeModal={handleClose} />;
      default:
        return "Unknown stepIndex";
    }
  }

  const [repairOrderAdded, setRepairOrderAdded] = React.useState({
    partners: [],
    orderData: {}
  });

  // const classes = useStyles();


  const modalTitle = () => {
    if (modalState.activeStep === 0)
      return `${t('New entry')}: ${modalState.entryData.brand ? modalState.entryData.brand.inputValue ? modalState.entryData.brand.inputValue : modalState.entryData.brand.name : ''} ${modalState.entryData.model || ''}`

    if (modalState.activeStep === 1 && repairOrderAdded)
      return <Box>{t("Additional actions")} <Typography variant={'h4'} color={'blue.main'} display={'inline'}>#{repairOrderAdded.orderData.organizationCounter} {repairOrderAdded.orderData.brand} {repairOrderAdded.orderData.model}</Typography></Box>

    handleClose();
    return `ERROR: Out of bounds`

  }

  const handleNext = async () => {
    if (modalState.activeStep !== 0) {

      handleClose();
      return;
    }
    try {
      let data = { ...modalState.entryData };
      if (!data.brand || data.brand === "") {
        enqueueSnackbar(t("newEntryStepper.errors.brandRequired"), { variant: "error" });
        return;
      }

      if (!data.model || data.model === "") {
        enqueueSnackbar(t("newEntryStepper.errors.modelRequired"), { variant: "error" });
        return;
      }
      if (!data.malfunction || data.malfunction === "") {
        enqueueSnackbar(t("newEntryStepper.errors.malfunctionRequired"), { variant: "error" });
        return;
      }

      if (!data.customer) {
        enqueueSnackbar(t("newEntryStepper.errors.customerRequired"), { variant: "error" });
        return;
      }
      data.brand = data.brand.inputValue ? data.brand.inputValue : data.brand.name; // FIX new brand or already existing brand

      setIsProcessing(true);
      // await new Promise((r) => setTimeout(r, 2000));
      const response = await axios.post(`/repair-orders/`, data);

      if (response.status === 200 || response.status === 201) {
        setRepairOrderAdded({
          orderData: response.data.repairOrderAdded,
          partners: response.data.partners,
        });
        enqueueSnackbar(`Repair order added succesfully`, { variant: "success" });
        const refetchResponse = await axios.get(`/repair-orders`)
        if (refetchResponse.status === 200) {
          // dispatch(dataTableActions.setDataTable(refetchResponse.data))
          fetchData()
        }
        dispatch(newEntryModalActions.clear());
        dispatch(newEntryModalActions.nextStep());
      }
      setIsProcessing(false);
    } catch (err) {
      setIsProcessing(false);
      enqueueSnackbar(`Something went wrong!`, { variant: "error" });
      console.log(err);
    }
  };
  const fetchData = async () => {
    try {

      const response = await axios.get(`/repair-orders`)
      if (response.status != 200) {
        // console.log("Repair Orders not fetched successfuly");
      }

      let data = response.data;
      // setRepairOrders(data);
      let now = dayjs();
      const activeData = data.filter(order => now.diff(order.updatedAt, 'month') < 3
      )
      // console.log(activeData);

      await dispatch(dataTableActions.setDataTable(activeData));
    } catch (err) {
      console.log(err);
    };
  }


  const handleClose = () => {
    dispatch(newEntryModalActions.toggleModal());
    dispatch(newEntryModalActions.setStep(0));
  };

  const handleButtonText = () => {

    if (modalState.activeStep === 0) {
      return t("Add entry")
    } else {
      return t("Ok");
    }
  };
  return (
    <Dialog
      open={modalState.showNewEntryModal}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="alert-dialog-title">
        {modalTitle()}
      </DialogTitle>
      <DialogContent id='dialogcontent'>
        {modalState.activeStep >= 2 ? (
          handleClose()
        ) : (
          getStepContent(modalState.activeStep)
        )}

      </DialogContent>
      <DialogActions>
        <LoadingButton loading={isProcessing} onClick={handleNext} loadingPosition="end" variant="contained" endIcon={<NavigateNextIcon />}>
          {handleButtonText()}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};


export default NewEntryStepper;
