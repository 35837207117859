import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    showSearchDialog: false,
};

const searchDialogSlice = createSlice({
    name: 'searchDialog',
    initialState: initialState,
    reducers: {
        toggleModal(state) {
            state.showSearchDialog = !state.showSearchDialog;
        },
    }
});

export const searchDialogActions = searchDialogSlice.actions;
export default searchDialogSlice.reducer;