import React from 'react';
import { styled } from '@mui/system';
import ToggleButton, { toggleButtonClasses } from '@mui/material/ToggleButton';

const ColoredToggleButton = styled(ToggleButton)(
  ({ theme, color }) => ({
    [`&.${toggleButtonClasses.selected}`]: {
      backgroundColor: theme.palette[color].main,
      '&:hover': {
        backgroundColor: theme.palette[color].dark,
      },
      color: theme.palette.getContrastText(theme.palette[color].main),
    },
  })
);

export default ColoredToggleButton;