export const LOGIN = "LOGIN";
export const AUTH_INITIATE_LOGOUT = "AUTH_INITIATE_LOGOUT";
export const AUTH_LOGOUT_FAILED = "AUTH_LOGOUT_FAILED";
export const AUTH_LOGOUT_SUCCESSFULLY = "AUTH_LOGOUT_FAILED";
export const LOGOUT = "LOGOUT";

export const INITIATE_NEW_ENTRY_MODAL = "INITIATE_NEW_ENTRY_MODAL"; // not used?
export const NEXT_STEP = "NEXT_STEP";
export const PREVIOUS_STEP = "PREVIOUS_STEP";
export const SET_STEP = "SET_STEP";


export const SHOW_NEW_ENTRY_MODAL = "SHOW_NEW_ENTRY_MODAL";
export const UPDATE_NEW_ENTRY_MODAL_STATE = "UPDATE_NEW_ENTRY_MODAL_STATE";

export const ADD_ENTRY = "ADD_ENTRY";
export const ADD_ENTRY_START = "ADD_ENTRY_START";
export const ADD_ENTRY_FAILED = "ADD_ENTRY_FAILED";
export const ADD_ENTRY_SUCCEED = "ADD_ENTRY_SUCCEED";



export const ADD_ENTRY_CATEGORY = "ADD_ENTRY_CATEGORY";
export const ADD_ENTRY_CATEGORY_START = "ADD_ENTRY_CATEGORY_START";
export const ADD_ENTRY_CATEGORY_FAILED = "ADD_ENTRY_CATEGORY_FAILED";
export const ADD_ENTRY_CATEGORY_SUCCEED = "ADD_ENTRY_CATEGORY_SUCCEED";

export const TOGGLE_NEW_CLIENT_MODAL = "TOGGLE_NEW_CLIENT_MODAL";
export const ADD_CLIENT= "ADD_CLIENT";

export const ADD_CLIENT_START = "ADD_CLIENT_START";
export const ADD_CLIENT_FAILED = "ADD_CLIENT_FAILED";
export const ADD_CLIENT_SUCCEED = "ADD_CLIENT_SUCCEED";

export const DATA_TABLE_FETCH = "DATA_TABLE_FETCH";
export const DATA_TABLE_FETCH_SUCCEED = "DATA_TABLE_FETCH_SUCCEED";
export const DATA_TABLE_FETCH_FAILED= "DATA_TABLE_FETCH_FAILED";


export const SET_SERVER_DOWN = "SET_SERVER_DOWN";
export const SET_UPDATE_AVAILABLE = "SET_UPDATE_AVAILABLE";
export const SET_NETOWKR_NOT_AVAILABLE = "SET_NETOWKR_NOT_AVAILABLE";
export const SET_MINIMUM_VERSION_NOT_MET = "SET_MINIMUM_VERSION_NOT_MET";

