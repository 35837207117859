import * as React from "react";
import { useEffect, useState } from "react";
import { useTheme, styled } from "@mui/material/styles";
import {
  Typography,
  Button,
  Grid,
  Box,
  Stack,
  Container,
  Popover,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ToggleButton,
  ToggleButtonGroup,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  SvgIcon,
  OutlinedInput,
  ListItemText,
  Checkbox,
  Skeleton,
  Tabs,
  Tab,
  Divider,
} from "@mui/material";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import RepairsLineChart from "./RepairsLineChart";
import PieChart from "./PieChart";
import {
  DateRangePicker,
  defaultInputRanges,
  createStaticRanges,
} from "react-date-range";
import * as rdrLocales from "react-date-range/dist/locale";

import axios from "../../axios-config";

//ICONS
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import RightArrow from "@mui/icons-material/KeyboardArrowRightRounded";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import LocalShippingTwoToneIcon from "@mui/icons-material/LocalShippingOutlined";
import PaymentsTwoToneIcon from "@mui/icons-material/PaymentsTwoTone";
import SignalCellularAltTwoToneIcon from "@mui/icons-material/SignalCellularAltTwoTone";
import MemoryTwoToneIcon from "@mui/icons-material/MemoryTwoTone";
import PendingTwoToneIcon from "@mui/icons-material/HourglassTopTwoTone";
import GraphicEqTwoToneIcon from "@mui/icons-material/GraphicEqTwoTone";
import CircleIcon from "@mui/icons-material/Circle";

import { set } from "date-fns";
import { useTranslation } from "react-i18next";
import { servicesCalculation } from "../../utils/service-helpers";
import ToolBarHelper from "../../components/ToolBarHelper/ToolBarHelper";
import Sorts from "../../components/DataTable/Sorts";
import PartnersFilter from "../../components/DataTable/PartnersFilter";
import { useSelector } from "react-redux";
import BoxContainer from "../../common/BoxContainer/BoxContainer";
import { useNavigate, Link } from "react-router-dom";
import StyledToggleButtonGroup from "../../components/StyledToggleButtonGroup/StyledToggleButtonGroup";
import ResolutionChip from "../../components/ResolutionChip/ResolutionChip";
import { processRepairOrder } from "../../utils/repair-order-helpers";
import { formatCurrency } from "../../utils/currency";
import FinancesLineChart from "./FinancesLineChart";
import RepairsDoneTable from "./RepairsDone";
import RepairsRevenuePerUser from "./RepairsRevenuePerUser";

const ChartsPageV2 = () => {
  const { t, i18n } = useTranslation();
  let dayjs = require("dayjs");
  const currency = useSelector((state) => state.auth.currency);
  const [fetching, setFetching] = useState(false);
  const [tabValue, setTabValue] = useState(0);


  const [selectionRange, setSelectionRange] = useState({
    startDate: dayjs().subtract(90, "day").toDate(),
    endDate: dayjs().endOf("day").toDate(),
  });

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Box
        className={"container mainContainer docsContainer "}
        sx={{ px: "20px", py: "10px" }}
      >
        <div className="headerPush"></div>
        <Stack direction={"row"} alignItems={"flex-start"} spacing={1}>
          <Typography variant="h3">{t("charts.title")}</Typography>
        </Stack>

        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          sx={{ marginBottom: "15px" }}
        >
          <Tab label={t("charts.tab.repairsDone")} />
          <Tab label={t("charts.tab.revenuePerUser")} />
        </Tabs>
        {/* <Divider sx={{ my: '15px' }} /> */}
        {tabValue === 0 && <RepairsDoneTable />}
        {tabValue === 1 && <RepairsRevenuePerUser />}
        {/* <RepairsDoneTable /> */}
      </Box>
    </>
  );
};

export default ChartsPageV2;
