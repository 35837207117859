import * as React from 'react';
import { Box, Chip } from '@mui/material';
import { repairResolutions } from '../../utils/repair-statuses-helpers';

// ICONS
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import RepairedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import DeclinedIcon from '@mui/icons-material/DoDisturbAltOutlined';
import IrreproducibleIcon from '@mui/icons-material/HelpOutlineOutlined';
import IrreparableIcon from '@mui/icons-material/PendingOutlined';
import WarrantyIcon from '@mui/icons-material/GppGoodOutlined';
import { useTranslation } from 'react-i18next';



const ResolutionChip = ({ variant, onClick }) => {
    const { t } = useTranslation();
    const variantProcessed = variant.toLowerCase();
    const resolutionIcons = {
        'repaired': { icon: <RepairedIcon />, color: 'primary' },
        'declined': { icon: <DeclinedIcon />, color: 'red' },
        'irreparable': { icon: <IrreparableIcon />, color: 'pink' },
        'irreproducible': { icon: <IrreproducibleIcon />, color: 'danube' },
        'warranty': { icon: <WarrantyIcon />, color: 'darkBlue' },
    }
    if (variantProcessed === null || variantProcessed === undefined)
        return null

    return <Chip color={resolutionIcons[variantProcessed].color}
        icon={resolutionIcons[variantProcessed].icon}
        variant={'outlined'}
        sx={{ fontWeight: '500' }}
        label={t(repairResolutions[variant].name)}
        onClick={onClick} />
}

export default ResolutionChip;