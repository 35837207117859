// First we need to import axios.js
import axios from 'axios';
import { authActions } from './store/auth'
import { setServerDown, setUpdateAvailable, setMinimumVersionNotMet } from 'store/actions/appActions';
import { checkServerHealth } from 'api/health';
import store from './store'
import semver from 'semver';
import ConsoleHelper from './utils/ConsoleHelper';


const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // Other default configurations
});

const THIRTY_MIN_IN_MS = 30 * 60 * 1000;

// Create a BroadcastChannel for communicating across tabs
const channel = new BroadcastChannel('app-version-channel');

// Function to check if a reload was triggered recently
const hasReloadedRecently = () => {
  const reloadTriggeredTime = window.localStorage.getItem('force-reload-triggered-time');
  return reloadTriggeredTime && (Date.now() - parseInt(reloadTriggeredTime)) < THIRTY_MIN_IN_MS;
};

// Set up interceptors

// **Response Interceptor**
axiosInstance.interceptors.response.use(
  (response) => {
    const currentVersion = process.env.REACT_APP_VERSION;
    const liveVersion = response.headers['x-version'];
    const minVersionRequired = response.headers['x-min-version'];

    const isServerVersionNewer = semver.gt(liveVersion, currentVersion);
    let minVersionRequiredMet;

    if (minVersionRequired) {
      minVersionRequiredMet = semver.gte(currentVersion, minVersionRequired);
    }

    if (
      isServerVersionNewer &&
      !minVersionRequiredMet &&
      minVersionRequiredMet !== undefined
    ) {
      if (!hasReloadedRecently()) {
        window.localStorage.setItem(
          'force-reload-triggered-time',
          Date.now().toString()
        );
        console.log('Reloading due to version update');
        channel.postMessage({ action: 'reload' });
        window.location.reload();
      }
    }

    // Update the updateAvailable state
    const currentUpdateAvailable = store.getState().app.updateAvailable;
    if (isServerVersionNewer !== currentUpdateAvailable) {
      store.dispatch(setUpdateAvailable(isServerVersionNewer));
    }

    // Reset the serverDown state
    if (store.getState().app.serverDown) {
      store.dispatch(setServerDown(false));
    }
    return response;
  },
  async (error) => {
    if (error.response && error.response.data) {
      const { message, code } = error.response.data;
      const status = error.response.status;
      if (status === 426) {
        console.log('Minimum version not met');
        if (!hasReloadedRecently()) {
          window.localStorage.setItem('force-reload-triggered-time', Date.now().toString());
          console.log('Reloading due to minimum version not met');
          channel.postMessage({ action: 'reload' });
          window.location.reload();
        } else {
          console.log('Dispatch minimum version not met');
          store.dispatch(setMinimumVersionNotMet(true));
        }
      }
      if (
        message === 'Not authenticated' ||
        message === 'Token expired, please log in again.'
      ) {
        store.dispatch(authActions.logout());
      }

      if (code === 'NOT_VERIFIED') {
        store.dispatch(authActions.unverifyUser());
      }

      if (status === 403 && message === 'Subscription expired.') {
        console.log('Subscription expired');
        store.dispatch(authActions.updateActiveSubscription(false));
      }
    } else if (error.request) {
      // No response received

      //TODO: dispatch a "noNetworkAvailable" on the store and show this instead of server down
      // First check network status
      if (!navigator.onLine) {
        console.warn('No internet connection available.');
        // Optionally notify the user
      }
      // Check for timeout errors
      if (error.code === 'ECONNABORTED') {
        console.warn('Request timed out:', error.message);
        // Optionally retry or notify the user
      } else if (error.message && error.message.includes('Network Error')) {
        console.warn('Network Error:', error.message);
        // Perform a health check without causing a loop
        await checkServerHealth();
      } else {
        // Other errors without a response
        console.warn('Unknown error without response:', error.message);
      }
    } else {
      // Error setting up the request
      console.error('Error setting up request:', error.message);
    }

    return Promise.reject(error);
  }
);

// **Request Interceptor**
axiosInstance.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = state.auth.token;
    if (token) {
      config.headers.Authorization = token;
    }
    config.headers['frontend-app-version'] = process.env.REACT_APP_VERSION;

    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;



// export default instance;