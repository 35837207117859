import * as React from 'react';
import { TextField, Box, Skeleton, Divider, Stack } from '@mui/material';
import { useState } from 'react';

const RightPanelSkeleton = () => {

    const p = { height: 10 }
    const h1 = { height: 40 }
    const h2 = { height: 30 }

    return (
        <>
            <Box py={'15px'} px={'25px'}>
                <Box display={'flex'} alignItems={'center'}>
                    <Skeleton width={100} height={h1.height} sx={{ marginRight: '25px' }} />
                    <Box flexGrow={1}>
                        <Skeleton width={100} height={h1.height} sx={{ marginRight: '25px' }} />
                    </Box>
                    <Skeleton width={80} height={h1.height} />
                </Box>
            </Box>
            <Divider />
            <Box py={'15px'} px={'25px'}>
                <Box display={'flex'} alignItems={'start'}>
                    <Skeleton variant={'rectangular'} width={100} height={70} sx={{ marginRight: '10px' }} />
                    <Box>
                        <Skeleton width={300} height={40} />
                        <Skeleton width={100} height={h2.height} />
                    </Box>
                </Box>
            </Box>
            <Box py={'15px'} px={'25px'}>
                <Box fullwidth>
                    <Skeleton variant={'rectangular'} width={'100%'} height={100} sx={{ marginBottom: '15px' }} />
                    <Skeleton variant={'rectangular'} width={'100%'} height={100} />
                </Box>
            </Box>
            <Box px={'25px'}>
                <Stack direction={'row'} spacing={2} >
                    <Skeleton variant={'rectangular'} width={'100%'} height={40} sx={{ marginBottom: '15px', px: '10px' }} />
                    <Skeleton variant={'rectangular'} width={'100%'} height={40} sx={{ marginBottom: '15px', px: '10px' }} />
                </Stack>
            </Box>
            <Divider />
            <Box py={'15px'} px={'25px'}>
                    <Skeleton  width={200} height={h1.height} sx={{ marginRight: '10px' }} />
            </Box>
            <Box py={'15px'} px={'25px'}>
                <Box fullwidth>
                    <Skeleton variant={'rectangular'} width={'100%'} height={100} sx={{ marginBottom: '15px' }} />
                </Box>
            </Box>
        </>
    )
}

export default RightPanelSkeleton;