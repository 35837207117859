import { useState, useEffect } from 'react';
import {
    Box, Divider, IconButton,
    Typography, Stack, Chip, Button, ToggleButtonGroup, ToggleButton, ButtonGroup, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, Autocomplete, Table, TableHead, TableRow, TableCell, Tab, TableBody, TextField
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import axios from '../../../axios-config';
import { processService } from '../../../utils/service-helpers';
import { useSelector } from 'react-redux';
import { tr } from 'date-fns/locale';
import { useSnackbar } from 'notistack';
import { formatCurrency } from '../../../utils/currency';

const RefundDialog = ({ open, onClose, repairOrder, onRefund }) => {
    const [services, setServices] = useState([]);
    const [refundAmounts, setRefundAmounts] = useState({});
    const currency = useSelector((state) => state.auth.currency);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`/repair-orders/parent/services/${repairOrder.id}`);
                setServices(response.data);
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };
        // Execute the created function directly
        if (open) {
            fetchData();
        }

    }, [open])
    const handleAddRefund = async () => {

        try {
            const refundData = Object.entries(refundAmounts).map(([serviceId, amount]) => ({
                serviceId: parseInt(serviceId),
                amount: parseFloat(amount)
            }));

            const response = await axios.post(`/repair-orders/${repairOrder.id}/refund`, { serviceRefunds: refundData });
            if (response.status !== 200) {
                throw response.status;
            }
            onRefund(response.data);
            enqueueSnackbar(`Refund added succesfully!`, { variant: "success" });
            onClose();

        } catch (error) {
            console.error('Failed to add refund:', error);
        }
    }

    const handleFullRefundButton = (service) => {
        const processedService = processService(service);
        handleRefundAmountChange(service, processedService.unitPrice * processedService.quantity - service.discount);

    }

    const handleRefundAmountChange = (service, amount) => {
        amount = parseFloat(amount);
        const processedService = processService(service);
        if (amount > (processedService.unitPrice * processedService.quantity - processedService.discount)) {
            amount = (processedService.unitPrice * processedService.quantity - processedService.discount);
        }
        setRefundAmounts((prevRefundAmounts) => ({
            ...prevRefundAmounts,
            [service.id]: amount
        }));
    };

    //TODO: Show alredy refunded services


    return (
        <Dialog open={open} onClose={onClose} maxWidth={'md'} fullWidth>
            <DialogTitle>{t('Refund')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {/* {t('Are you sure you want to refund this order?')} */}
                </DialogContentText>
                {/* <Stack>
                    <Stack direction={'row'} spacing={2} pt={3}>
                        <Typography variant={'h6'}>Service name</Typography>
                        <Typography variant={'h6'}>{t('Refund amount')}</Typography>
                        <Typography variant={'h6'}>{repairOrder.totalPrice}</Typography>

                </Stack> */}
                <Table>

                    <TableHead>
                        <TableRow>
                            <TableCell>{t('Service name')}</TableCell>
                            <TableCell>{t('Original service total')}</TableCell>
                            <TableCell>{t('Refund amount')}</TableCell>

                            <TableCell> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {services?.map((service) => {
                            const processedService = processService(service);
                            return <TableRow key={service.id}>
                                <TableCell>{service.name}</TableCell>
                                <TableCell>{formatCurrency(processedService.unitPrice * processedService.quantity - processedService.discount, currency)}</TableCell>
                                <TableCell>
                                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                        <Box width={'100px'}><TextField
                                            size='small'
                                            variant='filled'
                                            placeholder='0.00'
                                            hiddenLabel
                                            type='number'
                                            value={refundAmounts[service.id] || ''}
                                            onChange={(e) => handleRefundAmountChange(service, e.target.value)}
                                        ></TextField></Box>
                                        {/* <Typography>/{service.quantity}</Typography> */}
                                    </Stack>
                                </TableCell>
                                <TableCell>
                                    <Button variant={'outlined'} onClick={(e) => handleFullRefundButton(service)}>Full refund</Button>
                                </TableCell>

                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t('Cancel')}</Button>
                <Button onClick={handleAddRefund}>{t('Refund')}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default RefundDialog;
