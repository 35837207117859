import { createTheme } from '@mui/material/styles';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';

const defaultTheme = createTheme();

let theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,      // Extra small devices (phones)
            sm: 600,    // Small devices (tablets)
            md: 960,    // Medium devices (small laptops)
            lg: 1280,   // Large devices (laptops)
            xl: 1920,   // Extra large devices (large laptops and desktops)
        }
    },
    palette: {
        primary: {
            main: "#1B6DE6",
        },
        secondary: {
            main: "#c8b7cb"
        },
        black: defaultTheme.palette.augmentColor({
            color: { main: '#000000' },
            name: 'black'
        }),
        green: defaultTheme.palette.augmentColor({
            color: { main: '#41a11a' },
            name: 'green'
        }),
        red: defaultTheme.palette.augmentColor({
            color: { main: '#FF1E4B' },
            name: 'red'
        }),
        lightBlue: defaultTheme.palette.augmentColor({
            color: { main: '#F1F9FD' },
            name: 'lightBlue'
        }),
        // Request
        danube: defaultTheme.palette.augmentColor({
            color: { main: '#64AAD0' },
            name: 'danube'
        }),
        pink: defaultTheme.palette.augmentColor({
            color: { main: '#ec4d96' },
            name: 'pink'
        }),
        divGrey: defaultTheme.palette.augmentColor({
            color: { main: '#F1F5F8' },
            name: 'divGrey'
        }),
        lightGrey: defaultTheme.palette.augmentColor({
            color: { main: '#BBC2CD' },
            name: 'lightGrey'
        }),
        darkGrey: defaultTheme.palette.augmentColor({
            color: { main: '#525964' },
            name: 'darkGrey'
        }),
        darkBlue: defaultTheme.palette.augmentColor({
            color: { main: '#002864' },
            name: 'darkBlue'
        }),
        orange: defaultTheme.palette.augmentColor({
            color: { main: '#FFB978' },
            name: 'orange'
        }),
        blue: defaultTheme.palette.augmentColor({
            color: { main: '#1B6DE6' },
            name: 'blue'
        }),
        lightGreen: defaultTheme.palette.augmentColor({
            color: { main: '#F1FDF4' },
            name: 'lightGreen'
        }),
        lightRed: defaultTheme.palette.augmentColor({
            color: { main: '#FFE8EE' },
            name: 'lightRed'
        }),
        highlightYellow: defaultTheme.palette.augmentColor({
            color: { main: '#FEEA99' },
            name: 'highlightYellow'
        }),
        warningBackground: '#FBE9DB',
        /*
         ===== STATUSES =====
        */

        /*
         ======= NEW =======
        */
        request: defaultTheme.palette.augmentColor({
            color: { main: '#BBC2CD', contrastText: '#FFFFFF' },
            name: 'request'
        }),
        inQueue: defaultTheme.palette.augmentColor({
            color: { main: '#1FADCB', contrastText: '#FFFFFF' },
            name: 'inQueue'
        }),


        /*
         ======= WAITING =======
        */
        onHold: defaultTheme.palette.augmentColor({
            color: { main: '#9099A3', contrastText: '#FFFFFF' },
            name: 'onHold'
        }),
        notifyCustomer: defaultTheme.palette.augmentColor({
            color: { main: '#525964', contrastText: '#FFFFFF' },
            name: 'notifyCustomer'
        }),
        /*
        ======= PARTS =======
        */
        orderParts: defaultTheme.palette.augmentColor({
            color: { main: '#FEB954', contrastText: '#000000' },
            name: 'orderParts'
        }),
        waitingForParts: defaultTheme.palette.augmentColor({
            color: { main: '#F59606', contrastText: '#FFFFFF' },
            name: 'waitingForParts'
        }),
        /*
        ======= In Progress =======
        */
        diagnose: defaultTheme.palette.augmentColor({
            color: { main: '#61DEAA', contrastText: '#000000' },
            name: 'diagnose'
        }),
        qualityAssurance: defaultTheme.palette.augmentColor({
            color: { main: '#05BB6F', contrastText: '#FFFFFF' },
            name: 'qualityAssurance'
        }),
        inRepair: defaultTheme.palette.augmentColor({
            color: { main: '#009758', contrastText: '#FFFFFF' },
            name: 'inRepair'
        }),
        /*
       ======= Done =======
       */
        readyForPickUp: defaultTheme.palette.augmentColor({
            color: { main: '#6099EE', contrastText: '#FFFFFF' },
            name: 'readyForPickUp'
        }),
        inDelivery: defaultTheme.palette.augmentColor({
            color: { main: '#1B6DE6', contrastText: '#FFFFFF' },
            name: 'inDelivery'
        }),
        deliveredToCustomer: defaultTheme.palette.augmentColor({
            color: { main: '#084FB8', contrastText: '#FFFFFF' },
            name: 'deliveredToCustomer'
        }),
        delivered: defaultTheme.palette.augmentColor({
            color: { main: '#084FB8', contrastText: '#FFFFFF' },
            name: 'delivered'
        }),
        receivedFromPartner: defaultTheme.palette.augmentColor({
            color: { main: '#2979ff', contrastText: '#FFFFFF' },
            name: 'receivedFromPartner'
        }),


        accepted: defaultTheme.palette.augmentColor({
            color: { main: '#41a11a' },
            name: 'accepted'
        }),
    },
    label: {
        textTransform: "none", // removes uppercase transformation
    },
    typography: {
        button: {
            textTransform: "none"
        }
    }
})

theme = createTheme(theme, {

    components: {
        MuiButton: {
            // variants: [
            //     {
            //         props: { variant: 'dotted' },
            //         style: {
            //             border: '1px dotted black',
            //             '& .MuiButton-startIcon': {
            //                 border: '1px dotted black',
            //                 borderRadius: '50%',
            //                 padding: '0px',
            //             },
            //         },
            //     },
            // ],
            defaultProps: {
                disableElevation: true
            },
            styleOverrides: {
                root: {
                    borderRadius: 10,
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                    // boxShadow: 'rgba(255, 255, 255, 0.2) 0px 7px 29px 0px',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 5
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: theme.palette.lightGrey.main
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    ".MuiFilledInput-root::after": {
                        border: 'none'
                    }
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    "& .MuiFilledInput-root": {
                        backgroundColor: theme.palette.lightBlue.main,
                        border: '1px solid',
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                        borderRadius: '4px'
                    },
                    "& .MuiSelect-filled::before": {
                        border: 'none',
                    },
                    "& .MuiSelect-filled::after": {
                        border: 'none',
                    },
                    "& .MuiFilledInput-root::before": {
                        border: 'none',
                    },
                    "& .MuiFilledInput-root::after": {
                        border: 'none',
                    },
                    "& .MuiFilledInput-root:hover::before": {
                        border: 'none',
                    },
                    "& .MuiFilledInput-root:hover": {
                        border: '1px solid',
                        backgroundColor: theme.palette.lightBlue,
                    },
                    "& .MuiInputLabel-filled": {
                        fontWeight: 700,
                        color: theme.palette.darkGrey.main
                    },
                    "& .MuiFilledInput-root:hover:not(.Mui-disabled):before": {
                        border: 'none',
                    },
                },
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    height: '100%', display: 'flex', flexDirection: 'row', padding: '5px'
                },
            }
        }
        ,
        MuiTextField: {
            styleOverrides: {
                root: {
                    "& .MuiFilledInput-root": {
                        backgroundColor: theme.palette.lightBlue.main,
                        border: '1px solid',
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                        borderRadius: '4px',
                        '&.Mui-focused': {
                            borderColor: 'black',
                            backgroundColor: theme.palette.lightBlue.main,
                            '& > .MuiSvgIcon-root': {
                                color: theme.palette.blue.main,
                            },
                        },
                    },


                    "& .MuiFilledInput-root::before": {
                        border: 'none',
                    },
                    "& .MuiFilledInput-root::after": {
                        border: 'none',
                    },
                    "& .MuiFilledInput-root:hover": {
                        border: '1px solid',
                        borderBottom: '1px solid',
                        backgroundColor: '#F1F9FD',
                    },
                    "& .MuiFilledInput-root:hover:not(.Mui-disabled):before": {
                        border: 'none',
                    },
                    "& .MuiInputLabel-filled": {
                        fontWeight: 700,
                        color: theme.palette.darkGrey.main,
                        "&.Mui-focused": {
                            color: theme.palette.blue.main,

                        }
                    },
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: '1.4rem'
                }
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: "2em",
                color: "yellow",
                backgroundColor: "red"
            }
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(33, 43, 70, 0.6)'
                },
                invisible: {
                    backgroundColor: 'transparent'
                }
            }
        },

    },
});
// theme.typography.p = {
//     fontSize: '1.75rem',
//     lineHeight: '1.4',
//     [theme.breakpoints.down('sm')]: {
//         fontSize: '1.5rem',
//     },
// };
theme.typography.h1 = {
    fontSize: 64,
    lineHeight: '80px',
    fontWeight: 800,

    [theme.breakpoints.between('lg', 'xl')]: {
        fontSize: 58,
        lineHeight: '72.5px',

    },
    [theme.breakpoints.between('md', 'lg')]: {
        fontSize: 52,
        lineHeight: '65px',

    },
    [theme.breakpoints.between('xs', 'md')]: {
        fontSize: 40,
        lineHeight: '50px',
    },
};
theme.typography.h2 = {
    fontSize: 48,
    lineHeight: '64px',
    fontWeight: 800,
    [theme.breakpoints.between('lg', 'xl')]: {
        fontSize: 44,
        lineHeight: '58.66px',

    },
    [theme.breakpoints.between('md', 'lg')]: {
        fontSize: 40,
        lineHeight: '53.33px',
    },
    [theme.breakpoints.between('xs', 'md')]: {
        fontSize: 32,
        lineHeight: '42.66px',
    },
};
theme.typography.h3 = {
    fontSize: 32,
    lineHeight: '48px',
    fontWeight: 700,
    [theme.breakpoints.between('lg', 'xl')]: {
        fontSize: 30,
        lineHeight: '45px',

    },
    [theme.breakpoints.between('md', 'lg')]: {
        fontSize: 26,
        lineHeight: '39px',
    },
    [theme.breakpoints.between('xs', 'md')]: {
        fontSize: 24,
        lineHeight: '36px',
    },

}
theme.typography.h4 = {
    fontSize: 24,
    lineHeight: '36px',
    fontWeight: 700,
    [theme.breakpoints.between('lg', 'xl')]: {
        fontSize: 24,
        lineHeight: '36px',

    },
    [theme.breakpoints.between('md', 'lg')]: {
        fontSize: 20,
        lineHeight: '30px',
    },
    [theme.breakpoints.between('xs', 'md')]: {
        fontSize: 20,
        lineHeight: '30px',

    },
};
theme.typography.h5 = {
    fontSize: 20,
    lineHeight: '30px',
    fontWeight: 700,

    [theme.breakpoints.between('lg', 'xl')]: {
        fontSize: 20,
        lineHeight: '30px',
    },
    [theme.breakpoints.between('md', 'lg')]: {
        fontSize: 19,
        lineHeight: '28.5px',
    },
    [theme.breakpoints.between('xs', 'md')]: {
        fontSize: 18,
        lineHeight: '27px',
    },
}
theme.typography.h6 = {
    fontSize: 18,
    lineHeight: '28px',
    fontWeight: 700,

    [theme.breakpoints.between('lg', 'xl')]: {
        fontSize: 18,
        lineHeight: '28px',
    },
    [theme.breakpoints.between('md', 'lg')]: {
        fontSize: 18,
        lineHeight: '28px',
    },
    [theme.breakpoints.between('xs', 'md')]: {
        fontSize: 17,
        lineHeight: '26.4444px',
    },
}
theme.typography.caption = {
    fontSize: '0.8rem',
    lineHeight: '18px',
    fontWeight: 400,

}
theme.typography.overline = {
    fontSize: '0.8rem',
    lineHeight: '18px',
    fontWeight: 700,
}
theme.typography.subtitle1 = {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 600,
}
theme.typography.subtitle2 = {
    fontSize: 14,
    lineHeight: '22px',
    fontWeight: 600,
}
// theme.typography.body1 = {
//     fontSize: 16,
//     lineHeight: '24px',
//     fontWeight: 400,

// }

// theme.typography.body2 = {
//     fontSize: 14,
//     lineHeight: '22px',
//     fontWeight: 400,

// }


// theme.typography.button = {
//     fontSize: 14,
//     lineHeight: '24px',
//     fontWeight: 700,
// }
export default theme;