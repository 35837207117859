import React, { useState } from "react";
import Notification from "../Notification"
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import NotificationsActiveIcon from '@mui/icons-material/HandymanOutlined';
import { useNavigate } from "react-router-dom";


import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import { useTranslation } from "react-i18next";
function ServiceAddedNotification({ notification, handleClose }) {

    let navigate = useNavigate();
    const { t } = useTranslation();

    const handleNotificationClick = () => {

        if (!notification.repairOrder) return;
        navigate(`/editEntry/${notification.repairOrder.id}`, { replace: true });
        handleClose();
    };
    const getBody = () => {
        let body = "";
        if (notification.repairOrder?.outsourcedTo) {
            const { outsourcedTo } = notification.repairOrder;
            body = `${outsourcedTo.organization.name} ${t("added a new service")} ${notification.repairOrder.brand} ${notification.repairOrder.model}`
        }
        return body;
    }

    const modifiedTitle = () => {
        let title = t("New service");

        let orgId = notification.repairOrder?.organizationId;
        let counter = notification.repairOrder?.organizationCounter;

        if (notification.repairOrder?.outsourcedFrom) {
            const { outsourcedFrom } = notification.repairOrder;
            orgId = outsourcedFrom.organizationId;
            counter = outsourcedFrom.organizationCounter;
            if (orgId && counter) {
                return <Box component={'span'}>{title} <Box component={'span'} color='primary.main'>{orgId}#{counter}</Box> </Box>;
            }
        } else if (orgId && counter) {
            return <Box component={'span'}>{title} <Box component={'span'} color='primary.main'>{orgId}#{counter}</Box> </Box>;
        }

        return <Box component={'span'}>{title}</Box>;
    };

    return (
        <Notification
            notification={notification}
            id={notification.id}
            isRead={notification.isRead}
            avatar={<Avatar sx={{ bgcolor: 'secondary' }}><HandymanOutlinedIcon /></Avatar>}
            body={getBody()}
            title={modifiedTitle()}
            handleNotificationClick={handleNotificationClick}
        />
    );
}

export default ServiceAddedNotification;