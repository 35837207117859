import {
    Button,

    Popover,

} from "@mui/material";

import { DateRangePicker, defaultInputRanges, createStaticRanges } from "react-date-range";
import * as rdrLocales from 'react-date-range/dist/locale';


//ICONS
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { useTranslation } from "react-i18next";
import { useState } from "react";




const CalendarButton = ({ selectionRange, setSelectionRange }) => {
    const { t, i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const calendarOpen = Boolean(anchorEl);
    const calendarId = calendarOpen ? 'calendar-popover' : undefined;

    let dayjs = require('dayjs')
    const handleCalendarOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCalendarClose = () => {
        setAnchorEl(null);
    };

    const handleCalendarSelect = async (e) => {
        if (e.key === "selection") {
            setSelectionRange(e.selection);
        } else {
            e.range1.endDate = dayjs(e.range1.endDate).endOf('day').toDate();
            setSelectionRange(e.range1);
        }
    }


    const predefinedRanges = createStaticRanges([
        {
            label: t('date.today'),
            range: () => {
                return {
                    startDate: dayjs().startOf('day').toDate(),
                    endDate: dayjs().endOf('day').toDate(),
                };
            },
        },
        {
            label: t('date.yesterday'),
            range: () => {
                return {
                    startDate: dayjs().subtract(1, 'day').startOf('day').toDate(),
                    endDate: dayjs().subtract(1, 'day').endOf('day').toDate(),
                };
            },
        },
        {
            label: t('date.thisWeek'),
            range: () => {
                return {
                    startDate: dayjs().startOf('week').toDate(),
                    endDate: dayjs().endOf('week').toDate(),
                };
            },
        },
        {
            label: t('Last 30 days'),
            range: () => {
                return {
                    startDate: dayjs().subtract(30, 'day').toDate(),
                    endDate: dayjs().endOf('day').toDate(),
                };
            },
        },
        {
            label: t('Last 90 days'),
            range: () => {
                return {
                    startDate: dayjs().subtract(90, 'day').toDate(),
                    endDate: dayjs().endOf('day').toDate(),
                };
            },
        },
        {
            label: t('This month'),
            range: () => {
                return {
                    startDate: dayjs().startOf('month').toDate(),
                    endDate: dayjs().endOf('month').toDate(),
                };
            },
        },
        {
            label: t('Last month'),
            range: () => {
                return {
                    startDate: dayjs().subtract(1, 'month').startOf('month').toDate(),
                    endDate: dayjs().subtract(1, 'month').endOf('month').toDate(),
                };
            },
        },
        {
            label: t('This year'),
            range: () => {
                return {
                    startDate: dayjs().startOf('year').toDate(),
                    endDate: dayjs().endOf('year').toDate()
                }
            },
        },
        {
            label: t('Last year'),
            range: () => {
                return {
                    startDate: dayjs().startOf('year').subtract(1, 'year').toDate(),
                    endDate: dayjs().endOf('year').subtract(1, 'year').toDate()
                };
            },
        },

    ]);
    return <>
        <Button  startIcon={<CalendarMonthOutlinedIcon />} variant={'outlined'} color={'black'} onClick={handleCalendarOpen}>{dayjs(selectionRange.startDate).format('DD.MMM.YYYY')} - {dayjs(selectionRange.endDate).format('DD.MMM.YYYY')}</Button>
        <Popover
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            anchorEl={anchorEl} open={calendarOpen}
            id={calendarId} onClose={handleCalendarClose}>
            <DateRangePicker
                locale={rdrLocales[i18n.language]}

                // minDate={dayjs().startOf('month').toDate()}
                // maxDate={dayjs().endOf('month').toDate()}
                ranges={[selectionRange]}
                staticRanges={predefinedRanges}
                onChange={handleCalendarSelect}
                inputRanges={[{
                    ...defaultInputRanges[0],
                    label: t('Days until now')
                }]}
            />
        </Popover>
    </>
}

export default CalendarButton;