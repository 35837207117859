import * as React from 'react';
import { useSnackbar } from 'notistack';
import axios from "../../axios-config";

import {
    Box, List, ListItem, ListItemButton,
    ListItemIcon, ListItemText, Checkbox,
    IconButton, Typography, Stack, Divider, Popover,
    Button, ButtonGroup, circularProgressClasses
} from '@mui/material';
import { styled } from '@mui/system';
import BaseItemList from '../../components/ItemList/BaseItemList';
import EditServiceDialog from './Dialogs/EditServiceDialog';

//Icons
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import EditPaymentDialog from './Dialogs/EditPaymentDialog';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { formatCurrency } from '../../utils/currency';

const PaymentItemList = ({ item, onServiceDelete, setPayments, fromOutsource }) => {

    const currency = useSelector((state) => state.auth.currency);

    let dayjs = require('dayjs')
    const { t } = useTranslation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [mouseHover, setMouseHover] = React.useState(false);
    const [editDialog, setEditDialog] = React.useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleDelete = async (event) => {
        event.stopPropagation();
        try {
            const response = await axios.delete(`repair-orders/payments/${item.id}`);
            if (response.status !== 202) {
                throw response.status;
            }
            if (response.status === 202) {
                setPayments(response.data);
                enqueueSnackbar(`Payment deleted succesfully!`, { variant: "success" });

            }
        } catch (err) {
            console.log(err)
            enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        }
    }

    const handleCloseDialog = () => {
        setEditDialog(false);
    }

    const paymentMethods = {
        0: 'Cash',
        1: 'Card',
        2: 'Transfer'
    }

    const StyledButton = styled(Button, {
        shouldForwardProp: (prop) => prop !== 'positive',
    })(({ positive, theme }) => ({
        backgroundColor: 'white',
        whiteSpace: 'nowrap',
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 4px 12px',
        ...(positive && {
            color: theme.palette.green.main,
        }),
        '&:hover': {
            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 4px 12px',
            backgroundColor: 'white',
        }
    }));

    return (<>
        <BaseItemList
            sx={{
                border: 'solid 1px lightgrey',
                
                '&:hover': {
                    border: fromOutsource ? '' : 'solid 1px',
                    borderColor: fromOutsource ? '' : 'black',
                    cursor: fromOutsource ? 'default' : 'pointer'
                }
            }}
            onMouseEnter={() => setMouseHover(true)}
            onMouseLeave={() => setMouseHover(false)}
            itemIcon={<PaymentsOutlinedIcon color='darkGrey' />}
            onClick={() => fromOutsource ? null : setEditDialog(true)}
            mainStack=
            {<>

                <Typography >{t(paymentMethods[item.paymentMethod])}</Typography>
                <Typography flexGrow={1} variant={'caption'}>({item.paymentType === 'in' ? "Pay in" : "Pay out"})</Typography>
                <Typography variant='body' fontWeight={500} noWrap color={item.paymentType === 'out' ? "red.main" : "green.main"}>{formatCurrency(item.amount, currency)} </Typography>

            </>}
            footerStack={
                <>
                    <div>{dayjs(item.updatedAt).format('DD.MMM.YYYY HH:mm')}</div>
                </>}
            onDeleteButtonClick={fromOutsource ? null : handleDelete}
        />
        <EditPaymentDialog payment={item} dialogOpen={editDialog} handleDialogClose={handleCloseDialog} onEdit={(payments) => setPayments(payments)} />
    </>
    )
}

export default PaymentItemList;