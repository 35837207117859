import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from 'react-redux';
import { searchDialogActions } from '../../store/search'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Divider from '@mui/material/Divider';


import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Box from '@mui/material/Box';



import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

//Icons
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { fontSize } from '@mui/system';
import { Stack, Typography } from '@mui/material';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export default function SearchDialog(props) {


    const dispatch = useDispatch();
    const open = useSelector(state => state.searchDialog.showSearchDialog)

    const toggleDialog = () => {
        dispatch(searchDialogActions.toggleModal())
    }
    return (
        <div>
            <Dialog
                fullWidth
                open={open}
                onClose={toggleDialog}
            >
                <DialogTitle id="alert-dialog-title">
                    {/* <TextField

                        size='medium'
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: <InputAdornment position="start">
                                <SearchOutlinedIcon color='primary' sx={{ fontSize: 30 }} /></InputAdornment>,
                            style: { fontSize: 22 }
                        }}
                        id="standard-basic"
                        fullWidth
                        placeholder="Search..."
                        variant="standard" /> */}
                    <Typography>Search</Typography>

                </DialogTitle>
                <Divider />
                <DialogContent>
                    <FormControl fullWidth>
                        <Stack spacing={2}>
                            <TextField
                                fullWidth
                                id="standard-basic"
                                label='Device'
                                variant="outlined" />
                            <Stack direction='row' spacing={2}>
                                <TextField
                                    fullWidth
                                    id="standard-basic"
                                    label='Entry ID'
                                    variant="outlined" />
                                <TextField
                                    fullWidth
                                    id="standard-basic"
                                    label='IMEI'
                                    variant="outlined" />

                            </Stack>
                            <Stack direction='row' spacing={2}>
                                <Autocomplete
                                    fullWidth
                                    multiple
                                    id="checkboxes-tags-demo"
                                    options={top100Films}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.title}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.title}
                                        </li>
                                    )}

                                    renderInput={(params) => (
                                        <TextField {...params} label="Status" placeholder="Status" />
                                    )}
                                />
                                <Autocomplete
                                    fullWidth
                                    multiple
                                    id="checkboxes-tags-demo"
                                    options={top100Films}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.title}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.title}
                                        </li>
                                    )}

                                    renderInput={(params) => (
                                        <TextField {...params} label="Resolution" placeholder="Resolution" />
                                    )}
                                />
                            </Stack>
                            <FormLabel component="legend">Date</FormLabel>
                        </Stack>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleDialog}>Disagree</Button>
                    <Button onClick={toggleDialog} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const top100Films = [
    { title: 'Repaired', year: 1994 },
    { title: 'Declined', year: 1972 },
    { title: 'Accepted', year: 1974 },
   
];