import { useTranslation } from 'react-i18next';

export function useCurrencyFormatter() {
    const { i18n } = useTranslation();

    function formatCurrency(amount, currency) {
        const format = new Intl.NumberFormat(`es-${i18n.language}`, {
            style: 'currency',
            currency: currency,
        });

        return format.format(amount);
    }

    return { formatCurrency };
}