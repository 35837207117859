import React, { useState } from "react";
import Notification from "../Notification"
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function CommentNotification({ notification, handleClose }) {

    let navigate = useNavigate();
    const { t } = useTranslation();

    const handleNotificationClick = () => {
        if (!notification.repairOrder) return;
        navigate(`/editEntry/${notification.repairOrder.id}`, { replace: true });
        handleClose();
    };

    const modifiedTitle = () => {
        const orgId = notification.repairOrder?.outsourcedFrom?.organizationId || notification.repairOrder?.organizationId;
        const counter = notification.repairOrder?.outsourcedFrom?.organizationCounter || notification.repairOrder?.organizationCounter;

        if (orgId && counter) {
            return <Box component={'span'}>{t("New comment from partner")}<Box component={'span'} color='primary.main'> {orgId}#{counter}</Box> </Box>;
        }

        return <Box component={'span'}>{t("New comment from partner")}</Box>;
    };

    const getBody = () => {
        if (notification.repairOrder?.outsourcedFrom) {
            return notification.repairOrder.outsourcedFrom.organization.name;
        }
        if (notification.repairOrder?.outsourcedTo) {
            return notification.repairOrder.outsourcedTo.organization.name;
        }
        return null;
    };

    return (
        <Notification
            notification={notification}
            id={notification.id}
            isRead={notification.isRead}
            avatar={<Avatar sx={{ bgcolor: 'orange.main' }}><SmsOutlinedIcon /></Avatar>}
            body={getBody()}
            title={modifiedTitle()}
            handleNotificationClick={handleNotificationClick}
        />
    );
}

export default CommentNotification;