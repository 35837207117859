import React, { useState } from "react";
import PatternLock from "react-17-pattern-lock";
import "./PatternStyle.css";
import {
    OutlinedInput,
    FormControl,
    InputAdornment,
    Dialog,
    DialogTitle,
    Grid,
    Button,
    TextField,
    IconButton,
    DialogActions,
    Stack,
    Chip,
} from "@mui/material";
import PatternIcon from "@mui/icons-material/Pattern";
import PatternPopover from "./PatternPopover";
import { Delete, HighlightOffOutlined, Refresh } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

function PasswordTextField({ value, setValue, isPasswordPattern, setIsPasswordPattern, onBlur, onRemovePattern }) {
    const [path, setPath] = useState([]);
    const { t } = useTranslation();
    const [disabled, setDisabled] = useState(false);
    // const [value, setValue] = useState("");
    // const [isUsingPattern, setIsUsingPattern] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    const reset = () => {
        setDisabled(false);
        setValue("");
        setPath([]);
        setIsPasswordPattern(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setDisabled(false);
        setPath([]);
        if (onBlur) {
            onBlur();
        }
    };

    const removePattern = () => {
        setValue("");
        setIsPasswordPattern(false);
        if (onRemovePattern) {
            onRemovePattern();
        }
    }

    const handleOnChange = (newPath) => {
        setPath(newPath)
    }

    return (
        <>
            {/* <FormControl sx={{ m: 1, width: "25ch" }} variant="filled"> */}
            {/* {value.length > 0 && isPasswordPattern ? (
                <Stack alignItems={"flex-start"}>
                    <PatternPopover
                        value={value}
                        onDelete={setValue}
                        valueSetUsingPattern={isPasswordPattern}
                    />

                </Stack>
            ) : ( */}
            <TextField
                variant="filled"
                size="small"
                fullWidth

                label={` ${isPasswordPattern ? t("Pattern") : t("Password")}`}
                inputProps={{ inputMode: "numeric" }}
                id="outlined-adornment-weight"
                value={isPasswordPattern ? "" : value}
                onBlur={() => { if (onBlur) { onBlur() } }}
                onChange={(e) => {
                    // if (isNaN(e.target.value)) {
                    //     return;
                    // }
                    setIsPasswordPattern(false);
                    setValue(e.target.value);
                }}
                InputProps={{
                    startAdornment: (<>
                        {isPasswordPattern && <InputAdornment position="start"
                            onMouseEnter={(e) => {
                                if (isPasswordPattern) {
                                    setAnchorEl(e.currentTarget);
                                }
                            }}
                            onMouseLeave={() => {
                                setAnchorEl(null);
                            }}>
                            <PatternIcon color="primary" />

                        </InputAdornment>}
                        {isPasswordPattern && <InputAdornment position="start">
                            {isPasswordPattern ? value : ""}
                        </InputAdornment>}
                    </>
                    ),
                    endAdornment: (<>
                        {!isPasswordPattern && <InputAdornment position="end">
                            <IconButton color={isPasswordPattern ? "primary" : "inherit"} onClick={handleClickOpen}>
                                <PatternIcon />
                            </IconButton>
                        </InputAdornment>
                        }
                        {isPasswordPattern && <InputAdornment position="end">
                            <IconButton onClick={removePattern}>
                                <HighlightOffOutlined />
                            </IconButton>
                        </InputAdornment>
                        }
                    </>
                    ),
                }}
                aria-describedby="outlined-helper-text"
            />
            {/* )} */}
            {/* </FormControl> */}
            <PatternPopover
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                value={value}
                valueSetUsingPattern={isPasswordPattern} />
            <Dialog
                onClose={handleClose}
                open={open}
                fullWidth={true}
                maxWidth={"sm"}
            >
                <DialogTitle>
                    Draw Your Pattern
                </DialogTitle>
                <Stack direction={'column'} alignItems={'center'}>
                    <p> {path.length == 0 && "-"} {path.map(index => index + 1).join("")}</p>
                </Stack>
                <PatternLock
                    path={path}
                    width={280}
                    size={3}
                    disabled={disabled}
                    onChange={handleOnChange}
                    onFinish={() => {
                        if (path.length < 3) {
                            setPath([]);
                            return;
                        }
                        setDisabled(true);
                        setValue(path.map(index => index + 1).join(""));
                        setIsPasswordPattern(true);
                    }}
                    style={{
                        margin: "0 auto",
                    }}
                />
                <Stack direction={'column'} alignItems={'center'}>
                    <Button startIcon={<Refresh />} onClick={reset}>
                        Reset
                    </Button>
                </Stack>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        size="small"
                        sx={{ borderRadius: "4px" }}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>


        </>
    );
}

export default PasswordTextField;
