import { useState, useCallback, useRef } from 'react';
import TextField from '@mui/material/TextField';
import { debounce, max, min } from 'lodash';
import axios from '../axios-config';
import { Box, CircularProgress, InputAdornment, LinearProgress, Stack } from '@mui/material';



// const debouncedOnChange = debounce(async (url, dataKey, value, onPatchCompleted,) => {
//     try {
//         const response = await axios.patch(url, { [dataKey]: value });
//         onPatchCompleted(true, response.data); // Invoke the callback function on successful PATCH
//     } catch (error) {
//         console.error(error);
//         onPatchCompleted(false, null); // Invoke the callback function on failed PATCH
//     }
// }, 1000);

const DebouncedTextField = ({ label, dataKey, url, value, setValue, numericOnly = false, integersOnly = false, onPatchCompleted, onBlur, minValue, maxValue, minLength = 0, onBlurOnly = false, loadingType = "circularEnd", ...other }) => {
    const [isLoading, setIsLoading] = useState(false);

    const debouncedOnChange = useRef(debounce(async (url, dataKey, value, onPatchCompleted) => {
        try {
            setIsLoading(true);
            const response = await axios.patch(url, { [dataKey]: value });
            onPatchCompleted(true, response.data);
        } catch (error) {
            console.error(error);
            onPatchCompleted(false, null);
        } finally {
            setIsLoading(false);
        }
    }, 1000));

    const onChange = useCallback(
        (value) => {
            debouncedOnChange.current(url, dataKey, value, onPatchCompleted);
        },
        [url, dataKey, onPatchCompleted]
    );


    const handleChange = (e) => {
        let inputValue = e.target.value;

        if (numericOnly || integersOnly) {
            // Allow only digits and optionally a decimal point
            const isValid = /^[\d.]*$/.test(inputValue);
            if (!isValid) return;

            // Check minimum value for numeric input
            if (minValue && parseFloat(inputValue) < minValue) return;
            if (maxValue && parseFloat(inputValue) > maxValue) {
                inputValue = maxValue.toString();
            }

            // Check if integersOnly and the value contains a decimal point
            if (integersOnly && inputValue.includes('.')) return;
        }
        setValue(inputValue); // Use the setValue passed from parent

        if (inputValue.length >= minLength) {
            if ((numericOnly || integersOnly) && minValue && inputValue === '') {
                onChange(minValue);

                return;

            }
            if(onBlurOnly) return;
            onChange(inputValue);
        }
    };
    const handleBlur = (e) => {
        if (numericOnly && !integersOnly) {
            if (value.length > 0) {
                setValue(parseFloat(value).toFixed(2));
            }
        }
        if(onBlurOnly){
            onChange(value);
        }
        debouncedOnChange.current.flush(); // Force the execution of the debounced function

        if (onBlur) {
            onBlur();
        }
    };

    if (loadingType == "linearBottom" || loadingType == "linearTop") return (
        <Box >
            {isLoading && loadingType == "linearTop" ? <LinearProgress color="secondary" /> : null}
            <TextField
                label={label}
                variant="filled"
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                {...other}
            />
            {isLoading && loadingType == "linearBottom" ? <LinearProgress color="secondary" /> : null}
        </Box>
    )

    return (
        <TextField
            label={label}
            variant="filled"
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            {...other}
            InputProps={{
                ...other.InputProps,
                endAdornment: (
                    <InputAdornment position="end">
                        {isLoading && loadingType == "circularEnd" ? <CircularProgress size={20} color="secondary" /> : other.InputProps?.endAdornment}
                    </InputAdornment>
                ),
                startAdornment: (
                    <InputAdornment position="start">
                        {isLoading && loadingType == "circularStart" ? <CircularProgress size={20} color="secondary" /> : other.InputProps?.startAdornment}
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default DebouncedTextField;