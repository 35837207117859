import React, { useMemo } from "react";
import { Box, Typography, Stack, Checkbox, Snackbar, IconButton, Tooltip, Select, FormControl, InputLabel, MenuItem, LinearProgress, } from "@mui/material";

import { useState, useEffect, memo } from 'react';
import { FixedSizeList, areEqual } from "react-window";

import "./data-table.css";
import axios from "axios-config";

import { useSelector, useDispatch } from 'react-redux';
import { dataTableActions } from "../../store/data-table";
import { useTranslation } from "react-i18next";

import { useSnackbar } from "notistack";
import { PaymentsOutlined } from "@mui/icons-material";
import ConfirmationDialog from "common/ConfirmDialog";
import { isEqual, differenceWith, set } from "lodash";
import ConsoleHelper from "utils/ConsoleHelper";
import { useQuery, useQueryClient } from 'react-query';
import Row from "./Row";
import BulkStatusUpdate from "./BulkStatusUpdate/BulkStatusUpdate";
import useBulkMarkAsPaid from "hooks/mutations/orders/useBulkMarkAsPaid";

const WindowTable = ({ filters, ableToSelect, setAbleToSelect, searchInFields }) => {

    const [handlingRequests, setHandlingRequests] = useState([]);
    const bulkMarkAsPaid = useBulkMarkAsPaid();

    const [markAsPaidDialogOpen, setMarkAsPaidDialogOpen] = useState(false);
    const [height, setHeight] = useState(window.innerHeight - 190); // used for adjusting virtual list height

    const dispatch = useDispatch();
    // const repairOrders = useSelector(state => state.dataTable.repairOrders)
    const unpaidCheck = useSelector(state => state.dataTable.unpaidCheck)
    const dueToPartnerCheck = useSelector(state => state.dataTable.dueToPartnerCheck)
    const unpaidIds = useSelector(state => state.dataTable.unpaidIds)
    const dueToPartnerIds = useSelector(state => state.dataTable.dueTopartnerIds)
    const statusesLoaded = useSelector(state => state.dataTable.statusesLoaded)
    const queryClient = useQueryClient();
    const serverDown = useSelector((state) => state.app.serverDown);

    const { enqueueSnackbar } = useSnackbar();

    const setDataTable = (payload) => {
        dispatch(dataTableActions.setDataTable(payload))
    }


    const search = useSelector(state => state.dataTable.search);
    const statusesSort = useSelector(state => state.dataTable.statuses)

    const partnersFilter = useSelector(state => state.dataTable.partnersFilter)
    const urgenciesToggle = useSelector(state => state.dataTable.urgenciesToggle)

    const [selectedOrderIds, setSelectedOrderIds] = useState([]);
    const [selectedOrders, setSelectedOrders] = useState([]);


    const [fetchFullData, setFetchFullData] = useState(false);

    const handleRequestStart = (id) => {
        setHandlingRequests((prev) => [...prev, id]); // Add ID to the array
    };

    const handleRequestEnd = (id) => {
        setHandlingRequests((prev) => prev.filter((reqId) => reqId !== id)); // Remove ID from array
        //invalidate the query to refetch the data
        queryClient.invalidateQueries('repairOrders');
    };

    const isAnyRequestHandling = handlingRequests.length > 0;


    // useEffect(() => {
    //     setDebouncedSearch(search);
    // }, [search]);


    const { t } = useTranslation();
    // 
    // const memoStatusesSort = useMemo(() => statusesSort, [statusesSort]);

    const minWidthOrderColumn = { xs: '230px', md: "225px", lg: "240px" };
    const maxWidthOrderColumn = { xs: '230px', md: "225px", lg: "240px" };

    const minWidthDeviceColumn = { xs: '150px', md: "150px", lg: "200px", xl: "250px" };
    const maxWidthDeviceColumn = { xs: '150px', md: "150px", lg: "200px", xl: "250px" };

    const minWidthMalfunctionColumn = { xs: '200px', md: "200px", lg: "250px", xl: "300px" };
    const maxWidthMalfunctionColumn = { xs: '200px', md: "200px", lg: "250px", xl: "300px" };

    const minWidthStatusColumn = { xs: '170px', md: "170px", lg: "210px" };
    const maxWidthStatusColumn = { xs: '170px', md: "170px", lg: "210px" };

    const minWidthLastCommentColumn = { xs: '200px', md: "130px", lg: "150px", xl: "250px" };
    const maxWidthLastCommentColumn = { xs: '200px', md: "130px", lg: "150px", xl: "250px" };
    const [paymentMethod, setPaymentMethod] = useState(0);


    useEffect(() => {
        const handleResize = () => {
            setHeight(window.innerHeight - 190);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (search) {
            // If there is a search input and full data hasn't been fetched, force a refetch
            if (!fetchFullData) {

                queryClient.invalidateQueries('repairOrders'); // Force a refetch
                setFetchFullData(true); // Mark that full data has been fetched
            }
        } else {
            // If the search is cleared, reset the fetchedFullData flag
            setFetchFullData(false); // Reset to allow normal fetching
        }
    }, [search, fetchFullData, queryClient]);


    const fetchData = async () => {
        try {

            const queryParams = {
                search: search,
                statuses: JSON.stringify(statusesSort),
                unpaidCheck: unpaidCheck || dueToPartnerCheck ? true : null,
                // dueToPartnerCheck: dueToPartnerCheck,
                unpaidIds: unpaidCheck ? unpaidIds : dueToPartnerCheck ? dueToPartnerIds : null,
            }
            const response = await axios.get(`/repair-orders`, {
                params: queryParams
            });
            if (response.status === 200) {
                let data = response.data;
                return data;
            }
            return null;
        } catch (err) {
            console.log(err);
        }
    }
    const { data: repairOrders, isLoading, isError } = useQuery(
        ['repairOrders', statusesSort, unpaidIds, dueToPartnerIds, unpaidCheck, dueToPartnerCheck, fetchFullData],
        fetchData, // Fetch function
        {
            refetchInterval: 10000, // Poll every 10 seconds
            refetchOnWindowFocus: 'always',
            enabled: statusesLoaded && !isAnyRequestHandling && !serverDown, // Fetch normally or for search input
        }
    );

    // Handle side effect: Only update if data is different
    useEffect(() => {
        if (repairOrders && !isEqual(repairOrders, repairOrders)) {
            ConsoleHelper('data is different');
            setDataTable(repairOrders);
        }
    }, [repairOrders]);

    if (isError) {
        console.error("An error occurred while fetching the data");
    }


    // Update selectedOrders whenever selectedOrderIds or repairOrders change
    useEffect(() => {
        if (!repairOrders) return;
        if (selectedOrderIds.length === 0) {
            setSelectedOrders([]);
            return;
        }
        setSelectedOrders(repairOrders.filter(order => selectedOrderIds.includes(order.id)));
    }, [selectedOrderIds, repairOrders]);

    // Reset selectedOrders when ableToSelect or statusesSort change
    useEffect(() => {
        setSelectedOrderIds([]);
    }, [ableToSelect, statusesSort]);


    const handleSelectRow = (order) => {
        if (selectedOrderIds.includes(order.id)) {
            setSelectedOrderIds(selectedOrderIds.filter(id => id !== order.id));
        } else {
            if (selectedOrderIds.length >= 50) {
                // enqueueSnackbar(`Only 50 orders at once can be updated.`, { variant: "error" });
                return;
            }
            setSelectedOrderIds([...selectedOrderIds, order.id]);
        }
    };


    function escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }
    const sortByStatus = (filtered) => {
        // console.log('statusesSort')
        if (!Array.isArray(statusesSort)) {
            console.error('statusesSort is not an array');
            return filtered;
        }
        return filtered.sort((a, b) => {
            const aFind = statusesSort.find(status => status.id == a.repairOrderStatusId);
            if (!aFind) return 1;
            const aSort = statusesSort.indexOf(aFind);

            const bFind = statusesSort.find(status => status.id == b.repairOrderStatusId);
            if (!bFind) return -1;
            const bSort = statusesSort.indexOf(bFind);
            return aSort - bSort;
        });
    }

    const statusFilter = (filtered) => {
        if (!Array.isArray(statusesSort)) {
            console.error('statusesSort is not an array');
            return filtered;
        }

        return filtered.filter(order => {
            const status = statusesSort.find(status => status.id == order.repairOrderStatusId);
            return !status?.isHidden;
        });
    }

    const repairOrdersFiltered = useMemo(() => {
        if (!repairOrders || repairOrders.length === 0) return [];

        const searchVal = search.toLowerCase();
        let filtered = [...repairOrders];
        // console.log('filtered', filtered)
        // Apply sorting
        filtered = sortByStatus(filtered);
        // console.log('continue', filtered)

        // Apply filters
        if (urgenciesToggle) {
            filtered = filtered.filter(order => order.levelOfAttention);
        }

        if (dueToPartnerCheck) {
            filtered = filtered.filter(order => dueToPartnerIds.includes(order.id));
        }

        if (unpaidCheck) {
            filtered = filtered.filter(order => unpaidIds.includes(order.id));
        }

        if (partnersFilter.length > 0) {
            filtered = filtered.filter(order => {
                if (order.outsourcedFrom) {
                    return partnersFilter.some(element => element.id === order.outsourcedFrom.organization.id);
                }
                if (order.outsourcedTo) {
                    return partnersFilter.some(element => element.id === order.outsourcedTo.organization.id);
                }
                if (!order.outsourcedFrom && !order.outsourcedTo) {
                    return partnersFilter.some(element => element.id === order.organizationId);
                }
                return false;
            });
        }

        if (searchVal.length !== 0) {
            const searchTerms = searchVal
                .split(' ')
                .map(term => normalize(escapeRegExp(term)));

            filtered = filtered.filter(order => {
                const orderId = order.outsourcedFrom
                    ? `${order.outsourcedFrom.organization.id}#${order.outsourcedFrom.organizationCounter}`
                    : `${order.organizationId}#${order.organizationCounter}`;

                let searchStrings = [];

                if (searchInFields.includes("id")) {
                    searchStrings.push(orderId);
                }
                if (searchInFields.includes("device")) {
                    searchStrings.push(order.brand?.toLowerCase());
                    searchStrings.push(order.model?.toLowerCase());
                }
                if (searchInFields.includes("resolution")) {
                    searchStrings.push(t(order.resolutionType?.name)?.toLowerCase());
                }
                if (searchInFields.includes("imei")) {
                    searchStrings.push(order.imei?.toLowerCase());
                }
                if (searchInFields.includes("malfunction")) {
                    searchStrings.push(order.malfunction?.toLowerCase());
                }
                if (searchInFields.includes("customer")) {
                    searchStrings.push(order.customer?.name?.toLowerCase());
                    searchStrings.push(order.customer?.phone?.toLowerCase());
                }
                if (searchInFields.includes("note")) {
                    searchStrings.push(order.note?.toLowerCase());
                }
                if (searchInFields.includes("resolution")) {
                    searchStrings.push(t(order.resolutionType?.name)?.toLowerCase());
                }

                const normalizedStringToBeSearched = normalize(searchStrings.join(' '));

                return searchTerms.every(term =>
                    normalizedStringToBeSearched.includes(term)
                );
            });
        } else {
            if (!unpaidCheck && !dueToPartnerCheck) {
                filtered = statusFilter(filtered);
            }
        }

        return filtered;
    }, [
        repairOrders,
        statusesSort,
        search,
        urgenciesToggle,
        dueToPartnerCheck,
        unpaidCheck,
        partnersFilter,
        dueToPartnerIds,
        unpaidIds,
        sortByStatus
    ]);


    function normalize(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    const ordersCheck = () => {


        let repairOrdersFilteredLength = repairOrdersFiltered.length;
        let selectedOrdersLength = selectedOrders.length;

        if (repairOrdersFilteredLength > 0 && repairOrdersFilteredLength == selectedOrdersLength || selectedOrdersLength === 50) {
            return true;
        }
        return false;

    }
    const ordersIsIntermediate = () => {

        let repairOrdersFilteredLength = repairOrdersFiltered.length;
        let selectedOrdersLength = selectedOrders.length;

        if (repairOrdersFilteredLength > 0 && selectedOrdersLength > 0 && (repairOrdersFilteredLength !== selectedOrdersLength && selectedOrdersLength < 50)) {
            return true;
        }
        return false;
    }
    const handleCheckAll = () => {

        const isChecked = ordersCheck();
        if (isChecked) {
            // setSelectedOrders([]);
            setSelectedOrderIds([]);
        }
        else {
            setSelectedOrderIds(repairOrdersFiltered.slice(0, 50).map(order => order.id));
        }
    }



    const markSelectedAsPaid = async () => {

        setMarkAsPaidDialogOpen(false);
        bulkMarkAsPaid.mutate({ repairOrderIds: selectedOrderIds, paymentMethod: paymentMethod });

    }

    const location = useSelector((state) => state.dataTable.location);


    const itemData = useMemo(() => ({
        repairOrdersFiltered,
        ableToSelect,
        selectedOrders,
        handleSelectRow,
        handlingRequests,
        handleRequestStart,
        handleRequestEnd,
        rowStyle: {
            minWidthOrderColumn,
            maxWidthOrderColumn,
            minWidthDeviceColumn,
            maxWidthDeviceColumn,
            minWidthMalfunctionColumn,
            maxWidthMalfunctionColumn,
            minWidthStatusColumn,
            maxWidthStatusColumn,
            minWidthLastCommentColumn,
            maxWidthLastCommentColumn,
        }
    }), [repairOrders, repairOrdersFiltered, ableToSelect, selectedOrders, handlingRequests]);

    return (
        <>
            {isLoading ? <LinearProgress sx={{ width: '100%', zIndex: 1000 }} /> : <LinearProgress sx={{ width: '0%' }} />}
            < Stack
                direction={'row'}
                justifyContent
                className="header"
                alignItems={'center'}
            // divider={<Divider orientation="vertical" flexItem sx={{ height:'20px', alignSelf:'center' }} />}
            >
                <Stack spacing={1} className="orderColumn"
                    justifyContent={'center'}
                    direction={'row'}
                    alignItems={'center'}
                    minHeight={'42px'}
                    sx={{
                        minWidth: minWidthOrderColumn,
                        maxWidth: maxWidthOrderColumn,
                    }} >
                    {ableToSelect ?
                        <Checkbox
                            checked={ordersCheck()}
                            indeterminate={ordersIsIntermediate()}
                            onChange={handleCheckAll}
                        />
                        : null}
                    <Typography>
                        {t("Repair")}
                    </Typography>
                    <Typography>
                        •
                    </Typography>
                    <Typography>
                        {repairOrdersFiltered.length}
                    </Typography>
                </Stack>
                <Stack className="deviceColumn" justifyContent={'center'} direction={'row'}
                    sx={{
                        minWidth: minWidthDeviceColumn,
                        maxWidth: maxWidthDeviceColumn,
                    }}
                >{t("Device")}</Stack>
                <Stack className="malfunctionColumn" justifyContent={'center'} direction={'row'}
                    sx={{
                        minWidth: minWidthMalfunctionColumn,
                        maxWidth: maxWidthMalfunctionColumn,
                    }}
                >{t("Malfunction")}</Stack>
                {/* <Grid item>Comment</Grid> */}
                <Stack className="statusColumn" justifyContent={'center'} direction={'row'}
                    sx={{
                        minWidth: minWidthStatusColumn,
                        maxWidth: maxWidthStatusColumn,
                    }}>{t("Status")}</Stack>
                <Stack className="lastCommentColumn" justifyContent={'center'} direction={'row'}
                    sx={{
                        minWidth: minWidthLastCommentColumn,
                        maxWidth: maxWidthLastCommentColumn,
                    }}>{t("Last Comment")}</Stack>
                <Stack className="servicesColumn" flex={1} direction={'row'} justifyContent={'center'}>{t("Services")}</Stack>
            </Stack>
            {/* TODO: remove loading and make the "its a bit lonely" show only if they really don't have any order */}
            {isLoading && repairOrders?.length === 0 ? "Loading.." :

                repairOrders?.length === 0 ? <><Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="200px">
                    <Typography variant="h4" component="h2" gutterBottom>
                        {t("It's a bit lonely here...")}
                    </Typography>
                    <Typography variant="subtitle1" component="p" color="textSecondary" gutterBottom>
                        {t("Let's get to work! Add your first entry to fill this space.")}
                    </Typography>
                </Box></> :

                    repairOrdersFiltered.length === 0 && !isLoading ? <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="200px"><Typography variant="h4" component="h2" gutterBottom>{t("No data matching your filters.")}</Typography></Box> :
                        < FixedSizeList
                            className="no-scrollbars"
                            style={{ overflowX: 'hidden' }}
                            height={height}
                            initialScrollOffset={location * 170}
                            onItemsRendered={(props) => {
                                dispatch(dataTableActions.setTableLocation(props.visibleStartIndex));
                            }}
                            itemData={itemData}
                            itemCount={repairOrdersFiltered.length}
                            itemSize={170}
                            ableToSelect={ableToSelect}

                        >
                            {Row}
                        </FixedSizeList>
            }
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={selectedOrders?.length > 0}
            // onClose={handleClose}
            // message="I love snacks"
            >
                <Box sx={{ backgroundColor: "#252628", px: '20px', py: '10px', borderRadius: '50px' }}>
                    <Stack alignItems={'center'} color={'white'} direction={'row'} spacing={3} >
                        {selectedOrders?.length > 0 && <>
                            <Typography fontWeight={500} variant={'p'}>{selectedOrders.length}{selectedOrders.length == 50 ? "/50" : null} {selectedOrders.length > 1 ? t("orders selected") : t("order selected")}</Typography>
                            <BulkStatusUpdate selectedOrders={selectedOrders} repairOrders={repairOrders} />
                        </>
                        }
                        <Tooltip title={t('general.markAsPaid')} fontSize={15} placement='top'>
                            <IconButton color="inherit" aria-label="mark as paid" onClick={() => setMarkAsPaidDialogOpen(true)}>
                                <PaymentsOutlined />
                            </IconButton>
                        </Tooltip>

                    </Stack>
                </Box>
            </Snackbar>
            <ConfirmationDialog
                open={markAsPaidDialogOpen && selectedOrders?.length > 0}
                title={t("dialogs.markRepairsAsPaid.title")}
                message={<Stack spacing={2}>
                    <div>{t("dialogs.markRepairsAsPaid.message", { quantity: selectedOrders?.length })}</div>
                    <FormControl variant={'filled'} >
                        <InputLabel >{t('Payment method')}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            label="Payment method"
                            value={paymentMethod}
                            onChange={(event) => setPaymentMethod(event.target.value)}
                        >
                            <MenuItem value={0}>{t('Cash')}</MenuItem>
                            <MenuItem value={1}>{t('Card')}</MenuItem>
                            <MenuItem value={2}>{t('Transfer')}</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>}
                onConfirm={() => {
                    markSelectedAsPaid()
                }}
                onClose={() => setMarkAsPaidDialogOpen(false)}
            />
        </>
    );
}



export default memo(WindowTable);
