import * as React from 'react';
import { Box, Typography, Button, Stack } from '@mui/material';
import ServiceSkeleton from '../../images/service-image-skeleton.png'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

//ICONS

const ServicesEmptyPlaceholder = ({ addServiceButton }) => {

    const { t } = useTranslation();
    const [addSolutionDialog, setAddSolutionDialog] = useState(false);

    return (
        <>
            <Stack alignItems={'center'}>
                <Box >
                    <Box component={'img'} src={ServiceSkeleton} mb={'-20px'} height={100} />
                </Box>
                <Typography >
                    {t('There are no services')}.
                </Typography>

            </Stack>
        </>
    )
}

export default ServicesEmptyPlaceholder;