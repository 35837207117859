import axios from "axios";
import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from "@mui/material/CircularProgress";
import { connect } from "react-redux";

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const AsyncAutoComplete = (props) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState("");

  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }
    axios
      .get(props.fetchLink, {
        headers: {
          Authorization: props.token,
        },
      })
      .then((res) => {
        const data = res.data;
        if (active) {
          setOptions(
            data.map((value) => {
              return value;
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      freeSolo={props.freeSolo}
      inputValue={props.inputValue||inputValue}
      onChange={(event, value) => {
        var onSelectOption = props.onSelectOption || null;
        if (onSelectOption) onSelectOption(value);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        var onInputChange = props.onInputChange || null;
        if (onInputChange) onInputChange(newInputValue);
      }}
      size="small"
      id={props.id}
      style={{ width: "95%" }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => {
        let optionValues = "";
        let i = 0;
        props.optionsKey.keys.map((oKey) => {
          Object.keys(option).forEach((key) => {
            if (oKey.keyName === key) {
              optionValues += option[key].toString() + " ";
              optionValues = i > 0 ? " " + optionValues : optionValues;
            }
          });
        });
        return optionValues;
      }}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          size="small"
          label={props.label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};
export default connect(mapStateToProps)(AsyncAutoComplete);
