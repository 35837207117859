import React, { useEffect } from 'react';
import {
    Box,
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Divider,
    IconButton,
    InputAdornment,
    List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Stack, Switch, TextField, Tooltip, Typography
} from '@mui/material';
import { Send as SendIcon, Drafts as DraftsIcon, Inbox as InboxIcon, StarBorder, AddOutlined, DeleteOutline, EditOutlined, GridOnOutlined, ArrowForwardIosOutlined, ViewWeekOutlined, TableRowsRounded, ModeStandby, RadioButtonUnchecked, BlockOutlined, CancelOutlined, Close, WarningTwoTone } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import axios from "axios-config";

import ConsoleHelper from 'utils/ConsoleHelper';
import { set } from 'lodash';
import { zodResolver } from "@hookform/resolvers/zod";
import { string, z } from 'zod';
import { useForm, Controller } from "react-hook-form";
import EditLocationDialog from './EditLocationDialog';
import DroppableTest from './DroppableTest';
import DroppableContextTest from './DroppableContextTest';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import DeleteDialogWarningGeneric from 'common/DeleteDialogWarningGeneric';

const LocationsDialog = ({ open, onClose, warehouse, onLocationChange }) => {
    const { t } = useTranslation();
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedDeleteLocation, setSelectedDeleteLocation] = useState(null);
    const [selectedForParentChange, setSelectedForParentChange] = useState(null);
    const [selectedTargetParent, setSelectedTargetParent] = useState(null);
    const [isParentSelectModeEnabled, setIsParentSelectModeEnabled] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        setIsParentSelectModeEnabled(selectedForParentChange !== null && selectedForParentChange !== undefined);
    }, [selectedForParentChange]);


    // const [locations, setLocations] = useState([]);
    // const schema = z.object({
    //     email: string().email({ message: "Required" }),
    //     password: string().min(8, { message: "At least 8 characters." }),
    //     name: string().min(1, { message: "Required" }),
    //     organizationName: string().min(1, { message: "Required" }),
    //     invitationCode: string().optional(),
    //     termsAndConditions: z.boolean().refine(value => value === true, "You must accept the Terms and Conditions.")
    //   })
    // const { register, handleSubmit, control, reset, watch, formState: { isSubmitting, isDirty, isValid, errors } } = useForm({ resolver: zodResolver(schema) });

    useEffect(() => {
        // Fetch locations
        fetchLocations();
    }, [open]);

    const handleLocationSelect = (location) => {
        // console.log('selected location', location);
        setSelectedLocation(location);
    }

    const handleLocationDeleteSelect = (location) => {
        setSelectedDeleteLocation(location);
    }

    const fetchLocations = async () => {
        // console.log('fetching locations');
        try {
            // Make API call or database query to fetch inventory data
            const response = await axios.get(`/inventory/warehouse/${warehouse?.id}/locations`);
            // console.log(response);
            if (response.status === 200) {

                const data = response.data;
                ConsoleHelper(data);
                setLocations(data);
            }
        } catch (error) {
            console.error('Error fetching inventory data:', error);
        }
    }

    const handleDialogClose = () => {
        setSelectedLocation(null);
        setSelectedDeleteLocation(null);
        setSelectedForParentChange(null);
        setIsParentSelectModeEnabled(false);
        onClose();

    }


    const handleLocationDelete = async () => {
        try {
            const response = await axios.delete(`/inventory/location/${selectedDeleteLocation.id}`);
            if (response.status === 200) {
                fetchLocations();
                setSelectedDeleteLocation(null);
                if (onLocationChange) {
                    onLocationChange();
                }
            }
        }
        catch (err) {
            // snackbar error
            if (err.response.data.message) {
                enqueueSnackbar(err.response.data.message, { variant: 'error' });
            } else {
                enqueueSnackbar('Error deleting location', { variant: 'error' });
            }
            ConsoleHelper(err);
        }
    }


    const generateNestedList = (locations, parentId = null, level = 0) => {
        return locations
            .filter(location => Number(location.parentId || null) === Number(parentId))
            .sort((a, b) => a.name.localeCompare(b.name)) // Add this line
            .map(location => {
                const children = generateNestedList(locations, location.id, level + 1);
                return (
                    <React.Fragment key={location.id}>
                        <ListItemLocation location={location}
                            locations={locations}
                            handleAddNewLocation={handleAddNewLocation}
                            handleLocationSelect={handleLocationSelect}
                            handleLocationDeleteSelect={handleLocationDeleteSelect}
                            selectedForParentChange={selectedForParentChange}
                            setSelectedForParentChange={setSelectedForParentChange}
                            isParentSelectModeEnabled={isParentSelectModeEnabled}
                            setSelectedTargetParent={setSelectedTargetParent}
                        />
                        {children.length > 0 && (
                            <>
                                <Stack direction={'row'}>
                                    <Box sx={{ pr: 4 }} color={'transparent'}>-</Box>
                                    <List
                                        component="div"
                                        disablePadding
                                        sx={{
                                            width: "100%",
                                            // pl: 4,
                                            bgcolor: "background.paper",
                                            borderLeft: 1,
                                            borderColor: 'divider'
                                        }}
                                    >
                                        {children}
                                    </List>
                                </Stack>
                            </>
                        )}
                    </React.Fragment>
                );
            });
    };

    const handleAddNewLocation = () => {
        fetchLocations();
        if (onLocationChange) {
            onLocationChange();
        }
    }

    const handleLocationParentChange = async (e) => {
        e.preventDefault();
        try {
            setLocations(locations.map(location => {
                if (location.id === selectedForParentChange.id) {
                    return {
                        ...location,
                        parentId: selectedTargetParent.id
                    }
                } else {
                    return location;
                }
            }));

            setSelectedForParentChange(null);
            setSelectedTargetParent(null);
            const response = await axios.patch(`/inventory/location/${selectedForParentChange.id}/parent/${selectedTargetParent.id}`, {
                parentId: selectedTargetParent.id
            });
            if (onLocationChange) {
                onLocationChange();
            }

        }
        catch (err) {
            ConsoleHelper(err);
        }
    }

    return (
        <>
            <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth={'sm'}>
                <DialogTitle variant='h4'>
                    {t("Manage locations")}
                    <DialogContentText>
                        {isParentSelectModeEnabled && <>
                            <Typography variant='body'>Select a new parent for <strong>{selectedForParentChange?.name}</strong></Typography>
                        </>
                        }

                    </DialogContentText>
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        {locations.length === 0 && <>
                            <Stack alignItems={'left'} spacing={2}>
                                {/* <Stack alignItems={'left'}>
                                        <Typography variant='h6'>{t("There are no location")}</Typography>
                                        <Typography variant='body'>{t("Start adding you first location!")}</Typography>
                                    </Stack> */}
                            </Stack>

                        </>
                        }
                        <ListItemLocation handleAddNewLocation={handleAddNewLocation} isParentSelectModeEnabled={isParentSelectModeEnabled} setSelectedTargetParent={setSelectedTargetParent} />
                        {locations.length > 0 && <List
                            sx={{ width: "100%", bgcolor: "background.paper", borderLeft: 1, borderColor: 'divider' }}
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                        >

                            {generateNestedList(locations)}
                        </List>}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>{t("Close")}</Button>
                </DialogActions>
            </Dialog >

            {selectedLocation && <EditLocationDialog selectedLocation={selectedLocation} setSelectedLocation={setSelectedLocation} locations={locations} setLocations={setLocations} />}




            <DeleteDialogWarningGeneric
                dialogOpen={Boolean(selectedDeleteLocation)}
                handleDialogClose={() => setSelectedDeleteLocation(null)}
                onDeleteAccept={handleLocationDelete}
                isAccepting={false}
                title={t("dialogs.deleteLocation.title")}
                message={t("dialogs.deleteLocation.message")}
                warningText={t("dialogs.deleteLocation.warning")}
            />




            <Dialog open={Boolean(selectedForParentChange && selectedTargetParent)} onClose={() => setSelectedTargetParent(null)} fullWidth maxWidth={'xs'} onKeyDown={(event) => {
                // if (event.key === 'Enter' && selectedDeleteLocation) {
                //     handleLocationDelete(event);
                // }
            }}>
                <form onSubmit={handleLocationParentChange}>
                    <input type="submit" style={{ display: 'none' }} autoFocus />
                    <DialogTitle variant='h4'>
                        {t("Change parent location")}
                        <DialogContentText>
                            <Typography variant='body'>
                                You are about to change the parent location of the <strong>{selectedForParentChange?.name}</strong>. Do you confirm the new structure?
                            </Typography>

                        </DialogContentText>
                    </DialogTitle>
                    <DialogContent>
                        <Stack spacing={2}>
                            <Stack direction={'row'}>
                                <Box sx={{ pr: 0 }} color={'transparent'}>-</Box>
                                <List
                                    component="div"
                                    disablePadding
                                    sx={{
                                        width: "100%",
                                        // pl: 4,
                                        bgcolor: "background.paper",
                                        borderLeft: 1,
                                        borderColor: 'divider'
                                    }}
                                >
                                    <ListItem>
                                        <ListItemText primary={selectedTargetParent?.name} />
                                    </ListItem>
                                    <Stack direction={'row'}>
                                        <Box sx={{ pr: 4 }} color={'transparent'}>-</Box>
                                        <List
                                            component="div"
                                            disablePadding
                                            sx={{
                                                width: "100%",
                                                // pl: 4,
                                                bgcolor: "background.paper",
                                                borderLeft: 1,
                                                borderColor: 'divider'
                                            }}
                                        >
                                            <ListItem sx={{ backgroundColor: 'lightGreen.main' }}>
                                                <ListItemText primary={<Typography color={'green.main'}>{selectedForParentChange?.name}</Typography>} />
                                            </ListItem>
                                        </List>
                                    </Stack>
                                </List>
                            </Stack>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setSelectedTargetParent(null)}>{t("Cancel")}</Button>
                        <Button color='primary' variant='contained' type='submit'>{t("Confirm")}</Button>
                    </DialogActions>
                </form>
            </Dialog>

        </>
    )
}


const ListItemLocation = ({
    location,
    locations,
    isParentSelectModeEnabled,
    handleAddNewLocation,
    handleLocationSelect,
    handleLocationDeleteSelect,
    selectedForParentChange,
    setSelectedForParentChange,
    setSelectedTargetParent }) => {

    const [addingNewLocation, setAddingNewLocation] = useState(false);
    const [addLocationName, setAddLocationName] = useState('');

    const [hover, setHover] = useState(false);

    const handleClick = (e) => {

        if (!location && !isParentSelectModeEnabled) {
            setAddingNewLocation(true);
            return;
        }

        if (!location && isParentSelectModeEnabled) {
            setSelectedTargetParent({ id: null, name: 'Root / No Parent', isCellHolder: false });
        }

        if (location && !isParentSelectModeEnabled) {
            handleLocationSelect(location)
        }

        if (isParentSelectModeEnabled && !location?.isCellHolder && !isSelectedForParentChange && canHaveChildren) {
            setSelectedTargetParent(location);
        }

    }

    const handleAdd = (e) => {
        e.stopPropagation();
        setAddingNewLocation(true);
    }

    const handleOnBlur = (event) => {
        event.preventDefault();
        ConsoleHelper(addLocationName)
        if (addLocationName.length > 0) {
            addNewLocation(addLocationName);
        }
        setAddLocationName('');
        setAddingNewLocation(false);
    }

    const addNewLocation = async (name) => {

        try {
            const response = await axios.post(`/inventory/location`, { name, parentId: location?.id || null })
            if (response.status == 200 || response.status == 201) {
                handleAddNewLocation();
                ConsoleHelper(response.data);
            }
            // dispatchLogin(response.data);
            // updateLanguage(response.data);

            // if (location.state?.from) {
            //     navigate(location.state.from);
            //     return;
            // }
            // navigate('/');
        }
        catch (err) {
            ConsoleHelper(err);
        }

    }

    const handleOnChange = (e) => {
        setAddLocationName(e.target.value);
    }

    const handleTryDelete = async (e) => {
        e.stopPropagation();
        handleLocationDeleteSelect(location);

    }

    const handleTryChangeParent = async (e) => {
        e.stopPropagation();
        setSelectedForParentChange(location);
    }

    const handleStopChangingParent = (e) => {
        e.stopPropagation();
        setSelectedForParentChange(null);
    }
    function isDescendant(parent, child) {
        if (child.parentId === parent.id) {
            return true;
        } else if (child.parentId === null) {
            return false;
        } else {
            const parentOfChild = locations.find(location => location.id === child.parentId);
            return isDescendant(parent, parentOfChild);
        }
    }

    const isSelectedForParentChange = selectedForParentChange == location;
    const isChildrenOfSelectedForParentChange = location && selectedForParentChange && isDescendant(selectedForParentChange, location);
    const canHaveChildren = location && !location.isCellHolder && !isChildrenOfSelectedForParentChange;
    //Check to see if this location is a children of selectedForParentChange

    return <>
        <ListItemButton
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            disableRipple
            onClick={(() => handleClick())}
            sx={{ backgroundColor: isSelectedForParentChange && location ? 'lightGreen.main' : 'transparent' }}
        >

            {location && <>
                {isParentSelectModeEnabled && isSelectedForParentChange && <ListItemIcon>
                    <ModeStandby color='green' />

                </ListItemIcon>}

                {isParentSelectModeEnabled && canHaveChildren && !isChildrenOfSelectedForParentChange && !isSelectedForParentChange && <ListItemIcon>
                    <RadioButtonUnchecked />

                </ListItemIcon>}

                {isParentSelectModeEnabled && !canHaveChildren && !isSelectedForParentChange && <ListItemIcon>
                    <BlockOutlined color='disabled' />
                </ListItemIcon>}

                <ListItemText primary={<Typography color={isParentSelectModeEnabled && !canHaveChildren ? 'gray' : 'inherit'}>{location.name}</Typography>} secondary={location.isCellHolder && <><span>Rows: {location.rowCount}</span><br /> <span> Columns: {location.columnCount}</span></>} />
                {!isParentSelectModeEnabled && <>
                    {!location.isCellHolder && <Tooltip title="Add sub-location" sx={{ color: hover ? "inherit" : 'transparent' }}>
                        <IconButton aria-label="add" size='small' onClick={handleAdd}>
                            <AddOutlined />
                        </IconButton>
                    </Tooltip>
                    }
                    <Tooltip title="Delete" sx={{ color: hover ? "inherit" : 'transparent' }}>
                        <IconButton aria-label="delete" size='small' onClick={handleTryDelete}>
                            <DeleteOutline />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Change Parent" sx={{ color: hover ? "inherit" : 'transparent' }}>
                        <IconButton aria-label="reparent" size='small' onClick={handleTryChangeParent}>
                            <MoveDownIcon />
                        </IconButton>
                    </Tooltip>
                </>}
                {isParentSelectModeEnabled && isSelectedForParentChange && < Tooltip title="Cancel" sx={{ color: "inherit" }}>
                    <IconButton aria-label="reparent" size='small' onClick={handleStopChangingParent}>
                        <Close />
                    </IconButton>
                </Tooltip>
                }


                {!location.isCellHolder && false && locations.every(l => l.parentId !== location.id) &&
                    <Tooltip title={<span>Enable Bin Location structure for this location <br /> (e.g., R1 C1, R1 C6)</span>}
                        sx={{ color: hover ? "inherit" : 'transparent' }}>
                        <IconButton aria-label="edit" size='small' onClick={handleClick}>
                            <GridOnOutlined />
                        </IconButton>
                    </Tooltip>
                }
                {/* <Tooltip title={<span>Edit</span>}
                    sx={{ color: hover ? "inherit" : 'transparent' }}>
                    <IconButton aria-label="edit" size='small' onClick={handleClick}>
                        <EditOutlined />
                    </IconButton>
                </Tooltip> */}
                {/* {!location.isCellHolder && <Tooltip title="Edit" sx={{ color: hover ? "inherit" : 'transparent' }}>
                    <IconButton aria-label="edit" size='small' onClick={handleClick}>
                        <EditOutlined />
                    </IconButton>
                </Tooltip>
                } */}
            </>
            }

            {!location && !isParentSelectModeEnabled && <>
                <Tooltip title="Add sub-location">
                    <Button aria-label="add" color='green' startIcon={<AddOutlined />} onClick={handleClick}>
                        Add location
                    </Button>
                </Tooltip>
            </>
            }
            {!location && isParentSelectModeEnabled && <>
                {/* <ListItemIcon>
                    <RadioButtonUnchecked />
                </ListItemIcon> */}
                <ListItemText primary={"Root / No Parent"} />
            </>
            }

        </ListItemButton >
        {addingNewLocation && <ListItem>
            <Stack direction={'row'} spacing={2} flex={1}>
                <form onSubmit={handleOnBlur} style={{ width: '100%' }}>
                    <TextField
                        fullWidth
                        label="Name"
                        variant="filled"
                        size='small'
                        autoFocus
                        onBlur={handleOnBlur}
                        onChange={handleOnChange} />
                </form>
            </Stack>
        </ListItem>
        }
    </>
}


export default LocationsDialog;