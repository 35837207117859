import * as React from 'react';
import {
    Box, Divider, IconButton,
    Typography, Stack, Chip, Button, ToggleButtonGroup, ToggleButton, ButtonGroup, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, Autocomplete, Tooltip, List, ListItem, ListItemIcon, ListItemText, Table, TableContainer, TableRow, TableHead, TableCell, TableBody, TextField
} from '@mui/material';
import axios from "../../axios-config";
import { repairResolutions } from '../../utils/repair-statuses-helpers';
import { useSnackbar } from 'notistack';
import Checkbox from '@mui/material/Checkbox';
import { servicesCalculation, processService } from '../../utils/service-helpers';
//OUR COMP

import ResolutionDialog from './Dialogs/ResolutionDialog';
import OrderParentingDialog from './Dialogs/OrderParentingDialog';
import ServicesContent from './ServicesContent';
import ExternalServiceContent from './ExternalServiceContent';
import AddServiceButton from '../../components/AddServiceButton/AddServiceButton';
import './service-panel.css'
import { formatCurrency } from '../../utils/currency';
//ICONS
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import RepairedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import DeclinedIcon from '@mui/icons-material/DoDisturbAltOutlined';
import IrreproducibleIcon from '@mui/icons-material/HelpOutlineOutlined';
import IrreparableIcon from '@mui/icons-material/PendingOutlined';
import WarrantyIcon from '@mui/icons-material/GppGoodOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import HandymanIcon from '@mui/icons-material/Handyman';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import UTurnLeftIcon from '@mui/icons-material/UTurnLeft';
import ArrowLeftRoundedIcon from '@mui/icons-material/ArrowLeftRounded';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';

import StatusChip from '../../components/StatusChip/StatusChip';
import EmergencyIconFilled from '../../components/Icons/EmergencyIconFilled';
import { CheckCircle, FaceOutlined, GroupOutlined, HighlightOff, WhatshotRounded } from '@mui/icons-material';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { useTranslation } from 'react-i18next';
import UpdatedAtDate from '../../components/UpdatedAtDate/UpdatedAtDate';
import CreatedAtDate from '../../components/CreatedAtDate/CreatedAtDate';
import DeliveredAtDate from '../../components/DeliveredAtDate/DeliveredAtDate';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import RefundDialog from './Dialogs/RefundDialog';
import PartnerSelect from '../../components/PartnerSelect';
import { useUserPermissions } from 'contexts/UserPermissions';
import { updateOrderStatus } from 'api/repair-orders';
import useStatusUpdateLogic from 'hooks/useStatusUpdateLogic';
import useUpdateOrderStatusAndResolutionMutation from 'hooks/mutations/orders/useUpdateOrderStatusAndResolutionMutation';
import { requiresResolution, getStatusById } from 'utils/status-options';


const RepairOrderServicesPanel = (props) => {

    let dayjs = require('dayjs')
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { repairOrder, setRepairOrder } = props;
    const updateOrderStatusAndResolution = useUpdateOrderStatusAndResolutionMutation();
    // console.log(dayjs().toISOString());
    // console.log(repairOrder.resolutionAt);

    //TODO: using this method temporarily, will be removed after we migrate on fully using react-query
    const onStatusSelectCallback = (newStatus, resolution) => {
        let isRequiringResolution = requiresResolution(newStatus.id);
        if (isRequiringResolution && resolution) {
            let resolutionDate = dayjs().toISOString();
            setRepairOrder((prevState) => {
                return {
                    ...prevState,
                    repairOrderStatusId: newStatus.id,
                    repairOrderStatus: newStatus,
                    resolutionTypeId: resolution.id,
                    resolutionAt: resolutionDate,
                    resolutionType: resolution
                };
                return;
            });
        } if (!isRequiringResolution)
            setRepairOrder((prevState) => {
                return {
                    ...prevState,
                    repairOrderStatusId: newStatus.id,
                    repairOrderStatus: newStatus,
                    resolutionTypeId: null,
                    resolutionAt: null,
                    resolutionType: null
                };
            });
    }

    const { onStatusSelect, ResolutionDialogs } = useStatusUpdateLogic(props.repairOrder, ['repairOrder', repairOrder.id], onStatusSelectCallback);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const currency = useSelector((state) => state.auth.currency);

    
    const { services, setServices } = props;
    const { repairOrderParts, setRepairOrderParts } = props;
    const { repairPayments, setRepairPayments } = props;
    const [parentDialogOpen, setParentDialogOpen] = React.useState(false);
    const [refundDialogOpen, setRefundDialogOpen] = React.useState(false);
    const [externalizedInfoDialogOpen, setExternalizedInfoDialogOpen] = React.useState(false);
    const { outsourceServices, setOutsourceServices } = props;
    const [openFollowUpRepairOrderDialog, setOpenFollowUpRepairOrderDialog] = React.useState(false);
    const [followUpMalfunction, setFollowUpMalfunction] = React.useState("");
    const [followUpNote, setFollowUpNote] = React.useState("");
    const userPermissions = useUserPermissions();

    const childRef = React.useRef();

    const hanldeResolutionClose = () => {
        setResolutionOpen(false);
    };

    const hanldeResolutionOpen = (chip) => {

        setResolutionPrepared(chip.props)
        setResolutionOpen(true);
    };

    // const handleSetResolution = (props) => (event) => {

    //     setResolutionPreparedIndex(props.index);
    //     hanldeResolutionOpen(<Chip color={resolutionIcons[props.name].color} index={props.index} icon={resolutionIcons[props.name].icon} variant={'outlined'} key={props.index} sx={{ fontWeight: '500' }} label={props.name} />
    //     );
    // }

    const [resolutionOpen, setResolutionOpen] = React.useState(false);
    const [resolutionPrepared, setResolutionPrepared] = React.useState({});
    const [resolutionPreparedIndex, setResolutionPreparedIndex] = React.useState(0);

    const removeResolution = async () => {
        try {
            const response = await axios.delete(`/repair-orders/${repairOrder.id}/resolution`);

            if (response.status === 200 || response.status === 201) {
                setRepairOrder((prevState) => {
                    return { ...prevState, resolutionTypeId: null, resolutionAt: null, repairOrderStatusId: response.data.repairOrderStatusId, repairOrderStatus: response.data.repairOrderStatus }
                })
                enqueueSnackbar(`Resolution removed!`, { variant: "success" });
            }
        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        }
    }

    const [solutionsContent, setSolutionsContent] = React.useState(false);


    const onResolutionSet = (resolution) => {
        // console.log("resolution", resolution);
        //Force ready for pickup
        const newStatus = getStatusById(9);
        updateOrderStatusAndResolution.mutate({ repairOrderId: repairOrder.id, status: newStatus, resolution: resolution });
        onStatusSelectCallback(newStatus, resolution);
    
    }

    const handleStatusUpdate = (newStatus) => {
        //TODO: using this method temporarily, will be removed after we migrate on fully using react-query
        onStatusSelect(newStatus);
    }



    const resolutionIcons = {
        Repaired: { icon: <RepairedIcon />, color: 'primary' },
        Declined: { icon: <DeclinedIcon />, color: 'red' },
        Irreparable: { icon: <IrreparableIcon />, color: 'pink' },
        Irreproducible: { icon: <IrreproducibleIcon />, color: 'danube' },
        Warranty: { icon: <WarrantyIcon />, color: 'darkBlue' }
    }


    const Resolutions = () => {
        const keys = Object.keys(repairResolutions);
        return <>
            {/* <Typography fontWeight={'500'} fontSize={'1.35rem'} lineHeight={'2'}> */}
            <Typography variant='h5'>
                {t('Complete the repair by choosing a resolution')}:
            </Typography>
            <Stack direction='row' flexWrap={'wrap'} gap={2}>
                {keys.map((key) => <Chip color={resolutionIcons[key].color} index={repairResolutions[key].id} icon={resolutionIcons[key].icon} variant={'outlined'} key={repairResolutions[key].id} sx={{ fontWeight: '500' }} label={t(repairResolutions[key].name)} onClick={()=>onResolutionSet(repairResolutions[key])} />
                )}
            </Stack>
        </>
    }

    const ChosenResolution = () => {
        const resolutionDate = dayjs(repairOrder.resolutionAt).format('DD MMM. YYYY');
        return <Stack>
            <Stack direction={'row'} alignItems={'center'} gap={2} flexWrap={'wrap'}>
                <Typography variant='h6' >
                    {t('Repair completed with the resolution')}:
                </Typography>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Chip color={resolutionIcons[repairOrder.resolutionType.name].color} index={props.index} icon={resolutionIcons[repairOrder.resolutionType.name].icon} variant={'outlined'} key={props.index} sx={{ fontWeight: '500' }} label={t(repairOrder.resolutionType.name)} onDelete={repairOrder.deliveredAt ? null : removeResolution} />
                    <Typography variant='body2' >{resolutionDate}</Typography>
                </Stack>
            </Stack>
        </Stack>
    }

    const handleEmergencyCheck = async (event) => {
        setRepairOrder((prevState) => {
            return { ...prevState, levelOfAttention: event.target.checked }
        })
        try {
            const response = await axios.patch(`/repair-orders/${repairOrder.id}/levelOfAttention`, { levelOfAttention: event.target.checked });
            if (response.status === 200 || response.status === 201) {
                enqueueSnackbar(`${t('Emergency updated!')}`, { variant: "success" });
            }
        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        }
    }

    let isOutsourcedFrom = repairOrder.outsourcedFrom;
    let isOutsourcedTo = repairOrder.outsourcedTo;

    const acceptedAndRefundedServices = services.filter(service => service.isAccepted || service.refundedServiceId);

    const acceptedAndRefundedServicesProcessed = acceptedAndRefundedServices.reduce((acc, service) => {
        const processed = processService(service)
        return acc += processed.unitPrice * processed.quantity - processed.discount;
    }, 0)




    const totalParts = 0 || repairOrderParts?.reduce((acc, part) => {
        return acc + (parseFloat(part.unitPrice) * parseInt(part.quantity));
    }, 0)
    let expense = 0;
    expense += totalParts;

    // if (processedPartnerServices) {
    //     expense += processedPartnerServices.totalAccepted;
    // }
    const totalPayments = 0 || repairPayments?.reduce((acc, payment) => acc + (parseFloat(payment.amount)), 0)
    const handleOrderIdClick = () => {
        if (repairOrder?.deliveredAt) {
            return;
        }
        setParentDialogOpen(true);

    }
    const handleParentDialogClose = () => {
        setParentDialogOpen(false);
    }
    const handleParentDialogSave = () => {
        setParentDialogOpen(false);
    }
    const handleParentChange = (newRepairOrderData) => {

        setRepairOrder(newRepairOrderData);
    }
    const handleRefundClick = () => {
        setRefundDialogOpen(true);
    }
    const handleRefundDialogClose = () => {
        setRefundDialogOpen(false);
    }
    const handleRefundAdd = (allServices) => {
        setServices(allServices);
    }

    const handleExternalizedInfoClick = () => {
        setExternalizedInfoDialogOpen(true);
    }
    const handleExternalizedInfoDialogClose = () => {
        setExternalizedInfoDialogOpen(false);
    }

    const handleOpenFollowUpRepairOrder = () => {
        setOpenFollowUpRepairOrderDialog(true);
    }

    const handleFollowUpOrderCreation = async () => {
        try {
            const response = await axios.post(`/repair-orders/${repairOrder.id}/followUpOrder`, { malfunction: followUpMalfunction, note: followUpNote });
            if (response.status === 200 || response.status === 201) {
                enqueueSnackbar(`${t('Follow up repair order created!')}`, { variant: "success" });
                setOpenFollowUpRepairOrderDialog(false);
                navigate(`/editEntry/${response.data.id}`)
            }

        } catch (err) {
            console.log(err);
        }
    }
    // return null


    return (
        <>
            <Stack className='servicePanel' sx={{ borderColor: 'lightGrey.main' }}>
                <Box height={'75px'} display={'flex'} alignItems={'center'}>
                    <Stack flex={1} direction={'row'} spacing={2} sx={{ px: '25px', mb: '2px', }} alignItems={'center'}>
                        <Box>
                            <ButtonGroup variant="outlined" color='darkGrey' aria-label="outlined button group" sx={{ borderRadius: 1 }}>
                                {repairOrder.parent?.id || repairOrder.children?.id ?
                                    <Button sx={{ borderRadius: 1, p: '0px' }} disabled={!repairOrder.parent?.id} onClick={() => navigate(`/editEntry/${repairOrder.parent?.id}`)}>
                                        <ArrowLeftRoundedIcon />
                                    </Button>
                                    : null}
                                <Button sx={{ borderRadius: 1 }} onClick={handleOrderIdClick}>
                                    {isOutsourcedFrom ? repairOrder.outsourcedFrom.organization.id + "#" + repairOrder.outsourcedFrom.organizationCounter : repairOrder.organizationId + "#" + repairOrder.organizationCounter}
                                </Button>
                                {repairOrder.parent?.id || repairOrder.children?.id ?
                                    <Button sx={{ borderRadius: 1, p: '0px' }} disabled={!repairOrder.children?.id} onClick={() => navigate(`/editEntry/${repairOrder.children?.id}`)}>
                                        <ArrowRightRoundedIcon />
                                    </Button>
                                    : null
                                }
                            </ButtonGroup>

                        </Box>
                        <Box flexGrow={1}>{repairOrder.entry_state}</Box>
                        <Checkbox onChange={handleEmergencyCheck} checked={repairOrder.levelOfAttention == 1 ? true : false} icon={<WhatshotRounded color='darkGrey' />} checkedIcon={<EmergencyIconFilled />} inputProps={{ 'aria-label': 'controlled' }} />
                        <Box>
                            <StatusChip repairOrderData={repairOrder} onUpdateStatusCallback={handleStatusUpdate} onStatusSelect={handleStatusUpdate} />
                        </Box>
                    </Stack>
                </Box>
                <Divider sx={{ mb: '15px' }} />
                <Stack direction={'row'} spacing={2} alignItems={'center'} flexWrap={'wrap'} mb={'15px'} px='25px'>
                    <Box> <CreatedAtDate createdAt={repairOrder.createdAt} /></Box>
                    <Box><UpdatedAtDate updatedAt={repairOrder.updatedAt} /></Box>
                    <Box><DeliveredAtDate deliveredAt={repairOrder.deliveredAt} /></Box>
                    <Box flexGrow={1}></Box>


                    {repairOrder.parent?.id ?
                        <Button variant={'outlined'} color='red' startIcon={<UTurnLeftIcon sx={{ transform: 'rotate(-90deg)', }} />} onClick={handleRefundClick} >{t("Refund")}</Button>
                        : null}
                    < AddServiceButton childRef={childRef} />
                </Stack>
                {repairOrder.outsourcedTo ?
                    <Box px={'25px'} >
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <Tooltip enterDelay={20} title={`${t('Here you manage the services offered by you to your customer. Your partner is not able to see or interact with these.')}`}>
                                <InfoOutlinedIcon color={'darkGrey'} />
                            </Tooltip>
                            <Typography variant={'h4'}>{t("Services offered to customer")}</Typography>
                        </Stack>
                    </Box> : null}

                <Box px={'25px'} flex={1} mb={'15px'}>
                    <ServicesContent
                        repairPayments={repairPayments}
                        setRepairPayments={setRepairPayments}
                        services={services}
                        setServices={setServices}
                        repairOrderParts={repairOrderParts}
                        setRepairOrderParts={setRepairOrderParts}
                        outsourceServices={outsourceServices}
                        setOutsourceServices={setOutsourceServices}
                        addServiceButton={<AddServiceButton childRef={childRef} />} ref={childRef}
                        repairOrder={repairOrder}
                        setRepairOrder={setRepairOrder}
                        createdAtDate={<CreatedAtDate />}
                        updatedAtDate={<UpdatedAtDate />}
                        toggleSolutionsContent={() => setSolutionsContent(!solutionsContent)} />
                </Box>
                <Divider />

                <Stack direction={'row'} justifyContent={'space-between'} px={{ xs: '10px', sm: '25px' }}  >
                    <Stack direction={'row'} gap={3} pt={'4px'}>
                        <Stack>
                            <Typography color={'darkGrey.main'} textTransform={'uppercase'} variant={'overline'}>{t('Payments')}</Typography>
                            <Typography fontWeight={500} variant='body1'>{formatCurrency(totalPayments, currency)} / {formatCurrency(acceptedAndRefundedServicesProcessed, currency)}</Typography>
                        </Stack>
                    </Stack>
                    <Stack direction={'row'} gap={1} divider={<Divider orientation="vertical" flexItem />} pt={'4px'}>

                        <Stack alignItems={'flex-end'}>
                            <Typography color={'darkGrey.main'} textTransform={'uppercase'} variant={'overline'}>{t('Expenses')}</Typography>
                            <Typography color={'red.main'} noWrap fontWeight={500}>-{formatCurrency(expense, currency)}</Typography>
                        </Stack>
                        <Stack alignItems={'flex-end'}>
                            <Typography color={'darkGrey.main'} textTransform={'uppercase'} variant={'overline'} noWrap>{t('Services')}</Typography>
                            <Typography fontWeight={500}>{formatCurrency(acceptedAndRefundedServicesProcessed, currency)}</Typography>
                        </Stack>
                        <Stack alignItems={'flex-end'}>
                            <Typography color={'darkGrey.main'} textTransform={'uppercase'} variant={'overline'}>{t('Profit')}</Typography>
                            <Typography color={acceptedAndRefundedServicesProcessed - expense > 0 ? 'accepted.main' : 'red.main'} fontWeight={500} >
                                {formatCurrency(acceptedAndRefundedServicesProcessed - expense, currency)}</Typography>
                        </Stack>
                    </Stack>
                </Stack>
                <Divider sx={{ mb: '15px' }} />
                <Box px={'25px'} pb={'25px'}>

                    <Stack direction={'column'} spacing={1} >
                        {isOutsourcedTo && !repairOrder.outsourcedTo.deliveredAt ? t('The resolutions are handled by partner.') : repairOrder.resolutionTypeId ? <ChosenResolution /> : <Resolutions />}
                        {repairOrder.resolutionTypeId && repairOrder.deliveredAt && !repairOrder.outsourcedFrom && !repairOrder.children ?
                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                <Typography>{t("New entry of the same problem or a possible warranty?")}</Typography>
                                <Button size='small' endIcon={<KeyboardDoubleArrowRightRoundedIcon />} onClick={handleOpenFollowUpRepairOrder} >{t("Follow up repair")}</Button>
                            </Stack>
                            : null}
                    </Stack>

                    {repairOrder.invoice ? <Typography>Invoiced to reference: #{repairOrder.invoice.refId}</Typography> : null}

                </Box>
                <Divider sx={{ mb: '15px' }} />
                {/* TODO: Show this only if we have any partner */}
                {repairOrder.deliveredAt ? null :
                    userPermissions?.softLockedUser ? null : < Box px={'25px'}>
                        <PartnerSelect repairOrder={repairOrder} setRepairOrder={setRepairOrder} />
                        {!repairOrder.outsourcedTo && !repairOrder.outsourcedFrom ? < Button size='small' color='warning' onClick={handleExternalizedInfoClick}>{t("What will the partner access and view?")}</Button>
                            : null}
                    </Box>
                }
                {repairOrder.outsourcedTo ? <>
                    <Box px={'25px'} pb={'25px'}>
                        <Stack direction={'row'} spacing={1} pb={2}>
                            <Stack alignItems={'flex-start'} flexGrow={1}>
                                <Stack direction={'row'} alignItems={'center'} >
                                    <Tooltip enterDelay={20} title={t("Here you interact only with your parnter, accept service proposals as well as seeing how much you owe him.")} >
                                        <InfoOutlinedIcon color={'darkGrey'} />
                                    </Tooltip>
                                    <Typography variant={'h4'}  >{t("Services received from Partner")}</Typography>
                                </Stack>
                                <Button size='small' color='warning' onClick={handleExternalizedInfoClick}>{t("What can my partner access and view?")}</Button>
                            </Stack>
                            <Stack>
                                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                    <GroupOutlined color={'black'} sx={{ mr: '5px' }} />
                                    <Typography
                                        variant='h6'
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxWidth: '230px'
                                        }}
                                    >
                                        {repairOrder.outsourcedTo?.organization?.name}
                                    </Typography>

                                </Stack>
                                {repairOrder.outsourcedTo.resolutionType ?
                                    <Chip color={resolutionIcons[repairOrder.outsourcedTo.resolutionType.name].color} index={props.index} icon={resolutionIcons[repairOrder.outsourcedTo.resolutionType.name].icon} variant={'outlined'} key={props.index} sx={{ fontWeight: '500' }} label={t(repairOrder.outsourcedTo.resolutionType.name)} />
                                    : null}
                                {repairOrder.outsourcedTo && repairOrder.outsourcedTo.deliveredAt ?
                                    <DeliveredAtDate deliveredAt={repairOrder.outsourcedTo.deliveredAt} /> : null}

                            </Stack>
                        </Stack>
                        <Box >
                            <ExternalServiceContent
                                services={services}
                                setServices={setServices}
                                repairOrderParts={repairOrderParts}
                                setRepairOrderParts={setRepairOrderParts}
                                outsourceServices={outsourceServices}
                                setOutsourceServices={setOutsourceServices}
                                addServiceButton={<AddServiceButton childRef={childRef} />} ref={childRef}
                                repairOrder={repairOrder}
                                setRepairOrder={setRepairOrder}
                                createdAtDate={<CreatedAtDate />}
                                updatedAtDate={<UpdatedAtDate />}
                                toggleSolutionsContent={() => setSolutionsContent(!solutionsContent)} />
                        </Box>
                    </Box>
                </>
                    : null}
            </Stack >

            <ResolutionDialog repairOrder={repairOrder} openDialog={resolutionOpen} handleDialogClose={hanldeResolutionClose} onActionComplete={onResolutionSet} resolutionChip={<Chip {...resolutionPrepared} />} resolutionIndex={resolutionPreparedIndex} />
            <OrderParentingDialog open={parentDialogOpen}
                onClose={handleParentDialogClose}
                repairOrder={repairOrder}
                handleParentChange={handleParentChange}
                handleParentDialogClose={handleParentDialogClose}
                handleParentDialogSave={handleParentDialogSave} />
            <RefundDialog open={refundDialogOpen} onClose={handleRefundDialogClose} repairOrder={repairOrder} handleRefundDialogClose={handleRefundDialogClose} onRefund={handleRefundAdd} />

            <Dialog open={externalizedInfoDialogOpen} onClose={handleExternalizedInfoDialogClose} maxWidth={'md'} fullWidth>
                <DialogTitle>{t('Partner Access During Outsourcing')}</DialogTitle>
                <DialogContent>

                    <DialogContentText>
                        {t('When you outsource a repair to a partner organization, they gain temporary access to certain details. This access lasts until the order is marked as "Delivered" by your partner. Below is a detailed list of what your partner can do:')}
                    </DialogContentText>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>{t("View")}</TableCell>
                                    <TableCell>{t("Edit")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell><Typography variant='body2'>{t("Statuses")}</Typography></TableCell>
                                    <TableCell><CheckCircle sx={{ color: 'accepted.main' }} /></TableCell>
                                    <TableCell><CheckCircle sx={{ color: 'accepted.main' }} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Typography variant='body2'>{t("Brand")} & {t("Model")}</Typography></TableCell>
                                    <TableCell><CheckCircle sx={{ color: 'accepted.main' }} /></TableCell>
                                    <TableCell><CheckCircle sx={{ color: 'accepted.main' }} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Typography variant='body2'>{t("Estimate services")}</Typography></TableCell>
                                    <TableCell><CheckCircle sx={{ color: 'accepted.main' }} /></TableCell>
                                    <TableCell><CheckCircle sx={{ color: 'accepted.main' }} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Typography variant='body2'>{t("Emergency level")}</Typography></TableCell>
                                    <TableCell><CheckCircle sx={{ color: 'accepted.main' }} /></TableCell>
                                    <TableCell><CheckCircle sx={{ color: 'accepted.main' }} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Typography variant='body2'>{t("Malfunction")}</Typography></TableCell>
                                    <TableCell><CheckCircle sx={{ color: 'accepted.main' }} /></TableCell>
                                    <TableCell><HighlightOff sx={{ color: 'red.main' }} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Typography variant='body2'>{t("Note")}</Typography></TableCell>
                                    <TableCell><CheckCircle sx={{ color: 'accepted.main' }} /></TableCell>
                                    <TableCell><HighlightOff sx={{ color: 'red.main' }} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Typography variant='body2'>{t("Category")}</Typography></TableCell>
                                    <TableCell><HighlightOff sx={{ color: 'red.main' }} /></TableCell>
                                    <TableCell><HighlightOff sx={{ color: 'red.main' }} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Typography variant='body2'>{t("My customer or any data of this")}</Typography></TableCell>
                                    <TableCell><HighlightOff sx={{ color: 'red.main' }} /></TableCell>
                                    <TableCell><HighlightOff sx={{ color: 'red.main' }} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Typography variant='body2'>{t("Services, Parts and Payment between me and my customer")}</Typography></TableCell>
                                    <TableCell><HighlightOff sx={{ color: 'red.main' }} /></TableCell>
                                    <TableCell><HighlightOff sx={{ color: 'red.main' }} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Typography variant='body2'>{t("My private comments")}</Typography></TableCell>
                                    <TableCell><HighlightOff sx={{ color: 'red.main' }} /></TableCell>
                                    <TableCell><HighlightOff sx={{ color: 'red.main' }} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Typography variant='body2'>{t("Logs before the repair being outsourced")}</Typography></TableCell>
                                    <TableCell><HighlightOff sx={{ color: 'red.main' }} /></TableCell>
                                    <TableCell>-</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Typography pt={2}> {t("Please note, your partner's access to these details terminates once the order is delivered. The only ability left is communication through comments and processing payments between you and your partner.")}</Typography>
                    <Typography pt={2}>{t("In the event of additional follow-up repairs (e.g. a warranty claim) or subsequent discussions, your partner will see the order as it was prior to delivery. Be sure to notify them of any changes that occur after delivery.")}</Typography>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleExternalizedInfoDialogClose}>{t('Close')}</Button>
                </DialogActions>

            </Dialog>
            <Dialog open={openFollowUpRepairOrderDialog} onClose={() => setOpenFollowUpRepairOrderDialog(false)} maxWidth={'sm'} fullWidth>
                <DialogTitle>{t('Follow Up Repair')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('Create a follow-up repair to continue working on this device.')}
                    </DialogContentText>
                    <Stack spacing={1}>

                        <TextField label={t('Malfunction')} maxRows={3} multiline fullWidth variant='filled' onChange={(e) => setFollowUpMalfunction(e.target.value)} value={followUpMalfunction} />
                        <TextField label={t('Nota')} maxRows={3} multiline fullWidth variant='filled' onChange={(e) => setFollowUpNote(e.target.value)} value={followUpNote} />
                    </Stack>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenFollowUpRepairOrderDialog(false)}>{t('Close')}</Button>
                    <Button onClick={handleFollowUpOrderCreation}>{t('Create')}</Button>
                </DialogActions>
            </Dialog>
            {ResolutionDialogs}
        </>
    )
}



export default RepairOrderServicesPanel;