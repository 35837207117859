
import React from 'react';
import { Box, Menu, MenuItem, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useTranslation } from "react-i18next";
import { set } from 'lodash';
import { getContrastText } from 'utils/color-utils';
import { statusOptions } from 'utils/status-options';

const StatusMenu = ({
    anchorEl,
    setAnchorEl,
    onStatusSelect,
    anchorOrigin = { vertical: 'top', horizontal: 'right' },
    transformOrigin = { vertical: 'center', horizontal: 'left' }
}) => {
    const theme = useTheme();

    const { t } = useTranslation();

    const handleMenuItemClick = (event, status) => {
        event.stopPropagation();
        onStatusSelect(status);
        setAnchorEl(null);
    };

    return (<Menu
        id="status-menu"
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}

        open={anchorEl !== null}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
            'aria-labelledby': 'status-button',
        }}
        onClick={(e) => e.stopPropagation()} // Prevent menu clicks from propagating
    >
        {statusOptions
            .sort((a, b) => a.positionIndex - b.positionIndex) // Ensure categories are ordered by positionIndex
            .map((category) => (
                <Box key={category.id}>
                    <Box component='li'>
                        <Typography variant='overline' color='black' ml={1}>
                            {t(category.name)}
                        </Typography>
                    </Box>
                    {category.items
                        .sort((a, b) => a.positionIndex - b.positionIndex) // Ensure statuses are ordered by positionIndex
                        .map((status) => (
                            <MenuItem
                                key={status.id}
                                sx={{
                                    fontSize: '14px',
                                    py: '2px',
                                    minHeight: '30px',
                                }}
                                onClick={(event) => handleMenuItemClick(event, status)}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 'inherit',
                                        backgroundColor: status.color,
                                        color: getContrastText(status.color),
                                        px: '10px',
                                        borderRadius: '15px',
                                    }}
                                >
                                    {t(status.name)}
                                </Typography>
                            </MenuItem>
                        ))}
                </Box>
            ))}
    </Menu>
    );
};

export default StatusMenu;