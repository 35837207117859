
import * as React from 'react';
import { useEffect, useState } from 'react';
import axios from "../axios-config";
import { useDispatch } from 'react-redux';
import { dataTableActions } from '../store/data-table';

import {
    Button, Checkbox, Grid, Stack, DialogContent, DialogContentText, DialogActions, Typography, Box,
    InputLabel, Select, MenuItem, FormControl, TextField, InputAdornment, Tooltip, CircularProgress
} from "@mui/material";
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

const PartnerSelect = ({ repairOrder, setRepairOrder }) => {

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [partners, setPartners] = useState([]);
    const [loading, setLoading] = useState(true);
    const [outsourceTo, setOutsourceTo] = useState(0);
    const [outsourceLoading, setOutsourceLoading] = useState(false)
    const dispatch = useDispatch();

    useEffect(async () => {
        try {
            const response = await axios.get(`organizations/partners`);
            if (response.data) {
                setPartners(response.data);
                if (repairOrder && repairOrder.repairOrderOutsourceRequest) {
                    setOutsourceTo(repairOrder.repairOrderOutsourceRequest.toOrganizationId);

                }
                setLoading(false);
            }
        }
        catch (err) {
            console.log(err);
            // enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        };
    }, []);

    const cancelRequest = async (event) => {
        event.stopPropagation();
        try {
            const response = await axios.delete(`repair-orders/outsource/${repairOrder.id}`)

            if (response.status == 200) {
                setOutsourceTo(null);
                enqueueSnackbar(`Outsource request cancelled!`, { variant: "success" });

            }
        } catch (err) {
            console.log(err);
            // enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        };
    }

    const handleOutsourceSelect = async (e) => {
        const value = e.target.value;
        const prevState = outsourceTo;
        try {
            ;
            setOutsourceTo(value);
            if (value <= 0) {
                const response = await axios.delete(`repair-orders/outsource/${repairOrder.id}`);

                return;
            }
            if (!repairOrder) {
                return;
            }
            const data = { toOrganizationId: value };

            const response = await axios.put(`repair-orders/outsource/${repairOrder.id}`, data);
            if (response.status == 409) {
            }
            if (response.status == 200 && setRepairOrder) {
                setRepairOrder(response.data);
                enqueueSnackbar(`Outsource request sent to partner!`, { variant: "success" });

            }
        } catch (err) {
            console.log(err)
            setOutsourceTo(prevState);
        }
    }
    if (repairOrder.outsourcedFromId) {
        return null;
    }
    if (repairOrder.outsourcedTo) {
        // return <Typography>
        //     {t('Order outsourced to')} <strong>{repairOrder.outsourcedTo.organization.name}</strong>.
        // </Typography>
        return null;

    }

    if (loading)
        return "loading...";

    if (outsourceTo) {
        const organization = partners.find(partner => partner.id == outsourceTo);

        if (organization) {
            return <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <Typography>
                    {t("Order outsourced to")} <strong>{organization.name}</strong> {t("and is pending approval.")}
                </Typography>
                <Button size='small' color='red' variant='outlined' onClick={cancelRequest}> {t('Cancel')} </Button>
            </Stack>
        }
    }

    return <FormControl fullWidth variant="filled">
        <InputLabel>{t('Outsource to partner')}</InputLabel>
        <Select
            labelId="partner-select-label"
            id="partner-select"
            label="Payment method"
            value={outsourceTo}
            onChange={handleOutsourceSelect}
            endAdornment={outsourceLoading ? <Box sx={{ display: 'flex', marginRight: '25px' }}>
                <CircularProgress color="inherit" />
            </Box> : null}
        >
            <MenuItem value={0}>{t('Select a partner')}</MenuItem>
            {partners.map(partner => <MenuItem value={partner.id} key={partner.id}>{partner.name}</MenuItem>)}
        </Select>
    </FormControl>
}

export default PartnerSelect;