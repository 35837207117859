import {
  Box,
  Divider,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TableCell,
  Button,
  Collapse,
  IconButton,
  Paper,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  AppBar,
  Toolbar,
  TableSortLabel,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { repairsRevenuePerUser } from "api/reports";
import { useSnackbar } from "notistack";
import axios from "axios-config";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import StyledTableCell from "./StyledTableCell";
import StyledTableRow from "./StyledTableRow";
import { useCurrencyFormatter } from "hooks/useCurrencyFormatter";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { green, pink } from "@mui/material/colors";

import {
  ArrowForward,
  CheckCircleOutline,
  Close,
  GroupOutlined,
  HandymanTwoTone,
  HighlightOff,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { t } from "i18next";
import StyledAvatar from "components/StyledAvatar/StyledAvatar";
import BoxContainer from "common/BoxContainer/BoxContainer";
import ResolutionChip from "components/ResolutionChip/ResolutionChip";
import CalendarButton from "common/CalendarButton";

const RepairsRevenuePerUser = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { formatCurrency } = useCurrencyFormatter();
  const currency = useSelector((state) => state.auth.currency);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const [selectedUser, setSelectedUser] = useState(null);

  const [selectionRange, setSelectionRange] = useState({
    startDate: dayjs().startOf("month").toDate(),
    endDate: dayjs().endOf("day").toDate(),
  });

  const { data, isLoading, isError } = useQuery(
    ["repairsRevenuePerUser", selectionRange],
    () =>
      repairsRevenuePerUser({
        startDate: selectionRange.startDate,
        endDate: selectionRange.endDate,
      }),
    {
      retry: 1,
      enabled: !!selectionRange.startDate && !!selectionRange.endDate,

      onError: (error) => {
        const errorMessage = error.response?.data?.message || error.message;
        enqueueSnackbar(errorMessage, {
          variant: "error",
        });
      },
    }
  );

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onSelectUser = (user) => {
    setSelectedUser(user);
  };

  return (
    <>
      <Box>
        <Typography variant="body">
          {t("charts.repairsRevenuePerUser.pageDescription")}
        </Typography>
        <Divider sx={{ my: "10px" }} />
        <LinearProgress
          sx={{ visibility: isLoading ? "visible" : "hidden", my: "10px" }}
        />

        <Stack direction={"row"} alignItems={"center"} py={"10px"} spacing={1}>
          <Box flex={1}>
            <CalendarButton
              selectionRange={selectionRange}
              setSelectionRange={setSelectionRange}
            />
          </Box>
          <TablePagination
            rowsPerPageOptions={[15, 30, 60]}
            component="div"
            count={data?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t("Rows per page")}
          />
        </Stack>

        <TableContainer component={Paper} elevation={0} sx={{border:'1px solid lightGrey'}}>
          <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell align="left">#</TableCell>
                <TableCell align="left">{t("Name")}</TableCell>
                <TableCell align="right">{t("Total")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading && data?.length === 0 && (
                <StyledTableRow>
                  <StyledTableCell colSpan={4} align="center">
                    <Typography variant="body2">
                      {t("table.nothingWasFound")}
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              )}
              {!isLoading &&
                data
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow
                      key={row.id}
                      sx={{
                        "& > *": { borderBottom: "unset" },
                        cursor: "pointer",
                        "&:hover": { backgroundColor: "action.hover" },
                      }}
                      onClick={() => onSelectUser(row)}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="left">
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          spacing={1}
                        >
                          <StyledAvatar
                            sx={{ width: 30, height: 30 }}
                            name={row.name}
                            userId={row.id}
                            alt={row.name}
                            src="/static/images/avatar/1.jpg"
                          />
                          <Typography variant="body" fontWeight={500}>
                            {row.name}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="right">
                        {formatCurrency(row.totalUserRevenue, currency)}
                      </TableCell>
                    </StyledTableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
          <TablePagination
            rowsPerPageOptions={[15, 30, 60]}
            component="div"
            count={data?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t("Rows per page")}
          />
      </Box>
      <UserRevenueDetailDialog
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
      />
    </>
  );
};

export default RepairsRevenuePerUser;

const UserRevenueDetailDialog = ({ selectedUser, setSelectedUser }) => {
  const { formatCurrency } = useCurrencyFormatter();
  const currency = useSelector((state) => state.auth.currency);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("brand");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedRepairOrders = useMemo(() => {
    if (!selectedUser?.repairOrders) return [];

    const getValue = (repairOrder, property) => {
      if (property === "resolutionType") {
        return repairOrder.resolutionType?.name || "";
      }
      return repairOrder[property];
    };

    return [...selectedUser.repairOrders].sort((a, b) => {
      const valueA = getValue(a, orderBy);
      const valueB = getValue(b, orderBy);

      if (orderBy === "totalFromRepairOrder") {
        return order === "asc" ? valueA - valueB : valueB - valueA;
      } else {
        const stringA = valueA?.toString().toLowerCase().trim() || "";
        const stringB = valueB?.toString().toLowerCase().trim() || "";
        return order === "asc"
          ? stringA.localeCompare(stringB, undefined, { sensitivity: "base" })
          : stringB.localeCompare(stringA, undefined, { sensitivity: "base" });
      }
    });
  }, [selectedUser, order, orderBy]);
  if (!selectedUser) return null;

  return (
    <Dialog
      open={Boolean(selectedUser)}
      onClose={() => setSelectedUser(null)}
      fullScreen
    >
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={() => setSelectedUser(null)}
          aria-label="close"
        >
          <Close />
        </IconButton>
        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
          {t("charts.repairs")}
        </Typography>
      </Toolbar>
      <Toolbar sx={{ minHeight: "auto!important", pb: "15px" }}>
        <Stack
          direction={"column"}
          alignItems={"start"}
          py={"10px"}
          spacing={1}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Typography variant="body">{t("charts.selectedUser")}:</Typography>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <StyledAvatar
                sx={{ width: 30, height: 30 }}
                name={selectedUser.name}
                userId={selectedUser.id}
                alt={selectedUser.name}
                src="/static/images/avatar/1.jpg"
              />
              <Typography variant="body" fontWeight={500}>
                {selectedUser.name}
              </Typography>
            </Stack>
          </Stack>
          <Stack gap={"15px"}>
            <BoxContainer>
              <Stack direction={"row"} gap={2}>
                <Stack flex={1}>
                  <Typography variant="body">
                    {t("charts.servicesTotal")}
                  </Typography>
                  <Typography variant="h5">
                    {formatCurrency(selectedUser.totalUserRevenue, currency)}
                  </Typography>
                </Stack>
                <Stack
                  width={"45px"}
                  height={"45px"}
                  backgroundColor={"#e3f2fd"}
                  borderRadius={"50px"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <HandymanTwoTone color="primary" />
                </Stack>
              </Stack>
            </BoxContainer>
          </Stack>
        </Stack>
      </Toolbar>
      <DialogContent>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={sortedRepairOrders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t("Rows per page")}
        />
        <TableContainer component={Paper} elevation={0} sx={{border:'1px solid lightGrey'}}>
        <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">#</TableCell>
                <TableCell align="left">
                  <TableSortLabel
                    active={orderBy === "brand"}
                    direction={orderBy === "brand" ? order : "asc"}
                    onClick={() => handleRequestSort("brand")}
                  >
                    {t("charts.brand")}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left">
                  <TableSortLabel
                    active={orderBy === "model"}
                    direction={orderBy === "model" ? order : "asc"}
                    onClick={() => handleRequestSort("model")}
                  >
                    {t("charts.model")}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left">
                  <TableSortLabel
                    active={orderBy === "malfunction"}
                    direction={orderBy === "malfunction" ? order : "asc"}
                    onClick={() => handleRequestSort("malfunction")}
                  >
                    {t("charts.malfunction")}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left">
                  <TableSortLabel
                    active={orderBy === "resolutionType"}
                    direction={orderBy === "resolutionType" ? order : "asc"}
                    onClick={() => handleRequestSort("resolutionType")}
                  >
                    Resolution
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <TableSortLabel
                    active={orderBy === "totalFromRepairOrder"}
                    direction={
                      orderBy === "totalFromRepairOrder" ? order : "asc"
                    }
                    onClick={() => handleRequestSort("totalFromRepairOrder")}
                  >
                    {t("charts.total")}
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedRepairOrders
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((repairOrder, index) => {
                  const orgId =
                    repairOrder?.outsourcedFrom?.organization?.id ||
                    repairOrder?.organizationId;
                  const counter =
                    repairOrder?.outsourcedFrom?.organizationCounter ||
                    repairOrder?.organizationCounter;

                  return (
                    <StyledTableRow key={repairOrder.id}>
                      <TableCell>
                        <Button
                          component={Link}
                          to={`/editentry/${repairOrder.id}`}
                          target="_blank"
                          size="small"
                          endIcon={<ArrowForward />}
                        >
                          {orgId}#{counter}
                        </Button>
                      </TableCell>
                      <TableCell align="left">{repairOrder.brand}</TableCell>
                      <TableCell align="left">{repairOrder.model}</TableCell>
                      <TableCell
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "300px",
                        }}
                      >
                        {repairOrder.malfunction}
                      </TableCell>
                      <TableCell align="left">
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          spacing={1}
                        >
                          <ResolutionChip
                            variant={repairOrder.resolutionType?.name}
                          />
                        </Stack>
                      </TableCell>
                      <TableCell align="right">
                        {formatCurrency(
                          repairOrder.totalFromRepairOrder,
                          currency
                        )}
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

// `<TableRow>
// <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
//   <Collapse in={open} timeout="auto" unmountOnExit>
//     <Box sx={{ margin: 1 }}>
//       {/* <Typography variant="h6" gutterBottom component="div">
//         {t("charts.repairs")}
//       </Typography> */}
//       <Table size="small" aria-label="services">
//         <TableHead>
//           <TableRow>
//             <TableCell>Ref</TableCell>
//             <TableCell>{t("charts.name")}</TableCell>
//             <TableCell align="right">
//               {t("charts.malfunction")}
//             </TableCell>
//             <TableCell align="right">{t("charts.total")}</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {row?.repairOrders?.map((repairOrder) => (
//             <TableRow key={repairOrder.id}>
//               <TableCell>
//                 {repairOrder.organizationId}#
//                 {repairOrder.organizationCounter}
//               </TableCell>
//               <TableCell component="th" scope="row">
//                 {repairOrder.brand} {repairOrder.model}
//               </TableCell>
//               <TableCell align="right">
//                 {repairOrder.malfunction}
//               </TableCell>
//               <TableCell align="right">
//                 {repairOrder.totalFromRepairOrder}
//               </TableCell>
//             </TableRow>
//           ))}
//           {row?.repairOrders?.length === 0 && (
//             <TableRow>
//               <TableCell colSpan={3} align="center">
//                 No services found
//               </TableCell>
//             </TableRow>
//           )}
//         </TableBody>
//       </Table>
//     </Box>
//   </Collapse>
// </TableCell>
// </TableRow>`
