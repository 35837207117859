// useStatusLogic.js
import { useState } from 'react';
import ResolutionSelectDialog from 'components/DataTable/ResolutionSelectDialog';
import ConsoleHelper from 'utils/ConsoleHelper';
import useRepairStatusUpdate from 'hooks/mutations/orders/useRepairStatusUpdate';
import useUpdateOrderStatusAndResolution from 'hooks/mutations/orders/useUpdateOrderStatusAndResolutionMutation';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from 'common/ConfirmDialog';
import { Alert, AlertTitle, Typography } from '@mui/material';

/**
 * Custom hook to handle status updates for a repair order.
 *
 * @param {Object} repairOrder - The repair order object.
 * @param {Array} queyKeys - An array of query keys used for updating the status.
 */


const useStatusUpdateLogic = (repairOrder, queyKeys, callback) => {
    const { t } = useTranslation();

    const [statusSelected, setStatusSelected] = useState(null);
    const updateStatus = useRepairStatusUpdate(queyKeys);
    const updateStatusAndResolution = useUpdateOrderStatusAndResolution(queyKeys);
    const [resolutionSelectDialogOpen, setResolutionSelectDialogOpen] = useState(false);
    const [resolutionRemoveDialogOpen, setResolutionRemoveDialogOpen] = useState(false);

    const onStatusSelect = (status) => {
        if (status.requiresResolution && !repairOrder.resolutionType) {
            ConsoleHelper('requires resolution');
            setStatusSelected(status);
            setResolutionSelectDialogOpen(true);
            return;
        }
        if (!status.requiresResolution && repairOrder.resolutionType) {
            setStatusSelected(status);
            setResolutionRemoveDialogOpen(true);
            return;
        }
        handleStatusChange(status);

    };

    const handleStatusChange = (status) => {
        updateStatus.mutate({
            repairOrderId: repairOrder.id,
            status: status,
        });
        if (callback) {
            callback(status);
        }
    };

    const handleStatusChangeWithResolution = (status, resolution) => {
        updateStatusAndResolution.mutate({
            repairOrderId: repairOrder.id,
            status: status,
            resolution: resolution,
        });
        //TODO: remove this temporary callback, used for EditOrderPage until we finish the react-query refactor
        if (callback) {
            callback(status, resolution);
        }
    };

    const handleResolutionSelect = (resolution) => {
        const status = statusSelected;
        setStatusSelected(null);
        handleStatusChangeWithResolution(status, resolution);
        setResolutionSelectDialogOpen(false);
    };

    const handleResolutionRemoveConfirm = (event) => {
        event.stopPropagation();
        handleStatusChange(statusSelected)
        setStatusSelected(null)
        setResolutionRemoveDialogOpen(false)
    };

    const handleResolutionRemoveCancel = (event) => {
        event.stopPropagation();
        setResolutionRemoveDialogOpen(false)
    };

    const handleResolutionDialogClose = (event, reason) => {
        event.stopPropagation();
        setResolutionSelectDialogOpen(false);
    }



    const ResolutionDialogs = (
        <>
            <ResolutionSelectDialog open={resolutionSelectDialogOpen} onClose={(event, reason) => handleResolutionDialogClose(event, reason)} handleResolutionSelect={handleResolutionSelect} />
            <ConfirmationDialog
                title={t("dialogs.removeResolution.title")}
                message={
                    <Alert severity="warning">
                        <AlertTitle >{t("alert.warningTitle")}</AlertTitle>
                        <Typography variant="body1">{t("dialogs.removeResolution.message")}</Typography>
                    </Alert>}
                open={resolutionRemoveDialogOpen}
                onClose={handleResolutionRemoveCancel}
                onConfirm={handleResolutionRemoveConfirm}
            />
        </>
    );

    return {
        onStatusSelect,
        ResolutionDialogs,
    };
};

export default useStatusUpdateLogic;