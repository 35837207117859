import * as React from 'react';
import { Chip, Typography, Button, Stack, ListSubheader, Dialog, DialogTitle, DialogContent, CircularProgress } from '@mui/material';
import { repairStatuses, repairResolutions, statusColors, repairStatusesId, repairResolutionsId } from '../../utils/repair-statuses-helpers';
import axios from "../../axios-config";

//ICONS
import ArrowIcon from '@mui/icons-material/ArrowBackIosNewRounded';


import { useSnackbar } from 'notistack';
import { useTranslation } from "react-i18next";
import { useTheme } from '@emotion/react';
import { set } from 'lodash';
import { useSelector } from 'react-redux';
import StatusMenu from './StatusMenu';

const StatusChip = ({ repairOrderData, onStatusSelect }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const { t } = useTranslation();
    const theme = useTheme();
    const auth = useSelector(state => state.auth);

    const pooperOpen = Boolean(anchorEl);

    const handleAbleToChangeStatus = () => {
        if (repairOrderData.repairOrderStatusId == 11 && !repairOrderData.outsourcedToId && !repairOrderData.outsourcedFromId && repairOrderData.organizationId == auth.organizationId) {
            return false;
        }
        if (repairOrderData.outsourcedTo && !repairOrderData.outsourcedTo.deliveredAt) {
            return false;
        }

        if (repairOrderData.deliveredAt) return false;

        return true;
    }

    const handleClick = (event) => {
        event.stopPropagation();

        if (repairOrderData.isChangingStatus) return;
        if (!handleAbleToChangeStatus()) return;
        if (repairOrderData.deliveredAt) return;

        setAnchorEl(event.currentTarget);
    };

    const arrow = () => {
        if (!handleAbleToChangeStatus()) return null;
        if (pooperOpen)
            return <ArrowIcon sx={{ width: '14px', height: '14px', transform: 'rotate(90deg)' }} />
        return <ArrowIcon sx={{ width: '14px', height: '14px', transform: 'rotate(-90deg)' }} />

    }


    const getStatusStyle = (statusName) => {
        let statusStyle = statusColors[statusName];
        if (!statusStyle) {
            statusStyle = ['default'];
        }
        return statusStyle;
    }
    const statusChip = () => {
        const statusStyle = getStatusStyle(repairOrderData.repairOrderStatus.name);
        const statusClass = repairOrderData.repairOrderStatus.name.toLowerCase().split(' ').map((word, index) => index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)).join('');

        let statusToColor = theme.palette.lightGrey.main;

        const statusColor = theme.palette[statusClass];
        if (statusColor) {
            statusToColor = statusColor.main;
        }

        return <Chip variant={statusStyle.variant} onClick={handleClick} color={statusClass} sx={{ color: statusStyle.fontColor, cursor: 'pointer', textTransform: 'uppercase', fontWeight: '600', fontSize: '0.75rem' }}
            label={
                <Stack maxWidth={'3000px'} direction={'row'} spacing={'2px'} alignItems={'center'}>
                    <Typography variant='body2' fontWeight={500} textTransform={'none'} textAlign={'center'} sx={{ overflowWrap: 'break-word', whiteSpace: 'normal', textOverflow: 'clip' }}>
                        {t(repairOrderData.repairOrderStatus.name)}
                    </Typography>
                    {repairOrderData.isChangingStatus ? < CircularProgress size={14} thickness={5} sx={{ color: 'white' }} /> : arrow()}
                </Stack>
            } />
    };



    

    if (!repairOrderData || !repairOrderData.repairOrderStatus) {
        return null;
    }
    return (
        <React.Fragment>
            {statusChip()}
            <StatusMenu
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                onStatusSelect={onStatusSelect}
            />          
        </React.Fragment>
    )
}

export default React.memo(StatusChip);