import { useState, useEffect, useRef, forwardRef } from "react";
import { Paper, Stack, Box, Typography, Popover, TextField } from "@mui/material";
import LogoButton from "../../components/Buttons/LogoButton";
import ReactiveTextField from "../../components/ReactiveTextField/ReactiveTextField";

import axios from "../../axios-config";


//Date Picker
import { Calendar, defaultInputRanges } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { subDays, format } from 'date-fns';
import UploadFilesDialog from "../UploadFilesDialog/UploadFilesDialog";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getCurrencySymbol } from "../../utils/currency";

const SimplifiedInvoiceView = forwardRef(({ invoice, onUpdateRefId, setInvoice }, ref) => {

    const { t } = useTranslation();

    const [taxField, setTaxField] = useState(21);
    const [taxIncluded, setTaxIncluded] = useState(true);
    const [mediaDialogOpen, setMediaDialogOpen] = useState(false);

    //Calendar Popover
    const [anchorEl, setAnchorEl] = useState(null);
    const calendarOpen = Boolean(anchorEl);
    const calendarId = calendarOpen ? 'calendar-popover' : undefined;


    const dayjs = require('dayjs');

    const currency = useSelector((state) => state.auth.currency);

    const handleCalendarOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCalendarClose = () => {
        setAnchorEl(null);
    };

    const handleCalendarSelect = async (e) => {
        const value = dayjs(e).toISOString();
        const body = { date: value };

        try {
            const resp = await axios.patch(`invoices/date/${invoice.id}`, body);


            if (resp.status !== 200) {
                throw resp.status;
            }
            const newInvoice = { ...invoice };
            newInvoice.date = value;
            setInvoice(newInvoice);
            // handleCalendarClose();
        }
        catch (err) {
            console.log(err)
            // enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        }
    }


    let invoiceSubTotal = 0;
    let invoiceTax = 0;
    let invoiceTotal = 0;
    let rowsCounter = 0;

    const setInvoiceLogo = async (file) => {
        const body = { fileId: file.id };
        try {
            const resp = await axios.patch(`invoices/logo/${invoice.id}`, body);

            if (resp.status !== 200) {
                throw resp.status;
            }


            const newInvoice = { ...invoice };
            newInvoice.file = file;
            setInvoice(newInvoice);
        }
        catch (err) {
            console.log(err)
            // enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        }
    }

    const onBlur = async (key, newValue) => {
        const value = newValue;
        const body = { [key]: value };
        // console.log(body);
        try {
            const resp = await axios.patch(`invoices/${key}/${invoice.id}`, body);

            const newInvoice = { ...invoice };
            newInvoice[key] = newValue;
            setInvoice(newInvoice);

            if (resp.status !== 200) {
                throw resp.status;
            }
        }
        catch (err) {
            console.log(err)
            // enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        }
    }
    if (!invoice || !invoice.invoiceItems) {
        return "no invoice";
    }


    return <>
        < Paper sx={{ width: "80mm" }}>

            <Stack direction={'column'}
                ref={ref}
                sx={{
                    display: "flex",
                    paddingX: "5px",
                    paddingY: "25px",

                }}
                spacing={1}
            >
                <Stack direction={'row'} justifyContent={'center'}>
                    {invoice.file ? <Box className="editableRow clickable" height={'100px'} p={'10px'} borderRadius={'10px'} width={'190px'} display={'block'} textAlign={'center'} onClick={() => setMediaDialogOpen(true)} >
                        <img className="logo" src={`${process.env.REACT_APP_IMAGES_URL}${invoice.file.fileName}`} alt="logo" />
                    </Box>
                        : <LogoButton onClick={() => setMediaDialogOpen(true)} />}
                </Stack>
                <Stack direction={'column'} spacing={0} alignItems={'center'}
                >
                    <Stack direction={'row'} alignItems={'center'} px={'5px'}>
                        <Typography width={'100%'}>{t('Simplified invoice')} #</Typography>
                        <Stack >
                            <ReactiveTextField
                                sx={{ width: `6ch` }}
                                initialState={invoice.refId}
                                variant={'standard'}
                                onBlurCallback={(newValue) => onBlur('refId', newValue)}
                                placeholder={"Ref Id"}
                                className="textareaAsTypography"
                                InputProps={{ disableUnderline: true }} />
                        </Stack>
                    </Stack>



                    <ReactiveTextField
                        multiline
                        initialState={invoice.billingFrom}
                        variant={'standard'}
                        onBlurCallback={(newValue) => onBlur('billingFrom', newValue)}
                        placeholder={"Your billing details"}
                        className="textareaAsTypography centerInput"
                        InputProps={{ disableUnderline: true, style: { fontSize: '3.5mm' } }} />

                    <Stack direction={'row'} px={'5px'} className={'editableRow clickable'} onClick={handleCalendarOpen} >
                        <Typography>
                            {t('Date')}: {dayjs(invoice.date).format("DD/MM/YYYY")}
                        </Typography>
                    </Stack>
                </Stack>
                <Popover
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    anchorEl={anchorEl} open={calendarOpen}
                    id={calendarId} onClose={handleCalendarClose}>
                    <Calendar
                        date={dayjs(invoice.date).toDate()}
                        onChange={handleCalendarSelect}
                    />
                </Popover>
                <table className="simplifiedInvoiceTable">
                    <thead>
                        <tr>
                            <td>
                                <Typography >{t('Item')}</Typography>
                            </td>
                            <td>
                                <Typography >{t('Total')} ({getCurrencySymbol(currency)})</Typography>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {invoice.invoiceItems.map((service, index) => {
                            const taxFieldValue = parseFloat(taxField) >= 0 ? parseFloat(taxField) : 0;
                            let taxPercentage = 1.00 + (taxFieldValue / 100);
                            // Tax included or excluded?

                            let discount = service.discount ? parseFloat(service.discount) : 0;

                            let unitPrice = service.unitPrice ? parseFloat(service.unitPrice) : 0;
                            const quantity = service.quantity ? parseInt(service.quantity) : 1;
                            let subTotal = 0;
                            let tax = 0;
                            let total = 0;
                            if (!taxIncluded) {

                                subTotal = (unitPrice * quantity - discount);
                                tax = subTotal * taxPercentage - subTotal;
                                total = subTotal + tax;
                            }
                            else {
                                subTotal = (unitPrice * quantity - discount) / taxPercentage;
                                total = (unitPrice * quantity);
                                unitPrice = unitPrice;
                                discount = discount;
                                tax = total - total / taxPercentage;
                            }

                            invoiceSubTotal += subTotal;
                            invoiceTax += tax;
                            invoiceTotal += total - discount;
                            rowsCounter++;

                            return <tr key={rowsCounter}>
                                <td>
                                    <Typography >{service.name}</Typography>
                                    <Typography pl={2}>{unitPrice.toFixed(2)} x{quantity}</Typography>
                                </td>
                                <td>
                                    <Typography >{total.toFixed(2)}</Typography>
                                </td>
                            </tr>
                        })
                        }
                        {invoice.invoiceItems.map((service, index) => {
                            const taxFieldValue = parseFloat(taxField) >= 0 ? parseFloat(taxField) : 0;
                            let taxPercentage = 1.00 + (taxFieldValue / 100);
                            // console.log(taxField, "tax field");
                            // Tax included or excluded?

                            let discount = service.discount ? parseFloat(service.discount) : 0;
                            if (discount <= 0) return null;
                            rowsCounter++;
                            return <tr key={rowsCounter}>
                                <td>
                                    <Typography >{service.name} ({t('Discount')})</Typography>
                                </td>
                                <td>
                                    <Typography >-{discount > 0 ? discount.toFixed(2) : "asd"}</Typography>
                                </td>
                            </tr>
                        })
                        }

                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={1}>
                                <Typography >Total</Typography>
                            </td>
                            <td>
                                <Typography >{invoiceTotal.toFixed(2)}</Typography>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1}>
                                <Typography >Base</Typography>
                            </td>
                            <td>
                                <Typography >  {invoiceSubTotal.toFixed(2)}</Typography>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1}>
                                <Typography >{t('Tax')} {(taxField.toFixed(2))}%</Typography>
                            </td>
                            <td>
                                <Typography >{(invoiceTotal - invoiceSubTotal).toFixed(2)}</Typography>
                            </td>
                        </tr>
                    </tfoot>
                </table>
                <Stack direction={'row'} justifyContent={'center'} spacing={'3px'}>
                </Stack>

                <Stack direction={'row'} justifyContent={'center'} spacing={'3px'}>
                    <ReactiveTextField
                        multiline
                        fullWidth
                        initialState={invoice.terms}
                        variant={'standard'}
                        onBlurCallback={(newValue) => onBlur('terms', newValue)}
                        placeholder={`${t('Add terms or note')}....`}
                        className={`textareaAsTypography centerInput ${invoice.terms?.length > 0 ? '' : 'no-print'}`}
                        InputProps={{ disableUnderline: true, style: { fontSize: '4mm' } }}
                    />
                </Stack>
            </Stack>
        </Paper>
        <UploadFilesDialog open={mediaDialogOpen} onClose={() => setMediaDialogOpen(false)} onSelect={setInvoiceLogo} />
    </>
})

export default SimplifiedInvoiceView