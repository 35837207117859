import { useState, useEffect } from "react";
import {
    DialogContent, DialogContentText, DialogActions, Stack, ToggleButtonGroup, FormControl, MenuItem, Button,
    TextField, InputLabel, Select, FormControlLabel, Checkbox, InputAdornment, ToggleButton,
    Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { processService } from '../../../utils/service-helpers';
import axios from "../../../axios-config";
import { useSnackbar } from "notistack";

import { getCurrencySymbol } from "../../../utils/currency";
import { LoadingButton } from "@mui/lab";
import { PermIdentityRounded } from "@mui/icons-material";
import AssignUserButton from "components/AssignUserButton/AssignUserButton";
import { use } from "i18next";



const NewServiceTab = ({ repairOrder, services, handleAddServiceClose, onNewServiceAdded, isHandlingRequest, setIsHandlingRequest }) => {
    const [stock, setStock] = useState(null);
    const initialServiceState = { name: '', quantity: 1, unitPrice: '', discount: '' };
    const currency = useSelector((state) => state.auth.currency);
    const [newService, setNewService] = useState({ ...initialServiceState });
    const [formErrors, setFromErrors] = useState({ ...initialServiceState });
    const [assignedTo, setAssignedTo] = useState(null);
    // let's get the auth from redux
    const auth = useSelector((state) => state.auth);

    const handleNewServiceState = (prop) => (event) => {
        setNewService(prevState => {
            return { ...prevState, [prop]: event.target.value }
        });
    }


    const handleStockChange = (event, newStock) => {
        setStock(newStock);
    };

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const validateInput = () => {
        const errors = {};
        if (newService.name.length <= 2) {
            errors.name = 'error';
        }
        if (newService.quantity == 0) {
            errors.quantity = 'error';
        }
        if (newService.unitPrice < 0) {
            errors.unitPrice = 'error';
        }

        setFromErrors(errors);
        if (Object.keys(errors).length > 0)
            return false
        return true
    }

    const assignToMe = () => {
        if(auth.userName && auth.userId) {
            const user = { id: auth.userId, name: auth.userName, email: auth.email };
            onSelectUser(user);
        }
    }


    useEffect(() => {
       assignToMe();
    }, [])

    const onSelectUser = (user) => {
        setAssignedTo(user);
    }

    const handleAddNewService = async () => {
        if (!validateInput()) return;

        try {
            setIsHandlingRequest(true);
            const data = { ...newService, stock: stock === "null" ? null : stock };
            data.discount = data.discount == '' ? 0 : data.discount;
            data.assignedToId = assignedTo ? assignedTo.id : null;
            const response = await axios.post(`/repair-orders/${repairOrder.id}/service`, data);
            if (response.status == 200 || response.status == 201) {
                enqueueSnackbar(`Service added!`, { variant: "success" });
                handleAddServiceClose();
                onNewServiceAdded(response.data);
                setNewService(initialServiceState);
            }
        } catch (err) {
            console.log(err)
            enqueueSnackbar(err.response.data.message || `Something went wrong!`, { variant: "error" });
        } finally {
            setIsHandlingRequest(false);
        }
    }

    

    if (repairOrder.resolutionTypeId) {
        return <>
            <DialogContent>
                <DialogContentText>{t('The repair has already a resolution set. No other services can be proposed.')}</DialogContentText>

            </DialogContent>
            <DialogActions >
                <Button onClick={handleAddServiceClose}>{t('Cancel')}</Button>
            </DialogActions>
        </>
    }
    return <>
        <DialogContent>
            <DialogContentText>{t('Propose a service, will be calculated once accepted')}.</DialogContentText>
            <Stack direction={'row'} spacing={2} pt={3}>
                <TextField
                    autoFocus
                    id="name"
                    label={t("Service name")}
                    type='text'
                    fullWidth
                    variant="filled"
                    value={newService.name}
                    multiline
                    minRows={2}
                    maxRows={2}
                    onChange={handleNewServiceState('name')}
                />
            </Stack>
            <Stack direction={'row'} spacing={2} pt={3}>
                <TextField
                    id="unit-price"
                    label={t("Unit price")}
                    type='number'
                    variant="filled"
                    value={newService.unitPrice}
                    onChange={handleNewServiceState('unitPrice')}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>,
                    }}

                />
                <TextField
                    id="quantity"
                    label={t("Quantity")}
                    type='number'
                    variant="filled"
                    value={newService.quantity}
                    onChange={handleNewServiceState('quantity')}
                    sx={{ width: '150px' }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">x</InputAdornment>,
                    }}

                />
                <TextField
                    id="discount"
                    label={t("Discount")}
                    type='number'
                    variant="filled"
                    value={newService.discount}
                    onChange={handleNewServiceState('discount')}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>,
                    }}
                />
            </Stack>
            <Stack direction={'row'} spacing={2} pt={3} alignItems={'center'}>
                <Typography>{t("dialogs.newService.assignee")}:</Typography>
                <AssignUserButton assignedTo={assignedTo} onSelectUser={onSelectUser} assignToMe={assignToMe} />
            </Stack>
            <Stack direction={'row'} spacing={2} pt={3}>
                <ToggleButtonGroup
                    color="primary"
                    value={stock ? stock : "null"}
                    exclusive

                    onChange={handleStockChange}
                >
                    <ToggleButton value="1">{t("In stock")}</ToggleButton>
                    <ToggleButton value={"null"}>{t("Unkown")}</ToggleButton>
                    <ToggleButton value="0">{t("Out of stock")}</ToggleButton>
                </ToggleButtonGroup>
            </Stack>
        </DialogContent>
        <DialogActions >
            <Button onClick={handleAddServiceClose}>{t('Cancel')}</Button>
            <LoadingButton onClick={handleAddNewService} loading={isHandlingRequest}>{t('Add')}</LoadingButton>
        </DialogActions>
    </>
}

export default NewServiceTab;