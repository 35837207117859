// AUTOCOMPLETE
import React, { useState } from "react";
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from "@mui/material/CircularProgress";
import { TextField, Typography, Chip, IconButton, Paper, List, ListItem, ListItemIcon, ListItemText, Stack, Button, ListItemButton, InputAdornment } from "@mui/material";
import { createFilterOptions } from '@mui/material/Autocomplete';
import { Box } from "@mui/system";

import { useSelector, useDispatch } from "react-redux";


//  ICONS
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MailIcon from '@mui/icons-material/Mail';
import { useTranslation } from "react-i18next";
import axios from "axios-config";

import { AddOutlined, Circle, LocalShippingOutlined } from "@mui/icons-material";

// AUTOCOMPLETE
const SupplierSearch = ({ required, state, onOptionSelect, sx }) => {

  const [openClientModal, setOpenClientModal] = useState
    (false);
  const { t } = useTranslation();


  const handleOptionSelect = (option) => {
    onOptionSelect(option);
    setOpen(false);
}

  const SearchPopover = ({ children, ...other }) => (
    <Paper {...other} sx={{ height: '250px', p: '15px' }} onMouseDown={(event) => event.preventDefault()}>
      <Stack spacing={2}>
        <Stack spacing={1}>
          {/* <Typography variant="caption"> Actions</Typography> */}
          <Stack direction={'row'}>
            <Button variant="outlined" onClick={(event) => event.stopPropagation()} startIcon={<AddOutlined />} size="small" color="black">{t("New supplier")}</Button>
          </Stack>
        </Stack>
        <Stack spacing={1}>
          <Typography variant="caption">Suppliers</Typography>
          {children}

        </Stack>
      </Stack>
    </Paper >
  );
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  const loading = open && options.length === 0;


  React.useEffect(async () => {
    try {
      let active = true;
      if (!loading) {
        return undefined;
      }
      const response = await axios.get(`/inventory/suppliers`)

      const data = response.data;
      if (active) {
        setOptions([...data])
      }
      return () => {
        active = false;
      };
    } catch (err) {
      console.log(err);
    }
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const filter = createFilterOptions({
    stringify: (option) => option.name + option.phone + option.email,
  });

 
  return (
    <>
      <Autocomplete
        sx={{ ...sx }}
        filterOptions={filter}
        id="suppliers"
        value={state}
        // onChange={(e, v, r) => {
        //   updateClient(v);
        // }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        disableCloseOnSelect={true}
        onMouseDown={(event) => event.preventDefault()}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => {
          if (option.name) {
            return option.name;
          } else return "";
        }}
        options={options}
        loading={loading}
        PaperComponent={SearchPopover}
        noOptionsText={"There are no customers."}
        ListboxComponent={({ children }) => (
          <List sx={{ overflow: 'hidden', height: '100%' }}>
            {children}
          </List >
        )}
        renderOption={(props, option) => <OptionList {...props} key={option.id} option={option}  handleOptionSelect={handleOptionSelect}      />}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            label={t("Supplier")}
            placeholder={t("Search or add a supplier")}
            variant="filled"
            // size="small"
            InputProps={{
              ...params.InputProps,
              // The -3px is to align the icon with the text
              // It's just a workaround, not the best solution
              // TODO: Find a better solution
              startAdornment: <InputAdornment position="start" style={{ marginTop: '-3px' }}>
                <LocalShippingOutlined />
              </InputAdornment>
            }}
          />
        )}
      />
    </>
  );
};


const OptionList = ({ option, handleOptionSelect }) => {

  const handleOnClick = () => {
    handleOptionSelect(option);
  }

  return <ListItemButton onClick={handleOnClick}>
    <ListItemIcon sx={{ minWidth: '26px' }}>
      <Circle sx={{ fontSize: '8px' }} />
    </ListItemIcon>
    <ListItemText primary={option.name} />
  </ListItemButton>
};

export default SupplierSearch;
