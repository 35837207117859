import store from 'store';
import { setServerDown } from 'store/actions/appActions';

import axios from 'axios';

const healthCheckAxios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 5000, // 5 seconds
});




export const checkServerHealth = async () => {
    console.log('Checking server health...');
    try {
        await healthCheckAxios.get('/health');
        // Server is up
        if (store.getState().app.serverDown) {
            store.dispatch(setServerDown(false));
        }
    } catch (healthError) {
        // Server might be down
        if (!store.getState().app.serverDown) {
            store.dispatch(setServerDown(true));
        }
    }
};
