import React, { useState, useEffect,useRef,useCallback } from "react";
import { Chip, Popover, Typography } from "@mui/material";
import PatternLock from "react-17-pattern-lock";
import { Pattern } from "@mui/icons-material";

function PatternPopover({ onDelete, value, anchorEl, setAnchorEl }) {
    const [paths, setPaths] = useState('');
    const timeoutIdsRef = useRef([]);
    const popoverOpen = Boolean(anchorEl);

    const clearAllTimeouts = () => {
        timeoutIdsRef.current.forEach(clearTimeout);
        timeoutIdsRef.current = [];
    };

    const updatePaths = (newValue) => {
        setPaths((currentPaths) => currentPaths + newValue);
    };

    const showPathPattern = useCallback((loop = true) => {
        clearAllTimeouts();
        if (value) {
            value.split("").forEach((pathValue, index) => {
                const timeoutId = setTimeout(() => {
                    const adjustedValue = Math.max(0, Math.min(8, parseInt(pathValue) - 1));
                    setPaths(prevPaths => prevPaths + adjustedValue.toString());
                }, 100 * index);

                timeoutIdsRef.current.push(timeoutId);
            });

            if (loop) {
                const restartTimeoutId = setTimeout(() => {
                    setPaths('');
                    showPathPattern();
                }, 100 * value.length + 1000);
                timeoutIdsRef.current.push(restartTimeoutId);
            }
        }
    }, [value]);

    useEffect(() => {
        if (anchorEl) {
            setPaths('');
            showPathPattern();
        } else {
            clearAllTimeouts();
            setPaths('');
        }
    }, [anchorEl, showPathPattern]);



    return (
        <>
            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: "none",
                }}
                open={popoverOpen}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                // onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <PatternLock
                    width={100}
                    size={3}
                    pointSize={6}
                    connectorThickness={3}
                    path={paths}
                    disabled={true}
                />
            </Popover>
        </>
    );
}

export default PatternPopover;
