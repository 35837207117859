import * as React from 'react';
import axios from "../../axios-config";
import { useSnackbar } from 'notistack';

import {
    Box, Divider, IconButton, Stack,
    TableContainer, TableHead, TableRow,
    TableBody, TableCell, Table, Button, Typography,
} from '@mui/material';
import NewServiceDialog from './Dialogs/NewServiceDialog';
import ServiceRow from './ServiceRow';
import ServicesEmptyPlaceholder from './ServicesEmptyPlaceholder';
import ServicesList from './ServicesList';
import ServiceItemList from './ServiceItemList';

// ICONS
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import PaymentItemList from './PaymentItemList';
import PartItemList from './PartItemList';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import ServiceItemListPartner from './ServiceItemListPartner';
import { useTranslation } from 'react-i18next';
import { processService, servicesCalculation } from '../../utils/service-helpers';
import { useSelector } from 'react-redux';
import { formatCurrency } from '../../utils/currency';
const ExternalServiceContent = React.forwardRef(({ repairOrder, setRepairOrder, addServiceButton,
    services, setServices, outsourceServices,
    setOutsourceServices, repairPayments, setRepairPayments,
    repairOrderParts, setRepairOrderParts }, ref) => {

    const { t } = useTranslation();
    const currency = useSelector((state) => state.auth.currency);

    const [isLoading, setIsLoading] = React.useState(true);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [addServiceOpen, setAddServiceOpen] = React.useState(false);




    const hanldeServiceDialogOpen = () => {
        setAddServiceOpen(true);
    };

    const handleServiceDialogClose = () => {
        setAddServiceOpen(false);
    };

    const onServiceDelete = (removedService) => {
        setServices(
            services.filter(service => {
                return service.id !== removedService.id
            }))
    }

    React.useImperativeHandle(ref, () => ({

        openAddServicesDialog() {
            hanldeServiceDialogOpen();
        }

    }));

    const onNewServiceAdded = (newService) => {
        setServices(newService);
    }

    const onPaymentAdded = (newPayment) => {
        setRepairPayments(newPayment);
    }

    const onServiceAccept = (service) => {
        const updatedServices = outsourceServices.map(s => {
            if (s.id === service.id) {
                s.isAccepted = true;
            }
            return s;
        });
        setOutsourceServices(updatedServices);
        // setServices(updatedServices);
    }

    const onRemoveAccept = (service) => {
        const updatedServices = outsourceServices.map(s => {
            if (s.id === service.id) {
                s.isAccepted = null;
            }
            return s;
        });
        setOutsourceServices(updatedServices);
    }

    const onDecline = (service) => {
        const updatedServices = outsourceServices.map(s => {
            if (s.id === service.id) {
                s.isAccepted = false;
            }
            return s;
        }
        );
        setOutsourceServices(updatedServices);
    }
    

    const onPartsAdded = (parts) => {
        setRepairOrderParts(parts);
    }



    const acceptedAndRefundedServices = outsourceServices.filter(service => service.isAccepted || service.refundedServiceId);
    const acceptedAndRefundedServicesProcessed = acceptedAndRefundedServices?.reduce((acc, service) => {
        const processed = processService(service)
        return acc += processed.unitPrice * processed.quantity - processed.discount;
    }, 0)

    const declinedAndProposedServices = outsourceServices.filter(service => (!service.isAccepted || service.isAccepted === null) && !service.refundedServiceId);
    const declinedAndProposedServicesProcessed = declinedAndProposedServices?.reduce((acc, service) => {
        const processed = processService(service)
        return acc += processed.unitPrice * processed.quantity - processed.discount;
    }, 0)


    return (
        <>
            {
                !outsourceServices?.length ?
                    <>
                        <ServicesEmptyPlaceholder />
                    </>
                    :
                    <Box>
                        <Box>
                            {declinedAndProposedServices?.length > 0 ?
                                <ServicesList
                                    title={t('Partner proposed services')}
                                    additionalInfo={`${formatCurrency(declinedAndProposedServicesProcessed, currency)}`}
                                    servicesListItems={declinedAndProposedServices.map(service =>
                                        <ServiceItemList
                                            key={service.id}
                                            service={service}
                                            onServiceDelete={onServiceDelete}
                                            onAccept={onServiceAccept}
                                            onRemoveAccept={onRemoveAccept}
                                            onDecline={onDecline}
                                            setServices={setServices}
                                            repairOrder={repairOrder}
                                            fromOutsource={true}
                                        />)}
                                    onSolutionUpdate={repairOrder}
                                    sx={{ mb: '5px' }}
                                />
                                : null}

                            {acceptedAndRefundedServices?.length > 0 ?
                                <ServicesList
                                    title={`${t('Partner confirmed services')}`}
                                    additionalInfo={`${formatCurrency(acceptedAndRefundedServicesProcessed, currency)}`}
                                    servicesListItems={acceptedAndRefundedServices.map(service =>
                                        <ServiceItemList
                                            key={service.id}
                                            service={service}
                                            onServiceDelete={onServiceDelete}
                                            onAccept={onServiceAccept}
                                            onRemoveAccept={onRemoveAccept}
                                            onDecline={onDecline}
                                            setServices={setServices}
                                            repairOrder={repairOrder}
                                            fromOutsource={true}
                                        />)}
                                    onSolutionUpdate={repairOrder}
                                    sx={{ mb: '5px' }}
                                />
                                : null}


                        </Box>
                    </Box>
            }
            {repairOrder.outsourcedTo?.repairPayments?.length > 0 ?
                <ServicesList title={t('Payments')}
                    servicesListItems={
                        <>
                            {repairOrder.outsourcedTo?.repairPayments.map(payment =>
                                <PaymentItemList
                                    key={payment.id}
                                    item={payment}
                                    setPayments={setRepairPayments}
                                    fromOutsource={true}
                                />
                            )}
                        </>
                    }

                    onSolutionUpdate={setRepairOrder}
                    sx={{ mb: '5px' }} />
                : null}

            <NewServiceDialog repairOrder={repairOrder} services={services} repairPayments={repairPayments} addServiceOpen={addServiceOpen} handleAddServiceClose={handleServiceDialogClose} onNewServiceAdded={onNewServiceAdded} onPaymentAdded={onPaymentAdded} onPartsAdded={onPartsAdded} />
        </>
    )

})



export default ExternalServiceContent;