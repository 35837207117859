import * as actionTypes from "./actionTypes";

export const setServerDown = (isDown) => ({
    type: actionTypes.SET_SERVER_DOWN,
    payload: isDown,
  });
  
  export const setUpdateAvailable = (isAvailable) => ({
    type: actionTypes.SET_UPDATE_AVAILABLE,
    payload: isAvailable,
  });

  export const setNetworkNotAvailable = (isAvailable) => ({
    type: actionTypes.SET_NETOWKR_NOT_AVAILABLE,
    payload: isAvailable,
  });

  export const setMinimumVersionNotMet = (isMet) => ({
    type: actionTypes.SET_MINIMUM_VERSION_NOT_MET,
    payload: isMet,
  });