import * as React from "react";

import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const LogoButton = ({ onClick }) => {
    const {t} = useTranslation();
    
    return (<Box height={'100%'} width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Button variant={'outlined'} sx={{ px: '35px', py: '25px', color: "black.main", backgroundColor: 'divGrey.main', borderColor: "darkGrey.main" }} onClick={onClick}>
            + {t('Upload Logo')}
        </Button>
    </Box>)
}

export default LogoButton;