import * as React from "react";
import { Avatar } from "@mui/material";
import { styled } from "@mui/material/styles";

function stringToColor(string, userId = 0) {
    if (!string) return '#000000';
    let hash = 15 * (userId * string.length);

    /* eslint-disable no-bitwise */
    for (let i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    const hue = hash % 360;
    const saturation = 50 + (hash % 50);
    const lightness = 48 + (hash % 26); 

    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}

const StyledAvatar = styled(({ userId, name, ...props }) => (
    <Avatar {...props}>
        {name.charAt(0).toUpperCase()}
    </Avatar>
))(({ theme, name, userId }) => ({
    backgroundColor: stringToColor(name, userId)
}));

export default StyledAvatar;