import * as actionTypes from "./actionTypes";

export const toggleNewClientModal = (value) => {
  return {
    type: actionTypes.TOGGLE_NEW_CLIENT_MODAL,
    value: value,
  };
};

export const addNewClient = (client,token) => {
    return {
      type: actionTypes.ADD_CLIENT,
      client: client,
      token: token,
    };
  };

  export const addNewClientStart = () => {
    return {
      type: actionTypes.ADD_CLIENT_START,
    };
  };

  export const addNewClientFailed = (error) => {
    return {
      type: actionTypes.ADD_CLIENT_FAILED,
      error: error
    };
  };

  export const addNewClientSucceed = (client) => {
    return {
      type: actionTypes.ADD_CLIENT_SUCCEED,
      client: client,
    };
  };
  