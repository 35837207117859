// useUpdateOrderStatusMutation.js
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack'; // Correct import for useSnackbar
import dayjs from 'dayjs';
import { updateOrderStatus } from 'api/repair-orders'; // Adjust the path accordingly
import { statusOptions } from 'utils/status-options';

//queryKeys is most of the times 'repairOrders' or the open order
const useRepairStatusUpdate = (queryKeys) => {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation(updateOrderStatus, {
        onMutate: async ({ repairOrderId, status }) => {
            // console.log("QeryKeys in useRepairStatusUpdate", queryKeys);
            let nextStatus = status;

            // Cancel any outgoing refetches
            await queryClient.cancelQueries({ queryKey: queryKeys, exact: false });

            // Snapshot the previous value
            const previousOrders = queryClient
                .getQueriesData({ queryKey: queryKeys, exact: false })
                .filter(([queryKey, oldData]) => oldData !== undefined);

            // Optimistically update all matching queries
            previousOrders.forEach(([queryKey, oldData]) => {
                if (!oldData) {
                    console.warn(`No data for queryKey: ${queryKey}`);
                    return;
                }

                let newData;
                if (Array.isArray(oldData)) {
                    newData = oldData.map((o) => {
                        if (o.id === repairOrderId) {
                            if (nextStatus && !nextStatus.requiresResolution && o.resolutionTypeId) {
                                return {
                                    ...o,
                                    repairOrderStatus: status,
                                    repairOrderStatusId: status.id,
                                    updatedAt: dayjs().toISOString(),
                                    isChangingStatus: true,
                                    resolutionTypeId: null,
                                    resolutionType: null,
                                    resolutionAt: null,
                                };
                            }
                            return {
                                ...o,
                                repairOrderStatus: status,
                                repairOrderStatusId: status.id,
                                updatedAt: dayjs().toISOString(),
                                isChangingStatus: true,
                            };
                        } else {
                            return o;
                        }
                    });
                } else {
                    // oldData is a single object
                    if (oldData.id === repairOrderId) {
                        newData = {
                            ...oldData,
                            repairOrderStatus: status,
                            repairOrderStatusId: status.id,
                            updatedAt: dayjs().toISOString(),
                            isChangingStatus: true,
                        };
                        if (nextStatus && !nextStatus.requiresResolution && oldData.resolutionTypeId) {
                            newData.resolutionTypeId = null;
                            newData.resolutionType = null;
                            newData.resolutionAt = null;
                        }
                    } else {
                        newData = oldData;
                    }
                }

                queryClient.setQueryData(queryKey, newData);
            });

            // Return context with the previous orders for rollback
            return { previousOrders };
        },
        onSuccess: (data, variables) => {
            const updatedOrder = data;

            const queries = queryClient
                .getQueriesData({ queryKey: queryKeys, exact: false })
                .filter(([queryKey, oldData]) => oldData !== undefined);

            queries.forEach(([queryKey, oldData]) => {
                if (!oldData) {
                    console.warn(`No data for queryKey: ${queryKey}`);
                    return;
                }

                let newData;
                if (Array.isArray(oldData)) {
                    newData = oldData.map((o) =>
                        o.id === variables.repairOrderId
                            ? {
                                  ...o,
                                  ...updatedOrder,
                              }
                            : o
                    );
                } else {
                    // oldData is a single object
                    if (oldData.id === variables.repairOrderId) {
                        newData = {
                            ...oldData,
                            ...updatedOrder,
                        };
                    } else {
                        newData = oldData;
                    }
                }

                queryClient.setQueryData(queryKey, newData);
            });

            enqueueSnackbar(`Status updated`, { variant: 'success' });
        },
        onError: (err, variables, context) => {
            context.previousOrders.forEach(([queryKey, oldData]) => {
                queryClient.setQueryData(queryKey, oldData);
            });

            console.error(err?.response?.data?.message || 'Error updating status');
            enqueueSnackbar(err?.response?.data?.message || "Failed to update status", { variant: 'error' });
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys, exact: false });
        },
    });
};

export default useRepairStatusUpdate;

