import React, { useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/system';
import { Add, Close, CloudUploadOutlined } from '@mui/icons-material';
import { CircularProgress, IconButton } from '@mui/material';
import PropTypes from 'prop-types';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const ImageUploadButton = ({ imageUrl, onImageSelected, onImageRemoved, isLoading, ImageComponent, NoImageComponent, imageStyle, noImageStyle, noImageText, className }) => {
    const [isDragOver, setIsDragOver] = useState(false);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        onImageSelected(file);

    };
    const handleRemoveImage = (e) => {
        e.preventDefault();
        onImageRemoved();
    };


    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragOver(true);
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragOver(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragOver(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleFileChange({ target: { files: e.dataTransfer.files } });
            e.dataTransfer.clearData();
        }
        setIsDragOver(false);
    };


    const isValidUrl = (string) => {
        if (string === null) return true;
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    };

    if (!isValidUrl(imageUrl)) {
        console.log('Invalid URL provided for imageUrl prop');
        return null;
    }
    const NoImage = NoImageComponent || (() => (
        <label htmlFor="file-upload">
            <Box
                component="div"
                sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid',
                    borderRadius: '5px',
                    p: '30px',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease',
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    },
                    '&:active': {
                        backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    },
                    ...noImageStyle,
                }}
                onDragOver={handleDragOver}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                {isDragOver ? <CloudUploadOutlined /> : <>{noImageText}<Add /></>}
            </Box>
            <VisuallyHiddenInput
                id="file-upload"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
            />
        </label>
    ));

    const Image = ImageComponent || (({ src }) => (
        <Box position="relative" sx={{ overflow: 'visible' }}>
            <img
                src={imageUrl}
                alt="Selected"
                style={{
                    width: '80px',
                    height: '80px',
                    borderRadius: '5px',
                    border: '1px solid',
                    borderColor: 'gray',
                    objectFit: 'scale-down',
                    ...imageStyle,
                }}
            />
            <IconButton
                size="small"
                sx={{
                    position: 'absolute',
                    top: -10,
                    right: -10,
                    backgroundColor: 'rgba(200,200,200,1)',
                    border: "2px solid white",
                    transition: 'background-color 0.3s ease',
                    '&:hover': {
                        backgroundColor: 'rgba(150, 150, 150, 1)',
                    },
                    '&:active': {
                        backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    },
                }}
                onClick={handleRemoveImage}
            >
                <Close sx={{
                    color: 'white', fontSize: '20px',
                }} />
            </IconButton>
        </Box>
    ));

    if (isLoading) {
        return <Box
            component="div"
            sx={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid',
                borderRadius: '5px',
                p: '20px',
                cursor: 'pointer',
                transition: 'background-color 0.3s ease',
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                },
                '&:active': {
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                },
            }}

        >
            {/* Progress */}
            <CircularProgress sx={{ maxWidth: '38px', maxHeight: '38px' }} />

        </Box>

    }
    return <div className={className}>
        {imageUrl && <Image src={imageUrl} />}
        {!imageUrl && <NoImage />}
    </div>;

};


export default ImageUploadButton;