export const statusOptions = [
    {
        id: 1, // Category ID
        name: "New",
        positionIndex: 0,
        items: [
            {
                id: 1,
                name: "In Queue",
                color: '#1FADCB',
                requiresResolution: false,
                positionIndex: 1,
                repairOrderStatusCategoryId: 1
            },
        ],
    },
    {
        id: 2,
        name: "Waiting",
        positionIndex: 1,
        items: [
            {
                id: 4,
                name: "On Hold",
                color: '#9099A3',
                requiresResolution: false,
                positionIndex: 1
            },
            {
                id: 3,
                name: "Notify Customer",
                color: '#525964',
                requiresResolution: false,
                positionIndex: 2,
                repairOrderStatusCategoryId: 2
            },
        ],
    },
    {
        id: 3,
        name: "Parts",
        positionIndex: 2,
        items: [
            {
                id: 13,
                name: "Order Parts",
                color: '#FEB954',
                requiresResolution: false,
                positionIndex: 1,
                repairOrderStatusCategoryId: 3
            },
            {
                id: 6,
                name: "Waiting For Parts",
                color: '#F59606',
                requiresResolution: false,
                positionIndex: 2,
                repairOrderStatusCategoryId: 3
            },
        ],
    },
    {
        id: 4,
        name: "In Progress",
        positionIndex: 3,
        items: [
            {
                id: 2,
                name: "Diagnose",
                color: '#61DEAA',
                requiresResolution: false,
                positionIndex: 1,
                repairOrderStatusCategoryId: 4
            },
            {
                id: 8,
                name: "Quality Assurance",
                color: '#05BB6F',
                requiresResolution: false,
                positionIndex: 2,
                repairOrderStatusCategoryId: 4
            },
            {
                id: 5,
                name: "In Repair",
                color: '#009758',
                requiresResolution: false,
                positionIndex: 3,
                repairOrderStatusCategoryId: 4
            },
        ],
    },
    {
        id: 5,
        name: "Done",
        positionIndex: 4,
        items: [
            {
                id: 9,
                name: "Ready For Pick Up",
                color: '#6099EE',
                requiresResolution: true,
                positionIndex: 1,
                repairOrderStatusCategoryId: 5
            },
            {
                id: 7,
                name: "In Delivery",
                color: '#1B6DE6',
                requiresResolution: true,
                positionIndex: 2,
                repairOrderStatusCategoryId: 5
            },
            {
                id: 12,
                name: "Delivered",
                color: '#084FB8',
                requiresResolution: true,
                positionIndex: 3,
                repairOrderStatusCategoryId: 5
            },
            // {
            //     id: 14,
            //     name: "Received From Partner",
            //     color: '#2979FF',
            //     requiresResolution: true,
            //     positionIndex: 4
            //     repairOrderStatusCategoryId: 5
            // },
        ],
    },
];

export function requiresResolution(statusId) {
    for (const category of statusOptions) {
        for (const item of category.items) {
            if (item.id === statusId) {
                return item.requiresResolution;
            }
        }
    }
    return false;
}

export function getStatusById(statusId) {
    for (const category of statusOptions) {
        for (const item of category.items) {
            if (item.id === statusId) {
                return item;
            }
        }
    }
    return null;
}