// useUpdateOrderStatusMutation.js
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack'; // Correct import for useSnackbar
import dayjs from 'dayjs';
import { bulkUpdateStatus } from 'api/repair-orders'; // Adjust the path accordingly
import { statusOptions, getStatusById } from 'utils/status-options';

const useBulkStatusUpdate = () => {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar(); // Use the useSnackbar hook to get enqueueSnackbar

    return useMutation(bulkUpdateStatus, {
        onMutate: async ({ repairOrderIds, status, resolution }) => {
            let nextStatus = status;
            // Cancel any outgoing refetches
            await queryClient.cancelQueries({ queryKey: ['repairOrders'], exact: false });

            // Snapshot the previous value
            const previousOrders = queryClient
                .getQueriesData({ queryKey: ['repairOrders'], exact: false })
                .filter(([queryKey, oldData]) => oldData !== undefined);

            // Optimistically update all matching queries
            previousOrders.forEach(([queryKey, oldData]) => {
                if (!oldData) {
                    console.warn(`No data for queryKey: ${queryKey}`);
                    return;
                }

                const newData = oldData.map((o) => {
                    if (repairOrderIds.includes(o.id)) {
                        if (nextStatus && !nextStatus.requiresResolution && o.resolutionTypeId) {
                            return {
                                ...o,
                                repairOrderStatus: status,
                                repairOrderStatusId: status.id,
                                updatedAt: dayjs().toISOString(),
                                isChangingStatus: true, // Add the pending property
                                resolutionTypeId: null,
                                resolutionType: null,
                                resolutionAt: null,
                            };
                        }
                        else if (nextStatus && nextStatus.requiresResolution && !o.resolutionTypeId) {
                            return {
                                ...o,
                                repairOrderStatus: status,
                                repairOrderStatusId: status.id,
                                resolutionTypeId: resolution.id,
                                resolutionType: { id: resolution.id, name: resolution.name },
                                // createdAt: "2021-10-27T11:22:04.000Z"
                                updatedAt: dayjs().toISOString(),
                                isChangingStatus: true, // Add the pending property

                            };
                        }
                        return {
                            ...o,
                            repairOrderStatus: status,
                            repairOrderStatusId: status.id,
                            updatedAt: dayjs().toISOString(),
                            isChangingStatus: true, // Add the pending property
                        };
                    } else {
                        return o;
                    }
                });

                queryClient.setQueryData(queryKey, newData);
            });

            // Return context with the previous orders for rollback
            return { previousOrders };
        },
        onSuccess: (data, variables) => {
            // Update all matching queries with the new data
            const updatedOrders = data; // Assuming 'data' is an array of updated orders

            const queries = queryClient
                .getQueriesData({ queryKey: ['repairOrders'], exact: false })
                .filter(([_, oldData]) => oldData !== undefined);

            queries.forEach(([queryKey, oldData]) => {
                if (!oldData) {
                    console.warn(`No data for queryKey: ${queryKey}`);
                    return;
                }


                const newData = oldData.map((order) => {
                    const updatedOrder = updatedOrders.find((uo) => uo.id === order.id);
                    if (updatedOrder) {
                        return {
                            ...order,
                            repairOrderStatusId: updatedOrder.repairOrderStatusId,
                            repairOrderStatus: updatedOrder.repairOrderStatus,
                            resolutionTypeId: updatedOrder.resolutionTypeId,
                            resolutionType: updatedOrder.resolutionType,
                            isChangingStatus: false,
                        };
                    }
                    return order;
                });

                queryClient.setQueryData(queryKey, newData);
            });

            enqueueSnackbar(`Repairs updated successfully`, { variant: 'success' });
        },
        onError: (err, variables, context) => {
            // Rollback to previous orders
            context.previousOrders.forEach(([queryKey, oldData]) => {
                queryClient.setQueryData(queryKey, oldData);
            });

            
            console.error(err?.response?.data?.message || 'Error updating status');
            enqueueSnackbar(err?.response?.data?.message || `Failed to bulk update repairs`, { variant: 'error' });
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['repairOrders'], exact: false });
        },
    });
};

export default useBulkStatusUpdate;
