import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Typography, Paper, Tooltip, Stack, Checkbox, TableSortLabel,
    Snackbar, Box, Chip, IconButton, Dialog, TextField, DialogTitle, DialogContent, DialogActions, Button, InputAdornment, ToggleButton, FormControl, InputLabel, Select, MenuItem, FormControlLabel
} from '@mui/material'
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { tableCellClasses } from '@mui/material/TableCell';
import { Trans, useTranslation } from 'react-i18next';
import { formatCurrency, getCurrencySymbol } from '../../utils/currency';
import { useTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from "react-redux";
import { AddLinkOutlined, ControlPointDuplicateOutlined, DeleteOutline, DevicesOutlined, Edit, LinkOutlined, MobileFriendlyOutlined, MobileOffOutlined, NavigateNext, PriceChangeOutlined, WarningTwoTone } from '@mui/icons-material';
import DebouncedTextField from '../../common/DebouncedTextField';
import { useSnackbar } from 'notistack';
import { set } from 'lodash';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import axios from "../../axios-config";
import DialogComponent from '../../common/DialogComponent';
import { LoadingButton } from '@mui/lab';


const DuplicateDialogWarning = ({ dialogOpen, handleDialogClose, selectedServices, setSelectedServices, onDelete, services, setServices }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [isProcessing, setIsProcessing] = useState(false);
    const handleAccept = async () => {
        try {
            setIsProcessing(true);
            const response = await axios.put(`/service-catalogs/duplicate`, { serviceIds: selectedServices });
            setIsProcessing(false);
            if (response.status === 200) {
                const newServices = response.data;

                setServices([...services, ...newServices]);
                const newSelection = newServices.map(service => service.id);
                setSelectedServices(newSelection);
                handleDialogClose();
                enqueueSnackbar(t('Services duplicated successfully'), { variant: "success" });
            }
        } catch (err) {
            console.log(err)
            setIsProcessing(false);
            enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        }
    }



    return <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth maxWidth={'xs'}>
        <DialogTitle variant='h4'>
            {t("Duplicate services")}
        </DialogTitle>
        <DialogContent>
            <Stack backgroundColor={"lightBlue.main"} p={2} spacing={1} borderRadius={"10px"}>
                <Stack direction={"row"} spacing={1} alignItems={'center'}>
                    {/* <Typography variant='h6' color='textSecondary'>{selectedServices.length} {selectedServices.length == 1 ? 'service' : 'services'} selected</Typography> */}
                    <WarningTwoTone sx={{ color: 'blue.main' }} />
                    <Typography variant='body' fontWeight={500}>{t("Warning!")}</Typography>

                </Stack>
                <Typography variant='body' color='textSecondary'>
                    <Trans i18nKey="duplicateServices" count={selectedServices.length}>
                        Are you sure you want to duplicate <strong>{{ count: selectedServices.length }} services</strong>?
                    </Trans>
                </Typography>            </Stack>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleDialogClose} color='black'>{t("Cancel")}</Button>
            <LoadingButton loading={isProcessing} onClick={handleAccept} loadingPosition="end" variant="contained" color={'primary'} endIcon={<NavigateNext />} >
                {t("Duplicate")}
            </LoadingButton>
        </DialogActions>
    </Dialog>
}

export default DuplicateDialogWarning;