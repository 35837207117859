import { useState, useEffect } from "react";
import {
    DialogContent, DialogContentText, DialogActions, Stack, ToggleButtonGroup, FormControl, MenuItem, Button,
    TextField, InputLabel, Select, FormControlLabel, Checkbox, InputAdornment,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { processService } from '../../../utils/service-helpers';
import axios from "../../../axios-config";
import { useSnackbar } from "notistack";

import ColoredToggleButton from "../../../common/ColoredToggleButton";
import { getCurrencySymbol } from "../../../utils/currency";
import { LoadingButton } from "@mui/lab";

const NewPaymentTab = ({ repairOrder, services, repairPayments, handleAddServiceClose, onPaymentAdded, isHandlingRequest, setIsHandlingRequest }) => {

    const initialPaymentState = { amount: '', method: 0, isDeposit: false, paymentType: 'in' }
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const currency = useSelector((state) => state.auth.currency);
    const [payment, setPayment] = useState({ ...initialPaymentState });

    useEffect(() => {
        const acceptedAndRefundedServices = services.filter(service => service.isAccepted || service.refundedServiceId);

        const acceptedAndRefundedServicesProcessed = acceptedAndRefundedServices.reduce((acc, service) => {
            const processed = processService(service)
            return acc += processed.unitPrice * processed.quantity - processed.discount;
        }, 0)

        let totalPayments = 0;
        if (repairPayments) {
            totalPayments = repairPayments.reduce((acc, current) => {
                if (!current.deletedAt) {
                    return acc + parseFloat(current.amount);
                }
            }, 0)
        }
        const leftToBePaid = (acceptedAndRefundedServicesProcessed - totalPayments).toFixed(2);
        let paymentType = 'in';
        if (leftToBePaid >= 0) {
            paymentType = 'in';
        }
        else {
            paymentType = 'out';
        }
        setPayment(prevState => {
            return { ...prevState, amount: Math.abs(leftToBePaid), paymentType: paymentType }
        });

    }, [])

    const handlePaymentTypeChange = (event, newPaymentType) => {
        setPayment(prevState => {
            return { ...prevState, paymentType: newPaymentType }
        })
    }
    const handleDepositCheck = (e) => {
        setPayment(prevState => {
            return { ...prevState, isDeposit: e.target.checked }
        })
    }
    const handlePaymenntChange = (prop) => (event) => {
        setPayment(prevState => {
            return { ...prevState, [prop]: event.target.value }
        });
    }

    const handlePaymentAmountChange = (e) => {
        let inputValue = e.target.value;

        // Remove non-digit and non-decimal point characters
        inputValue = inputValue.replace(/[^0-9.]/g, '');

        // Remove extra decimal points if any
        let decimalPointIndex = inputValue.indexOf('.');
        if (decimalPointIndex !== -1) {
            inputValue = inputValue.substr(0, decimalPointIndex + 1) +
                inputValue.slice(decimalPointIndex).replace('.', '');
        }

        setPayment(prevState => {
            return { ...prevState, amount: inputValue }
        });


    }

    const handleAddNewPayment = async () => {
        try {
            setIsHandlingRequest(true);
            const data = { ...payment };
            const response = await axios.post(`/repair-orders/${repairOrder.id}/payment`, data);

            if (response.status == 200 || response.status == 201) {
                enqueueSnackbar(`Payment added!`, { variant: "success" });
                handleAddServiceClose();
                onPaymentAdded(response.data);
                setPayment(prevState => {
                    return { ...prevState, isDeposit: false }
                })
            }
        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        }
        finally {
            setIsHandlingRequest(false);
        }
    }

    return <>
        <DialogContent>
            <DialogContentText>{t('Add a payment received for this order')}.</DialogContentText>
            <Stack direction={'row'} spacing={2} pt={3}>
                <ToggleButtonGroup
                    color="primary"
                    exclusive
                    value={payment.paymentType}
                    onChange={handlePaymentTypeChange}

                >
                    <ColoredToggleButton color="green" value={'in'}>Pay in</ColoredToggleButton>
                    <ColoredToggleButton color="red" value={'out'}>Pay out </ColoredToggleButton>
                </ToggleButtonGroup>
            </Stack>
            <Stack direction={'row'} spacing={2} pt={3}>
                <FormControl fullWidth>
                    {/* <InputLabel id="Amount">Amount</InputLabel> */}
                    <TextField
                        id="payment-amount"
                        label={t("Amount")}
                        variant="filled"
                        value={payment.amount}
                        onChange={handlePaymentAmountChange}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>,
                        }}
                    />
                </FormControl>
                <FormControl fullWidth variant={'filled'} >
                    <InputLabel >{t('Payment method')}</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        label="Payment method"
                        value={payment.method}
                        onChange={handlePaymenntChange('method')}
                    >
                        <MenuItem value={0}>{t('Cash')}</MenuItem>
                        <MenuItem value={1}>{t('Card')}</MenuItem>
                        <MenuItem value={2}>{t('Transfer')}</MenuItem>
                    </Select>
                </FormControl>
            </Stack>
            {payment.paymentType == 'in' ? <FormControlLabel control={<Checkbox checked={payment.isDeposit} onChange={handleDepositCheck} />} label={t("Deposit")} /> : null}
        </DialogContent>
        <DialogActions >
            <Button onClick={handleAddServiceClose}>{t('Cancel')}</Button>
            <LoadingButton loading={isHandlingRequest} onClick={handleAddNewPayment} disabled={payment.amount == 0}>{t('Add')}</LoadingButton>
        </DialogActions>
    </>
}

export default NewPaymentTab;
