// utils/colorUtils.js

/**
 * Converts a HEX color string to an RGB object.
 * @param {string} hex - The HEX color string.
 * @returns {{ r: number, g: number, b: number }} The RGB representation.
 */
export const hexToRgb = (hex) => {
    // Remove the hash symbol if present
    hex = hex.replace('#', '');

    // Convert shorthand HEX to full form
    if (hex.length === 3) {
        hex = hex.split('').map((char) => char + char).join('');
    }

    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return { r, g, b };
};

/**
 * Calculates the relative luminance of a color.
 * @param {{ r: number, g: number, b: number }} rgb - The RGB representation.
 * @returns {number} The relative luminance.
 */
export const getLuminance = ({ r, g, b }) => {
    const a = [r, g, b].map((v) => {
        v /= 255;
        return v <= 0.03928
            ? v / 12.92
            : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    // Using the formula for relative luminance
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

/**
 * Determines whether black or white text would be more readable on the given background color.
 * @param {string} hexColor - The background color in HEX format.
 * @returns {string} '#000000' for black or '#FFFFFF' for white.
 */
export const getContrastText = (hexColor) => {
    const rgb = hexToRgb(hexColor);
    const luminance = getLuminance(rgb);
    return luminance > 0.5 ? '#000000' : '#FFFFFF';
};
