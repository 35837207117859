import React, { useState } from "react";
import Notification from "../Notification"
import Avatar from "@mui/material/Avatar";
import StarIcon from "@mui/icons-material/Star";


function UpdateNotificaiton({ notification }) {

    const handleNotificationClick = () => {

    };

    return (
        <Notification
            notification={notification}
            id={notification.id}
            isRead={notification.isRead}
            avatar={
                <Avatar>
                    <StarIcon sx={{ color: "#FFE978" }} />
                </Avatar>}
            title={notification.title}
            body={notification.body}
            handleNotificationClick={handleNotificationClick} />
    );
}

export default UpdateNotificaiton;